// =============================================
// Customer
// =============================================

// General
.account {
    // Title
    .page-title { }
    // Subtitle
    .sub-title { }
    // Content
    .columns {
        margin:     60px auto;
        max-width:  $largeurSite;
        padding:    0 20px;
    }
    
    .ee-banner-text {
        max-width: 660px;
        margin: 0 auto;
    }
    .h3-title {
        border-bottom:  1px solid $c-input-border;
        color:          $c-project2;
        padding:        10px 0;
        text-transform: uppercase;
    }
    // Columns
    .columns {
        font-family: "Montserrat", sans-serif;
    }
    // Sidebar
    .sidebar {
        float:          left;
        padding:        0 10px;
        width:          20%;
        // Navigation
        .block.account-nav {
            background: $c-grey-light;
            padding:    40px 0;
        }
        .content {
            max-width: 165px;
            margin: 0 auto;
            .nav { padding: 0; }
            .nav.item {
                color:  $c-project2;
                cursor: pointer;
                margin: 0 0 20px;
                &:last-child { margin: 0; }
                &:after {
                    content:    "";
                    background: $c-project5;
                    display:    block;
                    height:     4px;
                    margin:     10px 0 0;
                    width:      0px;
                    @include border-radius(5px);
                    @include linear-gradient(to right, $c-project5, $c-project7);
                    @include transition(width 0.5s ease-in-out);
                }
                &:hover:after {
                    width:      45%;
                }
                &.current {
                    &:after {
                        width:      45%;
                        @include linear-gradient(to right, $c-project5, $c-project7);
                    }
                    strong { font-weight:normal; }
                }
                a { color:  $c-project2; }
            }
        }
    }
    // Main Column
    .column.main {
        float:          left;
        padding:        0 10px;
        width:          80%;
    }
    //Edit link
    .action.edit {
        border-bottom: 1px solid transparent;
        color: $c-project5;
        @include transition(all 0.3s ease-in);
        &:hover {
            border-bottom: 1px solid $c-project5;
        }
    }
    // Name prefix
    .name-prefix {
        font-size:      1.4rem;
        text-transform: uppercase;
        .required {
            margin-right: 20px;
            position:     relative;
            top:          2px;
        }
        .control {
            display: inline-block;

        }
    }
    // Address

    address {
        font-style: normal;
    }
    .wide > div,
    .widget-name {
        margin: 0 0 15px;
        &:last-child { margin: 0; }
    }
    .back-link {
        float: left;
        + .button { float: right; }
    }

    .block + .block {
        margin-top: 30px;

        & + .actions-toolbar .primary {
            margin-bottom: 20px;
        }
    }
    .block-content {
        .box + .box {
            margin-top: 20px;
        }
        .box-content {
            margin-bottom: 10px;
        }
    }
}

// Create Account
.customer-account-create,
.block-new-customer {
    .page-main {
        margin: 50px auto;
        max-width: 1480px;
        min-height: 500px;
    }
    .block-new-customer {
        float: none;
        margin: 0 auto;
    }
    .create-account-button {
        margin: 40px 0 0;
    }
    .title-customer-create {
        font-size:  1.8rem;
        margin:     0 0 20px 0;
    }
    form {
        margin: 50px 0;
        ul li { margin: 0; }

    }
    .input-box { margin-bottom: 15px; }
    .buttons-set { margin-top: 50px; }
    // Name prefix
    .name-prefix {
        font-size:      1.4rem;
        text-transform: uppercase;
        .required {
            margin-right: 20px;
            position:     relative;
            top:          2px;
        }
    }
}
// Login page
.customer-account-login {
    .ee-banner {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 0;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
    .ee-banner-content { height: 650px;}

    .ee-infos-link .packages-link,
    .ee-infos-link .packages{
        display: none;
    }

    //Transition VueJS
    .accountForm-transition {
        transition: all .3s ease;
        overflow: hidden;
    }
    .accountForm-enter, .accountForm-leave {
        height: 0;
        opacity: 0;
    }

    .ee-banner-text {
        max-width: 660px;
        margin: 0 auto;
    }
    .h3-title {
        border-bottom:  1px solid $c-input-border;
        color:          $c-project2;
        padding:        10px 0;
        text-transform: uppercase;
    }
    .login-container {
        max-width:  1140px;
        margin:     50px auto;
        .block {
            padding:    0 20px;
        }
    }
    .block-customer-login {
        margin: 0 0 60px;
        .action.remind { text-decoration: underline; }
    }
    .registered-users {
        padding:      0 10% 0 0;
        border-right: 1px solid $c-input-border;
        .buttons-set { text-align: right; }
        .link {
            display:         block;
            font-size:       1.3rem;
            text-transform:  uppercase;
            text-decoration: underline;
        }
        button {
            display: inline-block;
            margin:  15px 0 0;
        }
    }
    .new-users {
        padding:   0 0 0 10%;
        font-size: 1.4rem;
        button { width: 100%; }
    }

    .footer-top-col:has(.account_conainer) {
        display: none;
    }
}
// Forget Password
.customer-account-createpassword,
.customer-account-forgotpassword {
    .page-main {
        margin: 50px auto;
        max-width: 580px;
        min-height: 500px;
        .button { width: 100%; }
    }
    .primary { margin: 0 0 20px; }
    .back { text-decoration: underline; }
}

// Dashboard
.block-dashboard-order {
    margin:     0 0 60px;
    .data.table {
        td.status { color: $c-project5; }

        .toolbar-number { display: none; }
    }
}
.block-dashboard-info, .block-dashboard-addresses {
    margin: 0 0 60px;
    .box-content {
        margin: 20px 0;
    }
}
.box-newsletter {
    .box-content {
        font-family:    $open;
    }
}
.box-newsletter,
.box-shipping-address {
    padding: 0 35px 0 0;
}

.box-information,
.box-billing-address {
    padding: 0;
}



.block-account {
    background: $c-grey-light;
    padding:    50px 50px 42px 20px;
    li {
        line-height: 30px;
    }
    .block-title {
        margin: 0 0 30px 20px;
    }
    .block-content {
        position: relative;
        li {
            padding:     0 0 0 20px;
            border-left: 5px solid transparent;
            font-size:   1.4rem;
            font-weight: 500;
            &.current {
                border-left: 5px solid $c-project1;
            }
            &.last { margin-top: 30px; }
        }
    }
}
.box-account {
    .button { max-width: 300px; }
}
.dashboard,
.my-account-container {
    .item-informations {
        font:   500 1.4rem;
        margin: 0 0 8px;
        span { color: #a6a6a6; }
    }
    .box-content { line-height: 20px; }
}

// Address
.customer-address-index {
    .block-addresses-default {
        .block-title {
            display: none;
        }
    }
    .actions-toolbar {
        max-width: 250px;
    }
}

// Edit Address
.customer-address-form {
    .form-address-edit {
        font-size: 0;
        > .fieldset {
            font-size: 1.3rem;
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding: 0 20px;
            &:first-child {
                padding: 0 20px 0 0;
            }
            select { width: 100%; }
        }
        .fieldset-fullname {
            margin: 0 0 20px;
        }
    }
    .form-list {
        margin: 0 0 50px;
    }
    .fields {
        .field {
            margin: 0 0 15px 0;
            &:last-child {
                margin: 0;
            }
        }
    }
    .buttons-set .button {
        margin: 20px auto 0;
    }
    .back-link {
        position: absolute;
        top:      0;
        right:    0;
        .button {
            display: inline;
            border:  none;
            &:hover {
                background: none;
                color:      $c-grey;
            }
        }
    }
}
// Wishlist
.wishlist-index-index { }
// Share Wishlist
.wishlist-index-share { }
// Sales Order
.sales-order-view {
    .h3-title span {
        color: $c-project5;
        font-weight:    bold;
    }
    .button {
        width: 235px;
        margin: 40px 0 0;
    }
    .order-details-items {
        margin: 0 0 50px;
        .grand_total {
            font-size: 1.8rem;
            th { color: $c-black; }
        }
    }
    .block-order-details-view {
        font-size: 0;
        .payment-method { margin: 0; }
        .box-title {
            display: block;
            font-size: 13px;
            margin: 0 0 5px;
            text-transform: uppercase;
        }
    }
    .box-order-shipping-address,
    .box-order-shipping-method,
    .box-order-billing-address,
    .box-order-billing-method {
        display: inline-block;
        vertical-align: top;
        font-size: 1.4rem;
        padding:    0 20px 0 0;
        width:      25%;
        &:last-child {
            padding: 0;
        }
    }
}
.sales-order-history { }
// Account Edit
.customer-account-edit {
    .form-list {
        li {
            margin: 0 0 30px;
        }
    }
    .form-edit-account {
        > .fieldset > .field {
            width: 50%;
            select { width: 100%; }
        }
    }
}
// Logout Page
.customer-account-logoutsuccess {
    .col-main {
        padding:    100px 50px;
        text-align: center;
    }
    .logout-content {
        font-size:  1.6rem;
    }
}
//
// Responsive
//
@media (min-width: $large) {
    .customer-account {
        .col-main { width: 66.66667%; }
        .sidebar,
        .col-main { left: 8.33333%; }
    }
}
@include respond-to(medium) {
    .account {
        .column.main {
            width:   70%;
        }
        .sidebar {
            width:   30%;
        }
        .sidebar-menu {
            cursor:         pointer;
            display:        block;
            padding:        15px 20px;
            text-transform: uppercase;
            &:after {
                content: "";
                top:     15px;
                right:   15px;
                @include triangle(down, 10px, $c-grey);
                @include transition(all 250ms ease-in-out);
            }
            &.active {
                &:after {
                    @include triangle(up, 10px, $c-grey);
                }
            }
        }
    }
    .sales-order-view {
        .box-order-shipping-address,
        .box-order-billing-address {
            margin: 0 0 50px;
            width:  50%;
        }
        .box-order-shipping-method,
        .box-order-billing-method {
            padding:    0;
            width:      50%;
        }
    }
}
@include respond-to(small) {
    .account {
        .column.main {
            float:      none;
            padding:    0;
            width:      100%;
        }
        .sidebar {
            float:      none;
            margin:     0 0 50px;
            padding:    0;
            width:      100%;
            .block.account-nav {
                text-align: center;
            }
            .content .nav.item {
                &.current:after {
                    width: 100%;
                }
                &:hover:after {
                    width: 100%;
                }
            }
        }
        .block-dashboard-addresses,
        .block-dashboard-info,
        .block-dashboard-order,
        .box-newsletter,
        .box-shipping-address {
            margin: 0 0 40px;
        }
        .box-newsletter,
        .box-shipping-address {
            padding: 0;
        }
        .action.edit {
            padding: 0 10px;
            &:first-child {
                padding-left:0;
            }
        }
    }
    .account-login {
        .registered-users,
        .new-users {
            border:  none;
            padding: 0;
            margin:  0 0 40px;
            width:   100%;
            .button { width: 100%; }
        }
    }
    .orders-history {
        overflow: auto;
    }
    .sales-order-view {
        .order-items { overflow: auto; }
        .button { margin: 40px auto 0; }
    }
    .customer-account-login {
        .ee-banner {
            border-radius: 24px;
        }
    }
}
@include respond-to(600px) {
    .sales-order-view {
        .box-order-shipping-address,
        .box-order-billing-address,
        .box-order-shipping-method,
        .box-order-billing-method {
            margin:     0 0 50px;
            padding:    0;
            width:      100%;
        }
    }
}