// =============================================
// FAQ page
// =============================================
.soon-faq-index-index {
    .page-faq {
        background: $c-grey-light;
        .h2-title {
            font-size:      2.5rem;
            margin:         0 0 35px;
            text-align:     center;
            text-transform: uppercase;
        }
        .faq-container {
            padding:    0 0 80px;
        }
        .faq-content {
            max-width:  870px;
            margin:     0 auto;
            padding:    0 10px;
        }
        .category {
            background: $c-white;
            @include border-radius(5px);
            display:    table;
            max-width:  1046px;
            margin:     0 auto;
            padding:    0 10px;
            position:   relative;
            text-align: center;
            top:        -40px;
            width:      100%;
        }
        .category-item {
            color:      $c-project2;
            cursor:     pointer;
            display:    table-cell;
            font-family:$montserrat;
            padding:    30px 0 20px;
            &:after {
                content:    "";
                background: $c-project5;
                display:    block;
                height:     4px;
                margin:     10px auto 0;
                width:      0px;
                @include border-radius(5px);
                @include transition(width 0.5s ease-in-out);
            }
            &:hover:after {
                width:      30%;
            }
            &.active {
                &:after {
                    width:      30%;
                }
            }
        }
        .faq-questions-answers {
            @include border-radius(5px);
            background: $c-white;
            border:     1px solid $c-input-border;
            margin:     0 0 15px;
            &.active {
                border: none;
                border-bottom: 1px solid red;
                border-top: 1px solid orange;
                @include border-radius(5px);
                background-size: 1px 100%;
                background-position: 0 0, 100% 0;
                background-repeat: no-repeat;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
                background-image: -webkit-linear-gradient(orange, red), -webkit-linear-gradient(orange, red);
                background-image: -moz-linear-gradient(orange, red), -moz-linear-gradient(orange, red);
                background-image: -o-linear-gradient(orange, red), -o-linear-gradient(orange, red);
                background-image: linear-gradient(orange, red), linear-gradient(orange, red);
                .faq-question:after { @include transform(scaleY(-1)); }
            }
        }
        .faq-question {
            color:          $c-project2;
            cursor:         pointer;
            font-family:    $montserrat;
            font-size:      1.6rem;
            padding:        15px 75px 15px 30px;
            position:       relative;
            &:after {
                background:      url("../images/arrow-down.png");
                background-size: cover;
                content:         "";
                display:         block;
                height:          6px;
                width:           9px;
                position:        absolute;
                right:           30px;
                top:             50%;
                margin-top:      -3px;
                @include transition(all .3s linear);
            }
        }
        .faq-answer {
            padding: 0 75px 0 30px;
            p {
                margin: 15px 0 20px;
            }
        }
    }
    .faq-reinssurance {
        color:      $c-project2;
        max-width:  1046px;
        margin:     0 auto;
        padding:    50px 0 60px;

        .title-blocks {
            color:      $c-project2;
            font-family: $montserrat;
        }
    }
    .any-question, .faq-newsletter {
        float:      left;
        position:   relative;
        width:      50%;
        &:after {
            content:    "";
            display:    block;
            width:      100px;
            height:     80px;
            position:   absolute;
            top:        10px;
        }
    }
    .any-question {
        border-right:   1px solid $c-project2;
        padding:        0 90px 0 100px;
        &:after {
            background: url("../images/phone.png") no-repeat 50% 0;
            left:       0;
        }
    }
    .faq-newsletter {
        padding: 0 20px 0 180px;
        &:after {
            background: url("../images/mail.png") no-repeat 50% 0;
            left:       60px;
        }
        label { padding: 0 0 10px; }
        .field-news {
            max-width:  260px;
        }
        .input-text {
            border: 1px solid $c-project5;
        }
    }
}

//
// Transition VueJS
//
.question-transition,
.listQuestion-transition {
    transition: all .3s ease;
    overflow: hidden;
}
.question-enter, .question-leave,
.listQuestion-enter, .listQuestion-leave {
    height: 0;
    opacity: 0;
}


//
// Responsive
//
@include respond-to(medium) {
    .soon-faq-index-index {
        .faq-newsletter:after {
            left: 30px;
        }
        .faq-newsletter {
            padding: 0 20px 0 150px;
        }
    }
}

@include respond-to(small) {
    .soon-faq-index-index {
        .page-faq {
            .h2-title {
                font-size:      2rem;
                margin:         0 0 35px;
                text-align:     center;
                text-transform: uppercase;
            }
            .question-content {
                padding: 0 0 0 20px;
            }
            .faq-question {
                font-size:  1.4rem;
                &:after {
                    right: 15px;
                }
            }
            .faq-response {
                margin: 0 0 30px 0;
            }
            .category {
                padding:    0 10px;
            }
        }
        .faq-reinssurance {
            padding: 30px 0;
        }
        .any-question, .faq-newsletter {
            float:      none;
            padding:    0 20px 0 110px;
            width:      100%;
            &:after {
                left:       0;
                top:        50%;
                margin-top: -40px;
            }
        }
        .any-question {
            border-right:   none;
            margin:         0 0 60px;
        }
    }
}