// =============================================
// Common
// =============================================
//
// Font Smoothing & Border/Padding Box
//
* {
    outline: none;
    box-sizing: border-box;
    &:active,
    &:focus { outline: none; }
}
//
// Remove Background for autocomplete (only Webkit)
//
*:-webkit-autofill {
    // @use !important due to style for remove other border on autofill
    border-width:   0 0 2px !important;
    border-color:   $c-input-border !important;
    border-style:   solid !important;
    -webkit-box-shadow:      0 0 0 1000px $c-bg-input inset;
    -webkit-text-fill-color: $c-input-text;
    &:hover,
    &:focus {
        // @use !important due to style for remove other border on autofill
        border-width:   0 0 2px !important;
        border-color:   $c-input-border !important;
        border-style:   solid !important;
    }
}
//
// Remove default icon (cross) for input in IE
//
*::-ms-clear { display: none; }
//
// Remove dotted on firefox focus
//
*::-moz-focus-inner {
    border:  0;
    padding: 0;
}
//
// Magento base style
//
.hidden {
    border:      0;
    display:     block;
    font-size:   0;
    height:      0;
    line-height: 0;
    margin:      0;
    overflow:    hidden;
    padding:     0;
    width:       0;
}
.nobr { white-space: nowrap; }
.wrap { white-space: normal; }
.a-left { text-align: left; }
.a-center,
.al-center { text-align: center; }
.a-right { text-align: right; }
.upper { text-transform: uppercase; }
.lower { text-transform: none; }
.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.f-left,
.left { float: left; }
.f-right,
.right { float: right; }
.f-none { float: none; }
.f-fix {
    float: left;
    width: 100%;
}
.no-display { display: none; }
.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-bg { background: none; }
.clear { clear: both; }
.c-black { color: $c-black; }
.c-black-light { color: tint($c-black, 20); }
.c-white { color: white; }
.cur-p { cursor: pointer; }
.d-block { display: block; }
.d-inline { display: inline; }
.ov-h { overflow: hidden; }
.p-r { position: relative; }
.underline {
    text-decoration: underline;
    &:hover { text-decoration: none; }
}
.rotate45 { @include transform(rotate(45deg)); }
.rotate90 { @include transform(rotate(90deg)); }
.rotate180 { @include transform(rotate(180deg)); }
.rotate-45 { @include transform(rotate(-45deg)); }
.rotate-90 { @include transform(rotate(-90deg)); }
.rotate-180 { @include transform(rotate(-180deg)); }
//
// Base Markup
//
a,
.link {
    @include transition(all 150ms linear);
    color:           $c-link;
    text-decoration: none;
    &:hover,
    &.active {
        color: $c-link-hover;
    }
}
address { }
b { }
code { }
del { text-decoration: line-through; }
i { font-style: italic; }
img {
    max-width: 100%;
    height: auto;
}
p { }
sub { }
sup { }
strong,
.strong { font-weight: bold; }

//
// List
//
ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
    li { }
}
//
// Definition List
//
dl {
    dt { }
    dd { }
}
//
// Data table
//
.dp-table {
    // Use this class to put a dom element in display table
    display: table;
    width:   100%;
}
.data.table {
    width: $w-full;
    th,
    td { }
    thead {
        th {
            background-color: $c-grey-light;
            padding: 15px 20px;
            text-align: left;
            &.last {  }
        }
    }
    tbody {
        tr {
            &.first { }
            &.last { }
        }
        td {
            border-bottom: 1px solid $c-project4;
            padding: 15px 20px;
            a { }
            &.first { }
            &.last { }
        }
    }
    tfoot {
        tr {
            &.first { }
            &.last { }
        }
        td {
            border-bottom: 1px solid $c-project4;
            padding: 15px 20px;
            a { }
            &.first { }
            &.last { }
        }
    }
    th,
    tr,
    td { vertical-align: middle; }
    .odd { }
}
//
// Project Common specific
//
html {
    font-size: 62.5%; // Value to use rem (ex 1.1rem = 11px)
}
body {
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:             $base-font;
    color:                   $c-project2;
    font-size:               1.4rem;
    font-weight:             normal;
    padding: 0;
    margin: 0;
}
//
// Titles Styles
//
// Reset margin Hn
h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: normal;
}
// End reset
.h1-title {
    font-size:   4.0rem;
    margin:      0 0 15px 0;
    font-family: $roboto;
}
.h2-title {
    font-size:   2rem;
    margin:      0 0 15px 0;
    font-family: $roboto;
    color:       $c-project2;
    span{
        color: $c-project5;
    }
}
.h3-title {
    font-size:   1.6rem;
    margin:      0 0 15px 0;
    font-family: $roboto;
    color:       $c-project2;
}
.h4-title {
    font-size:   1.4rem;
    margin:      0 0 15px 0;
    font-family: $roboto;
}
.light-title{
    font-size: 1.6rem;
    margin: 0 0 15px 0;
    color: tint($c-project3, 30%);
}
.title-uppercase {
    text-transform: uppercase;
    margin:         0 0 15px 0;
}
.title-blocks {
    font-size:   1.8rem;
    margin:      0 0 15px 0;
}

.border-gradient {
    @include linear-gradient(to right, red, orange);
}

//
// Overflows
//
.ov-x{
    overflow-x: auto;
}
.ov-y{
    overflow-y: auto;
}

//
// Magento Clears
//
.clearer:after,
.header-container:after,
.header-container .top-container:after,
.header .quick-access:after,
#nav:after,
.main:after,
.footer:after,
.footer-container .bottom-container:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col3-layout .product-options-bottom .price-box:after,
.col4-set:after,
.search-autocomplete li:after,
.block .block-content:after,
.block .actions:after,
.block li.item:after,
.block-poll li:after,
.block-layered-nav .currently li:after,
.page-title:after,
.products-grid:after,
.products-list li.item:after,
.box-account .box-head:after,
.dashboard .box .box-title:after,
.box-reviews li.item:after,
.box-tags li.item:after,
.pager:after,
.sorter:after,
.ratings:after,
.add-to-box:after,
.add-to-cart:after,
.product-essential:after,
.product-collateral:after,
.product-view .product-img-box .more-views ul:after,
.product-view .box-tags .form-add:after,
.product-view .product-shop .short-description:after,
.product-view .box-description:after,
.product-options .options-list li:after,
.product-options-bottom:after,
.product-review:after,
.cart:after,
.cart-collaterals:after,
.cart .crosssell li.item:after,
.opc .step-title:after,
.checkout-progress:after,
.multiple-checkout .place-order:after,
.group-select li:after,
.form-list li:after,
.form-list .field:after,
.buttons-set:after,
.page-print .print-head:after,
.advanced-search-summary:after,
.gift-messages-form .item:after,
.send-friend .form-list li p:after { display: block; content: "."; clear: both; font-size: 0; line-height: 0; height: 0; overflow: hidden; }

//
// Responsive
//
@include respond-to(medium) {

}
@include respond-to(small) {
    .hide-mobile { display: none; }
    .h1-title {
        font-size:   3rem;

    }
    .h2-title {
        font-size:   2rem;
    }
    .h3-title {
        font-size:   1.4rem;
    }
    .h4-title {
        font-size:   1.2rem;
    }
    .light-title {
        font-size: 1.4rem;
    }
}
