// =============================================
// Display none
// =============================================

/**
* Remove element after Xs 
* (used for logo intro scale is 5) 
* ToDo createde mixin to passed scale in argument
*/ 
@keyframes removeElement {
    50% {
        opacity: 1;
    }
    90% {
        transform: scale(5);
        
    }
    100% {
        display: none !important;
        transform: scale(0);
        opacity: 0;
    }
}

// =============================================
// Draw svg stroke
// =============================================
@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

// Draw svg
@mixin drawSvg ($stroke-dash:1000, $fill-opacity: false)
{
    stroke-dashoffset: $stroke-dash;
    stroke-dasharray: $stroke-dash;

    @if $fill-opacity {
        fill-opacity: 0;
    }
}

@keyframes drawSuccess {
    
    to {
        stroke-dashoffset: 0;
    }
}

// =============================================
// Fill svg fill
// =============================================
@keyframes fill-in {
    to {
        fill-opacity: 1;
    }
}

// =============================================
// Rotate and scale
// =============================================
@keyframes roll {
    0% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(.8);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}
// =============================================
// Wheel Loader 
// =============================================
@keyframes loaderProxiWheel {
    0% {
      transform: rotate(0) scale(.7);
    }
    50% {
      transform: rotate(360deg) scale(1);
    }
    100% {
      transform: rotate(0deg) scale(.7);
    }
}
// =============================================
// Technician Loader
// =============================================
// Draw
@mixin animateDrawLoader ($stroke-dash:1000)
{
    stroke-dashoffset: $stroke-dash;
    stroke-dasharray: $stroke-dash;
    
    @keyframes draw {
        40% { 
            stroke-dashoffset: 0;
        }
        65% { 
            stroke-dashoffset: 0;
        }
    }
    
    animation: draw 2s ease-out infinite;
}

// Letter appear
@keyframes letter-translate {
    0% {
        transform: translateY(150%);
    }
    60% {
        transform: translateY(0%);
    }
    80% { transform: translateY(1%); }
    100% { transform: translateY(0); }
}

// =============================================
// Contract Card appear
// =============================================
@keyframes scaledAndShadow {  
    55% {
        @include transform(scale(1.1));
        box-shadow: 0 2px 51px rgba(0, 0, 0, 0.14);
        z-index: 2;
    }

    100% {
        @include transform(scale(1));
        box-shadow: 0px 20px 30px 0px #0000000D;
    }
}

// =============================================
// Bounce
// =============================================

// Bounce Y
@keyframes bounce-in-up {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    60% {
        opacity: 1;
        transform: translateY(0%);
    }
    80% { transform: translateY(1%); }
    100% { transform: translateY(0); }
}
