// =============================================
// Menu
// =============================================
.nav{
    max-width: 1460px;
    margin: 0 auto;
    padding: 0 20px;
    & > ul{
        display: table;
        width: 100%;
        position: relative;
        & > li{
            display: table-cell;
            text-align: center;
            font-size: 1.6rem;
            // Link Menu
            & > a{
                display: block;
                padding: 20px 5px;
                @include transition(all 150ms linear);
                position: relative;
                cursor: pointer;
            }
            &:hover > a{
                color: $c-black;
            }
            &.active > a{
                font-weight: 600;
                color: $c-black;
            }
            &:hover > .submenu{
                opacity: 1;
                visibility: visible;
                z-index: 9999;
            }
        }
        .over-menu{
            position:absolute;
            width: 13px;
            height: 13px;
            top: -6.5px;
            background: $c-grey-light;
            @include border-radius(50%);
            display: inline-block;
        }
    }
    // Sub menu
    .submenu{
        position: absolute;
        left: 0;
        right: 0;
        background: $c-grey-light;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        @include transition(all 250ms ease-in-out);
        text-align: left;
        li.level1{
            width: 33%;
            display:        inline-block;
            vertical-align: top;
            padding:        0 20px 0 0;
            & > a {
                display:        block;
                margin-bottom:  20px;
                font-size:      1.6rem;
                font-weight:    bold;
            }
            span{
                display: block;
                margin-bottom: 15px;
            }
            li a{
                color: $c-grey;
                display: inline-block;
                padding: 6px 0;
                &:hover{
                    color: $c-black;
                }
            }
            ul.level1 {
                padding: 0 0 50px;
            }
        }

        .custom-attributes-block{
            position: absolute;
            top: 0;
            right: 0;
            width: 33%;
            background: #f2f2f2;
            padding: 40px 25px 25px;
        }
        .menu-subcategory-block{
            max-width:250px;
            &.first{
                margin-bottom: 20px;
                a{
                    display: block;
                    height: 40px;
                    line-height: 38px;
                    text-align: center;
                    border: 1px solid $c-black;
                    color: $c-black;
                    text-transform: uppercase;
                    @include transition(all 250ms ease-in-out);
                    &:hover{
                    }
                }
            }
            &.second{
                position: relative;
                a{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                    color: $c-white;
                    font-size: 3.0rem;
                    padding-top: 35%;
                    text-shadow: 0 0 16px $c-black;
                    @include transition(all 250ms ease-in-out);
                    &:hover{
                        padding-top: 40%;
                        background: rgba($c-white, 0.3);
                    }
                }
            }
        }
    }
    .custom-attributes-block{
        .inspiration-menu-item{
            float: none;
            width: 100%;
            padding: 0;
            max-width: 250px;
            min-height: 350px;
            .item-description{
                p{
                    &.inspiration-menu-title{
                        margin: 5px 0 0;
                    }
                }
            }
        }
    }
}

.bloc-left-submenu{
    padding:    25px 0 20px 5%;
    min-height: 328px;
    width:      60%;
    float:      left;
}
// Push right menu
.block-right-menu-push {
    float:      left;
    padding:    25px 0 20px 0;
    width:      40%;
    .first-push, .second-push {
        float:      left;
        padding:    0 10px;
        position:   relative;
        text-align: center;
        width:      50%;
        a {
            color:          #FFFFFF;
            font-size:      1.8rem;
            font-weight:    bold;
            margin:         0 auto;
            padding-top:    75%;
            width:          70%;
            text-align:     center;
            position:       absolute;
            top:            0;
            bottom:         0;
            left:           0;
            right:          0;
            z-index:        2;
        }
    }
}

// =============================================
// Mobile
// =============================================

// Sticky mobile cta (burger / phone)
.menu-mob,
.phone-mob {
    position: fixed;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 200px;
    box-shadow: 0px 10px 15px rgba(12, 35, 44, 0.15);
    z-index: 100;
}

// Burger Icon
.menu-mob {
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-white;

    label {
        display:flex;
        flex-direction:column;
        width:25px;
        cursor:pointer;

        input[type="checkbox"]{
            display:none;
        }

        span {
            background: $c-main;
            border-radius:10px;
            height:3px;
            margin: 3px 0;
            transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1){
            width:50%;
        }

        span:nth-of-type(2){
            width:100%;
        }


        span:nth-of-type(3){
            width:75%;
        }

        // Input checket
        input[type="checkbox"]:checked {
            ~ span:nth-of-type(1){
                transform-origin:bottom;
                transform:rotatez(45deg) translate(4px,0px)
            }

            ~ span:nth-of-type(2){
                transform-origin:top;
                transform:rotatez(-45deg)
            }

            ~ span:nth-of-type(3){
                transform-origin:bottom;
                width:50%;
                transform: translate(11px,-6px) rotatez(45deg);
            }
        }
    }
}

.menu-mobile {
    display: none;
}

// Phne Icon
.phone-mob {
    top: 130px;
    background-color: $c-main;

    a {
        display: block;

        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            left: 0;
            background-image: url('../images/icons/uEA24-phone_white.svg');
            background-size: 30%;
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }
}

//
// Search Algolia
//
.main-container {
    .footer_algolia {
        display: none;
    }
    #algolia-autocomplete-container .aa-dropdown-menu {
        border: 3px solid $c-black;
        left:   0!important;
    }

}

@media (min-width: 992px){
    .main-container {
        #algolia-autocomplete-container .aa-dropdown-menu {
            width: 100%;
        }
    }
}
//
// Responsive
//
@include respond-to(medium) {
    nav { }
    .main-menu-content .nav-container .submenu {
        right: 20px;
    }
    .bloc-left-submenu{
        width:70%;
    }
    .block-right-menu-push {
        width:30%;
        .first-push, .second-push {
            float:      none;
            width:      100%;
        }
    }
}
@include respond-to(small) {
    //Block body scroll
    body {
        overflow-y: initial;
        position: relative;

        &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            background: $c-grey-1;
            opacity: 0;
        }

        &:has(.menu-mobile.active) {
            overflow-y: hidden;

            &::before {
                opacity: .7;
                z-index: 10;
                height: 100vh;
                transition: opacity .3s .3s linear;
            }
        }
    }

    .bt-menu-mob {
        display:    block;
    }
    .bloc-left-submenu {
        padding:    0 0 0 30px;
        min-height: auto;
    }

    .menu-mobile {
        overflow: scroll;
        position: fixed;
        top: 0;
        left: 0;
        width: 90vw;
        height: 100vh;
        background-color: $c-white;
        z-index: 99;
        transform: translateX(-200%);
        transition: transform .5s linear;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6vh 40px;

        &.active {
            transform: translateX(0);
        }

        .panel-right {
            display: block;
            margin: 40px 0;

            li {
                margin: 35px auto;
            }

            .btn {
                padding: 15px 20px;
                width: 100%;
                font-size: 1.8rem;

                &__white {
                    margin-top: 15px;
                }
            }

            /* ---------------
                Products list
            ----------------- */
            .packages {
                font-size: 1.8rem;
                font-weight: 600;

                &>div {
                    margin-bottom: 30px;
                    text-align: center;
                }

                .push-product-head {
                    padding: 0;
                }
                .push-product-title {
                    display: none;
                }

                .push-product-tabs {
                    margin: 15px 0;
                    padding: 0;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 10px;

                    li {
                        display: flex;
                        width: 45%;
                        flex-direction: column;
                        justify-content: space-between;
                        font-weight: 400;
                        margin: 0;
                    }
                    .tab {
                        &.active {
                            position: static;
                            background-color: $c-white;
                            color: $c-project2;

                            &::after {
                                display: none;
                            }

                            .icon-category {
                                &.ch_gaz {
                                    background: url('../images/icons/uEA19-ch_gaz.svg') center no-repeat;
                                }
                            
                                &.ch_fioul {
                                    background: url('../images/icons/uEA20-ch_fioul.svg') center no-repeat;
                                }
                            
                                &.pac {
                                    background: url('../images/icons/uEA09-pump.svg') center no-repeat;
                                }
                            
                                &.clim {
                                    background: url('../images/icons/uEA12-clim.svg') center no-repeat;
                                }
                            }
                        }
                    }
                }
            }

            .packages-link .btn__round-check::before {
                display: none;
            }

            .infos{
                display: none;
            }

            /* --------------
                sponsorship btn
            ----------------- */
            .sponsorship-link {
                .btn__white {
                    color: $c-main;
                    border-color: $c-main;
                    border-width: 2px;
                }
            }

            /* --------------
                Reviews widget
            ----------------- */
            .reviews-widget {
                width: fit-content;
                margin: 25px auto;
            }

            .btn__account::before {
                left: 80%;
            }


        }

        .ee-list-link {
            display: flex;
            flex-wrap: wrap;
            min-height: 150px;

            li {
                font-size: 1.5rem;
                width: 49%;

                a {
                    display: inline-block;
                    margin: 0 auto;

                    &.current {
                        color: $c-main;
                        border-bottom: 1px solid $c-main;
                    }
                }

            }
        }
    }
}
