// =============================================
// Header
// =============================================

// Home Intro animation Desktop
@media (min-width: $small) {
    // Before Animated
    .cms-home .page-header {
        // Menu bar
        .panel.wrapper {
            overflow-y: hidden;

            .panel.header {
                // Left link
                .ee-list-link {
                    #ee_home-link {
                        opacity: 0;
                        transition: all .6s 1.2s linear;
                    }

                    .link {
                        opacity: 0;
                        transform: translateY(100%);

                        @for $i from 1 through 4 {
                            $timeMultiplyer: calc(#{$i}/2);
            
                            &:nth-child(#{$i}) {
                                transition: all .5s calc( 1s + (0.1s * #{$timeMultiplyer})) cubic-bezier(.47,1.64,.41,.8);
                            }
                            
                        }
                    }
                }
                // Right link
                .ee-infos-link {
                    .panel-right li {
                        opacity: 0;
                        transform: translateX(-20%);
                        @for $i from 1 through 8 {
                            $timeMultiplyer: calc(#{$i}/2);
                            &:nth-child(#{$i}) {
                                transition: all .5s calc(1s + (0.1s * #{$timeMultiplyer})) cubic-bezier(.47,1.64,.41,.8);
                            }
                        }
                    }

                    .reviews-widget {
                        transform: translateY(-100%);
                        transition: transform .3s 1.2s ease-in-out;
                    }
                }
            }

            // Red border bottom
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: $c-project9;
                transform: scaleX(0);
                transition: transform .3s .9s linear;
            }
        }

        // Banner
        .ee-banner {
            opacity: 0;
            transform: translateY(-50px);
            transition: opacity .5s 1.2s ease-in, transform .3s 1.2s ease-in;
        }

        // Logo svg
        .header.content .EE_logo-container{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: $c-white;
            transform: scale(5);

            // Logo
            svg {
                #svgEE-wheel-ee {
                    @include drawSvg(140, true);
                }

                .svgEE-red_letter {
                    @include drawSvg(65, true);
                }

                .svgEE-black_letter {
                    @include drawSvg(60, true);
                }

                #svgEE-by {
                    @include drawSvg(75, true);
                }

                #svgEE-wheel_mcp {
                    opacity: 0;
                    transition: opacity 0.2s ease-out;
                }

                #svgEE-proxiserve {
                    path {
                        opacity: 0;
                        transform: translateX(-5%);
                    }
                }
            }
        }
  
    }

    // After Animated
    .cms-home.animate-in .page-header {

        // Menu bar
        .panel.wrapper {
            z-index: 200;

            &::after {
                transform: scaleX(1);
            }

            .panel.header {
                opacity: 1;
                transform: translateY(0);

                // Left link
                .ee-list-link {
                    #ee_home-link {
                        opacity: 1;
                    }
    
                    .link {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                // Right link
                .ee-infos-link {
                    .panel-right li {
                        opacity: 1;
                        transform: translateX(0);
                    }
                    .reviews-widget {
                        transform: translateY(0);
                    }
                }
            }
        }

        // Banner
        .ee-banner {
            z-index: 150;
            transform: translateY(0);
            opacity: 1;
        }

        // Logo svg
        .header.content .EE_logo-container {
            z-index: 100;
            animation: 2s forwards removeElement;
            -webkit-animation:2s forwards removeElement;
            
            svg {
                //Draw
                #svgEE-wheel-ee,
                .svgEE-black_letter,
                .svgEE-red_letter,
                #svgEE-by {
                    animation: 3s forwards draw, 1s 0.5s forwards fill-in;
                }

                //Mcp Wheel
                #svgEE-wheel_mcp {
                    display: inline-block;
                    position: relative;
                    opacity: 1;
                    transform-origin: 61.5% 80%;
                    animation: roll 1.5s 1;
                }

                // Reveal
                #svgEE-proxiserve {
                    @for $i from 1 through 10 {
                        $timeMultiplyer: calc(#{$i}/ 4);

                        path:nth-child(#{$i}) {
                            opacity: 1;
                            transform: translateX(0);
                            transition: all
                                0.1s
                                calc(0.2s * #{$timeMultiplyer})
                                ease-in-out;
                        }
                    }
                }
            }
        }
    }
}


// Content
.page-header {
    background-color: $c-white;

    .logo {
        img {
            width: auto;
        }
    }

    // Mobile no display
    .header_mobile,
    .ee-banner_background.mobile {
        display: none;
    }

    //
    // Panel (top header)
    //
    .panel {
        &.wrapper {
            position: relative;
            background-color: $c-white;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.09);
        }
        &.header {
            max-width: 1400px;
            margin: 0 auto;
            height: $height-panel;
            display: flex;
            justify-content: space-between;
            align-content: flex-end;
        }

        // Left Links
        .ee-list-link {
            font-family: $roboto;
            display: flex;
            flex-wrap: wrap; // Reset nowrap somewhere
            align-content: flex-end;

            li {
                position: relative;
                height: 90%;
                margin-right: 1rem;
                font-size: 1.4rem;
                font-weight: 500;
                font-family: $roboto;

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: $c-project2;
                    line-height: 36px;
                    padding: 0 15px;
                    text-align: center;
                    position: relative;

                    img,
                    span {
                        display: inline-block;
                    }
                }
            }

            li:not(.ee-list-link-proxi) {
                a {
                    background-color: $c-grey-light;
                    border: 1px solid $c-project4;
                    border-radius: 6px 6px 0 0;
                }

                &.link {
                    &:hover a {
                        color: $c-white;
                        background-color: $c-main;
                        border-color: $c-main;
                    }
                    .current {
                        color: $c-white;
                        background-color: $c-main;
                        border-color: $c-main;
                    }
                }
            }
        }

        // Right Links
        .ee-infos-link {
            display: flex;

            .panel-right {
                display: flex;
                align-items: center;
                margin: 10px 0 0;
                font-family: $roboto;
                font-size: 1.4rem;

                li {
                    padding: 0 0.5rem;

                    @include breakpoint(xl) {
                        padding: 0 1.3rem;
                    }
                }

                li:nth-child(1) {
                    a {
                        color: $c-project2;
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    &:hover a {
                        color: $c-project5;
                    }
                }

                .sponsorship-link {
                    display: none;
                }

                .btn__account {
                    width: 5.5rem;
                    height: 4.2rem;

                    span {
                        display: none;
                    }
                }

                @include breakpoint(only-xl) {
                    .packages {
                        display: none;
                    }
                }

                @media screen and (max-width: $mq-start-large-desktop) {
                    .packages-link {
                        display: none;
                    }
                }
            }

            @media (max-width: $mq-start-desktop) and (min-width: $small) {
                .reviews-widget {
                    display: none;
                }
            }
        }
    }

    // Phone number Link
    .infos {
        display: flex;
        padding-right: 0;
        align-items: center;
        height: fit-content;
        color: $c-project3;
        font-size: 1.4rem;

        .number {
            margin-left: 10px;
            position: relative;
            height: fit-content;
            border: 2px solid $c-project5;
            @include border-radius(50px);

            a {
                display: block;
                padding: 10px 15px;
                font-size: 1.6rem;
                color: $c-project5;

                .phone_number {
                    opacity: 1;
                    transition: opacity 0.3s linear;
                }

                .hover_infos {
                    position: absolute;
                    top: 5px;
                    left: 35px;
                    width: calc(100% - 35px);
                    height: calc(100% - 10px);
                    color: $c-main;
                    font-size: 1.2rem;
                    font-weight: 600;
                    opacity: 0;
                    transition: opacity 0.3s linear;

                    .desc {
                        margin: 0;
                        color: $c-black;
                        font-weight: normal;
                    }
                }

                &:hover {
                    .phone_number {
                        opacity: 0;
                    }

                    .hover_infos {
                        opacity: 1;
                    }
                }
            }
        }
        .icon {
            font-size: 1.6rem;
            vertical-align: middle;
            position: relative;
            top: 0px;
            margin: 0 6px 0 0;
            @include transform(rotate(15deg));
        }
    }

    // Reviews Widget
    .reviews-widget {
        .reviews-widget__container {
            position: relative;
            display: flex;
            align-self: flex-start;
            padding: 12px 15px 12px 0;
            box-shadow: 0px 0px 15px rgba(11, 35, 45, 0.2);
            border-radius: 5px 5px 10px 5px;
            background-color: $c-white;
            max-width: 155px;

            @include breakpoint(lg) {
                margin-left: 20px;
                max-width: none;
            }

            .reviews-widget__logo {
                width: 40px;
                height: 40px;

                img {
                    margin-left: -10px;
                    transition: transform 0.2s ease-in;
                }
            }

            .reviews-widget__rate {
                height: 15px;
                margin-bottom: 5px;

                img {
                    max-height: 100%;
                }
            }

            .reviews-widget__content {
                p {
                    font-size: 1.2rem;
                    margin: 0;
                    font-style: italic;
                }

                span {
                    color: $c-black;
                    font-weight: 700;
                }
            }

            .pleats {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 10px;
                height: 10px;
                background: #d7dfe2;
                border-radius: 0px 0px 10px 0px;
            }

            &:hover {
                .reviews-widget__logo {
                    img {
                        transform: scale(1.1);
                    }
                }

                span {
                    color: $c-main;
                }
            }
        }
    }

    .header.content {
        position: relative;
    }
}

// Responsive
@media only screen and (max-width: 992px) and (min-width: 801px) {
    .page-header {
        // Number btn
        .infos .icon {
            display: none;
        }

        .panel .ee-list-link li a {
            padding: 0 5px;
        }

        .panel .ee-infos-link .panel-right {
            li {
                padding: 0 0.5rem;
            }

            // Account btn
            .btn__account {
                width: auto;
                padding: 20px;
            }
        }
    }
}

.nav-toggle {
    cursor: pointer;
    display: none;
}

// VueJs Transition Menu Mobile
.expandMenu-transition {
    transition: all 0.3s ease;
}
.expandMenu-enter,
.expandMenu-leave {
    height: 0;
    opacity: 0;
}
//
// Banner
//
.ee-banner {
    position: relative;

    .ee-banner_background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 40% 50%;
        border-bottom-left-radius: $radius-md;
        border-bottom-right-radius: $radius-md;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.8) 100%
            );
        }
    }
}

.ee-banner-content {
    position: relative;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 20px;
    height: 700px;
}

.ee-banner-list {
    li {
        position: relative;
        margin: 10px;
        padding-left: 25px;
        font-size: 1.8rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            background-image: url("../images/green_check.png");
        }
    }
}

//
// Popin Frequently questions
//
.faq-header {
    background: #fff;
    position: absolute;
    right: 20px;
    top: 100px;
    width: 50%;
    z-index: 5;
    transition: all 0.3s ease;
    padding: 10px;
    @include transition(opacity 0.3s, max-height 0.3s linear);
    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &.active {
        max-height: 300px;
        opacity: 1;
    }
    .faq-content {
        padding: 10px 15px;
    }
    .faq-questions-answers {
        border-top: 1px solid $c-input-border;
        cursor: pointer;
        padding: 20px 5px;
        &:first-child {
            border: none;
        }
    }
    .link {
        border-top: 1px solid $c-input-border;
        color: $c-black;
        display: inline-block;
        padding: 10px 0 0;
        width: 100%;
        &:after {
            content: "\25b6";
            display: inline-block;
            margin: 0 0 1px 10px;
            vertical-align: middle;
        }
    }
}

//Faq Banner
.faq-banner {
    background-image: url("../images/faq-banner.jpg");
}

.ee-banner-text {
    color: $c-white;

    .buttons-set,
    h1 {
        margin-top: 30px;
    }

    .buttons-set {
        font-size: 2rem;

        @media (max-width: 350px) {
            .btn__search {
                display: block;
                padding: 20px;

                &::before {
                    display: none;
                }
            }
        }
    }
}
.title-banner {
    font-family: $roboto;
    font-size: 4.8rem;
    font-weight: 600;
    margin-bottom: 15px;
    .price-box .price {
        font-weight: inherit;
        font-size: inherit;
    }
}
.subtitle-banner {
    font-size: 1.8rem;
    margin: 0 0 10px 0;
    line-height: 2.5rem;
}

//
// Responsive
//

@include breakpoint(md) {
    .ee-banner-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 50%;
        margin-left: 50%;
    }
}

@include respond-to(1280px) {
    .page-header {
        .ee-list-link li a {
            padding: 0 8px;
        }
        .panel-right {
            font-size: 1.2rem;
            .number {
                margin: 0;
            }
            .desc {
                font-size: 1rem;
            }
        }
    }
}
@include respond-to(1150px) {
    .page-header {
        .panel-right {
            display: none;
        }
    }
}
@include respond-to(medium) {
    .panel.header {
        text-align: center;
    }
}

@include respond-to(small) {
    //
    // Panel (top header)
    //
    .panel {
        display: none;
    }
    .page-header .header_mobile {
        display: block;
    }

    .page-header {
        .logo-block {
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            width: auto;
            height: 60px;
            background: rgba($c-project2, 0.3);
            .logo {
                display: block;
                width: 175px;
                padding: 13px 0 0 20px;
            }
        }

        // Mobile header
        .header_mobile {
            padding: 30px 36px;
        }

        // Banner Background
        .ee-banner_background.mobile {
            display: block;
            border-radius: $radius-l;

            &::before {
                border-radius: $radius-l;
                background: rgba(0, 0, 0, 0.5);
            }
        }
        .ee-banner_background.desktop {
            display: none;
        }
    }

    .nav-toggle {
        display: block;
        color: $c-white;
        font-size: 1.8rem;
        padding: 20px;
        &:before {
            content: "\EA05";
            display: inline-block;
            font-family: "iconfont";
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            text-transform: none;
        }
        span {
            display: none;
        }
    }

    .faq-header {
        width: 100%;
        right: 0;
        left: 0;
        top: 140px;
    }

    .ee-banner-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 560px;
    }

    .ee-banner-text {
        padding-bottom: 60px;

        h1 {
            margin-bottom: 40px;
        }

        .buttons-set {
            margin: 45px 0 0 0;
        }
        .button {
            padding: 0 20px;
        }
    }
    .title-banner {
        font-size: 3.4rem;
        line-height: 4.6rem;
    }
    .subtitle-banner {
        display: none;
    }
    .ee-banner-list li {
        font-size: 1.6rem;
        margin: 20px 10px;
    }
}
