// =============================================
// Checkout / 4Steps
// =============================================

.easyentretien-checkout-steps {
  min-height: 100vh;
  background-color: $c-grey-light;

  .column.main {
    min-height: 500px;
  }

  // HEADER
  .page-header {
    border-bottom: 2px solid $c-main;
    /* .panel-right {
      @include respond-to(1150px) {
          display: none;
      }
    } */
    .infos .icon {
      @media only screen and (max-width: 992px) and (min-width: 801px) {
        display: inline-block;
      }
    }
  }

  .panel.wrapper {
    background: none;
    box-shadow: none;
    border: none;
  }

  .panel.header {
    max-width: 1500px;
    align-items: center;
    height: auto;
    margin: 0 auto;
    padding: 0 10px;

    .phone-mob {
        display: none;

        &.isLead {
            display: block;
            top: 20px;
            
            @include breakpoint(sm) {
                display: none;
            }
        }
    }

    // Only in tunnel
    @include breakpoint(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }



    .panel-left,
    .panel-center {
      display: flex;
      align-items: center;
    }

    .panel-center p,
    .panel-left .reviews-widget {
      margin-left: 25px;
    }


    .panel-center {
        font-size: 2rem;
        
        @include breakpoint(only-lg) {display: none;}
        @include breakpoint(lg) {
            margin: 0 auto;
            grid-column-start: 2;
            grid-column-end: 3;
        }

        .technician-logo svg {
            max-width: 33px;
            max-height: 32px;
        }

    }

    .panel-right {
        .packages,
        .packages-link,
        .sponsorship-link,
        .account_conainer,
        .infos {
          display: none;
        }

        &.isLead .infos{
            display: flex;
            justify-content: center;
        }
    }
  }

  .page-main {
    margin: 0 auto;
  }

  // FOOTER
  .footer-checkout {
    clear: both;
    max-width: 1240px;
    margin: 0 auto;
    padding: 45px 10px;

    .logo {
      width: 160px;
    }

    .panel-right {
      float: right;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 1.4rem;
      margin: 0;
      color: $c-project3;

      a {
        background: none;
        border: none;
        color: $c-project5;
        margin: 0;
        padding: 0 0 0 5px;
        text-transform: none;
      }
    }
  }

  // STEP BLOCK CONTAINER
  .btn__back {
    color: $c-project2;
    background-color: transparent;
  }

  .content-steps-eligibility,
  .content-steps-options,
  .content-steps-appointment {
    position: relative;

    @include breakpoint(sm) {
      display: flex;
      justify-content: center;
      gap: 10px;
      padding: 50px 0;

      .steps-item-container {
        width: 15%;
        }
    }

    @include breakpoint(lg) {
        gap: 20px;
    }

    .eligibility-content,
    .options-content,
    .appointment-content {
      @include breakpoint(sm) {
        width: 45%;
      }
      @include breakpoint(lg) {
        width: 55%;
      }
    }

    .summary{
      @include breakpoint(sm) {
        width: 35%;
      }
      @include breakpoint(lg) {
        width: 25%;
      }
    }
  }

  // Steps labels
  .steps-item {
    font-size: 1.4rem;

    @include breakpoint(sm) {
        margin: 50px 0;
    }


        &__action {
            @include breakpoint(sm) {
                margin-bottom: 50px;
            }
        }

    .stepItem-container {
      display: flex;
      position: relative;

      @media screen and (max-width: $mq-large) {
        text-align: center;
      }

      @media only screen and (max-width: $mq-large) and (min-width: $small)  {
        display: block;
      }

      @include breakpoint(sm) {gap: 10px;}

      .stepItem {
        &__link {
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

    }

    &.active {
      margin: 25px 0;

      .stepItem-container {
        .step-number {
          background: $c-project5;
          border: 1px solid $c-project5;
          color: $c-white;
        }
        .step-label {
          color: $c-project5;
        }
      }
    }

    &.passed {
      margin: 25px 0;

      .stepItem-container {
        .step-number {
          background: $c-project1;
          border: 1px solid $c-project1;
          color: $c-white;
        }

        .step-label {
          color: $c-project1;
        }
      }
    }

    .stepItem {

      &__number {
        @include border-radius(100%);
        border: 1px solid $c-link-bullet;
        color: $c-white;
        background-color: $c-link-bullet;
        display: inline-block;
        font-family: $roboto;
        height: 25px;
        line-height: 25px;
        width: 25px;
        text-align: center;
      }

      &__infos {
        font-family: $roboto;

        .step-label {
          color: $c-project2;
          line-height: 2.5rem;
        }

        .step-answer {
          position: relative;
          margin: 10px 0;
          padding: 5px 20px 5px 5px ;
          color: $c-project2;
          background-color: rgba($c-white, .5);
          border-radius: $radius-s;

          &::after {
            content: '';
            position: absolute;
            right: 5px;
            top: 5px;
            width: 10px;
            height: 10px;
            background-image: url("../images/rewrite.svg");
            background-repeat: no-repeat;
            background-size: cover
          }
        }
      }
    }

  }

  // GLOBAL CONTENT
  .steps-main-content {

    .h1-subtitle {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.1rem;
      color: $c-main;
    }

    .h1-title {
      font-family: $roboto;
      font-size: 3rem;
      padding:10px 0;
      border-bottom: 1px solid $c-project4;
    }

    .h2-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .h2-subtitle {
      font-size: 1.4rem;
      color: $c-project3;
      margin: 0;
    }

    form .button {
      height: 55px;
      line-height: 55px;
      margin: 20px auto 0;
      width: 100%;
      max-width: 300px;

    }

    .icon-scroll {
      margin: 0;
      top: -15px;
    }
  }

  .list-choice {
    font-size: 0;
    margin: 20px 0;

    @include breakpoint(sm) {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .list-item {
      cursor: pointer;
      flex: 1;
      position: relative;
      text-align: center;
      padding: 25px;
      font-size: 1.4rem;
      border: 2px solid $c-project4;
      @include border-radius(6px);
      @include transition(all 250ms linear);

      &:hover {
        border: 2px solid $c-project5;
      }

      &.active {
        border: 2px solid $c-validate;

        // Check
        &:after {
          background-image: url("../images/green_check.png");
          background-size: cover;
          content: "";
          height: 15px;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 15px;
        }

        .item-label {
          color: #000000;
        }
      }
      .list-item-content {
        background: $c-white;
        display: block;
        margin: 0 auto 20px;
        position: relative;
        min-height: 57px;
      }
    }

    &.tax-list {
        @include breakpoint(only-lg) {
            display: block;
            
            .list-item {
                margin: 10px 0;
            }
        }

      .list-item {
        @include breakpoint(lg) {width: 30%;}
      }
    }
  }

  .select-choice {
    cursor: pointer;
    position: relative;
    min-width: calc(50% - 15px);
    height: auto;
    padding: 25px;
    text-align: center;
    font-size: 1.4rem;
    @include transition(all 250ms linear);

    &.active {
      border: 2px solid $c-validate;
    }
  }

  .item-label {
    font-family: $roboto;
    font-size: 1.4rem;
    color: $c-project2;
  }

  .title-presentation {
    font-size: 1.8rem;
    font-family: $roboto;
    color: $c-project2;
    margin: 30px 0;
  }

  .back-content {
    color: $c-project3;
    font-family: $roboto;
    font-size: 1.3rem;

    .back {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  // SIDEBAR
  /* .summary {
    width: 400px;
  } */

  #summary-col {
    @include transition(top .3s linear);
    max-width: 400px;

    &.stick {
      margin-top: 0;
      position: fixed;
      top: 20px;
      z-index: 10;
      /* width: 100%; */

      &.pos-fixed {
        position: fixed;
        /* width: 100%; */
      }
    }
  }

  .summary-content {
    margin: 0 10px 0 0;
    max-width: 400px;
    /* position: absolute; */
    background-color: $c-white;
    top: 50px;
    width: 100%;

    .title-cart-summary {
      margin: 25px 0;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      color: $c-project2;
      font-family: $roboto;
      font-weight: 600;
      background-color: $c-grey-light;
      border-radius: 6px;
    }

    .title-cart-summary .label {
      font-size: 1.6rem;
      max-width: 60%;
    }

    .options-list {
      margin: 25px 0;
      padding: 10px;
      border: 1px solid $c-project4;
      border-radius: $radius-s;
    }

    .prorata-price {
      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__label,
      &__amount {
        font-weight: 600;
      }

    }
  }

  // MODAL CHANGE CONTRACTS
  .block-products-list.grid {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);




    &.visible {
      display: flex;
    }

    .push-product-content {
        max-width: 1400px; 
        background-color: $c-white;

        @include breakpoint(sm) {
          overflow: auto;
          height: 90vh;
          width: 95%;
          padding: 50px 15px;
          border-radius: $radius-banner;
        }

        @include breakpoint(lg) {
          width: 90%;
          padding: 50px;
        }

      .modal-header {
        position: relative;
        margin: 25px 0;
        text-align: center;
        font-size: 2.5rem;
        color: $c-main;

        @include breakpoint(sm) {
            font-size: 3.6rem;
        }

        span {
          cursor: pointer;
          position: absolute;
          margin-left: auto;
          font-size: 1.6rem;
          color: $c-project3;
          text-decoration: underline;

          @include breakpoint(sm) {
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }

      .product-items.widget-product-grid-mobile {
        @media (min-width: $small) {
          display: none;
        }
      }
    }
  }

  // STEP ONE - ELIGIBILITY
  .eligibility-content {
    .title-presentation {
      max-width: 465px;
    }

    @media screen and (max-width: $small) {
        .info-block {
            padding: 20px 15px;

            &::before {
                top: 15px;
                transform: translateY(0);
            } 

            .info-block__text {
                text-indent: 25px;
                padding: 0;
            }
        }
    }

    

    .check-zipcode-eligibility {
        position: relative;
        z-index: 2;
        margin-bottom: 10px;
        @include breakpoint(sm){margin-bottom: 25px;}


        .zipcode {
            @include breakpoint(sm) {padding: 25px 0;}
            width: 100%;
        }

        input[type="text"] {
        background-color: $c-grey-light;
        border: 1px solid $c-project4;
        border-radius: 6px;
        color: $c-project2;
        font-family: $roboto;
        font-size: 1.8rem;
        margin-top: 10px;
        padding: 20px;
        width: 100%;

        &.valid {
            // @use !important due to style for remove other border on autofill
            border-bottom: 2px solid $c-project1 !important;

            &:-webkit-autofill {
            border: none !important;
            border-bottom: 2px solid $c-project1 !important;
            }
        }

        &:-webkit-autofill {
            border: none !important;
            border-bottom: 2px solid $c-project2 !important;
            -webkit-box-shadow: 0 0 0 1000px $c-grey-light inset;
            -webkit-text-fill-color: $c-input-text;
        }
        }

        span {
        display: block;
        margin: 10px 0;
        color: $c-error;

        &.success {
            padding: 15px;
            font-weight: 500;
            color: $c-white;
            background-color: $c-success;
            border-radius: 6px;
        }
        }
    }

    #customer_profil {
        position: relative;
        z-index: 1;
        opacity: 0;
        transform: translateY(-20%);
        transition: all .4s ease-in-out;

        &.show {
            opacity: 1;
            transform: translateY(0);
        }
    }
   } 

  .options-content .products-type_list {
    @include breakpoint(sm) {padding: 50px 0 15px;}
  }

  // STEP TWO - OPTIONS
  .options-content .products-type_list,
  .options-content .units_ext-list,
  .options-content .equipment_age-list,
  .options-content .options-list {
    position: relative;

    .list-choice {
      flex-wrap: wrap;
      gap: 0;
    }

    .list-item {
      margin-right: 15px;
      padding: 20px 40px;
      text-align: start;

      
      @include breakpoint(sm) {
        display: flex;
        align-items: center;
        flex: 1;
      }
      
      @media screen and (max-width: $mq-large) {
        display: block;
        text-align: center;
        .list-item-content {
          margin: 0 0 10px;
        }
      }

      .list-item-content {
        min-height: 0;
        margin: 0 20px 0 0;
        @media screen and (max-width: $mq-large) {
          margin: 0 0 10px;
        }
        @include respond-to(small)  {
          margin: 0 20px 0 0;
        }


        img {
          max-width: 50px;
          padding: 0;
          @include breakpoint(sm) {max-width: 40px;}
        }
      }
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

  }

  // Nbr units ext/int Clim
  .options-content .clim_questions-container {
    padding: 25px 0;

    .units_ext-list {
      .list-item {
        justify-content: center;
      }
    }

    .units_int-list {}
  }

  // Options list (only ramonage)
  .options-content .options-list {
    .list-item {
      justify-content: space-between;
    }

    .item {
        @include breakpoint(sm) {display: flex};

        @media screen and (max-width: $mq-large) {
          display: block;
        }

      img {
        margin-right: 10px;
        width: 50px;
        height: 50px;
      }

      p {
        margin: 0;
        line-height: 2rem;
      }

      .product-label {
        position: relative;
        padding-right: 30px;
        width: fit-content;
        color: $c-project2;
        font-weight: 600;
        @media screen and (max-width: $mq-large) {
          margin: 0 auto;
        }
      }

      .product-info {
        font-size: 1.4rem;
        color: $c-project3;
      }
    }
  }

  .tax-profil-container {

    .list-item {
      .type {
        font-size: 1.4rem;
        color: $c-project3;
      }
    }
  }

  .check-eligibility-content {
    margin: 55px auto 50px;

    .messages {
      display: none;
    }

    .success {
      display: none;
    }

    .messages {
      padding: 0;
    }
  }

  // STEP TWO - OPTIONS
  .options-content {
    .list-item {
      width: 50%;
      min-width: 40%;
      padding: 0 10px;
      margin: 0 0 10px;
    }

    .product-label {
      font-family: $roboto;
      font-size: 1.6rem;
      display: block;
      @include breakpoint(sm) {margin: 0 0 10px;}

    }

    .qty-content {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0;
    }

    .price-content {
      display: inline-block;
      vertical-align: middle;

      .price {
        font-family: $roboto;
        font-size: 2.4rem;
      }

      .old-price {
        font-family: $roboto;
        font-size: 1.6rem;
        color: $c-project3;
        position: relative;

        &:after {
          background: $c-project3;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
        }
      }
    }

    .special-price {
      font-family: $roboto;
      font-size: 2.4rem;
      color: $c-project5;
      margin: 0 0 0 10px;
    }

    .discount-details {
      font-size: 1.3rem;
      color: $c-project3;
      margin: 0;
    }

    .title-presentation {
      max-width: 320px;
    }

    // POPIN PRODUCT OPTION

    .option-info__mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        z-index: 100;
    }

    // Button Show modal
    .show-modal {
        position: absolute;
        font-size: 1.5rem;
        padding: 5px 0;
        color: $c-white;
        cursor: pointer;
        display: block;
        width: 25px;
        height: 25px;
        @include border-radius(100%);
        background: $c-info;
        line-height: 16px;
        top: 10px;
        right: 10px;
        text-align: center;
        
        
        &.tablet_screen-start { display: none;}
      
        @include breakpoint(sm) {
            font-size: 1.2rem;
            padding: 0;
            width: 15px;
            height: 15px;
            right: 0px;
            top: 0px;
        }

      
    }

    .modal-mask {
      display: none;

      &.active {
        position: absolute;
        z-index: 9998;
        top: 0;
        left: -75%;
        min-width: 400px;
        max-width: 450px;
        display: block;
        transform: translateY(-50%);
        transition: opacity .3s ease;

        @include breakpoint(sm) {
            left: calc(100% + 20px);
        }
      }
    }

    // Modal Popin

    .modal-container {
      position: relative;
    }

    .modal-bg {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
      color: $c-project3;
      padding: 40px 30px;
      position: relative;
      text-align: left;
      transition: all .3s ease;
      width: 100%;
    }

    .modal-header h3 {
      color: $c-black;
      font-family: $roboto;
      font-size: 3rem;
      letter-spacing: 1px;
      line-height: 30px;

    }

    .modal-body {
      font-weight: 400;
      margin: 10px 0 0;

      .sub-title {
        color: $c-project2;
        font-family: $roboto;
        margin: 30px 0 15px;
        text-transform: uppercase;
      }

      .list {
        padding: 0 0 0 20px;
        list-style: disc;
      }

      .redlight {
        color: $c-project5;
      }
    }

    .modal-default-button {
      background: transparent;
      border: 2px solid #fff;
      cursor: pointer;
      @include border-radius(100%);
      height: 30px;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1px;
      position: absolute;
      color: #fff;
      right: -30px;
      top: -40px;
      width: 30px;
    }

    /*
     * VueJs Transition
     */
    .modal-enter, .modal-leave {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

  }

  // STEP THIRD - APPOINTMENT
  .content-steps-appointment {

    /* .appointment-content {
      min-width: 50%;
    } */

    // Calendar
    .ui-datepicker {
      @include border-radius(5px);
      background: #ffffff;
      border: 1px solid $c-project4;
      box-shadow: none;
      font-family: $roboto;
      padding: 30px;
    }

    .ui-datepicker-title {
      color: $c-project2;
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    .ui-datepicker-header {
      padding: 0 0 30px;
    }

    .ui-datepicker-month {
      margin: 0 5px 0 0;
    }

    .ui-datepicker-prev {
      left: 25%;
      width: 25px;
      height: 25px;
      background-color: $c-project2;
      border-radius: 100%;

      @include breakpoint(only-lg) {left: 10%;}

      &::before {
        content: '';
        position: absolute;
        background-image: url("../images/icons/arrow-prev.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      span {
        display: none;
      }
    }

    .ui-datepicker-next {
      width: 25px;
      height: 25px;
      right: 25%;
      background-color: $c-project2;
      border-radius: 100%;

      @include breakpoint(only-lg) {right: 10%;}

      &::before {
        content: '';
        position: absolute;
        background-image: url("../images/icons/arrow-next.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      span {
        display: none;
      }
    }

    .ui-datepicker-calendar {
      border: none;
      background: none;

      th, td {
        border: none;
        background: none;
      }

      th {
        color: $c-project3;
        font-size: 1.4rem;
        font-weight: normal;
      }

      td a {
        font-weight: bold;
        height: 36px;
        line-height: 30px;
      }

      .ui-state-default {
        text-align: center;
        position: relative;

        &:before {
          @include border-radius(100%);
          @include transform (translate(-50%));
          background: $c-validate;
          content: "";
          display: block;
          height: 36px;
          line-height: 36px;
          position: absolute;
          left: 50%;
          top: 0;
          width: 36px;
          z-index: -1;
          opacity: 0;
          @include transition(all .3s linear);
        }
      }

      .ui-state-hover {
        background: none;
        color: $c-white;
        position: relative;
        z-index: 0;

        &:before {
          opacity: 0.5;
        }
      }

      .ui-state-active {
        background: none;
        color: $c-white;
        position: relative;
        z-index: 0;

        &:before {
          @include border-radius(100%);
          @include transform (translate(-50%));
          background: $c-validate;
          content: "";
          display: block;
          height: 36px;
          line-height: 36px;
          position: absolute;
          left: 50%;
          top: 0;
          width: 36px;
          z-index: -1;
          opacity: 1;
        }
      }
    }

    .ui-datepicker-buttonpane {
      display: none;
    }

    .calendar-info {
      color: $c-project3;
      font-size: 1.3rem;
      text-align: left;
    }

    .time-slot {
      margin: 35px 0 0;

      .label {
        margin: 0;
        padding-bottom: 5px;
        font-family: $roboto;
        font-size: 1.3rem;
        color: $c-project3;
      }

      .buttons_container {
        gap: 10px;
      }

      .morning-content, .afternoon-content {
        flex: 1
      }

      .item {
        @include border-radius(5px);
        @include transition(all 250ms linear);
        background: $c-white;
        border: 2px solid $c-project4;
        display: block;
        font-size: 1.4rem;
        margin: 0 auto 10px;
        position: relative;
        cursor: pointer;
        padding: 30px 0;
        width: 100%;

        &:hover:not(:disabled) {
          border: 2px solid $c-validate;
        }

        &.active {
          border: 2px solid $c-validate;

          &:after {
            background-image: url("../images/green_check.png");
            background-size: cover;
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .3;
        }
      }
    }

    .time-slot-confirm {
      margin: 50px 0;
    }
  }

  //Checkout Loader
  .loading-mask {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #fff, $alpha: .5);
    text-align: center;
    z-index: 50;

    .loader {
      
        .technician-logo {
            margin: 25px;

            svg {
                width: 150px !important;
                height: 150px !important;

            //visage
            path:nth-child(1) {
                @include animateDrawLoader(56);
                stroke: $c-white;
                stroke-width: 1;
            } 
    
            // Shoulder
            path:nth-child(2) {
                    @include animateDrawLoader(17);
                    stroke: $c-white;
                    stroke-width: 1;
            }
    
            // Visiere
            path:nth-child(3) {
                    @include animateDrawLoader(17);
                    stroke: $c-white;
                    stroke-width: 1;
            }
    
            // Tool
            path:nth-child(4) {
                    @include animateDrawLoader(54);
                    stroke-width: 1;
            }
    
            // Mouth
            path:nth-child(5) {
                    @include animateDrawLoader(5);
                    stroke: $c-white;
                    stroke-width: 1;
            }
            
            }
        }

        .loader-text {
            overflow: hidden;
            font-weight: 600;
            color: $c-white;


            @for $i from 1 through 13 {
                $timeMultiplyer: calc(#{$i}/4);
                $timeduration: calc(11-#{$i});

                span:nth-child(#{$i}) {
                    display: inline-block;
                    margin: 0 2px;
                    font-size: 2.5rem;
                    transform: translateY(150%);
                    animation: letter-translate 1s calc(0.5s * #{$timeMultiplyer}) ease-out infinite alternate;
                }
                
            }
            
        }

        p {
            display: none;
        }
    }
  }
}

// STEP FOURTH - ADDRESS/CHECKOUT
.checkout-index-index {
  position: relative;

  .modals-wrapper,
  .submitCta-shipping,
  .submitCta-contract_visualisation {
    display: none;
  }

  .column.main .checkout-content {
    justify-content: center;
    gap: 25px;
    @include breakpoint(sm) {padding: 50px 25px;}

    &::after {
      display: none;
    }

    #shipping-method-buttons-container {
      display: none;
    }
  }

  .summary-container {
    width: 30%;
  }
    //Summary
  .summary {
    position: relative;

    .summary-content {
      top: 0 !important;
      bottom: auto !important;
    }


    #summary-content .info-block.pdc-info {
        display: none;
    }
  }

  .contract_validation-content {
    display: none;
  }

}

// CHECKOUT CONTRACT STEP
.checkout-index-index.tunnel_step-contract_validation {
  .shipping-content { display: none;}
  .contract_validation-content { display: block;}
  .checkout-container {width: 50%;}

  .contract_container {
    iframe {
      border: none;
      border-radius: $radius-md;
    }
  }
  //Checkbox
  .checkbox-container .custom-checkbox + label::after { color: $c-validate;}
  .checkbox-container .custom-checkbox + label {
    align-items: center;
    text-transform: none;
    font-size: 1.4rem;

    a {
      padding-left: 5px;
      color: $c-main;
      text-decoration: underline;
    }
  }
}

/* ----------------------------------------------
  CHECKOUT CONTRACT VISUALISATION/VALIDATIONS
----------------------------------------------- */
.checkout-index-index {
  .contract_container .btn__full-screen {
    display: none;
  }

  .contract_container {
    display: none;
  }

  .contract_container:has(iframe) {
    display: block;
    padding: 25px 0;

    // iframe loaded but not full screen
    .btn__full-screen {
      display: block;
      width: fit-content;
      margin-bottom: 10px;
      margin-left: auto;
      padding: 5px 10px;
      background-color: $c-white;
      border: 1px solid $c-project3;
      box-shadow: 0px 20px 30px 0px #0000000D;
    }

    #iframe-yousign-container,
    #iframe-contract-container {
      width: 100%;
      height: 600px;

      iframe {
        border: none;
        border-radius: $radius-md;
        width: 100%;
        height: 100%;
      }
    }

    // iframe loaded and Full screen
    &:has(iframe) {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, .5);
      z-index: 10000;

      .btn__full-screen {
        display: none;
      }

      .full-screen__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
      }

      #iframe-yousign-container:has(iframe),
      #iframe-contract-container:has(iframe) {
        position: relative;
        margin: 2.5% auto;
        width: 90%;
        height: 90%;
      }
    }
  }
}

/* -----------------------
  CHECKOUT PAYEMENT STEP
------------------------ */
.checkout-index-index.tunnel_step-payment {
    .shipping-content,
    .contract_validation-content { display: none;}
    .checkout-container {
        @include breakpoint(sm) {width: 50%;}
    }




  // Dalenys refonte
  .payment-method.dalenys {
    display: none;

    &._available { display: block;}
  }

  .payment-method.dalenys,
  .payment-method.iban-method {
    margin-bottom: 25px;
    border: 1px solid $c-project4;
    border-radius: $radius-s;
    padding: 10px;

    .field {
      margin: 0;
      width: auto;
    }

    .payment-method-title {
      width: 100%;

      label {
        display: flex;

        &>span,
        .dalenys-method-logo,
        .method-logo {
          display: block;
        }

        .dalenys-method-logo,
        .method-logo {
          max-height: 20px;
          margin-left: auto;
        }
      }
    }

    .payment-method-content {
      display: none;
    }

    &._active {
      .payment-method-content {
        display: block;
      }
    }
  }

  .dalenys-payment-method-title.field.choice {
    width: 80%;
  }

  .checkout-agreement {
    margin: 10px 0;
  }

  .dalenys-form .brand-area { display: none;}
  .dalenys-form form,
  form.form-sepadirectdebit-order {
    padding: 0;

    label {
      margin: 0;
      font-size: 1.3rem;
      color: $c-project3;
    }

    input[type=text],
    .input-container {
      padding: 5px 7px 3px 7px;
      font-family: $roboto;
      font-size: 1.4rem;
      border: none; // Reset
      border-radius: 0;// Reset
      background-color: transparent;
      border-bottom: 1px solid $c-project3;
    }

    p:has(#card-container) {
      span {width: 100%;}
    }

    .sepa-field {
      margin: 15px 0 !important;

      &.field-iban {
        width: 75% !important;
      }
    }

    // Card expiry / card secu code
    p:has(#expiry-container) {
      margin-right: 8%;
    }

    p:has(#expiry-container),
    p:has(#cvv-container) {
      display: inline-block;
      width: 45%;

      &>span {
        width: 100%;
      }
    }

  }

  .dalenys-form .submit {
    margin: 0;
  }

  //Summary
  #summary-content .info-block.pdc-info {
    display: block!important;
    opacity: 1;
    transform: translateY(0);
  }
}

// Lead payment page hided
.checkout-index-index.lead_payment-page {
    #maincontent {opacity: 0;}
}

/*
  Checkout message popin
*/
.checkout-messages_container {

    .message-error {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 112;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, .5);
    }

    .popin-error {
        position: relative;
        font-size: 1.4rem;
        padding: 110px 50px 50px 50px;
        border-radius: $radius-md;
        background-color: $c-white;
        max-width: 90vw;

        &:before {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50%;
            top: 50px;
            transform: translateX(-50%);
            background-image: url("../images/icons/warning.svg");
            background-size: 90%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.checkout-agreement,
.submitCta-payment,
.btn-place-order,
.btn-dalenys-place-order {
  display: none;
}

// CHECKOUT SUMMARY

// Periodic / annual price
.checkout-index-index {

    .annual-price_show,
    .annual-payment_show,
    .periodic-price_show,
    .periodic-payment_show {
        opacity: 0;
        transform: translateY(30%);
        transition: all 0.3s ease-in;
    }

  //.summary-content {}
    // Monthly
    .monthly-payment {
      .annual-price_show,
      .annual-payment_show {
        display: none !important;
      }

      .periodic-price_show,
      .periodic-payment_show {
        opacity: 1;
        transform: translateY(0);
      }
    }

    // Annual
    .annual-payment {
      .periodic-price_show,
      .periodic-payment_show {
        display: none !important;
      }

      .annual-price_show,
      .annual-payment_show {
        opacity: 1;
        transform: translateY(0);
      }
    }

}

.checkout-container {
    @include breakpoint(sm) {width: 50%;}

  .steps-main-content {
    @include border-radius(5px);
    color: $c-project2;
    padding: 30px 50px;

    .button {
      @include transition(all .3s linear);

      &.disabled {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
      }
    }
  }

  *:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $c-grey-light inset;
  }

  .authentication-dropdown {
    text-align: left;
    visibility: hidden;
    width: 100%;
    height: 0;
    overflow: hidden;
    @include transform(scale(1, 0));
    @include transform-origin(0 0);
    @include transition(transform linear .1s, visibility 0s linear .1s);

    &._show {
      visibility: visible;
      height: auto;
      overflow: visible;
      @include transform(scale(1, 1));
      @include transition(transform linear .1s, visibility 0s linear 0s);
    }

    .block-title {
      display: none;
    }

    .field {
      padding: 0;
      width: 100%;
    }

    *:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    }

    .actions-toolbar {
      .primary,
      .secondary {
        display: block;
        float: none;
        margin: 0 auto;
        text-align: center;

        button {
          margin: 0 auto 20px;
          max-width: 280px;
        }
      }
    }
  }

  .checkout-form-step {
    text-align: left;
    // for the checkbox absolute position
    position: relative;
    @include breakpoint(sm)  {padding-top: 75px;}

    // login button (hide)
    .authentication-wrapper {
      display: none;
      @include border-radius(5px);
      background-color: $c-white;
      border: 1px solid $c-success;
      color: $c-project2;
      font-family: $roboto;
      font-size: 1.4rem;
      margin: 0 auto 50px;
      padding: 15px;
      position: relative;
      max-width: 500px;

      button {
        font-family: $roboto;
      }

      .block-content {
        padding: 20px 0 0;
      }

      .action-auth-toggle {
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.4rem;
        width: 100%;

        .link {
          text-decoration: underline;
        }
      }

      .modal-header {
        position: absolute;
        top: -20px;
        right: 0px;

        .action-close {
          background: transparent;
          border: none;
          cursor: pointer;

          &:before {
            background-image: url("../images/crush.png");
            content: "";
            display: block;
            height: 14px;
            width: 14px;
          }

          span {
            display: none
          }
        ;
        }
      }

      .modal-inner-wrap {
        max-width: 380px;
        margin: 0 auto;
      }
    }

    //Checkbox billing address
    #opc-shipping_method {
      .billing-address-same-as-shipping-block.checkbox-container {
        position: absolute;
        top: 25px;
        @include respond-to(800px) {
          top: -45px;
        }
      }
    }
  }

  .checkout-payment-method,
  .checkout-form-step  {

    fieldset.street .control .label > span,
    .field-tooltip.toggle > span.label{
      display: none;
    }

    .form-login {

      fieldset {
        margin: 0;
      }

      .fieldset.hidden-fields {
        margin: 0;

        .note {
            display: block;
            margin: 15px 0;
        }
      }

      .field {
        width: 100%;

        .note {
          display: block;
          margin: 30px 0 0;
          color: $c-project3;
          text-align: center;
        }
      }

      .actions-toolbar {
        padding: 0 15px;

        .secondary {
          text-align: center;
          margin: 10px 0 0;

          button {
            margin: 0 auto;
          }
        }
      }
    }

    .form.payments {
      .fieldset {
        margin: 0;
        padding-bottom: 10px;
      }

      .h2-title {
        margin: 0 auto 20px;
      }
    }
  }

  fieldset.field.street {
    .field._required {
      width: 100%;
    }

    .field.additional {
      display: none;
    }
  }

  .field:not(.full-width) {
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
    width: 100%;

    @include breakpoint(lg){width: 49%;}

    &.admin__control-fields {
      width: 100%;

      legend.label {
        font-family: $roboto;
        font-weight: 400;
      }

      & > .control {
        margin: 0 -15px;
      }
    }

    &[name^="billingAddresscheckmo.telephone"] .control._with-tooltip {
      .input-text {
        width: 100%;
      }

      .field-tooltip.toggle {
        display: none;
      }
    }

    &[name^="billingAddresscheckmo.custom_attributes.comment"] {
      width: 100%;

      textarea {
        width: 50%;
      }

    }
  }

  .field:has(textarea){
    width: 100% !important;

    textarea {
      width: 100%;
      height: 200px;
    }
  }

  .optin-container {
    input[name='custom_attributes[proxiserve_optin]'] {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    input[name='custom_attributes[proxiserve_optin]'] + label {
        position: relative;
        padding-top: 0;
        padding-left: 40px;
        min-height: 24px;
        cursor: pointer;
        font-size: 1.2rem;
        display: flex;
        align-items: flex-start;

        &::before,
        &::after {
            cursor: pointer;
            position: absolute;
            transition: all .2s;
        }

        &::before {
            content: "";
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            background-color: transparent;
        }

        &::after {
            content: "\EA01";
            font-family: "iconfont";
            left: 5px;
            top: 5px;
            color: #F44D57;
            font-size: 1.5rem;
            line-height: 2rem;
            opacity: 0;
            transform: scale(0);
        }
    }

    input[name='custom_attributes[proxiserve_optin]']:checked + label {
        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
  }

  /* ------------------------
    billing-new-address-form
  ------------------------ */
  .billing-address-same-as-shipping-block > label{
    align-items: center;

    &::after {
      color: $c-validate;
    }
  }

  #billing-new-address-form {
    margin-top: 50px;
  }

  .billing-address-form,
  .checkout-shipping-address {
    margin: 0 -15px;

    select {
      width: 100%;
      height: 63px;
      font-size: 1.6rem;
    }
  }

  .billing-address-details .content {
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px;
    margin: 0 auto;
    @include border-radius(5px);
  }

  .control._with-tooltip {
    .input-text:not([type=email]):not([name=telephone]) {
      display: inline-block;
      margin: 0 5% 0 0;
      vertical-align: middle;
      width: 48%;
    }

    .field-tooltip.toggle {
      display: block;
      margin-top: 10px;
      font-size: 1.4rem;
      color: $c-info;
      background: rgba($c-info, .2);
      border-radius: $radius-s;
      padding: 15px 20px 15px 40px;
      position: relative;

      &:before {
        background-image: url("../images/info_icon.svg");
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 14px;
        width: 14px;
        position: absolute;
        top: 50%;
        left: 15px;
        @include transform(translateY(-50%));
      }
    }
  }

  .billing-address-details{
    display: none;
  }


  .checkout-shipping-method{

    .step-title, .table-checkout-shipping-method{
      display: none;
    }

  }

  /* ------------------------
    Payment Step
  ------------------------ */
  #payment {
    #terms-agreement.checkbox-container {
        margin-bottom: 30px;
        label {
            align-items: center;

            a {
                margin-left: 5px;
                color: $c-project3;
                text-decoration: underline;

                &:hover {
                    color: $c-main;
                }
            }
        }

        div.mage-error {
            display: block;
            top: 60%;
            padding-left: 40px;
        };

        div.mage-error.hide,
        input[name="terms"]:checked + label + div.mage-error {
            display: none;
        }
    }
  }

  .checkout-payment-type{
    gap: 20px;
    padding: 25px 0;

    @include breakpoint(xl) {display: flex;}

    p {
      margin: 0;
    }

    .payment-type {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px;
      border: 2px solid $c-project4;
      border-radius: $radius-s;
      text-align: center;
      cursor: pointer;
      margin-bottom: 25px;

      @include breakpoint(sm) {margin-bottom: 0;}

      @include breakpoint(only-xl) {margin: 10px 0;}

      &.active {
        border-color: $c-validate;

        // Check
        &:after {
          background-image: url("../images/green_check.png");
          background-size: cover;
          content: "";
          height: 15px;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 15px;
        }
      }

      &__label {
        font-size: 1.6rem;
        padding-bottom: 20px;
        border-bottom: 1px solid $c-project4;
      }

      &__price {
        margin-top: 15px;
        margin-bottom: 5px;
      }

      &__infos {
        color: $c-project3;
      }

      &__icon {
        margin-top: 25px;
        .icon {
            display: inline-block;
            width: 30px;
            height: 20px;
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: center;

            .text {
                vertical-align: super;
                color: $c-project3;
            }

            &.sepa {
                background-image: url("../images/sepa.svg");
            }

            &.amex {
                background-image: url("../images/amex.svg");
            }

            &.visa{
                background-image: url("../images/visa.svg");
            }

            &.mastercard {
                background-image: url("../images/mastercard.svg");
            }
        }
      }
    }

  }


}

// SIDEBAR
.easyentretien-checkout-steps,
.checkout-onepage-success {
  .summary-content {
    @include border-radius(12px);
    padding: 40px 30px 20px 30px;
    box-shadow: 0px 20px 30px 0px #0000000D;

    @media only screen and (max-width: $mq-large) and (min-width: $small)  {
        padding: 40px 10px 20px 10px;
    }
  }

  .summary-content-title {
    font-family: $roboto;
    font-size: 1.4rem;
    color: $c-project2;
   
    &__head {
        display: flex;
        justify-content: space-between;

        div:not(.contract-change) {
            font-size: 1.8rem;
            font-weight: 700;
        }

        .contract-change {
            cursor: pointer;
            font-size: 1.2rem;
            color: $c-project3;
            text-decoration: underline;

            &:hover {
              color: $c-main;
            }
          }
    }

    &.directenergie,
    &.foncia {
      padding: 5px 10px;
      letter-spacing: 0;
      font-weight: bold;
    }

    &.directenergie {
      color: $c-project2;

      .cobranding-name {
        background: $c-project11;
      }

      .contract-title {
        color: $c-project2;
      }
    }

    &.foncia {
      .cobranding-name {
        color: #fff;
        background: $c-foncia1;
      }
    }

    .summary-title {
      font-size: 1.8rem;
      position: relative;
    }

    .summary-title .flex {
      justify-content: space-between;
      gap: 10px;
      margin-top: 30px;
    }

    .cobranding-name {
        border-radius: 6px;
        text-align: center;
    }

    .clearer {
      min-height: 45px;

      &::after {
        display: none;
      }
    }

    .contract-title {
      &__type {
        font-size: 1.4rem;
      }
    }

    .amount {
      font-family: $roboto;
      text-align: right;

      .label {
        font-size: 1.2rem;
      }
    }
  }

  .info-block {
    margin: 20px 0px;
    padding: 15px;
    
    @include breakpoint(sm) {
        margin: 20px 10px;
    }
  }

  .product-desc {
    padding: 10px 30px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    line-height: 11px;
    font-weight: 600;
    max-width: 250px;
  }

  .options-list {


    li {
      color: $c-project2;
      font-size: 1.4rem;
      line-height: 35px;

      .icon {
        margin-right: 5px;
        font-size: .7rem;
        color: #65CC9B;
      }
    }
  }

  .firstYear-resume,
  .contract-resume {
    justify-content: space-between;

    &__price {
        .special-price {
            font-weight: 600;
        }

        .old-price {
            font-size: 1rem;
            
            span:nth-child(2) {
                font-size: .9rem;
            }
        }

        .tax_label {
            font-size: 1rem;
        }
    }
  }

  .options-selected {
    font-family: $roboto;
    padding: 20px 0px;

    .title {
      color: $c-project2;
      font-weight: 600;
    }

    .list-option {
      padding: 10px 0 0;
    }

    .item-option {
      display: flex;
      align-items: center;
      margin: 0 0 15px;
      position: relative;
    }

    .option-name {
      font-family: $roboto;
      padding: 0 15px 0 0;
    }

    .option-price_container {
      margin-left: auto;
      margin-right: 10px;

      .tax_label {
        font-size: 1rem;
      }
    }

    .option-price {
      font-family: $roboto;
      font-weight: 700;
    }

    .option-old-price {
      color: $c-project3;
      font-family: $roboto;
      font-weight: 700;
      font-size: 1.2rem;

      &:after {
        background: $c-project3;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 8px;
        left: 0;
        width: 100%;
      }
    }

    .special-price {
      color: $c-project5;
      font-weight: 700;
      font-size: 1.4rem;
    }

    .qty {
      color: $c-project3;
      margin: 5px 0 0;
    }

    .remove-option {
      cursor: pointer;
      width: 25px;
      height: 25px;
      background-color: $c-grey-light;
      background-image: url("../images/icons/uEA22-bin.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: $radius-s;

      &:hover {
        background-color: $c-error;
      }
    }
  }

  .cart-summary {
    font-family: $roboto;
    font-size: 1.4rem;

    .subtotal {
      margin: 0 0 15px;
      position: relative;
    }

    .total {
      color: $c-project2;
      position: relative;
      font-family: $open;
      font-weight: 600;
      padding-top: 20px;
      text-align: end;
      margin: 10px 0;
      border-top: 1px solid $c-project3;
    }

    .tax {
      display: block;
      font-family: $roboto;
      margin: 0 0 10px;
      padding: 0 75px 0 0;
      text-transform: uppercase;
      position: relative;
    }

    .tax-list {
      display: block;
      font-family: $roboto;
      margin: 0 0 10px;
      text-transform: uppercase;

      li {
        display: block;
        margin: 0 0 10px;
        position: relative;
        padding: 0 75px 0 0;
      }
    }

    .desc {
      display: flex;
      gap: 10px;
      margin: 16px 0;
      padding: 15px 20px;
      text-transform: none;
      color: $c-info;
      font-size: 1.4rem;
      line-height: 1.6rem;
      background: rgba($c-info, .2);
      border-radius: 6px;

      .icon_container {
        min-width: 15px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .title-cart-summary {
    .tax_label {
        font-size: 1rem;
        font-weight: 500;
    }
  }

  // Cart Amounts
  .amount {
    color: $c-project2;
    font-family: $roboto;
  }

  .cta-next_step {
    margin: 20px 0;

    .btn {
      width: 100%;
      padding: 15px;
      font-size: 2rem;
    }

    span.checkout-cta_label {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

// Success
.checkout-onepage-success {
  .success {
    &__container {
      margin: 200px auto 0;
      max-width: 850px;
      text-align: center;
    }

    &__subText {
      font-size: 1.8rem;
      font-weight: 600;
      color: $c-project3;
      line-height: 2.1rem;
    }

    &__bigText {
      margin: 10px 0;
      font-size: 3.6rem;
      color: $c-project5;
      line-height: 4.3rem;
    }

    &__infoText {
      font-size: 2.4rem;
      line-height: 3.3rem;
    }

    &__img {
      margin-top: 100px;
    }
  }
}


// =============================================
// Animations (fade-in...)
// =============================================

/*----------------------------------------
                Loaded state || Animate out  (Transitions pages)
    ----------------------------------------*/
.easyentretien-checkout-steps:not(.tunnel_step-payment),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment){
    
    //Header
    .page-header {
        transform: translateY(-150%);
        transition: transform .3s linear;
    }

    // Tunnel
    #maincontent {
        .page.messages {display: none;}

        // Steps breadcrumbs
        .steps-item-container {
            .steps-item__action {
                opacity: 0;
                transition: opacity .2s ease-in;
            }

            @for $i from 1 through 6 {
                $timeMultiplyer: calc(#{$i}/4);
    
                .steps-item:nth-child(#{$i}) {
                    opacity: 0;
                    transform: translateX(-100%);
                    transition: all .5s calc(.2s * #{$timeMultiplyer}) ease-in-out;
                }
            }
        }

        // Main content
        .round-white_box {
            opacity: 0;
            transition: all .3s ease-in;
        }

        .cgv-info {
            transform: translateY(100px);
            opacity: 0;
            transition: all .3s ease-in;
        }

        //Summary block
        .summary-content {
            transform: translateY(-50px);
            opacity: 0;
            transition: all .3s ease-in;
        }

        //Summary fixed mobile
        #mobile-fixed_summary {
            transform: translateY(100%);
            opacity: 0;
            transition: all .2s .3s ease-in;
        }
    }
}

// Not on checkout
.easyentretien-checkout-steps.animate-out:not(.checkout-index-index),
.easyentretien-checkout-steps:not(.checkout-index-index) {
    #maincontent {
        .round-white_box {
            transform: translateY(-20%);
            opacity: 0;
            transition: all .3s ease-in;
        }
    }
}

/*----------------------------------------
                Animate In (Transitions pages)
    ----------------------------------------*/
.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) {
    
    // Header
    .page-header {
        transform: translateY(0);
    }
    
    #maincontent {

        // Steps breadcrumbs
        .steps-item-container {
            .steps-item__action {
                opacity: 1;
            }

            @for $i from 1 through 6 {
                $timeMultiplyer: calc(#{$i}/4);
    
                .steps-item:nth-child(#{$i}) {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        // Main content
        .round-white_box {
            opacity: 1;
        }

        .cgv-info {
            transform: translateY(0);
            opacity: 1;
        }

        //Summary block
        .summary-content {
            transform: translateY(0);
            opacity: 1;
        }

        //Summary fixed mobile
        #mobile-fixed_summary {
            transform: translateY(0);
            opacity: 1;
        }
    }
}


// Not on checkout
.easyentretien-checkout-steps.animate-in:not(.checkout-index-index) {
    #maincontent {
        .round-white_box {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

// =============================================
// Responsive medium
// =============================================
@include respond-to(medium) {
  .easyentretien-checkout-steps {
    .steps-main-content {
      //padding: 30px 20px;

      .modal-default-button {
        right: 10px;
        top: 10px;
        color: $c-project3;
        border-color: $c-project3;
      }
    }

    .steps-item-container {
      &:after {
        max-width: 600px;
      }
    }

    .products-type_list {
      .list-choice {
        display: block;
        margin: 0 auto;
      }

      .list-item {
        width: 100%;

        .item {
          margin: 0 0 10px;
        }
      }
    }

    .summary-content-title {
      padding: 15px 15px 0;

      .contract-title, .amount {
        margin: 5px 0 10px;
      }
    }

    .options-list {
      padding: 15px;
    }

    .options-selected {
      padding: 20px 40px 10px 15px;

      .remove-option {
        right: -30px;
      }
    }
  }
}

// =============================================
// Responsive small
// =============================================
@include respond-to(small) {
  .easyentretien-checkout-steps,
  .checkout-onepage-success {
    .cart-summary {
      .desc span {
        display: inline;
      }
    }
  }

  .easyentretien-checkout-steps {
      .page-header {
          .panel.wrapper {display: block;}
  
          .panel.header {
              padding: 20px 10px 20px 50px;
  
              .panel-left .reviews-widget,
              .panel-center{
                  display: none;
              }
          }
      }

      .options-list {
        padding: 15px 0;
        .list-item {
          width: 100%;
        }
      }


    // SUMMARY
    .content-steps-eligibility .summary,
    .content-steps-options .summary,
    .content-steps-appointment .summary,
    .checkout-content .summary{
      position: fixed;
      width: 100vw;
      height: fit-content;
      max-height: calc(100vh - 20px);
      bottom: 0;
      left: 0;
      overflow: auto;
      transform: translateY(100%);
      transition: transform 1s linear;
      z-index: 50;

      &.show {
        animation: bounce-in-up .8s ease forwards;
      }

    }

    .summary-content {
        padding: 40px 30px 120px 30px;
        position: static;
        width: 100vw;
        max-width: none;
    }

    .submit-cta_container {
      position: fixed;
      padding: 20px;
      width: 100vw;
      bottom: 0;
      left: 0;
      gap: 15px;
      border-top: 1px solid $c-project4;
      background-color: $c-white;
      z-index: 100;

        .cross.toggle-recap {
            position: absolute;
            top: 0;
            left: 50%;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            border: 1px solid $c-project4;
            box-shadow: 0px 4px 13px 2px rgba(0, 0, 0, .33);
            background-color: $c-white;
            background-image: url("../images/icons/uEA25-close_orange.svg");
            background-size: 35%;
            background-repeat: no-repeat;
            background-position: center;
            transform:translate(-50%, -50%) rotate(45deg);
            transform-origin: center;
            transition: transform .2s ease-in;
        }
    
        .fixed-resume,
        .cta-next_step {
            margin: 10px 0 0 0;

            &__line {
                justify-content: space-between;
                align-items: center;
                margin: 2px 0;
                line-height: 1.4rem;
            }
        }

        .fixed-resume {
            .contract-title {
                font-size: 1.8rem;
                font-weight: 600;
            }

            &.show {
                .contract-title .cross {
                    transform: rotate(0deg);
                }
            }

            .contract-resume,
            .firstYear-resume {
                margin: 5px 0;
                font-size: 1.2rem;

                &__label {
                    .text__blue.text__bold {
                        margin-left: 5px;
                    }
                }

                .tax_label {
                    font-size: 1rem;
                }
            }

            .amount {
                font-size: 1.8rem;

                span.label {
                font-size: 1rem;
                }
            }
        }

        .cta-next_step {
            .btn__main {
            width: 100%;
            font-size: 2rem;
            padding: 10px;
            }
        }

        &.show {
            .cross.toggle-recap {
                transform:translate(-50%, -50%) rotate(90deg);
                box-shadow: 0px -4px 13px 2px rgba(0, 0, 0, .33);
            }

            .fixed-resume {
                display: none;
            }
        }

    }

    // HEADER
    .panel.header {
        .panel-left {
            margin-left: 15px;
        }
    }

    .steps-item {
        &__action {
            @include breakpoint(only-sm) {
                .btn__back {
                    position: absolute;
                    top: -60px;
                    left: 5px;
                    padding: 18px;

                    &::before {left: 18px;}
                    span {display: none;}
                }
            }
        }
    }

    // STEPS BREADCRUMBS
    .steps-item {
        margin: 0 10px;
        white-space: nowrap;

        .step-label { display: none;}

        &.active {
            display: block;
            margin: 0;

            .stepItem-container {gap: 10px;}

            .step-label {
                display: block;
                font-size: 1.6rem;
            }
        }

        &.active .step-answers {display: none;}

        &.passed {
            margin: 0 10px;

            .stepItem__infos {
                display: none;
            }
        }
    }

    .steps-item-container {
        display: flex;
        padding: 25px 5px;
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: $c-grey-light;
    }

    // CONTRACTS CHANGE MODAL
    .block-products-list.grid {
        .push-product-content {
            position: relative;
            max-height: 100vh;
            overflow-y: scroll;

            .modal-header {
                padding-top: 25px;

                span {
                    top: 0;
                    right: 10px;
                }
            }
        }
    }

    // MAIN CONTENT
    .steps-main-content {
        margin-bottom: 50px;

        .h1-title {
          font-size: 2.2rem;
          margin: 0;
        }

        .h2-title {
            margin-top: 35px;
            font-size: 1.8rem;
        }

        .list-item {
            margin: 25px 0;
        }

        .products-type_list,
        .equipment_age-list {
            .list-item {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }
    }

    .cgv-info {
        padding-left: 25px;
        margin-bottom: 90px;
    }

    // CHECKOUT STEPS
    &.checkout-index-index .checkout-content {
        display: block;

        .steps-item-container .steps-item:last-child {
            display: block;
            margin: 0 auto;
        }
    }


    // FOOTER CHECKOUT
    .footer-checkout {
      .logo {
        display: none;
      }

      .panel-right {
        float: none;
        text-align: center;

        .link {
          display: block;
          margin: 5px 0 0;
        }
      }
    }


    .appointment-content {
      padding: 0;
    }
  }

  .checkout-onepage-success {
    .summary-content {
      margin: 20px 0 0;
    }
  }

  #checkout-step-shipping {
    .form-login {
      margin-top: 70px;
    }
  }
}

// =============================================
// Responsive 700px
// =============================================
@include respond-to(700px) {
  .easyentretien-checkout-steps {
    .options-content {
      .modal-content {
        width: 60%;
      }

      .modal-picture {
        padding: 0 20px 0 0;
        width: 40%;
      }
    }
  }
}

// =============================================
// Responsive 600px
// =============================================
@include respond-to(600px) {
  .easyentretien-checkout-steps {
    .page-header {
        .panel.wrapper {display: block;}

        .panel.header {
            .panel-left .reviews-widget,
            .panel-center{
                display: none;
            }
        }
    }

    .options-content {

      .modal-content {
        float: none;
        width: 100%;
        margin: 0;
      }

      .modal-body {
        overflow: visible;
      }

      .modal-picture {
        position: static;
        width: 100%;
        margin: 0 0 20px;
        padding: 0;
        @include transform(translateY(0%));

        img {
          width: 100%;
          max-width: 300px;
        }
      }

      .modal-bg {
        padding: 40px 20px;
        overflow: auto;

        &:before {
          display: none;
        }
      }
    }

    .content-steps-appointment {
      .ui-datepicker-prev {
        left: 5%;
      }

      .ui-datepicker-next {
        right: 5%;
      }

      .time-slot {
        .morning-content, .afternoon-content {
          float: none;
          width: 100%;
          padding: 0;
        }

        .morning-content {
          margin: 0 0 20px;
        }
      }

      .time-slot-confirm {
        margin: 20px auto;
        padding: 20px 0;
      }
    }

    .back-content span {
      display: block;
    }
  }
}

// =============================================
// Responsive 600px
// =============================================
@include respond-to(600px) {
  .easyentretien-checkout-steps {
    .page-header {
      .infos {
        .icon {
          margin-left: -3px;
        }
        .number {
          a {
            width: 39px;
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
