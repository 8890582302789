// =============================================
// Product view
// =============================================

// Content
.catalog-product-view {
    .product-top-left {
        float:   left;
        width:   60%;
        padding: 0 20px 0 0;
    }
    .product-top-right,
    .product-name-container {
        float: right;
        width: 40%;
    }
    // Back Button
    .back-product { }
    .col-main { }
    .product-view { }
    // Content
    .product-top-details { margin: 0 0 20px; }
    .product-img-box {
        position:   relative;
        text-align: right;
        .product-image {
            padding: 0 0 0 100px;
            a {
                opacity: 0;
                @include transition(all 350ms ease-in-out);
                display:    block;
                visibility: hidden;
                z-index:    1;
                position:   absolute;
                top:        0;
                right:      0;
                &.is-active {
                    position:   relative;
                    z-index:    2;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .more-views {
            left:       0;
            position:   absolute;
            text-align: center;
            top:        0;
        }
        .more-views-link {
            img { }
            &.active,
            &:hover {
                img { }
            }
        }
        .more-views-item {
            a {
                border:  2px solid $c-grey-light;
                display: inline-block;
                @include transition(all 0.3s ease-out);
                &:hover {
                    border: 2px solid $c-black;
                }
                &.is-active {
                    border: 2px solid $c-black;
                }
            }
        }
        .btn-zoom {
            font-size: 2.4rem;
            margin:    10px 0 0;
        }
    }
    .product-name-container .product-name {
        font-weight: 500;
        font-size:   2.4rem;
        line-height: 30px;
        margin:      0 0 10px;
    }
    .product-cart-sku {
        color:       $c-grey-light;
        font-weight: 500;
        font-size:   1.2rem;
    }
    .product-price {
        margin: 20px 0 15px;
        .price {
            font-size: 2.6rem;
        }
    }
    .short-description {
        margin:      0 0 20px;
        line-height: 20px;
    }
    .product-info { }
    .product-shop {
        .top {
        }
        // Product Options
        .product-options {
            margin: 0 0 20px;
            .product-options-list { }
            label,
            .input-box { }

            label {
                text-transform: none;
                font-size:      1.2rem;
            }
        }

        // Add To Cart
        .add-to-cart {
            margin: 0 0 20px;
            .qty-content { }
            .qty-text { }
            .qty { }
            .customdropbg {
                width:   75px;
                display: inline-block;
                margin:  0 20px 10px 0;
                .customdroptext {
                    background-color: $c-white;
                    border:           1px solid $c-input-border;
                    padding:          0 25px;
                }
            }
        }
        .btn-cart {
            display:        inline-block;
            margin:         0 0 10px;
            width:          auto;
            letter-spacing: 1px;
        }
    }
    .sticky-products {
        right: 0;
        span {
            margin-right: 0px;
        }
    }
    // Product Size
    .size-content {
        margin: 0 0 20px;
        li {
            border:         2px solid $c-black;
            display:        inline-block;
            font-weight:    bold;
            height:         25px;
            line-height:    24px;
            margin:         0 10px 0 0;
            padding:        0 5px;
            vertical-align: middle;
            @include transition(all 0.3s ease-out);
            &:hover {
                background-color: $c-black;
                color:            $c-white;
            }
            &.active {
                background-color: $c-black;
                color:            $c-white;
            }
        }
    }
    //Product Colors
    .colors-content { margin: 0 0 30px; }
    .colors-list {
        margin: 10px 0 0;
        li {
            color:       $c-grey-light;
            display:     inline-block;
            font-weight: bold;
            margin:      0 15px 0 0;
            position:    relative;
        }
        img {
            border:         2px solid $c-transparent;
            margin:         0 5px 0 0;
            vertical-align: middle;
            padding:        5px;
            @include border-radius(50%);
            @include transition(all 0.3s ease-out);
        }
        a {
            color: rgba($c-black, 0.6);
            &:hover { color: $c-black; }
            &.active img,
            &:hover img { border-color: $c-black; }
        }
    }

    // Block Store Choice
    .availability {
        font-size: 1.5rem;
        padding:   10px;
        position:  relative;
        .icon {
            border:        1px solid;
            border-radius: 100%;
            padding:       5px;
            position:      absolute;
            left:          10px;
            top:           15px;
        }
    }
    .product-stock {
        background: lighten( $c-project1, 70% );
        border:     1px solid $c-project1;
        color:      $c-project1;
    }
    .product-out-of-stock {
        background: lighten( $c-project5, 70% );
        border:     1px solid $c-project5;
        color:      $c-project5;
    }

    .availability-content {
        font-weight: bold;
        line-height: 20px;
        padding:     0 0 0 40px;
        span {
            display:     block;
            font-weight: normal;
            font-size:   1.2rem;
        }
    }

    .product-choice-store {
        border:  1px solid $c-input-border;
        padding: 20px;
        .block-title {
            font-size:      1.2rem;
            font-weight:    500;
            margin:         0 0 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        .icon {
            font-size: 2.2rem;
        }
        .button {
            display:        inline-block;
            margin:         0 0 10px;
            vertical-align: middle;
        }
        .block-zipcode {
            display:        inline-block;
            margin:         0 0 10px;
            vertical-align: top;
        }
        span {
            color:          $c-grey-light;
            display:        inline-block;
            margin:         0 5px;
            text-transform: uppercase;
        }
        input {
            background:     $c-grey-light;
            border:         1px solid $c-input-border;
            height:         40px;
            line-height:    40px;
            padding:        0 10px;
            text-transform: uppercase;
        }
        input[type="submit"] {
            background: $c-black;
            border:     1px solid $c-black;
            color:      $c-white;
            cursor:     pointer;
            margin:     0 0 0 -5px;
            width:      40px;
            @include transition(all 0.3s ease-out);
            &:hover {
                background: $c-grey-light;
                color:      $c-black;
            }
        }
    }
    .popin-store-stock {
        display:    none;
        max-width:  500px;
        margin:     auto;
        .h2-title {
            text-align: center;
            margin: 0 0 40px;
        }
        .store-item {
            background-color:   $c-grey-light;
            margin:             20px 0;
            height:             40px;
            line-height:        40px;
            padding:            0 210px 0 20px;
            position:           relative;
        }
        .button {
            position:   absolute;
            right:      0;
            top:        0;
            width:      200px;
        }
    }
    .popin-store-stock-content {
        margin: 0 0 35px;
    }

    // Icon Social
    .social-content {
        margin: 20px 0 0;
        .social-link {
            border:      1px solid;
            color:       $c-grey-light;
            display:     inline-block;
            font-size:   15px;
            margin:      0 15px 0 0;
            line-height: 34px;
            height:      32px;
            width:       32px;
            text-align:  center;
            @include border-radius(100%);
            &:hover {
                color: $c-black;
            }
        }
        .icon_facebook:before {
            margin: 0 0 0 6px;
        }
    }
    // Description
    .product-bottom-details {
        margin:      0 -10px;
        line-height: 20px;
        .description, .composition {
            padding: 0 10px;
            float:   left;
            width:   50%;
            .content {
                background: $c-grey-light;
                padding:    40px 12%;
                min-height: 200px;
            }
        }
    }
    .product-collateral { }
    // Upsell
    .box-up-sell {
        margin: 30px -5px;
        .products-grid {
            margin: 0;
        }
    }

    // See Also
    .discoveralso {
        .h2-title { text-align: center; }
        .discoveralso-product {
            position: relative;
            strong {
                bottom:      15px;
                color:       $c-white;
                display:     block;
                font:        500 2.5rem;
                left:        0;
                line-height: 30px;
                padding:     0 15px;
                position:    absolute;
                text-align:  center;
                width:       100%;
                z-index:     2;
            }
        }
    }

    .box-collateral {
        .h2-title { text-align: center; }
    }
    .products-grid { width: 100%; }

    // Most Viewed / Reclently Viewed
    .block-related,
    .block-viewed {
        .block-title {
            strong { }
        }
        li {
            .product-image { }
        }
    }
    .block-related { }
    .std { }
}
//
// Responsive
//

@include respond-to(medium) {
    .catalog-product-view {
        .product-img-box {
            .product-image {
                padding: 0 0 0 75px;
            }
            .more-views-item {
                a {
                    width: 70px;
                }
            }
            .btn-zoom {
                left: -50px;
                top:  360px;
            }
        }
    }
}
@include respond-to(768px) {
    .catalog-product-view {
        // See Also
        .discoveralso {
            .discoveralso-product {
                strong { font-size: 2rem; }
            }
        }
    }
}
@include respond-to(small) {
    .catalog-product-view {
        .product-name-container {
            text-align: center;
            margin:     0 0 30px;
        }
        .product-top-left {
            float: none;
            width: 100%;
        }
        .product-top-right, .product-name-container {
            float: none;
            width: 100%;
        }
        .product-img-box {
            .product-image {
                padding: 0;
            }
            .more-views {
                position: static;
            }
            .more-views-list {
                text-align: center;
            }
            .more-views-item {
                display: inline-block;
                margin:  0 5px;
                a {
                    width: 50px;
                }
            }
            .btn-zoom {
                display: none;
            }
        }
        .sticky-products {
            //right: -20px;
        }
        .product-price {
            text-align: center;
            margin:     20px 0;
        }
        .product-shop {
            .add-to-cart {
                .customdropbg {
                    display: block;
                    float:   none;
                    margin:  0 0 20px 0;
                    width:   100px;
                }
            }
            .btn-cart {
                display: block;
                height:  50px;
                width:   100%;
            }
        }
        .product-bottom-details {
            .description, .composition {
                padding: 0;
                float:   none;
                width:   100%;
                .content {
                    background: $c-grey-light;
                    padding:    40px 12%;
                    min-height: 200px;
                }
            }
        }

        .box-collateral {
            max-width:  480px;
            margin:     30px auto;
        }
        // See Also
        .discoveralso {
            .discoveralso-product { margin: 0 0 20px; }
        }
    }
}
