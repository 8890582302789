//
// Sorter / Pager / Limiter
//
.toolbar {
    a {
        &:hover,
        &.active { }
    }
    label { }

    .pages {
        > strong { }
        ol { }
        li {
            &.current { }
        }
        a {
            &:hover { }
        }
    }
    .sorter {
        .view-mode {
            a { }
            label { }
            strong { }
        }
        .sort-by {
            float:  right;
            margin: 0 10px;
            a { }
            label {
                color:  $c-black;
                font:   600 1.2rem;
                float:  left;
                margin: 6px 15px 0;
            }
        }
    }
    .amount { }
    .pager {
        .limiter { }
    }
}