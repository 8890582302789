// =============================================
// Price
// =============================================
.price-box {
    .special-price {
        display: inline-block;
        .price { }
    }
    .old-price {
        display: inline-block;
        margin: 0 0 0 10px;
        .price {
            text-decoration: line-through;
            font-size: 2.2rem;
            color: $c-project2;
            font-weight: normal;
        }
    }
    .regular-price {
        .price { }
    }
    .price {
        font-weight: 600;
        font-size: 2rem;
    }
}
