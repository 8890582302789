// =============================================
// Skin of a product item
// =============================================

.product-item {
    &.directenergie,
    &.foncia,
    &.myfoncia {
        overflow: hidden;
        .web-price {
            top: -65px;
            .web-price-content {
                font-size:      1.3rem;
                height:         105px;
                line-height:    160px;
                max-width:      200px;
                text-transform: uppercase;
                width:          95%;
                @include border-radius(0 0 50% 50%);
            }
        }
    }
    &.directenergie {
        .web-price {
            .web-price-content {
                background:     $c-project10;
                color:          $c-project2;
            }
        }
        .price-box .price {
            color: $c-project2;
        }
        .listactions li .icon {
            color: $c-project10;
        }
        .button {
            background: $c-project10;
            border:     2px solid $c-project10;
            color:      $c-project2;
            &:hover {
                background: transparent;
            }
        }
    }
    &.foncia,
    &.myfoncia {
        .web-price {
            .web-price-content {
                background:     $c-foncia1;
                color:          $c-white;
            }
        }
        
        .button {
            &:hover {
                background: transparent;
                border:     2px solid $c-foncia1;
                color:      $c-foncia1;
            }
        }
    }
    .desc { // PDC
        border-bottom: 1px solid $c-project5;
        border-top: 1px solid $c-project5;
        padding: 5px 0;
        margin: 20px 10px;
        .pdc-price {
            color: $c-project5;
        }
    }

    .info-block {
        margin: 20px 0;

        @include breakpoint(sm) {
            margin: 20px 10px;
        }
    }

    .services-list {
        flex: 1;
    }
}
.web-price{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    .web-price-content{
        display: inline-block;
        margin: -1px auto 0;
        width: 95%;
        max-width:175px;
        height: 30px;
        line-height: 30px;
        background: $c-project5;
        color: $c-white;
        @include border-radius(0 0 5px 5px);
        font-family: $montserrat;
        font-size: 1.5rem;
    }
}

.product-item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    padding: 45px 5px 35px;
    background: $c-white;
    border-radius: $radius-banner;
    box-shadow: 0px 20px 30px 0px #0000000D;

    @include breakpoint(sm) {
        flex: 1;
        max-width: 25%;
    }


    @include transition(all 250ms ease-in-out);
    vertical-align: top;
    // Hover
    &:hover {
        @include transform(scale(1.1));
        box-shadow: 0 2px 51px rgba(0, 0, 0, 0.14);
        z-index: 2;
    }
    .product-name{
        margin: 0 3rem;
        padding-bottom: 20px;
        font-family: $roboto;
        color: $c-project2;
        font-size: 3rem;
        font-weight: bold;
        border-bottom: 1px solid $c-project4;

        span{
            font-weight: normal;
            display: block;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-size: 1.2rem;
            color: $c-project3;
        }
    }
    .internet-rate-text {
        color: $c-project2;
        font-size: 1.4rem;
        margin: 15px 0;
    }
    .price-box {
        margin-bottom: 15px;
        display: inline-block;
        vertical-align: middle;
        .price{
            font-family: $montserrat;
            font-size: 3.4rem;
            font-weight: normal;
            color: $c-project5;
        }
        .price-excluding-tax{
            display: none;
        }
    }
    .per-month{
        color: tint($c-project2, 50%);
        display: inline-block;
        vertical-align: middle;
        font-size: 1.1rem;
        margin: 0;
    }
    .rate-agency-text {
        span {
            color: tint($c-project2, 50%);
            display: block;
        }
    }

    .listactions{
        text-align: left;
        display: flex;
        flex-direction: column-reverse;

        li{
            padding: 7px 7px 7px 45px;
            font-size: 1.6rem;
            color: $c-project2;
            position: relative;
            .icon{
                font-size: 1.1rem;
                color: $c-validate;
                position: absolute;
                left: 17px;
                top: 11px;
            }

            &.not-included{
                position: relative;
                color: tint($c-project2, 50%);

                &::before {
                    content: '';
                    position: absolute;
                    left: 17px;
                    top: 0;
                    width: 15px;
                    height: 100%;
                    background: url('../images/icons/uEA21-cross.svg') center no-repeat;
                }
            }
            &.free-option{
                color: #008000;
                font-weight: bold;
                .icon{
                    color: #008000;
                }
            }
        }
    }
    .buttons-set{
        margin-top: 30px;


        .btn {
            padding: 10px;
            width: calc(100% - 34px);
            margin: 0 auto;
            font-size: 2rem;
            font-weight: 500;
        }
    }
}

.head-product{
    padding: 5px 0 0 0;
    //flex: 1;
}

.product-item-infos {
    padding: 30px 0 0 0;
    min-height: 145px;
}
.product-name {
    font-size: 1.4rem;
}

@include respond-to(medium){
    .product-item{
        .product-name{
            font-size: 2rem;
        }
        .price-box {
            .price{
                font-size: 3rem;
            }
            .old-price .price{
                font-size: 2rem;
            }
        }
        .buttons-set{
            .button{
                width: 100%;
                padding: 0;
                font-size: 1.1rem;
            }
        }
    }
}

@include respond-to(small){
    .web-price{
        left: auto;
        .web-price-content{
            width: 95px;
            height: 25px;
            line-height: 25px;
            @include border-radius(0 0 0 5px);
            font-size: 1.3rem;
        }
    }
    .product-item {
        width: auto;
        display: block;
        padding: 40px 20px;
        @include transition(none);
        margin: 0 10px 40px 10px;
        &:last-child{
            margin: 0;
        }
        // Hover
        &:hover {
            @include transform(scale(1));
            box-shadow: none;
        }

        .product-name{
            margin: 0 0 5px 0;
            font-size: 3rem;
        }

        .price-box {
            display: block;
            margin: 0 auto;

            .price{
                font-size: 3.6rem;
            }

            span {
                display: block;
            }

            .price-including-tax *{
                display: inline-block;
            }

        }
        .per-month{
            display: inline-block;
            vertical-align: bottom;
            margin: 0 0 3px 5px;
            font-size: 1.4rem;
        }
        .listactions{
            overflow-x: auto;
            padding: 15px 0 0;
            font-size: 0;

            li{
                margin: 5px 0;
                padding-top: 0px;
                padding-left: 30px;
                display: inline-block;
                vertical-align: top;
                font-size: 1.6rem;

                .icon{
                    top: 0;
                    left: 0;
                    display: block;
                    margin: 0 auto 7px;
                }
                &:nth-child(odd){
                    background: none;
                }
                &.not-included{
                    color: tint($c-project2, 50%);

                    &::before {
                        left: 0;
                    }
                }
            }
        }
        .buttons-set{
            margin: 25px 0 0 0;
        }
    }
    // Fix issue change category slider item break (width 0)
    .slick-initialized .slick-slide.product-item {
        width: calc(100vw - 100px);
        
        &:first-child {
            margin-left: 50px;
        }
    }

    .head-product{
        padding: 5px 15px 15px;
        text-align: center;
    }
    .product-name {
        font-size: 1.4rem;
    }
}
