// =============================================
// HomePage
// =============================================

// Header - menu
.cms-home .page-header {
    .panel-right {
        .infos {
            &>span {
                display: none;
            }
        }
    }
}

// SponsorShip
.sponsorship-home {
    &__content {
        margin-bottom: 75px;

        @include breakpoint(lg) {
            display: flex;
            gap: 50px;
        }

        //Banner
        .sponsorship-banner {
            position: relative;
            display: flex;
            overflow: hidden;
            padding: 60px 50px;
            max-width: 1000px;
            border-radius: $radius-banner;
            background-repeat: no-repeat ;

            @include breakpoint(lg) {
                width: 75%;

                &__left,
                &__right {
                    position: relative;
                    width: 50%;
                    z-index: 5;
                }
            }


            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(239, 96, 78, 0.6);
                // Animation
                transform: scaleX(0);
                transform-origin: right;
                transition: transform .6s linear;

                @include breakpoint(lg) {
                    background: linear-gradient(270deg, #EF604E 0%, rgba(239, 96, 78, 0) 100%);
                }
            }

            &__right {
                z-index: 5;

                p {
                    margin: 0;
                    font-size: 3rem;
                    font-weight: 600;
                    line-height: 3.6rem;
                    color: $c-white;
                }

                .btn__white {
                    margin-top: 20px;
                    padding: 15px 30px;
                    border-color: transparent;
                    font-size: 2rem;
                    color: $c-main;

                    &:hover {
                        color: $c-white;
                        border-color: $c-grey-5;

                    }
                }
            }
        }

        //Sponsor
        .sponsorship-partners {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            margin-top: 25px;

            @include breakpoint(only-lg) {justify-content: space-around;}

            @include breakpoint(lg) {
                max-width: 30%;
                margin-top: 0;
            }

            li {
                margin: 20px;
                // Animation
                opacity: 0;
                transform: translateX(-50%);
                @for $i from 1 through 5 {
                    $timeMultiplyer: calc(#{$i}/2);
    
                    &:nth-child(#{$i}) {
                        transition: all .5s calc(0.2s * #{$timeMultiplyer}) linear;
                    }
                    
                }
                
            }
        }
    }

    // Animation
    &.animate-in {
        .sponsorship-home__content .sponsorship-banner::before {
            transform: scaleX(1);
        }

        .sponsorship-home__content .sponsorship-partners li {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

// Reinssurance
.reinssurance-home{
    text-align: center;
    padding: 100px 0;

    .h2-title{
        margin: 10px 0;
        font-size: 3.6rem;
        font-weight: 600;
    }

    .light-title {
        font-size: 1.8rem;
        color: $c-project3;

        @include respond-to($small) {
            margin: 25px 0;
            line-height: 2.5rem;
            padding: 10px;
        }
    }
}

.reinssurance-home-list {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 50px 0 0 0;

    li{
        padding: 40px 20px;
        background-color: $c-white;
        box-shadow: 0px 20px 30px 0px #0000000D;
        text-align: center;
        border-radius: $radius-banner;

        img{
            max-width: 165px;
        }

        .reinssurance-text{
            position: relative;
            margin: 20px 0 0 0;
            font-family: $roboto;
            font-size: 1.8rem;
            line-height: 2.1rem;
            font-weight: 600;
            color: $c-project2;
            
            // Black Text
            span {
                display: block;
                background: white;
                position: relative;
                z-index: 2;
            }
        
            // Orange text
            strong {
                font-size: 2.2rem;
                line-height: 3rem;
                font-weight: 400;
                color: $c-project5;
                
                //Animation
                @media (min-width: $small) {
                    display: block;
                    position: relative;
                    z-index: 1;
                    transform: translateY(-90%);
                    opacity: 0;
                    transition: all .3s .3s ease-in;
                }
            }
        
        }

        // Animation transform
        &:nth-child(1) {
            z-index: 4;
            transition: transform .4s cubic-bezier(.47,1.64,.41,.8);

            @media (min-width: $small) {
                transform: translateX(calc(150% + 20px)); // 20px for gap above 
            }
            
        }
        &:nth-child(2) {
            z-index: 3;
            transition: transform .4s .3s cubic-bezier(.47,1.64,.41,.8);
            transform: translateY(calc(-100%)); // 20px for gap above

            @media (min-width: $small) {
                transform: translateX(calc(50% + 20px)); // 20px for gap above
            }
        }
        &:nth-child(3) {
            z-index: 2;
            transition: transform .4s .2s cubic-bezier(.47,1.64,.41,.8);
            transform: translateY(calc(-200%)); // 20px for gap above
            
            @media (min-width: $small) {
                transform: translateX(calc(-50% + 20px)); // 20px for gap above
            }
        }
        &:nth-child(4) {
            z-index: 1;
            transition: transform .4s .1s cubic-bezier(.47,1.64,.41,.8);
            transform: translateY(calc(-300% + 60px)); // 20px for gap above
            
            @media (min-width: $small) {
                transform: translateX(calc(-150% + 20px)); // 20px for gap above
            }
        }
    }

    &.animate-in {
        li {transform: translateX(0);}

        .reinssurance-text strong { transform: translateY(0); opacity: 1;} 
    }
}


//
// Products
//
.push-product{
    position: relative;
    padding: 0 20px;
    background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0) 100%);

    // VueJs Transition
    .expandgaz-transition,
    .expandfioul-transition {
        transition: all .5s ease;
    }
    .expandgaz-enter,
    .expandgaz-leave,
    .expandfioul-enter,
    .expandfioul-leave
    {
        opacity: 0;
    }
}

.push-product-head{
    text-align: center;
    padding: 100px 0 40px 0;
}

.push-product-title{
    font-family: $roboto;
    font-size: 3.6rem;
    margin: 0 0 50px 0;
    color: $c-main;
    font-weight: 600;
}

.push-product-tabs{
    margin: 0 auto;
    display: flex;
    gap: 10px;
    justify-content: space-around;

    .tab{
        cursor: pointer;
        padding: 10px;

        text-align: center;
        font-size: 1.8rem;
        font-family: $roboto;
        background: $c-white;
        border: 1px solid $c-project4;
        border-radius: 6px;

        @include breakpoint(sm) {
            flex: 1;
        }

        p {
            margin: 0;
        }



        @include respond-to(small) {
            width: 100%;
        }

        @include breakpoint(xl) {
            display: flex;
            padding: 10px 40px;
            height: 60px;
            gap: 10px;
            justify-content: center;
            align-items: center;
        }



        @include transition(all 0.3s linear);

        &:hover{
            background: $c-project2;
            color: $c-white;
        }

        &.active{
            position: relative;
            background: $c-project2;
            color: $c-white;

            &::after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: calc(50% - 5px);
                width: 10px;
                height: 10px;
                background: $c-project2;
                transform: rotate(45deg);
            }
        }

        &:hover,
        &.active {
            .icon-category {

                &.ch_gaz {
                    background: url('../images/icons/uEA19-ch_gaz_white.svg') center no-repeat;
                }

                &.ch_fioul {
                    background: url('../images/icons/uEA20-ch_fioul_white.svg') center no-repeat;
                }

                &.pac {
                    background: url('../images/icons/uEA09-pump_white.svg') center no-repeat;
                }

                &.clim {
                    background: url('../images/icons/uEA12-clim_white.svg') center no-repeat;
                }
            }
        }

        .icon-category{
            margin: 0 auto 10px;
            @include breakpoint(xl) {
                margin: 0;
            }
        }

        .icon{
            font-size: 4rem;
            margin: 0 25px 0 0;
            vertical-align: middle;
            position: relative;
            top: -2px;
        }
        span { text-transform: uppercase; }
    }
}

.block-products-list:first-child{
    display: none;
}

.block-products-list {
    left: 0;
    right: 0;
}

.push-product-content{
    margin: 0 auto;

    .products-grid{
        position:relative;
        z-index: 10;

    }
}

.product-items{
    padding: 20px 0;
    font-size: 0;
    text-align: center;
    justify-content: center;
    gap: 10px;

    // Animation
    .product-item {
        opacity: 0;

        @for $i from 1 through 4 {
            $timeMultiplyer: calc(#{$i}/4);

            &:nth-child(#{$i}) {
                transition: opacity .2s calc(.2s * #{$timeMultiplyer}) linear, transform .25s ease-in;
            }
            
        }
    } 

    &.animate-in {
        .product-item {
            opacity: 1;

            @for $i from 1 through 4 {
                $timeMultiplyer: calc(#{$i}/4);

                &:nth-child(#{$i}) {
                    animation: scaledAndShadow .3s calc(.3s * #{$timeMultiplyer}) linear;
                }
                
            }
           
        }
    }

    
    .price-box .special-price, 
    .price-box .old-price {
        display: block;
    }

    @media (min-width: $small) {
        display: flex;
    }
}

//
// One shot product
//
#one-shot-container {
    
    // Animation
    .one-shot-banner {
        opacity: 0;
        transform: translateY(-50%);
        transition: all .3s linear;
    }

    &.animate-in .one-shot-banner{
        transform: translateY(0);
        opacity: 1;
    }
}

.one-shot-banner{
    margin-top: 50px;
    padding: 40px 60px;
    background: $c-project5;
    border-radius: $radius-banner;

    .one-shot-content{
        position: relative;


        @include breakpoint(xl) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .price-excluding-tax { display: none; }
    }

    .one-shot-left {
        @include breakpoint(xl) {width: 25%;}
        padding-right: 10px;

        .one-shot-title{
            font-size: 3rem;
            font-weight: 600;
            padding: 0 0 20px 0;
            color: $c-white;
            border-bottom: 1px solid $c-white;
        }
        .one-shot-price{
            font-size: 1.4rem;
            font-family: $roboto;
            font-weight: 400;
            margin: 20px 0px 5px 0;
            color: $c-white;
        }
        .price-container {
            display: flex;
            align-items: baseline;
            gap: 10px;
            color: $c-white;
        }
        .price-box {
             .price-container.price-final_price {
                font-size: 3.6rem;
                color: $c-white;
             }
        }
        .asterix{
            display: block;
            padding: 10px 0 0 0;
            font-size: 1.5rem;
            color: $c-white;
        }
    }

    .one-shot-list{
        color: $c-white;

        @include breakpoint(xl) {padding: 17px 0;}
        @include breakpoint(only-xl) {
            display: inline-block;
            width: 45%;
            margin: 15px 0;
            vertical-align: top;
        }

        li {
            margin: 10px 0;
            font-size: 1.6rem;
            color: $c-white;

            .icon{
                font-size: 1.3rem;
                margin: 0 10px 0 0;
            }

            &.not-included {
                position: relative;
                padding-left: 45px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 17px;
                    top: 0;
                    width: 15px;
                    height: 100%;
                    background: url('../images/icons/uEA21-cross.svg') center no-repeat;
                }
            }
        }
    }

    .one-shot-right{
        .btn__white {
            font-family: $roboto;
            padding: 15px 30px;

            font-size: 2rem;
            border-color: transparent;

            @include breakpoint(md) {
                min-width: 300px;
            }

            &:hover {
                color: $c-white;
                background-color: $c-project2;
            }
        }
    }
}

//
// Block Partners
//
.partners-home{
    position: relative;
    margin-top: 100px;
    padding: 50px 0;
    border-top: 1px solid $c-main;


    &.animate-in {

        .partners-home-content .partners-slider{
            // Animation
            .slick-slide {
                transform: translateX(0);
            }
        }
    }

    .partners-home-content{

        .h4-title{
            margin: 30px 0;
            font-size: 2.4rem;
            text-align: center;
            font-weight: 600;
        }

        .partners-slider {
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 300px;
                height: 100%;
                z-index: 5;
                background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
                transform-origin: center;
                transform: rotate(180deg);
            }

            // Animation
            @media (min-width: $small) {}
            .slick-slide {
                transform: translateX(100vw);

                @for $i from 1 through 7 {
                    $timeMultiplyer: calc(#{$i}/2);
    
                    &:nth-child(#{$i}) {
                        transition: transform .6s calc(0.1s * #{$timeMultiplyer}) cubic-bezier(.47,1.64,.41,.8);
                    }
                    
                }
                
            }
        }
    }

    .list-partners{
        display: flex;
        justify-content: center;
        gap: 4vw;
        overflow: hidden;

        li {
            min-width: 130px;
        }
    }
}

//
// Block Review
//
.push-reviews{
    position: relative;
    background-image: url("../images/engagement.jpeg");
    background-size: cover;
    padding: 100px 30px;
    border-top-left-radius: $radius-banner;
    border-top-right-radius: $radius-banner;

    // Animation
    &.animate-in {
        &::before {
            transform: scaleY(1);
        }

        .push-review-list {
            .review-item { opacity: 1;}

            @include breakpoint(sm) {
                .review-item:nth-child(1),
                .review-item:nth-child(2),
                .review-item:nth-child(3),
                .review-item:nth-child(4) {
                    transform: translateY(0);
                }
            }
        }

        .review_left {
            .h2-title,
            .light-text,
            .btn__main {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    // Overlay
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000BF;
        border-top-left-radius: $radius-banner;
        border-top-right-radius: $radius-banner;
        
        // Animation
        @media (min-width: $small) {
            transform: scaleY(0);
            transform-origin: top;
            transition: transform .6s linear;
        }
    }

    .push-review-content>div {
        position: relative;
        z-index: 2;

        @include breakpoint(sm) {
            display: flex;
            align-items: center;
        }

    }

    // Left
    .review_left {
        min-width: 30%;

        .h2-title,
        .light-text,
        .btn__main {
            // Animation
            @media (min-width: $small) {
                opacity: 0;
                transform: translateY(50px);
            }
        }


        .h2-title{
            font-size: 3.6rem;
            line-height: 4.3rem;
            font-weight: 600;
            color: $c-white;
            transition: all .4s .2s linear;
        }

        .light-text{
            margin: 30px 0;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $c-white;
            transition: all .4s .3s linear;
        }

        .btn__main {
            font-size: 2rem;
            padding: 15px 30px;
            transition: all .4s .4s linear;
        }
    }

    //// Right
    .push-review-list{

        @include breakpoint(sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .review-item {
            margin: 20px;
            padding: 40px;
            border-radius: $radius-banner;
            background: tint($c-project3, 95%);

            // Animation
            @media (min-width: $small) {
                opacity: 0;
                transition: all .5s .2s linear;
            }

            @include breakpoint(sm) {
                width: 40%;
            }

            .review-header {
                display: flex;
                justify-content: space-between;

                div:nth-child(2) {
                    max-width: 90px;
                }
            }

            p{
                font-size: 1.4rem;
                color: $c-project2;
                margin: 0;
                text-align: justify;

                &.review-name{
                    font-family: $roboto;
                    font-size: 1.6rem;
                    margin: 0 0 5px 0;
                }

                &.review-date{
                    font-size: 1.2rem;
                    color: $c-project3;
                    margin: 0 0 15px 0;
                }
            }
        }

        @include breakpoint(sm) {
            .review-item:nth-child(1),
            .review-item:nth-child(3) {
                margin-bottom: 70px;
                margin-top: -30px;
                // Animation
                transform: translateY(100px);
            }

            .review-item:nth-child(2),
            .review-item:nth-child(4) {
                transform: translateY(-100px);
            }
        }
    }

    .allReviews-link {
        position: relative;
        font-weight: 600;
        z-index: 5;

        a {
           color: $c-main;
        }

    }

}

//
// FAQ
//
.faq-home {
    padding-top: 100px;
    padding-bottom: 100px;

    .faq {
        &__head {
            .h2-title {
                margin: 10px 0;
                font-size: 3.6rem;
                font-weight: 600;
            }

            p {
                max-width: 800px;
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $c-project3;
            }
        }

        &__category {

            @include breakpoint(sm) {
                display: flex;
                gap: 20px;
                margin: 30px 0;
            }


            .category-item {
                cursor: pointer;
                padding: 10px;
                font-size: 1.6rem;
                border-radius: $radius-s;
                background-color: $c-grey-light;

                &.active {
                    color: $c-white;
                    background-color: $c-project5;
                }
            }
        }

        &__content {
            padding: 20px 50px;
            border: 20px solid $c-grey-light;
            border-radius: $radius-l;

            .faq-questions-answers {
                padding: 20px 0 ;
            }
            .faq-questions-answers:not(:first-child) {
                border-top: 2px solid $c-grey-light;
            }
        }

        &__question {
            cursor: pointer;
            position: relative;
            padding-right: 100px;
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.6rem;

            &::after,
            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                height: 40px;
                width: 40px;
            }

            // Background grey
            &::after {
                border-radius: $radius-s;
                background-color: $c-grey-light;
            }
            // Icon
            &::before {
                z-index: 2;
                background-image: url("../images/icons/faq-plus.svg");
                background-repeat: no-repeat;
                background-position: 50%;
                transform-origin: center;
                transition: transform .2s ease-in;
            }

            &.active {
                &::before {
                    transform: rotate(-45deg);

                }
            }
        }

        &__answer {

            font-size: 1.5rem;
            line-height: 2.4rem;

            .answer-container {
                margin-top: 30px;
                
                    .pagebuilder-column-group .pagebuilder-column-line {
                           
                        @include breakpoint(only-sm) {
                            display: block !important;

                            .pagebuilder-column { width: 100% !important;}
                        }
                         
                    }
            }

            ul li {
                position: relative;
                padding-left: 30px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    background-image: url('../images/icons/uEA23-coche_green.svg');
                    background-position: 50%;
                    background-repeat: no-repeat;
                }
            }

            img.pagebuilder-mobile-hidden {
                display: none;
            }

            @include breakpoint(md) {
                img.pagebuilder-mobile-hidden {
                    display: block;
                }

                img.pagebuilder-mobile-only {
                    display: none;
                }
            }
        }
    }
}


// Asterisk
.asterisk-character {
    font-weight:    bold;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
}

//
// Responsive
//
@include respond-to(medium) {
    .push-review-col{
        width: 50%;
        &:last-child{
            display: none;
        }
    }
}

@include respond-to(small) {
    .sponsorship-home {
        .sponsorship-partners {
            justify-content: center;
        }
    }

    // Reinssurance
    .reinssurance-home{
        padding: 75px 0;

        &__content {
            padding: 0 35px;
        }
    }
    .reinssurance-home-list{
        margin: 10px 0 0 0;
        display: inline-block;
        li{
            display: block;
            float: none;
            width: auto;
            margin: 0 0 20px 0;
            img{
                max-width: 60px;
                display: inline-block;
                vertical-align: middle;
                margin: 0 15px 0 10px;
            }
        }
    }
    .reinssurance-text{
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        width: 70%;
    }


    //
    // Products
    //
    .block-products-list {
        position: static;
        padding: 0;
    }
    .push-product{
        padding: 0px;
        height: auto;
    }

    .push-product-title{
        padding: 0 20px;
        font-size: 3.6rem;
    }
    .push-product-tabs {
        padding: 0 20px;

        .tab{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 1.4rem;
            width: 40%;

            .icon{
                display: none;
            }
        }
    }

    .push-product-content .products-grid {
        margin: 0;

        .widget-product-grid-mobile {

            @media screen and (max-width: $small) {
                .info-block {
                    padding: 20px 15px;

                    &::before {
                        top: 15px;
                        transform: translateY(0);
                    } 
        
                    .info-block__text {
                        text-indent: 25px;
                        padding: 0;
                    }
                }
            }
        }
    }


    //
    // One shot product
    //
    #one-shot-container {
        width: calc(100vw - 50px);
        

        .one-shot-banner {
            padding: 30px 20px;
            height: auto;

            .one-shot-left {
                width: 100%;
                padding: 0;
            }

            .price-container {
                .n-time {
                    font-weight: 600;
                }
            }
        }

        .one-shot-title{
            margin: 0 0 15px 0;
            font-size: 3rem;
            text-align: center;
        }
        .one-shot-price{
            display: none;
        }

        .one-shot-list{
            border: none;
            width: auto;
            display: inline-block;

            &.includes {
                padding-top: 20px;
                padding-bottom: 0px;
            }

            &.not-included {
                padding-top: 0px;
                padding-bottom: 20px;
            }


            li{
                display: block;
                position: relative;
                padding-left: 30px;
                font-size: 1.6rem;
                text-align: left;
                margin: 10px 0;

                &:last-child{
                    text-align: left;
                }

                &.not-included::before {
                    left: 0;
                }

                .icon {
                    position: absolute;
                    font-size: 1.1rem;
                    top: 0;
                    left: 0;
                    display: block;
                    margin: 0 auto 7px;
                }
            }
        }
        .asterix{
            padding: 0;
            font-size: 1.3rem;
        }
        .one-shot-right{
            position: relative;
            top: 0;
            right: 0;
            //width: 260px;
            margin: 0 auto;
            .btn__white{
                padding: 10px;
                width: 100%;
            }
        }
    }

    //
    // Block Partners
    //
    .partners-home .partners-home-content{
        padding: 15px 0px;

        .partners-slider {
            &::before,
            &::after {
                width: 50px;
            }
        }

        .slick-slide {
            margin: 0 25px
        }
    }

    //
    // Block Review
    //
    .push-reviews{
        padding: 100px 36px;

        .h2-title {
            font-size: 2rem;
        }
        .buttons-set{
            margin: 20px 0 0 0;
        }

        .push-review-list {
            .review-item {
                margin: 20px 15px;
                padding: 30px 25px;

                p { 
                    text-align: start;
                    line-height:24px ;
                }
            }
        }    
    }
    .push-review-content{
        padding: 25px 0;

        .review_left {
            margin-bottom: 50px;
        }
    }
    .push-review-col{
        width: auto;
        float: none;
        padding: 60px 0 0;
    }
    .picture-review{
        position: absolute;
        top: 0;
        left: 50%;
        @include transform(translateX(-50%));
    }
    

    //
    // FAQ
    //
    .faq-home {
        padding: 100px 36px;


        .faq {
            &__category {
                display: flex;
                margin: 25px 0;
                padding: 10px 0;
                overflow-x: scroll;
                gap: 20px;

                .category-item {
                    white-space: nowrap;
                }
            }


            &__content {
                padding: 0px;
                border: none;
            }

            &__question {
                padding-right: 70px;
            }
        }
    }
}
