// =============================================
// Forms
// =============================================
//
// CONTENT
//
form,
.billing-address-form {
    font-family: $roboto;

    fieldset,
    .fieldset {
        border:     none;
        margin:     0 0 50px;
        padding:    0;
    }
    legend {
        font-size:      1.6rem;
        font-weight:    600;
    }
    label {
        display:        block;
        font-size:      1.6rem;
    }
    ul {
        li {
            margin: 0 0 15px 0;
        }
    }
    textarea,
    .textarea {
        resize:  vertical;
        padding: 10px;
        border: 1px solid $c-project4;
        border-radius: $radius-s;
        background:     $c-grey-light;
        &.validation-failed { border-color: $c-error-border !important; }
    }
    .field {
        margin: 0 0 15px;
        position: relative;
        .error{
            &:before {
                content:"X";
                color: $c-error;
                display: block;
                font-size:  2.4rem;
                position: absolute;
                right: 0;
                bottom: 25px;
                font-weight: bold;
            }
        }
        .checked{
            &:before {
                content:"\EA01";
                color: $c-project1;
                display: block;
                font-family: "iconfont";
                font-size:  2rem;
                position: absolute;
                right: 0;
                bottom: 10px;
            }
        }
        .control {
            position: relative;

            &.One-Time-Code {
                text-align: center;
            }
        }
    }

    .validation-advice {
        color: $c-error-border;
        font:  1.2rem;
    }

    .button {
        width:  235px;
        margin: 40px 0 0;
    }
    .password-strength-meter {
        color: $c-project3;
        font-size:  1.1rem;
        margin: 5px 0 0;
        span {
            font-weight: bold;
            font-size:  1.2rem;
        }
    }
    div.mage-error, div.field-error {
        font-size:  1.2rem;
        color:      $c-error;
        margin:     5px 0 0;
        position:   absolute;
        top:        100%;
    }
}

#checkout-step-shipping .form-login label,
.form-shipping-address label,
.billing-address-form label,
.payments label {
    margin: 15px 0;
}

 fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

input {
    margin:  0;
    padding: 0;
    &.mage-error {
        border-color: $c-error;
    }
}
._error input {
    border-color: $c-error;
}

.input-text {
    background:     $c-grey-light;
    border:         solid 1px $c-project4;
    border-radius:  $radius-s;
    color:          $c-input-text;
    font-family:    $roboto;
    font-size:      1.8rem;
    padding:        20px;
    width:          100%;
    
    @include transition(all 250ms ease);
    &:focus { border-color: $c-input-border; }
    &.validation-failed { border-color: $c-error-border; }

    &.validate {
        background-color: $c-white;
        border-color: $c-project1;
        color: $c-project1;

        
    }
}

div.control:has(.validate) {
    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 15px;
        height: 15px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../images/green_check.png");
    }
}
.textarea {
    @extend .input-text;
    height: $height-text-area;
    line-height: normal;
}
.form-text {
    margin: 0 0 20px;
}

select {
    @include appearance(none);
    background: #fff url(../images/select-bg.svg) no-repeat 100% 45%;
    background-size: 30px 60px;
    border: 1px solid $c-select-border;
    height: 40px;
    padding: 0 25px 0 10px;
    text-indent: .01em;
    border-radius: 5px;
    min-width:  50px;
    option:checked { color: red; }
}

//
// PlaceHolder
//
input {
    @include placeholder {
        color: $c-input-placeholder;
        font-size: 1.3rem;
        font-weight: normal;
    }
}

@include respond-to(small) {
    form .button {
        margin: 40px auto 0;
    }
}

// =============================================
// Custom Input
// =============================================

// Grid placement
// Grid
.grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;

    .grid-col-1 {
        grid-column: 1;
    }
    .grid-col-2 {
        grid-column: 2;
    }

    @media screen and (max-width:640px) {
        .grid-col-1,
        .grid-col-2 {
            grid-column: 1/3;
        }
    }
}

// Radio Btn
.radio-container {
    text-align: center;

    input[type="radio"] {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    label {
        width: 100%;
        line-height: 1.8;
        padding: 20px 25px;
        background-color: #F4F5F6;
        color: #A8B9C1;
        border-radius: 5px;
    }

    input[type="radio"]+label {
        position: relative;
        display: inline-block;
        min-height: 24px;
        cursor: pointer;
    }

    input:checked+label {
        background-color: $c-project5;
        color: #fff;
    }
}

.field-row {
    position: relative;
    padding: 20px 0px 20px 25px;
    border: 1px solid #D7DFE2;
    font-size: 16px;
    border-radius: 5px;

    
}

// Icone valid
form .field .field-row .checked:before {
    font-size: 1rem;
    right: 10px;
    bottom: 25px;
}

// Float Labels
.float-labels {
    
    input[type="text"],
    input[type="email"] {
        display: block;
        height: 28px;
        width: 100%;
        margin-top: 0px;
        font-size: 1.4rem;
        font-weight: 500;
        color: #282B31;
        border: 0 none;
        background-color: transparent;
        padding: 0 30px 0 0;
        border-radius: 0;
        -webkit-transition: all .2s;
        transition: all .2s;

        &::placeholder {
            opacity: 0;
        }
    }

    label {
        padding: 0;

        span {
            position: absolute;
            transform: translateY(-25px);
            color: #A8B9C1;
            font-size: 1.4rem;
            transition: all .1s;
        }
    }

    input[type="text"]:focus+label span,
    input[type="email"]:focus+label span,
    div.mage-error + label span,
    label.filled span {
        transform: translateY(-45px);
    }
}

// Custom checkbox


.checkbox-container {
    position: relative;
    min-height: 30px;

    .custom-checkbox {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
    
    .custom-checkbox+label {
        position: relative;
        padding-top: 0;
        padding-left: 40px;
        min-height: 24px;
        cursor: pointer;
        font-size: 1.4rem;
        display: flex;
        align-items: flex-start;

        &::before,
        &::after {
            cursor: pointer;
            position: absolute;
            transition: all .2s;
        }

        &::before {
            content: "";
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            background-color: transparent;
        }

        &::after {
            content:"\EA01";
            font-family: "iconfont";
            left: 5px;
            top: 5px;
            color: #F44D57;
            font-size: 1.5rem;
            line-height: 2rem;
            opacity: 0;
            transform: scale(0);
        }
    }

    input[type="checkbox"].custom-checkbox:checked+label::after {
        opacity: 1;
        transform: scale(1);
    }
}




// Error validation
div.mage-error {
    position: absolute;
    margin-top: 0;
    bottom: -15px;
}

// Autofill reset
:-webkit-autofill {
    border: none !important;
}

.return {
    height: auto;
    padding: 10px 25px;
    width: 50%;
    margin: 20px auto;
    font-size: 1.8rem;
    line-height: 1.6;
    border-radius: 0;
    text-align: center;
    text-transform: uppercase;
    background-color: $c-project5;
    border-radius: 5px;

    a {
        display: block;
        color: #fff;
    }
}