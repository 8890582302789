// =============================================
// Pages CMS
// =============================================

.cms-page-view:not(.cms-parrainage) {
    .column.main {
        max-width:  870px;
        margin:     50px auto;
        padding:    0 10px;
    }
    // Titre
    h1, h2, h3, h4, h5, h6 {
        color:          $c-project2;
        font-family:    $montserrat;
    }
    h1 {
        font-size:      2.5rem;
        margin:         0 0 35px;
        text-align:     center;
        text-transform: uppercase;
    }
    h2 {
        font-size:  2.2rem;
        margin:     30px 0 10px;
    }
    h3 {
        font-size:  1.8rem;
        margin:     20px 0 10px;
    }
    h4, h5, h6 {
        font-size:  1.6rem;
        margin:     20px 0 10px;
    }
    // Text / Div
    p, div {
        font-size:      1.2rem;
        line-height:    20px;
    }
   
    
    // Tableau
    table {
        margin: 0 0 15px;
        width:  100%;
        border: 1px solid $c-black;
        thead {
            th {
                font-size:     1.4rem;
                padding:       10px 15px;
                border-bottom: 1px solid $c-black;
            }
        }
        tbody {
            td { padding: 10px 15px; }
        }
    }
  
}

// PAGE COOKIE
.cms-particuliers-cookie {
    .column.main {
        max-width: 870px;
        margin: 50px auto;
        padding: 0 10px;
    }
    h1 { text-align: center; }
}

// PAGE CMS MENU MOBILE
.cms-page-view:not(.cms-parrainage) {
    .no-banner {
        .ee-banner_background,
        .ee-banner-content {
            display: none;
        }
    }
}

//
// Responsive
//
@include respond-to(medium) {
}
@include respond-to(small) {
}
