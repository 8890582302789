// =============================================
// Responsive Grid
// =============================================
//
// $Variables
//
$largeurSite: 1440px !default;
$columns: 16 !default;
$gutter: 10px !default;
$small: 640px !default;
$medium: 1024px !default;
$large: 1200px !default;
//
// Content Site
//
@mixin content-site($contenSite: 1200px) {
    margin:    0 auto;
    max-width: $contenSite;
    width:     100%;
}
//
// $Mixins
//
@mixin responsive-columns($suffix: '') {
    @for $i from 0 through $columns {
        @if $i != 0 {
            .col#{$suffix}-#{$i} { width: $i / $columns * 100%; }
        }
        .col#{$suffix}-push-#{$i} { left: $i / $columns * 100%; }
        .col#{$suffix}-offset-#{$i} { margin-left: $i / $columns * 100%; }
        .col#{$suffix}-pull-#{$i} { right: $i / $columns * 100%; }
    }
    .col#{$suffix}-center { margin: 0 auto; float: none; }
}
//
// $Extends
//
%col {
    float:      left;
    position:   relative;
    min-height: 1px;
    padding:    0 $gutter * 0.5;
}
//
// $Class
//
.row {
    margin: 0 $gutter * -0.5;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after { clear: both; }
    & { *zoom: 1; }
    & > * { @include transition(all 400ms ease-out); }
}
//
// Grid
//
@for $i from 1 through $columns {
    .col-#{$i}, .col-s-#{$i}, .col-m-#{$i}, .col-l-#{$i} { @extend %col; }
}
.m-show { display: none; }
.l-show { display: none; }
@include responsive-columns('-l');

//
// Responsive
//
// Medium Screen
@media only screen and (max-width: $medium) {
    @include responsive-columns('-m');
    .m-hide { display: none; }
    .m-show { display: block; }
    .s-show { display: none; }

}
// Small Screen
@media only screen and (max-width: $small) {
    @include responsive-columns('-s');
    .s-hide { display: none; }
    .s-show { display: block; }
    .m-show { display: none; }
}
//
// Responsive for video/iframe/object
//
.video-holder {
    position:       relative;
    padding-bottom: 56.25%;
    padding-top:    30px;
    height:         0;
    overflow:       hidden;
    display:        block;
    iframe,
    object,
    embed {
        position: absolute;
        top:      0;
        left:     0;
        width:    100%;
        height:   100%;
    }
}
