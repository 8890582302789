@font-face {
    font-family: 'iconfont';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/iconfont/iconfont.eot');
    src: url('../fonts/iconfont/iconfont.woff2') format('woff2'),
         url('../fonts/iconfont/iconfont.woff') format('woff'),
         url('../fonts/iconfont/iconfont.ttf') format('truetype'),
         url('../fonts/iconfont/iconfont.eot?#iefix') format('embedded-opentype');
}

.icon[class^="icon-"],
.icon[class*=" icon-"] {
    display: inline-block;
    font-family: 'iconfont';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

$icons: (
    coche: '\EA01',
    fioul: '\EA02',
    gaz: '\EA03',
    tel: '\EA04',
    menu: '\EA05',
    arrow: '\EA06',
    envelop: '\EA07',
    phone: '\EA08',
    pac: '\EA09',
    clim: '\EA10',
);

@each $name, $icon in $icons {
    .icon-#{$name}:before {
        content: $icon;
    }
}

.icon-category {
    width: 42px;
    height: 42px;

    &.ch_gaz {
        background: url('../images/icons/uEA19-ch_gaz.svg') center no-repeat;
    }

    &.ch_fioul {
        background: url('../images/icons/uEA20-ch_fioul.svg') center no-repeat;
    }

    &.pac {
        background: url('../images/icons/uEA09-pump.svg') center no-repeat;
    }

    &.clim {
        background: url('../images/icons/uEA12-clim.svg') center no-repeat;
    }
}