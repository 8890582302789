// =============================================
// Catalog / Research
// =============================================

// Content
.catalog-category-view,
.catalogsearch-result-index {
    .col-main { }
    // Title
    .category-description-block {
        margin:                 0 10px 20px;
        position:               relative;
        height:                 300px;
        background-size:        cover;
        background-position:    70% 0%;
        > img {
            left:     0;
            position: absolute;
            top:      0;
            z-index:  1;
        }
        .std {
            color:      $c-white;
            font:       500 1.2rem;
            margin:     0 auto;
            padding:    0 20px;
            position:   relative;
            text-align: center;
            width:      500px;
            z-index:    2;
        }
        .title {
            font-size: 2.5rem;
            padding:   63px 0 25px;
        }
    }
    // Breadcrums
    .breadcrumbs {
        border:     none;
        padding:    15px 10px;
    }
    // Toolbar
    .toolbar {
        height: 42px;
        margin: -42px 0 10px;
    }

    // Left Column
    .amshopby-filters-left {
        background-color: $c-grey-light;
        padding:          30px;
        dt { display: none; }
        .amshopby-cat {
            &.level1:not(.has-child),
            &.level2,
            &.amshopby-cat-level-2 {
                a {
                    color: rgba($c-black, .6);
                    font:  normal 1.3rem;
                    &:hover { color: $c-black; }
                }
            }
            a {
                color: $c-black;
                font:  600 1.6rem;
            }
            .count { display: none; }
        }
    }
    // Top Filter
    .amshopby-container-top {
        display:      table;
        margin:       0 0 30px;
        table-layout: fixed;
        width:        100%;
    }
    .amshopby-item-top {
        display:  table-cell;
        padding:  0 15px 0 0;
        position: relative;
        &:last-child {
            margin:  0;
            padding: 0;
            dd {
                left:  auto;
                right: 0;
                li {
                    background: none;
                    margin:     0;
                    padding:    0 10px;
                    width:      100%;
                }
            }
        }
        dt {
            border:         2px solid $c-input-border;
            color:          $c-black;
            cursor:         pointer;
            font:           500 1.2rem;
            padding:        8px 13px;
            position:       relative;
            text-transform: uppercase;
            z-index:        10;
            &.open {
                border-bottom-color: $c-white;
                &:after {
                    top:        14px;
                    @include transform(rotateX(180deg));
                }
            }
            &:after {
                background: url('../images/customdrop.png') no-repeat 0 0 transparent;
                content:    '';
                display:    inline-block;
                height:     image_height('../images/customdrop.png');
                position:   absolute;
                right:      0;
                top:        18px;
                width:      image_width('../images/customdrop.png');
                @include transform(translateY(-50%));
                @include transition(all 0.3s ease-out);
            }
        }
        dd {
            background-color: $c-white;
            border:           2px solid $c-input-border;
            display:          none;
            left:             0;
            min-height:       0 !important;
            padding:          10px;
            position:         absolute;
            top:              37px;
            width:            200%;
            z-index:          9;
            ol { max-width: none; }
            li {
                background-color: $c-grey-light;
                display:          inline-block;
                margin:           0 30px 25px 0;
                width:            28%;
                &:nth-child(3n) { margin: 0 0 25px; }
            }
        }
        .amshopby-attr,
        .amshopby-attr:hover {
            background:     none no-repeat 0 0;
            color:          $c-black;
            display:        block;
            float:          none;
            font:           600 1.1rem;
            height:         25px;
            padding:        0;
            line-height:    28px;
            text-align:     center;
            vertical-align: middle;
            .count { display: none; }
        }
        .amshopby-attr-selected {
            height:         25px;
            line-height:    28px;
            padding:        0;
            width:          100%;
        }
    }

    .currently {
        margin:     0 0 30px;
        padding:    0 180px 0 0;
        li {
            display:    inline-block;
            margin:     0 0 10px;
        }
        a {
            cursor: pointer;
        }
        .multiselect-child {
            background-color: $c-grey-light;
            display:    inline-block;
            height: 25px;
            line-height: 25px;
            margin:     0 10px 0 0;
            padding: 0 5px;
        }
        .single-select {
            background-color: $c-grey-light;
            display:    inline-block;
            height: 25px;
            line-height: 25px;
            margin:     0 10px 0 0;
            padding: 0 5px;
        }
    }
    .amshopby-filters-top {
        .actions {
            position: absolute;
            right:    0;
            top:      75px;
            a {
                border-bottom:  1px solid transparent;
                font-size:      1.2rem;
                font-weight:    500;
                letter-spacing: 1px;
                text-transform: uppercase;
                @include transition(all 0.3s ease-out);
                &:hover {
                }
            }
        }
    }
}
// Filter Content - Show only mobile
.filter-content-mob {
    border:         2px solid $c-input-border;
    cursor:         pointer;
    display:        none;
    font-weight:    500;
    letter-spacing: 1px;
    margin:         0 0 10px;
    padding:        15px;
    text-align:     center;
    text-transform: uppercase;
    width:          100%;
    @include transition(all 0.3s ease-out);
    &.open {
        background: $c-grey-light;
    }
}

// Product Grid
.products-grid {
    margin:   0 -10px;
    position: relative;
    .item { }
    .price-box {
        .price { }
        .special-price { }
    }
    .product-image {
        img { }
    }
    .product-name {
        a {
            &:hover { }
        }
    }
    // Sticky Reduction
    .sticky-products {
        right: 0;
        &.with-operation {
            left: 0;
            .operation { float: right; }
        }
        span { margin: 0; }
    }
}

// Ajax Button More Products
.btn-more-products {
    font-weight: bold;
    line-height: 35px;
    &:before {
        background-color:   $c-grey-light;
        content:"+";
        display:            block;
        height:             22px;
        line-height:        25px;
        margin:             0 auto;
        width:              22px;
        @include border-radius(100%);
    }
    &:after {
        content:    "\a004";
        color:      $c-grey-light;
        font-family: 'icons';
        font-size:  20px;
        font-weight: bold;
        display:    block;
    }
}


// No result
.result-select-product {
    margin: 35px 0 100px;
}


//
// Responsive
//
@include respond-to(medium) {
    .catalog-category-view,
    .catalogsearch-result-index {
        .sidebar {
            margin: 0 0 20px;
        }
    }
}
@include respond-to(small) {
    .catalog-category-view,
    .catalogsearch-result-index {
        .main {
            max-width: 480px;
            padding:   20px 10px 0;
        }
        .category-description-block {
            margin: 0 -10px 20px;
            .std {
                width:  100%;
            }
        }
        .sidebar {
            display: none;
        }
        .toolbar {
            margin: 5px 0 20px;
            .sorter .sort-by {
                float: none;
                label {
                    margin: 0 0 5px;
                }
                .customdropbg {
                    width: 100%;
                }
            }
        }
        .amshopby-filters-top {
            .block-container {
                display: none;
            }
            .actions {
                top:    100%;
            }
        }
        .amshopby-item-top {
            display:    block;
            margin:     0 0 10px;
            padding:    0;
            max-width:  100%;
            dt {
                font-size: 1.3rem;
                &.open {
                    font-weight:    bold;
                    //text-align:     center;
                }
            }
            dd {
                background-color:   $c-white;
                border-top:         none;
                max-height:         180px;
                overflow:           auto;
                position:           static;
                width:              100%;
                z-index:            15;
                li {
                    background-color:   transparent;
                    display:            block;
                    margin:             0 0 10px;
                    width:              100%;
                    &:nth-child(3n) {
                        margin: 0 0 10px;
                    }
                }
                .amshopby-attr {
                    font-weight:    100;
                    font-size:      1.2rem;
                    text-align:     left;
                    &:hover {
                        text-align: left;
                    }
                }
            }
        }
        .category-products {
            padding: 10px 0 0;
        }
        .currently {
            padding: 0 0 10px;
            .single-select, .multiselect-child {
                height: 40px;
                line-height: 42px;
                padding: 0 20px;
            }
        }
        .actions {
            top:    100%;
        }
    }
    .filter-content-mob {
        display: block;
    }
}
