.cms-parrainage {
    /** ---------------
        HEADER
    ------------------ */
    .page-header {
        margin-bottom: 50px;

        .subtitle-banner {display: block;}

        // Infos box
        .ee-banner__infos-boxes {

            .transparent-box {
                padding: 25px;
                border-radius: $radius-banner;
                background-color: rgba(255, 255, 255, .2);
                backdrop-filter: blur(10px);

                &__text-middle {
                    font-size: 4.8rem;
                }
                &__text-bottom {
                    font-size: 1.8rem;
                }
            }
        }

        // Cta
        .ee-banner-cta {
            .btn {
                color: $c-white;

                &:hover {
                    color: $c-main;
                }
            }
        }
    }

     /** ---------------
        Main content
    ------------------ */
    #maincontent {
        // Common
        .h2-title {
            margin: 10px 0;
            font-size: 3.6rem;
            line-height: 4.3rem;
            font-weight: 600;
        }

        // Contents
        .sponsorship-content {
            
            // Steps
            &__Steps {
                margin: 50px auto;
                padding-top: 50px;

                p {
                    margin: 30px 0;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }      

                .steps-list {

                    .step-item {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        margin: 15px;
                        padding: 35px;
                        border-radius: $radius-banner;
                        box-shadow: 0px 20px 30px 0px rgba(0, 0, 13, .05);

                        @include breakpoint(lg) {
                            width: 45%;
                        }


                        &:nth-child(1){
                            margin-bottom: 70px;
                            margin-top: -30px;
                        }

                        img {
                            display: block;
                            height: 80px;
                        }

                        &__title {
                            font-size: 2rem;
                            color: $c-main;
                            font-weight: 600;
                        }

                        &__desc {
                            margin: 5px 0;
                        }
                    }
                }
            }

            // Card amount banner
            &__Card-amount_banner {
                padding: 0 25px;

                @include breakpoint(lg) {
                        display: flex;
                        justify-content: center;
                        gap: 5vw;
                    }

                .amount-item {

                    &__text-content {
                        font-size: 3.4rem;
                        line-height: 3.5rem;
                        font-family: $handwrite;

                        .red-amount {
                            font-size: 5.8rem;
                            line-height: 5.9rem;
                            color: $c-main;
                        }
                    }

                    &__illustration {
                            
                        &:nth-child(2) {
                            svg {
                                display: block;
                                margin-left: auto;
                            } 
                        }
                    }

                    .text__end {
                        width: 50%;
                        margin-left: auto;
                    }
                }
                
            }

            // Advantages
            &__Advantages {
                padding: 50px 0;
                background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0) 100%);


                .h2-title {
                    margin: 0 auto;
                    padding: 50px 0;
                    text-align: center;
                }

                .advantages-list {
                    

                    .advantage-item {
                        flex: 1;
                        padding: 40px;
                        text-align: center;
                        box-shadow: 0px 20px 30px 0px rgba(0, 0, 13, 0.05);


                        &__title {
                            margin: 5px 0;
                            font-size: 3rem;
                            line-height: 4.2rem;
                            color: $c-main;
                        }

                        &__desc {
                            font-size: 1.8rem;
                            line-height: 2.16rem;
                            font-weight: 600;
                        }
                    }
                }
            }
            
            // Form container section
            &__Form {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                gap: 50px;

                @include breakpoint(lg) {flex-direction: row;}

                .banner {
                    display: none;

                    @include breakpoint(lg) {display: flex;}
                }

                .sponsorship-partners {
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    
                }

                .form-container {
                    padding: 60px;
                    box-shadow: 0px 20px 30px 0px #0000000D;

                    .desc {
                        padding: 25px 0;
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        border-bottom: 2px solid $c-project4;

                        span {
                            font-weight: 700;
                        }
                    }

                    .btn {
                        display: block;
                        margin: 50px 0;
                        padding: 20px;
                        font-size: 2rem;
                    }
                }
            }
        }
        
    }

     /** ---------------
        Footer
    ------------------ */
    .page-footer {
        .sponsorship-link {
            display: none;
        }
    }
}


/** ----------------------------------------
            RESPONSIVE
------------------------------------------ */

/**
* Max width 801px
*/ 
@include respond-to(small) {
    .cms-parrainage {

        // HEADER / BANNER
        .page-header {
            // Banner
            .ee-banner {
                .ee-banner_background {
                    background-position: 15% 50%;
                }

                .ee-banner-content {
                    padding: 75px 35px;
                    height: auto;

                    // Infos box
                    .ee-banner__infos-boxes {
                        .transparent-box { margin: 15px auto;}
                    }
                }
            }

            .ee-mobile_cta {
                position: fixed;
                width: 100%;
                bottom: 0;
                left: 0;
                padding: 25px 0;
                transform: translateY(200%);
                animation: all .3s ease-in;

                &.intersect_fixed {
                    transform: translateY(0);
                }

                a {
                    display: block;
                    margin: 0 auto;
                    width: 80%;
                }
            }
        }

        // MAIN CONTENT
        #maincontent {

            // COMMON
            .wrapper-large {
                padding: 0 35px;
            }

            // SECTIONS
            .sponsorship-content {

                &__Advantages {
                    .h2-title {
                        img { 
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    
                    .advantages-list {
                        .advantage-item {margin: 20px auto;}
                    }
                }

                // Card amount banner
                &__Card-amount_banner {
                    .amount-item {
                        &__illustration {
                            svg {max-width: 80%;}

                        }
                    }
                }

                // Form container section
                &__Form {

                    &.wrapper-large { padding: 0;}

                    .sponsorship-partners {
                        flex-wrap: wrap;
                        justify-content: center;
                        margin-bottom: 50px;
                        padding: 0 35px;
                    }
                }
            }
        }
    }
}



/** 
* Min width 801px
*/
@include breakpoint(sm) {
    .cms-parrainage {

        // HEADER / BANNER
        .page-header {
            margin-bottom: 100px;
 
            // Infos box
            .ee-banner__infos-boxes {
                display: flex;
                gap: 20px;

                .transparent-box {
                    padding: 40px 25px;
                    max-width: 200px;
                }
            }

            .ee-banner-cta {
                display: flex;
            }
        }

        // MAIN CONTENT
        #maincontent {
            .sponsorship-content {

                // Steps
                &__Steps {
                    display: flex;
                    gap: 40px;

                    .left-content {
                        max-width: 440px;
                    }

                    .steps-list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                    }
                }

                // Card amount banner
                &__Card-amount_banner {
                    overflow-x: hidden;

                    .amount-item {
                        display: flex;
                        align-items: center;

                        &__illustration {
                            padding: 0 3%;
                        }
                    }
                }

                // Advantages
                &__Advantages {
                    margin-bottom: 50px;
                    padding: 100px 0;

                    .h2-title {
                        img {
                            margin-left: 20px;
                            vertical-align: middle;
                        }
                    }

                    .advantages-list {
                        display: flex;
                        gap: 40px;
                    }
                }
                
                // Form container section
                &__Form {
                    margin: 50px auto;
                    
                    .banner {
                        position: relative;
                        overflow: hidden;
                        padding: 90px 50px;
                        max-width: 1000px;
                        min-width: 650px;
                        border-radius: $radius-banner;
                        background-repeat: no-repeat ;
                        background-position-x: center;

                        &__left,
                        &__right {
                            position: relative;
                            width: 50%;
                            z-index: 5;
                        }

                        &__right {
                            font-size: 3rem;
                            font-weight: 600;
                            line-height: 3.6rem;
                            color: $c-white;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(270deg, #EF604E 0%, rgba(0, 0, 0, 0) 100%);
                        }
                    }

                    .sponsorship-partners {
                        margin-top: 50px;
                        height: 55px;
                    }
                }
            }
        } 
    }
}