// =============================================
// Footer
// =============================================
.footer-top{
    background: $c-project2;
    .footer-top-content{
        margin: 0 auto;
        padding: 100px 20px 70px;
    }
}
    .footer-top-table{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .footer-top-col{
        
        &:first-child{
            vertical-align: middle;
            padding: 0;
            
            img{
                max-width: 210px;
            }
        }

        &:last-child{
            li.packages,
            li.infos,
            li.nav.item {
                display: none;
            }
            
            .btn {
                width: 225px;
                margin-bottom: 25px;
                padding: 10px 50px 10px 75px;

                &::before {
                    left: 50px;
                }
            }

            .account_conainer {

                a.btn__account {  
                    font-size: 1.7rem;
                    font-weight: 700;
                    color: $c-white;

                    &::before {
                        background: url('../images/icons/uEA11-account_white.svg') center no-repeat;
                    }

                    &:hover {
                        background-color: $c-main;
                    }
                }
            }
        }

        .number {
            position: relative;
            height: fit-content;
            width: fit-content;
            border: 2px solid $c-white;
            margin-top: 40px;
            @include border-radius(50px);

            a {
                display: block;
                padding: 10px 15px;
                font-size: 1.6rem;
                color: $c-white;
            }
        }

        .number_infos {
            color: $c-white;
            font-size: 1.2rem;
            font-weight: 600;
            
            .desc {
                margin: 0;
                color: $c-project3;
                font-weight: normal;
            }
        }
        .panel-right{
            font-size: 1.3rem;
            color: $c-white;
            position: relative;

            .sponsorship-link {
                .btn:hover {
                    color: $c-white;
                }
            }
            
            .icon{
                color: #bfbfbf;
                position: absolute;
                left: 10px;
                top: 15px;
                font-size: 3rem;
                @include transform(rotate(10deg));
            }
            .number {
                color: $c-project5;
            }
            span{
                display: block;
                font-weight: 700;
                font-size: 1.7rem;
            }
            .desc {
                font-size: 1.1rem;
                font-weight: normal;
                color: #bfbfbf;
            }
        }
    }
    .footer-top-title{
        font-size: 1.8rem;
        font-family: $roboto;
        margin: 0 0 15px 0;
        color: $c-white;

        &.whoweareLinks {
            margin: 0;
        }
    }
        .footer-top-list{
            li{
                margin: 20px 0;
                font-size: 1.3rem;
            }
            a{
                color: $c-project3;
                &:hover{
                    color: tint($c-project3, 70%);
                }
            }
        }

        // Newsletter footer
        .newsletter-footer{
            .label{
                color: $c-project3;
                font-size: 1.3rem;
                margin: 0 0 20px 0;
                padding: 0;
            }
        }
            .field-news{
                position: relative;
                padding-right: 80px;
                .input-text{
                    @include border-radius(5px 0 0 5px);
                    background: $c-white;
                    border: none;
                    padding: 0 10px;
                }
                .button{
                    position: absolute;
                    @include border-radius(0 5px 5px 0);
                    top: 0;
                    right: 0;
                    width: 80px;
                    margin: 0;
                }
            }

//
// Footer Bottom
//
.footer-bottom-content{
    max-width: 1260px;
    margin: 0 auto;
    padding: 22px $paddingContent;
    .copyright{
        color: shade($c-project2, 60%);
        font-size: 1.3rem;
        font-family: $roboto;
    }
    ul{
        float: right;
    }
    li{
        display: inline-block;
        font-size: 1.3rem;
        a{
            color: shade($c-project2, 60%);
            &:hover{
                color: tint($c-project2, 40%);
            }
        }
    }
}

// VueJs Transition Footer Link Mobile
.expandMaintenance-transition,
.expandService-transition,
.expandWhoweare-transition {
    transition: all .3s ease;
}
.expandMaintenance-enter,
.expandMaintenance-leave,
.expandService-enter,
.expandService-leave,
.expandWhoweare-enter,
.expandWhoweare-leave {
    height: 0;
    opacity: 0;
}

#copyright-tod {
    background: $c-project2;
    text-align: right;
    span {
        display: block;
        padding: 0 20px 30px;
        opacity: 0.3;
    }
    span,
    a {
        color: $c-white;
        font-size: 1rem;
    }
}

//
// Responsive
//
@include respond-to(medium) {
    .footer-top-col{
        width: 33.3333%;
        &:first-child{
            display: none;
        }
    }
}
@include respond-to(small) {

    .footer-top{
        .footer-top-content{
            padding: 100px 36px;
        }
    }
    .footer-top-table{
        width: auto;
        display: block;
    }
    .footer-top-col{
        display: block;
        width: auto;
        padding: 15px 0;
        //text-align: center;
        
        &:first-child{
            border: none;
            display: block;
            margin: 0 0 20px 0;
            
            img{
                max-width: 200px;
            }
        }

        &:last-child {
            .btn {
                width: 100%;

                &::before {
                    left: 30%;
                }
            }
        }
        
        .panel-right{
            margin: 20px auto 0;
            
            span{
                display: block;
                font-weight: 700;
                font-size: 1.7rem;
            }
        }
    }

    .footer-top-title{
        font-size: 1.8rem;
        font-family: $roboto;
        margin: 0 0 15px 0;
        color: $c-white;
        cursor: pointer;
    }

    .footer-top-list{
        li{
            margin-bottom: 20px;
            font-size: 1.3rem;
        }
        a{
            color: $c-project3;
            &:hover{
                color: tint($c-project3, 70%);
            }
        }
    }

    // Newsletter footer
    .newsletter-footer{
        margin: 0 0 20px;
        .label{
            color: $c-project3;
            font-size: 1.3rem;
            margin: 0 0 20px 0;
            padding: 0;
        }
    }
    .field-news{
        position: relative;
        padding-right: 80px;
        .input-text{
            @include border-radius(5px 0 0 5px);
        }
        .button{
            position: absolute;
            @include border-radius(0 5px 5px 0);
            top: 0;
            right: 0;
            width: 80px;
            margin: 0;
        }
    }

    .footer-bottom{
        display: none;
    }

    #copyright-tod {
        text-align: center;
    }
}