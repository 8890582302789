//
// Override Slick Slider CSS
// Don't forget to create 2 Arrows
// arrow-slider-left / arrow-slider-right
//
.slick-slider {
    margin-bottom: 50px;
    .slick-list { }
    .slider-image { }
    //
    // Override Default Configuration
    //
    .slick-slide {
        img {
            margin: auto;
        }
    }
    .slick-next,
    .slick-prev,
    .slick-dots { }
    .slick-next,
    .slick-prev {
        &:hover { }
    }
    .slick-prev {}
    .slick-next {}
    .slick-dots {
        z-index: 500;
        li {
            width: auto;
            button:before {
                background-color: $c-project4;
                display: block;
                width: 12px;
                height: 12px;
                @include border-radius(100%);
            }
            &.slick-active {
                button:before {
                    background-color: $c-project5;
                }
            }
        }
    }
}