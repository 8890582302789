// =============================================
// Tod Ui-kit
// =============================================

/* ---------------------------
    Wrapper
---------------------------*/
.wrapper-large {
    max-width: 1410px;
    margin: 0 auto;
    

    @media (min-width: $mq-extra-small) {
        padding: 0 10px;
    }
}

.wrapper-medium {
    max-width: 1300px;
    margin: 0 auto;
}

/* ---------------------------
    Responsive classes
---------------------------*/
// ---- Display only
// 480px
.mobile_screen-only {
    display: initial;

    @media (min-width: $mq-extra-small)  {
        display: none;
    }
}

// 800px
.tablet_screen-only {
    display: initial;

    @media (min-width: $small)  {
        display: none !important;
    }
}

// 1024px
.medium_screen-only {
    display: initial;

    &.btn {
        display: inline-block;
    }

    @media (min-width: $mq-large)  {
        &.btn {
            display: none;
        }
        display: none !important;
    }
}

// 1200px
.large_screen-only {
    display: none;

    @media (min-width: $large)  {
        display: initial;
    }
}

// 1440px
.extra-large_screen-only {
    display: none;

    @media (min-width: $mq-start-extra-large-desktop)  {
        display: initial;
    }
}

// ---- Display start
// 480px
.mobile_screen-start {
    display: none;

    @media (min-width: $mq-extra-small)  {
        display: initial;
    }
}

// 800px
.tablet_screen-start {
    display: none;

    @media (min-width: $small)  {
        display: initial;
    }
}

// 1024px
.medium_screen-start {
    display: none;

    @media (min-width: $mq-large)  {
        display: initial;

        &.btn {
            display: inline-block;
        }
    }
}


/* ---------------------------
    Utils classes
---------------------------*/
.flex {
    display: flex;
}

// Text
.text {
    //Colors
    &__main-color {
        color: $c-main;
    }

    &__blue {
        color: $c-project6;
    }

    &__small-light-grey {
        color: $c-project3;
        font-weight: 400;
    }

    &__small {
        font-size: 1.2rem;
    }

    //Weight
    &__bold {
        font-weight: 600;
    }

    //Positions
    &__end {
        text-align: end;
    }
}

// Handle vue variable before vue loaded in phtml files
.vue-variables {
    display: none;

    &.vue-loaded {
        display: block;
    }
}
   
/* ---------------------------
    Layout block classes
---------------------------*/

.round-white_box {
    border-radius: $radius-banner;
    background-color: #ffffff;
    padding: 30px 20px;

    @include breakpoint(sm) {padding: 30px 40px;}

}
// Info block layout (blue with icon)
.info-block {
    position: relative;
    padding: 20px;
    background: rgba($c-info, .2);
    text-align: left;
    border-radius: $radius-s;

    &__text {
        color: $c-info;
        padding: 0 0 0 40px;
        display: inline-block;
        vertical-align: middle;
    }

    &:before {
        background-image: url("../images/info_icon.svg");
        background-size: cover;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
    }

    // Background darker
    &.dark-blue {
        background: $c-info;
        
        .info-block__text {
            color: $c-white;
        }

        &:before {
            background-image: url("../images/info_icon_white.svg");
        }
    }
}

.price {
    &__cross-out {
        position: relative;
        display: inline-block;
        color: $c-project2;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: $c-project2;
        }
    }
}

.vue-modal {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 50; // Need to be under 100
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);

    &.show {display: flex;}

    &__content {
        max-width: 600px;
        text-align: center;
    }

    &__picto {
        margin: 0 auto;
        width: 50px;
        height: 50px;
        background-size: 100%;
        background-repeat: no-repeat;

        &.warning {
            background-image: url("../images/icons/warning.svg");
        }
    }

    &__title {
        margin: 20px 0;
        font-size: 2.5rem;
        font-weight: 600;
    }

    &__infos {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    &__primary {
        font-size: 2rem;
        width: 100%;
        margin: 20px 0;
        padding: 10px 30px;
    }

    &__secondary {
        cursor: pointer;
        font-size: 1.6rem;
        color: $c-project3;
        text-decoration: underline;
    }
}

// OTC One Time Code
[autocomplete=one-time-code] {
    --otp-digits: 6;
    --otp-ls: 2ch;
    --otp-gap: 1.25;
  
    /* private consts */
    --_otp-bgsz: calc(var(--otp-ls) + 1ch);
    --_otp-digit: 0;
  
    all: unset;
    background: linear-gradient(90deg, 
      var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0),
      linear-gradient(90deg, 
      var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0
    );
    
    text-align: left;
    background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
    background-repeat: no-repeat, repeat-x;
    background-size: var(--_otp-bgsz) 100%;
    caret-color: var(--otp-cc, #222);
    caret-shape: block;
    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
    font-size: var(--otp-fz, 2em);
    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otp-ls);
    padding-block: var(--otp-pb, 1ch);
    padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));

    @include breakpoint(sm) {
        font-size: var(--otp-fz, 2.5em);
    }
  }
