// =============================================
// Content
// =============================================

.page-title {
    text-align:     center;
    margin-bottom:  30px;
}
.block-title {
    margin: 0 0 30px;
    p {
        text-transform: uppercase;
        margin-bottom: 5px;
    }
}
//
// One Column
//
.page { }
.col1-layout {
    .section {
        clear:    both;
        overflow: hidden;
    }
    .full {
        width: $w-full;
    }
    .half {
        float: left;
        width: $w-half;
    }
    .third {
        float: left;
        width: $w-third;
    }
    .quarter,
    .fourth {
        float: left;
        width: $w-fourth;
    }
    .three-quarter {
        float: left;
        width: $w-fourth * 3;
    }
    .two-thirds {
        float: left;
        width: $w-third * 2;
    }
}
//
// Two Columns Left
//
.col2-left-layout {
    .col-main {
        float:    left;
        overflow: hidden;

    }
    .col-left {
        float: left;
    }
}
//
// Two Columns Right
//
.col2-right-layout {
    .col-main {
        float:    left;
        overflow: hidden;
    }
    .col-right {
        float: right;
    }
}
//
// Third Columns
//
.col3-layout {
    .col-main {
        float: left;
    }
    .col-left {
        float: left;
    }
    .col-right {
        float: right;
    }
}
//
// Magento Columns
//
.col2-set{
    .col-1{
        width: $w-half;
    }
    .col-2{
        width: $w-half;
    }
}