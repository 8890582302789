//
// Cookie Notice
//
.message.cookie {
    background-color: rgba(245, 245, 245, 0.8);
    bottom:         30px;
    right:          30px;
    left:           auto;
    top:            auto;
    padding:        40px 30px;
    position:       fixed;
    vertical-align: middle;
    text-align:     center;
    width:          350px;
    z-index:        999;
    .actions a {
        display:    inline-block;
        margin:     0 10px;
        text-decoration: underline;
    }
    .action:after {
        content:    "\00d7";
        cursor:     pointer;
        font-size:  35px;
        position:   absolute;
        right:      15px;
        top:        0px;
    }
}