// =============================================
// Settings
// =============================================
//
// Classic fonts
//
$slick:             slick, Tahoma, Verdana, sans-serif;
$Arial:             Arial, "Helvetica Neue", Helvetica, serif;
$Helvetica:         "Helvetica Neue", Helvetica, Arial, sans-serif;
$Tahoma:            Tahoma, Verdana, Segoe, sans-serif;
$TimesNewRoman:     "Times New Roman", Times, Baskerville, Georgia, serif;
$Verdana:           Verdana, Geneva, sans-serif;
//
// Project fonts
//
$montserrat: 'Montserrat', sans-serif;
$open: 'Open Sans', sans-serif;
$roboto: 'Roboto', sans-serif;
$roboto-m: "Roboto-Medium", sans-serif;
$roboto-b: 'Roboto-Bold', sans-serif;
$handwrite: 'Caveat-Regular', sans-serif;

$base-font: $roboto;
//
// Base Color
//
$c-black:           #000000;
$c-grey:            #424242;
$c-grey-light:      #eeeeee;
$c-white:           #ffffff;
$c-warning:         #ff0000;
$c-transparent: transparent;
//
// Header
//
$height-panel: 72px;
$c-bg-panel: #f7f7f7;

$c-bg-header: #f8f8f8;
$height-header: 200px;
//
// Footer
//
$height-footer: 200px;
$c-bg-footer: #c1c1c1;

//
// Project Colors
//

$c-project1: #66cc9b;
$c-project2: #373737;
$c-project3: #8c8c8c;
$c-project4: #dddddd;
$c-project5: #ef604e;
$c-project6: #4e8eef;

$c-project7: #f8b166;
$c-project8: #75bae7;
$c-project9: #E5002E;

$c-project10: #fee600;
$c-project11: #fcda01;

$c-foncia1: #063869;
$c-foncia2: #168E23;

//
// Messages Colors
//
$c-error: #ef604e;
$c-success: #66cc9b;
//
// Base Links Colors
//
$c-link: #373737;
$c-link-hover: #555555;
$c-link-bullet: #CCCCCC;
//
// Base Button Colors
//
$c-button: $c-project5;
$c-button-hover: rgba($c-button, 0.8);
$c-little-btn: #e66755;
$c-little-btn-hover: rgba($c-little-btn,0.8);
//
// Form colors
//
$c-label: #8c8c8c;
$c-input-text: #000;
$c-input-border: #8c8c8c;
$c-bg-input: #ffffff;
$c-error-border: #f50057;
$c-input-placeholder: tint($c-project3, 30%);
$c-select-border : #d8d8d8;

//Tod Color
$c-white:           #ffffff;
$c-main:            #ef604e;
$c-main-darker:     #411827;
$c-main-ligther:    #FFBBBF;
$c-accent:          #B4E100;
$c-accent-darker:   #005E31;
$c-accent-ligther:  #E0F5B1;
$c-grey-1:          #0C232C;
$c-grey-2:          #224B61;
$c-grey-3:          #667F8D;
$c-grey-4:          #A8BAC1;
$c-grey-5:          #D7DFE2;
$c-grey-6:          #F5F5F7;
$c-success:         #72C070;
$c-validate:        #65CC9B;
$c-warning:         #EDCA4E;
$c-info:            #5694F2;


//
//Radius
//
$radius-s: 6px;
$radius-banner: 12px;
$radius-md: 15px;
$radius-l: 24px;


//
// Responsive
//
$largeurSite: 1440px;
$paddingContent: 20px;
$columns: 16;
$gutter: 20px;
$small: 800px;
$medium: 1100px;
$large: 1200px;

// -------------
// Media queries Tod
// -------------

// Dimensions
$mq-start-tablet: 700px;
$mq-start-desktop: 1100px;
$mq-layout-width: 1200px;
$mq-start-large-desktop: 1245px;
$mq-start-extra-large-desktop: 1445px;
$mq-container-break: 1620px;

/* Responsive
-------------------------- */
$mq-extra-small:  480px;
$mq-small:        640px;
$mq-medium:       768px;
$mq-large:        1024px;
$mq-extra-large:  1280px;

//
// Height
//
$height-input: 40px;
$height-text-area: 100px;
//
// Width
//
$w-content: auto;
$w-full: 100%;
$w-half: $w-full / 2;
$w-third: $w-full / 3;
$w-two-third: ($w-full / 3) * 2;
$w-fourth: $w-full / 4;
