// =============================================
// USE WIDTH 100% AND MAX-WIDTH
// =============================================
@mixin max_width($width) {
    max-width: $width;
    width:     100%;
}
// =============================================
// Text Hidden
// =============================================
@mixin text-hidden() {
    font-size:   0;
    overflow:    hidden;
    text-indent: 100%;
    white-space: nowrap;
}
@mixin text-visible() {
    font-size:   inherit;
    overflow:    visible;
    text-indent: 0;
    white-space: normal;
}
// =============================================
// Remove User Selectable Text
// =============================================
@mixin not-selectable {
    -moz-user-select:    none;
    -ms-user-select:     none;
    -webkit-user-select: none;
    -o-user-select:      none;
    user-select:         none;
}
// =============================================
// Center element
// =============================================
@mixin center($width: null, $height: null) {
    position: absolute;
    top:      50%;
    left:     50%;

    @if not $width and not $height {
        transform:   translate(-50%, -50%);
    } @else if $width and $height {
        width:       $width;
        height:      $height;
        margin:      -($width / 2) #{0 0} -($height / 2);
    } @else if not $height {
        width:       $width;
        margin-left: -($width / 2);
        transform:   translateY(-50%);
    } @else {
        height:      $height;
        margin-top:  -($height / 2);
        transform:   translateX(-50%);
    }
}
// =============================================
// Mixin to create BreakPoint for responsive Grid
// =============================================
@mixin respond-to($breakpoint) {
    @if $breakpoint == "small" {
        @media only screen and (max-width: $small) {
            @content;
        }
    } @else if $breakpoint == "medium" {
        @media only screen and (max-width: $medium) {
            @content;
        }
    } @else if $breakpoint == "large" {
        @media only screen and (max-width: $large) {
            @content;
        }
    } @else {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }
}

/* Media queries
-------------------------- */

// Example of declaration :
// @include breakpoint(sm) {}


@mixin breakpoint($class) {
    @if $class == xs {
        @media (min-width: $mq-extra-small + 1px) { @content; }
    }
  
    @else if $class == only-xs {
        @media (max-width: $mq-extra-small) { @content; }
    }

    @else if $class == ms {
        @media (min-width: $mq-small) { @content; }
    }
  
    @else if $class == sm {
        @media (min-width: $small + 1px) { @content; }
    }
  
    @else if $class == only-sm {
        @media (max-width: $small) { @content; }
    }
  
    @else if $class == md {
        @media (min-width: $mq-medium + 1px) { @content; }
    }
  
    @else if $class == only-md {
        @media (max-width: $mq-medium) { @content; }
    }
  
    @else if $class == lg {
        @media (min-width: $mq-large + 1px) { @content; }
    }
  
    @else if $class == only-lg {
        @media (max-width: $mq-large) { @content; }
    }
  
    @else if $class == xl {
        @media (min-width: $mq-extra-large + 1px) { @content; }
    }
  
    @else if $class == only-xl {
        @media (max-width: $mq-extra-large) { @content; }
    }
  
    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
  }
