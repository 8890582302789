// =============================================
// Button
// =============================================

// Base button
.button {
    -webkit-appearance: none;
    background:         $c-button;
    border:             2px solid $c-button;
    color:              $c-white;
    cursor:             pointer;
    display:            block;
    font-size:          1.4rem;
    font-weight:        500;
    height:             40px;
    line-height:        36px;
    padding:            0 10px;
    position:           relative;
    text-align:         center;
    vertical-align:     middle;
    width:              100%;
    font-family: $montserrat;
    @include border-radius(5px);
    @include transition(all 250ms linear);
    &:hover {
        background-color: transparent;
        color:            $c-button;
    }
    &.add {
        width: 130px;
        background: $c-black;
        margin: 0 auto;
        border: none;
        @include border-radius(40px);
    }
    &.button-big{
        height: 52px;
        line-height: 48px;
        font-size: 1.6rem;
    }
    &.bt-border{
        background: none;
        @include border-radius(50px);
        border: 2px solid $c-white;
        &:hover{
            border-color: $c-project5;
        }
        &.my-account {
            border-color: $c-project5;
        }
    }
    &.bt-border-black-big{
        background: none;
        height: 52px;
        line-height: 48px;
        text-transform: uppercase;
        border: 2px solid $c-project2;
        color: $c-project2;
        &:hover{
            color: $c-project5;
            border-color: $c-project5;
        }
    }
    &.button-grey{
        height: 45px;
        line-height: 40px;
        text-transform: uppercase;
        font-size: 1.2rem;
        background: $c-project2;
        border: 2px solid $c-project2;
        &:hover{
            border-color: $c-project5;
            background: $c-project5;
            color: $c-white;
        }
    }
    // Medium button
    &.button-medium {
        height:      32px;
        line-height: 30px;
        font-weight: 500;
    }
    // Little button
    &.button-little {
        height:         26px;
        line-height:    24px;
        font-size:      1.1rem;
        text-transform: none;
        font-weight:    600;
    }
    // Black button
    &.button-black {
        background:  $c-black;
        color:       $c-white;
        font-weight: 600;
        &:hover {
            background: $c-white;
            color:      $c-black;
        }
    }
    //White buttons
    &.button-white {
        border:      none;
        background:  $c-white;
        color:       $c-black;
        font-size:   1.4rem;
        line-height: 40px;
        font-weight: 500;
        &:hover {
            background: $c-black;
            color:      $c-white;
        }
    }

    //White button big
    &.button-white-big {
        height:      50px;
        line-height: 50px;
        border:      none;
        background:  $c-white;
        color:       $c-black;
        font-size:   1.4rem;
        font-weight: 500;
        &:hover {
            background: $c-black;
            color:      $c-white;
        }
    }

    // Small
    &.small {
        display: inline-block;
        width:   auto;
    }
    // Icon button
    .icon {
        font-size: 20px;
        margin:    0 10px 0 0;
    }
    // Loading button
    &.loading {
        @include text-hidden();
        > span { }
        &:before { }
        &:hover { }
    }
    // Cart
    &.btn-cart {
        > span { }
        &:before { }
        &:hover { }
    }

    &.accept{
        width:45px;
        display: inline;
        height: 25px;
        line-height: 15px;
    }
    &.cancel{
        width: 70px;
        padding: 2px;
        display: inline;
        height: 25px;
        line-height: 15px;
    }
}
// Animation rotating
@keyframes rotating {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(360deg));
    }
}
// Remove Button
.btn-remove {
}

// =============================================
// Btn
// =============================================

// Base button
.btn {
    position:               relative;
    -webkit-appearance:     none;
    display:                inline-block;
    align-items:            center;
    padding:                20px 30px;
    cursor:                 pointer;
    border-radius:          200px;
    text-align:             center;

    .btn-label {
        display:                block;
        color:                  $c-white;
        font-family:            $roboto-b;
        font-size:              1.8rem;
        font-weight:            500;
    }

    &:disabled {
        background-color: $c-project4;
        border-color: $c-project4;
        cursor: not-allowed;
    }
}

.btn {
    font-family: $roboto;

    &:hover:not(:disabled) {
        background-color: transparent;
        color:            $c-button;
    }

    //Red
    &__main {
        background-color:       $c-main;
        color:                  $c-white;
        border:                 1px solid $c-main;
    }

    //White
    &__white {
        background-color:       $c-white;
        border:                 1px solid $c-grey-5;

        .btn-label {
            color:                  $c-grey-2;
        }

    }

    &__back {
        padding: 15px 30px 15px 45px ;
        &::before {
            content: '';
            position: absolute;
            background: url('../images/icons/uEA15-arrow_back.svg') center no-repeat;
            width: 15px;
            height: 15px;
            left: 30px;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }

    //Blue
    &__blue {
        background-color:       $c-grey-2;
    }

    //Green
    &__green {
        background-color:       $c-success;
    }

    &__text {
        color: $c-project3;
        text-align: center;
        text-decoration: underline;
        background-color: transparent;
        border: none;
    }

    //Account
    &__account {
        position: relative;
        border: 1px solid $c-project4;
        border-radius: 5rem;

        &::before {
            content: '';
            position: absolute;
            background: url('../images/icons/uEA11-account.svg') center no-repeat;
            width: 25px;
            height: 25px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }

        &:hover {
            border: 1px solid rgba($c-project5, .4);

            &::before {
               background: url('../images/icons/uEA11-account_orange.svg') center no-repeat;
            }
        }
    }

    &__round-check {
        position: relative;
        padding: 10px 15px 10px 40px;
        border: 2px solid $c-project5;
        font-size: 1.6rem;

        &::before {
            content: '';
            position: absolute;
            left: 15px;
            width: 20px;
            height: 20px;
            background: url('../images/icons/uEA13-round_check_white.svg') center no-repeat;
        }

        &:hover {
            &::before {
                background: url('../images/icons/uEA14-round_check.svg') center no-repeat;
            }
        }
    }

    // Arrow icon right
    &__arrow {
        padding-right: 55px;

        &::before{
            content: '';
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translatey(-50%);
            width: 25px;
            height: 25px;
            background: url('../images/full_arrow.svg') center no-repeat;
        }

        &:hover {
            &::before {
                background: url('../images/full_arrow_orange.svg') center no-repeat;
            }
        }
    }

    // Glass icon left
    &__search {
        padding-left: 55px;

        &::before{
            content: '';
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translatey(-50%);
            width: 25px;
            height: 25px;
            background: url('../images/icons/uEA10-loupe_white.svg') center no-repeat;
        }

        &:hover {
            background-color: transparent;

            span {
                color:            $c-button;
            }

            &::before{
                background: url('../images/icons/uEA10-loupe.svg') center no-repeat;
            }
        }
    }
}
