//
// Messages
// Error,Notice,Warning,Success
//

$widthMessageImage:    53px;
$heightMessageImage:   53px;

// Content
.page.messages {
    max-width: 610px;
    margin:    0 auto ;
    .messages {
        padding: 0 10px;
        position:   relative;
        .message-content {
            margin: 50px 0;
            text-align: center;
            font-size: 20px;

            .icon { display: none;}
            .icon__success { display: block;}
        }
        .error-msg,
        .message-error,
        .notice-msg,
        .warning-msg,
        .success-msg {
            border:     1px solid;
            margin:     50px 0;
            padding:    15px 20px;
            @include border-radius(5px);
            background-repeat: no-repeat;
            .message-content {
                color:          $c-project2;
                padding:        0 0 0 20px;
                text-align:     left;
                display:        inline-block;
                vertical-align: middle;
                width:          80%;
            }
            &:before {
                content:        "";
                display:        inline-block;
                height:         $heightMessageImage;
                width:          $widthMessageImage;
                vertical-align: middle;
            }
        }
        .error-msg, .message-error {
            background-color:   lighten($c-error, 32%);
            border-color:       $c-error;
            &:before {
                background: url("../images/picto-error.png");
            }
        }
        .notice-msg {
            background-color:   lighten($c-warning, 32%);
            border-color:       $c-warning;
            &:before {
                background: url("../images/picto-notice.png");
            }
        }
        .success-msg {
            background-color:   lighten($c-success, 32%);
            border-color:       $c-success;
            &:before {
                background: url("../images/picto-success.png");
            }
        }
        .warning-msg {
            background-color: #e6f5f7;
            border-color : #1f81ad;
            &:before {
                background: url(../images/picto-notice.png) no-repeat;
                width: 36px;
                background-size: 25px;
            }
        }
    }
}

@include respond-to(small) {
    .page.messages {
        .messages {
            .error-msg,
            .message-error,
            .notice-msg,
            .warning-msg,
            .success-msg {
                .message-content {
                    padding: 0;
                }
            }
        }
    }

}

.cms-home {
    .page.messages:has(.message-success) {
        position: fixed;
        align-content: center;
        max-width: none;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 1000;

        .message-success {
            position: relative;
            margin: 0 auto;
            max-width: 50%;
            padding: 25px;
            border-radius: $radius-md;
            background-color: $c-white;

            .message-content {
                padding-left: 55px;
            }

            .icon__success {
                display: block !important;
                position: absolute;
                top: 50%;
                left: 50px;
                width: 50px;
                height: 50px;
                transform: translateY(-50%);

                path {
                    &:nth-child(1) {
                        stroke-dasharray: 55px;
                        stroke-dashoffset: 55px;
                        animation: drawSuccess .5s .3s ease-in forwards;
                    }

                    &:nth-child(2) {
                        stroke-dasharray: 17px;
                        stroke-dashoffset: 17px;
                        animation: drawSuccess .25s .6s ease-in forwards;
                    }
                }
            }

            @keyframes drawSuccess {
    
                to {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    .page.messages.hide {display: none;}
}