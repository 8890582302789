// =============================================
// Contact page
// =============================================

.contact-index-index,
.easycore_contact-index-success {
    .header.content {
        display: none;
    }
}

.contact-index-index {

    #maincontent {
        margin-top: 100px; 
    }

    .form.contact {
        position: relative;
        max-width: 1070px;
        margin:     50px auto;
        padding: 65px 77px;

        // Shadow
        &::before {
            content: "";
            position: absolute;
            inset: 0px 0px 50%;
            box-shadow: 0px -25px 25px rgba(39, 73, 94, 0.15);
        }

      
        // Autofill reset
        :-webkit-autofill {
            border: none !important;
        }

        // Select
        select.field-row {
            height: auto;
            width: 100%;
        }
      
        // Textarea
        textarea {
            position: relative;
            font-weight: 400;
            min-height: 100px;
            height: auto;
            width: 100%;
            border: none;
        }

        .custom-checkbox+label {
            margin-bottom: 20px;
        }

        // Btn Submit
        .actions-toolbar {

            .button.submit {
                height: auto;
                margin-top: 0;
                padding: 10px 25px;
                width: 100%;
                font-size: 1.8rem;
                line-height: 1.6;
                text-transform: uppercase;
            }
        }

    }

    .h2-title {
        font-size: 2.5rem;
        margin: 0 0 35px;
        text-align: center;
        text-transform: uppercase;
    }

    .select-box {
        position: relative;
    }

    .requestObject-option {
        display: none;

        &.displayed {
            display: block;
        }
    }

    .recaptcha-container {
        .grecaptcha-badge {
            margin: 0 auto;
        }
    }
}


//SUCCESS
.easycore_contact-index-success {
    .success-img {
        width: 350px; 
        margin: 100px auto 25px;

        svg {
            width: 100%; 
            height: 100%;
  
            path.outside {
                stroke-dasharray: 400px;
                stroke-dashoffset: 400px;
                animation: anim .5s ease-in forwards;
            }
  
            path.inside-top {
                stroke-dasharray: 120px;
                stroke-dashoffset: 120px;
                animation: anim .5s ease-in forwards;
            }
            path.inside,
            path.inside,
            path.wind,
            path.wind {
                stroke-dasharray: 70px;
                stroke-dashoffset: 70px;
                animation: anim .5s ease-in forwards;
            }
            path.circle {
                stroke-dasharray: 170px;
                stroke-dashoffset: 170px;
                animation: anim .3s .4s ease-in forwards;
            }
            path.check {
                stroke-dasharray: 50px;
                stroke-dashoffset: 50px;
                animation: anim .3s .7s ease-in forwards;
            }
        }

        @keyframes anim {
            to {
                stroke-dashoffset: 0;
            }
        }
    }

    .success-message {
        width: 80%;
        margin: 0px auto 50px;
        text-align: center;
        font-size: 2.5rem;
    }
}

//
// Responsive
//
@include respond-to(medium) {

}

@include respond-to(small) {
    .contact-index-index .header.content,
    .easycore_contact-index-success .header.content {
        display: block;
    }

    .easycore_contact-index-success {
        .success-img {
            width: 100px;
            margin: 100px auto 25px;

            svg {
                width: 100%; 
                height: 100%;
             }
        }
        
        .success-message {
            font-size: 1.5rem;
        }
    }
}
