//
// Default CSS for Slick Slider
//

// Default Variables

$slick-font-path: "./fonts/pacifico/" !default;
$slick-font-family: "pacifico" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url, false, false);
    } @else {
        @return url($slick-loader-path + $url);
    }
}
@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    } @else {
        @return url($slick-font-path + $url);
    }
}
//* Slider */

.slick-slider {
    position:                    relative;
    display:                     block;
    box-sizing:                  border-box;
    -moz-box-sizing:             border-box;
    -webkit-touch-callout:       none;
    -webkit-user-select:         none;
    -khtml-user-select:          none;
    -moz-user-select:            none;
    -ms-user-select:             none;
    user-select:                 none;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display:  block;
    margin:   0;
    padding:  0;
    &:focus { outline: none; }
    .slick-loading & { background: #ffffff slick-image-url("ajax-loader.gif") center center no-repeat; }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
//.slick-slider .slick-list,
//.slick-track,
//.slick-slide,
//.slick-slide img { @include translate3d((0, 0, 0)); }
.slick-track {
    display: flex;
    position: relative;
    left:     0;
    top:      0;
    zoom:     1;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after { clear: both; }
    .slick-loading & { visibility: hidden; }
}
.slick-slide {
    //float:      left;
    display:    none;
    height:     100%;
    min-height: 1px;
    img {
        display:  block;
        margin:   auto;
    }
    &.slick-loading img { display: none; }
    &.dragging img { pointer-events: none; }
    .slick-initialized & { display: block; }
    .slick-loading & { visibility: hidden; }
    .slick-vertical & {
        display: block;
        height:  auto;
        border:  1px solid transparent;
    }
}
//* Icons */

//                      //
// Move in font modules //
//                      //
//@font-face {
//  font-family: "slick";
//  src:         slick-font-url("slick.eot");
//  src:         slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//  font-weight: normal;
//  font-style:  normal;
//}
//* Arrows */

.slick-prev,
.slick-next {
    position:    absolute;
    z-index:     50;
    opacity:     0;
    display:     block;
    height:      20px;
    width:       20px;
    line-height: 0;
    font-size:   0;
    cursor:      pointer;
    background:  transparent;
    color:       transparent;
    top:         50%;
    margin-top:  -10px;
    padding:     0;
    border:      none;
    outline:     none;
    @include transition(opacity 0.3s ease-out);
    &:focus { outline: none; }
    &.slick-disabled:before { opacity: 0.25; }
}
/* Show arrow when the cursor is over slider*/
.slick-slider:hover {
    .slick-prev,
    .slick-next {
        opacity: 1;
    }
}
.slick-prev:before, .slick-next:before {
    font-family:             $slick-font-family;
    font-size:               20px;
    line-height:             1;
    color:                   $slick-arrow-color;
    opacity:                 0.85;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: 20px;
    &:before { content: $slick-prev-character; }
}
.slick-next {
    right: 20px;
    &:before { content: $slick-next-character; }
}
//* Dots */
.slick-slider { margin-bottom: 30px; }
.slick-dots {
    position:   absolute;
    bottom:     -35px;
    list-style: none;
    display:    block;
    text-align: center;
    padding:    0px;
    width:      100%;

    li {
        position: relative;
        display:  inline-block;
        height:   4px;
        width:    45px;
        margin:   0px 5px;
        padding:  0px;
        cursor:   pointer;
        button {
            border:      0;
            background:  transparent;
            display:     block;
            height:      45px;
            width:       4px;
            outline:     none;
            line-height: 0;
            font-size:   0;
            color:       transparent;
            padding:     5px;
            cursor:      pointer;
            &:focus { outline: none; }
            &:before {
                position:                absolute;
                top:                     0;
                left:                    0;
                content:                 "";
                width:                   45px;
                height:                  4px;
                font-family:             $slick-font-family;
                font-size:               6px;
                line-height:             20px;
                text-align:              center;
                color:                   $slick-dot-color;
                opacity:                 0.25;
                -webkit-font-smoothing:  antialiased;
                -moz-osx-font-smoothing: grayscale;
                background-color:        $c-black;
            }
        }
        &.slick-active button:before { opacity: 0.75; }
    }
}
