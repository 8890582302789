@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/**
* Remove element after Xs 
* (used for logo intro scale is 5) 
* ToDo createde mixin to passed scale in argument
*/
@keyframes removeElement {
  50% {
    opacity: 1; }
  90% {
    transform: scale(5); }
  100% {
    display: none !important;
    transform: scale(0);
    opacity: 0; } }

@keyframes draw {
  to {
    stroke-dashoffset: 0; } }

@keyframes drawSuccess {
  to {
    stroke-dashoffset: 0; } }

@keyframes fill-in {
  to {
    fill-opacity: 1; } }

@keyframes roll {
  0% {
    transform: rotate(0) scale(1); }
  50% {
    transform: rotate(180deg) scale(0.8); }
  100% {
    transform: rotate(360deg) scale(1); } }

@keyframes loaderProxiWheel {
  0% {
    transform: rotate(0) scale(0.7); }
  50% {
    transform: rotate(360deg) scale(1); }
  100% {
    transform: rotate(0deg) scale(0.7); } }

@keyframes letter-translate {
  0% {
    transform: translateY(150%); }
  60% {
    transform: translateY(0%); }
  80% {
    transform: translateY(1%); }
  100% {
    transform: translateY(0); } }

@keyframes scaledAndShadow {
  55% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0 2px 51px rgba(0, 0, 0, 0.14);
    z-index: 2; }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    box-shadow: 0px 20px 30px 0px #0000000D; } }

@keyframes bounce-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  60% {
    opacity: 1;
    transform: translateY(0%); }
  80% {
    transform: translateY(1%); }
  100% {
    transform: translateY(0); } }

/* Media queries
-------------------------- */
/* Responsive
-------------------------- */
@font-face {
  font-family: "source-sans-pro";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/source-sans-pro/source-sans-pro-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-regular.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-regular.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-regular.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-regular.svg#source-sans-pro") format("svg"); }

@font-face {
  font-family: "source-sans-pro";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/source-sans-pro/source-sans-pro-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-bold.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-bold.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-bold.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-bold.svg#source-sans-pro") format("svg"); }

@font-face {
  font-family: "source-sans-pro";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/source-sans-pro/source-sans-pro-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro/source-sans-pro-italic.woff2") format("woff2"), url("../fonts/source-sans-pro/source-sans-pro-italic.woff") format("woff"), url("../fonts/source-sans-pro/source-sans-pro-italic.ttf") format("truetype"), url("../fonts/source-sans-pro/source-sans-pro-italic.svg#source-sans-pro") format("svg"); }

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../fonts/roboto/Roboto-Regular.woff") format("woff"), url("../fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("../fonts/roboto/Roboto-Regular.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium.svg#Roboto-Medium") format("svg"); }

@font-face {
  font-family: "Roboto-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../fonts/roboto/Roboto-Bold.woff") format("woff"), url("../fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("../fonts/roboto/Roboto-Bold.svg#Roboto-Bold") format("svg"); }

@font-face {
  font-family: "Caveat-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/caveat/Caveat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/caveat/Caveat-Regular.woff2") format("woff2"), url("../fonts/caveat/Caveat-Regular.woff") format("woff"), url("../fonts/caveat/Caveat-Regular.ttf") format("truetype"), url("../fonts/caveat/Caveat-Regular.svg#Caveat-Regular") format("svg"); }

@font-face {
  font-family: "Caveat-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/caveat/Caveat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/caveat/Caveat-Medium.woff2") format("woff2"), url("../fonts/caveat/Caveat-Medium.woff") format("woff"), url("../fonts/caveat/Caveat-Medium.ttf") format("truetype"), url("../fonts/caveat/Caveat-Medium.svg#Caveat-Medium") format("svg"); }

@font-face {
  font-family: "Caveat-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/caveat/Caveat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/caveat/Caveat-Bold.woff2") format("woff2"), url("../fonts/caveat/Caveat-Bold.woff") format("woff"), url("../fonts/caveat/Caveat-Bold.ttf") format("truetype"), url("../fonts/caveat/Caveat-Bold.svg#Caveat-Bold") format("svg"); }

@font-face {
  font-family: 'iconfont';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/iconfont/iconfont.eot");
  src: url("../fonts/iconfont/iconfont.woff2") format("woff2"), url("../fonts/iconfont/iconfont.woff") format("woff"), url("../fonts/iconfont/iconfont.ttf") format("truetype"), url("../fonts/iconfont/iconfont.eot?#iefix") format("embedded-opentype"); }

.icon[class^="icon-"],
.icon[class*=" icon-"] {
  display: inline-block;
  font-family: 'iconfont';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none; }

.icon-coche:before {
  content: ""; }

.icon-fioul:before {
  content: ""; }

.icon-gaz:before {
  content: ""; }

.icon-tel:before {
  content: ""; }

.icon-menu:before {
  content: ""; }

.icon-arrow:before {
  content: ""; }

.icon-envelop:before {
  content: ""; }

.icon-phone:before {
  content: ""; }

.icon-pac:before {
  content: ""; }

.icon-clim:before {
  content: ""; }

.icon-category {
  width: 42px;
  height: 42px; }
  .icon-category.ch_gaz {
    background: url("../images/icons/uEA19-ch_gaz.svg") center no-repeat; }
  .icon-category.ch_fioul {
    background: url("../images/icons/uEA20-ch_fioul.svg") center no-repeat; }
  .icon-category.pac {
    background: url("../images/icons/uEA09-pump.svg") center no-repeat; }
  .icon-category.clim {
    background: url("../images/icons/uEA12-clim.svg") center no-repeat; }

* {
  outline: none;
  box-sizing: border-box; }
  *:active, *:focus {
    outline: none; }

*:-webkit-autofill {
  border-width: 0 0 2px !important;
  border-color: #8c8c8c !important;
  border-style: solid !important;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: #000; }
  *:-webkit-autofill:hover, *:-webkit-autofill:focus {
    border-width: 0 0 2px !important;
    border-color: #8c8c8c !important;
    border-style: solid !important; }

*::-ms-clear {
  display: none; }

*::-moz-focus-inner {
  border: 0;
  padding: 0; }

.hidden {
  border: 0;
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 0; }

.nobr {
  white-space: nowrap; }

.wrap {
  white-space: normal; }

.a-left {
  text-align: left; }

.a-center,
.al-center {
  text-align: center; }

.a-right {
  text-align: right; }

.upper {
  text-transform: uppercase; }

.lower {
  text-transform: none; }

.v-top {
  vertical-align: top; }

.v-middle {
  vertical-align: middle; }

.f-left,
.left {
  float: left; }

.f-right,
.right {
  float: right; }

.f-none {
  float: none; }

.f-fix {
  float: left;
  width: 100%; }

.no-display {
  display: none; }

.no-margin {
  margin: 0; }

.no-padding {
  padding: 0; }

.no-bg {
  background: none; }

.clear {
  clear: both; }

.c-black {
  color: #000000; }

.c-black-light {
  color: #333333; }

.c-white {
  color: white; }

.cur-p {
  cursor: pointer; }

.d-block {
  display: block; }

.d-inline {
  display: inline; }

.ov-h {
  overflow: hidden; }

.p-r {
  position: relative; }

.underline {
  text-decoration: underline; }
  .underline:hover {
    text-decoration: none; }

.rotate45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-45 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.rotate-180 {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg); }

a,
.link {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  transition: all 150ms linear;
  color: #373737;
  text-decoration: none; }
  a:hover, a.active,
  .link:hover,
  .link.active {
    color: #555555; }

del {
  text-decoration: line-through; }

i {
  font-style: italic; }

img {
  max-width: 100%;
  height: auto; }

strong,
.strong {
  font-weight: bold; }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.dp-table {
  display: table;
  width: 100%; }

.data.table {
  width: 100%; }
  .data.table thead th {
    background-color: #eeeeee;
    padding: 15px 20px;
    text-align: left; }
  .data.table tbody td {
    border-bottom: 1px solid #dddddd;
    padding: 15px 20px; }
  .data.table tfoot td {
    border-bottom: 1px solid #dddddd;
    padding: 15px 20px; }
  .data.table th,
  .data.table tr,
  .data.table td {
    vertical-align: middle; }

html {
  font-size: 62.5%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  color: #373737;
  font-size: 1.4rem;
  font-weight: normal;
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal; }

.h1-title {
  font-size: 4.0rem;
  margin: 0 0 15px 0;
  font-family: "Roboto", sans-serif; }

.h2-title {
  font-size: 2rem;
  margin: 0 0 15px 0;
  font-family: "Roboto", sans-serif;
  color: #373737; }
  .h2-title span {
    color: #ef604e; }

.h3-title {
  font-size: 1.6rem;
  margin: 0 0 15px 0;
  font-family: "Roboto", sans-serif;
  color: #373737; }

.h4-title {
  font-size: 1.4rem;
  margin: 0 0 15px 0;
  font-family: "Roboto", sans-serif; }

.light-title {
  font-size: 1.6rem;
  margin: 0 0 15px 0;
  color: #afafaf; }

.title-uppercase {
  text-transform: uppercase;
  margin: 0 0 15px 0; }

.title-blocks {
  font-size: 1.8rem;
  margin: 0 0 15px 0; }

.border-gradient {
  background-color: red;
  background-image: -webkit-linear-gradient(left, red, orange);
  background-image: linear-gradient(to right,red, orange); }

.ov-x {
  overflow-x: auto; }

.ov-y {
  overflow-y: auto; }

.clearer:after,
.header-container:after,
.header-container .top-container:after,
.header .quick-access:after,
#nav:after,
.main:after,
.footer:after,
.footer-container .bottom-container:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col3-layout .product-options-bottom .price-box:after,
.col4-set:after,
.search-autocomplete li:after,
.block .block-content:after,
.block .actions:after,
.block li.item:after,
.block-poll li:after,
.block-layered-nav .currently li:after,
.page-title:after,
.products-grid:after,
.products-list li.item:after,
.box-account .box-head:after,
.dashboard .box .box-title:after,
.box-reviews li.item:after,
.box-tags li.item:after,
.pager:after,
.sorter:after,
.ratings:after,
.add-to-box:after,
.add-to-cart:after,
.product-essential:after,
.product-collateral:after,
.product-view .product-img-box .more-views ul:after,
.product-view .box-tags .form-add:after,
.product-view .product-shop .short-description:after,
.product-view .box-description:after,
.product-options .options-list li:after,
.product-options-bottom:after,
.product-review:after,
.cart:after,
.cart-collaterals:after,
.cart .crosssell li.item:after,
.opc .step-title:after,
.checkout-progress:after,
.multiple-checkout .place-order:after,
.group-select li:after,
.form-list li:after,
.form-list .field:after,
.buttons-set:after,
.page-print .print-head:after,
.advanced-search-summary:after,
.gift-messages-form .item:after,
.send-friend .form-list li p:after {
  display: block;
  content: ".";
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  overflow: hidden; }

@media only screen and (max-width: 800px) {
  .hide-mobile {
    display: none; }
  .h1-title {
    font-size: 3rem; }
  .h2-title {
    font-size: 2rem; }
  .h3-title {
    font-size: 1.4rem; }
  .h4-title {
    font-size: 1.2rem; }
  .light-title {
    font-size: 1.4rem; } }

.button {
  -webkit-appearance: none;
  background: #ef604e;
  border: 2px solid #ef604e;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  height: 40px;
  line-height: 36px;
  padding: 0 10px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear; }
  .button:hover {
    background-color: transparent;
    color: #ef604e; }
  .button.add {
    width: 130px;
    background: #000000;
    margin: 0 auto;
    border: none;
    border-radius: 40px; }
  .button.button-big {
    height: 52px;
    line-height: 48px;
    font-size: 1.6rem; }
  .button.bt-border {
    background: none;
    border-radius: 50px;
    border: 2px solid #ffffff; }
    .button.bt-border:hover {
      border-color: #ef604e; }
    .button.bt-border.my-account {
      border-color: #ef604e; }
  .button.bt-border-black-big {
    background: none;
    height: 52px;
    line-height: 48px;
    text-transform: uppercase;
    border: 2px solid #373737;
    color: #373737; }
    .button.bt-border-black-big:hover {
      color: #ef604e;
      border-color: #ef604e; }
  .button.button-grey {
    height: 45px;
    line-height: 40px;
    text-transform: uppercase;
    font-size: 1.2rem;
    background: #373737;
    border: 2px solid #373737; }
    .button.button-grey:hover {
      border-color: #ef604e;
      background: #ef604e;
      color: #ffffff; }
  .button.button-medium {
    height: 32px;
    line-height: 30px;
    font-weight: 500; }
  .button.button-little {
    height: 26px;
    line-height: 24px;
    font-size: 1.1rem;
    text-transform: none;
    font-weight: 600; }
  .button.button-black {
    background: #000000;
    color: #ffffff;
    font-weight: 600; }
    .button.button-black:hover {
      background: #ffffff;
      color: #000000; }
  .button.button-white {
    border: none;
    background: #ffffff;
    color: #000000;
    font-size: 1.4rem;
    line-height: 40px;
    font-weight: 500; }
    .button.button-white:hover {
      background: #000000;
      color: #ffffff; }
  .button.button-white-big {
    height: 50px;
    line-height: 50px;
    border: none;
    background: #ffffff;
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500; }
    .button.button-white-big:hover {
      background: #000000;
      color: #ffffff; }
  .button.small {
    display: inline-block;
    width: auto; }
  .button .icon {
    font-size: 20px;
    margin: 0 10px 0 0; }
  .button.loading {
    font-size: 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap; }
  .button.accept {
    width: 45px;
    display: inline;
    height: 25px;
    line-height: 15px; }
  .button.cancel {
    width: 70px;
    padding: 2px;
    display: inline;
    height: 25px;
    line-height: 15px; }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.btn {
  position: relative;
  -webkit-appearance: none;
  display: inline-block;
  align-items: center;
  padding: 20px 30px;
  cursor: pointer;
  border-radius: 200px;
  text-align: center; }
  .btn .btn-label {
    display: block;
    color: #ffffff;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 1.8rem;
    font-weight: 500; }
  .btn:disabled {
    background-color: #dddddd;
    border-color: #dddddd;
    cursor: not-allowed; }

.btn {
  font-family: "Roboto", sans-serif; }
  .btn:hover:not(:disabled) {
    background-color: transparent;
    color: #ef604e; }
  .btn__main {
    background-color: #ef604e;
    color: #ffffff;
    border: 1px solid #ef604e; }
  .btn__white {
    background-color: #ffffff;
    border: 1px solid #D7DFE2; }
    .btn__white .btn-label {
      color: #224B61; }
  .btn__back {
    padding: 15px 30px 15px 45px; }
    .btn__back::before {
      content: '';
      position: absolute;
      background: url("../images/icons/uEA15-arrow_back.svg") center no-repeat;
      width: 15px;
      height: 15px;
      left: 30px;
      top: 50%;
      transform: translate(-50%, -50%); }
  .btn__blue {
    background-color: #224B61; }
  .btn__green {
    background-color: #72C070; }
  .btn__text {
    color: #8c8c8c;
    text-align: center;
    text-decoration: underline;
    background-color: transparent;
    border: none; }
  .btn__account {
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 5rem; }
    .btn__account::before {
      content: '';
      position: absolute;
      background: url("../images/icons/uEA11-account.svg") center no-repeat;
      width: 25px;
      height: 25px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .btn__account:hover {
      border: 1px solid rgba(239, 96, 78, 0.4); }
      .btn__account:hover::before {
        background: url("../images/icons/uEA11-account_orange.svg") center no-repeat; }
  .btn__round-check {
    position: relative;
    padding: 10px 15px 10px 40px;
    border: 2px solid #ef604e;
    font-size: 1.6rem; }
    .btn__round-check::before {
      content: '';
      position: absolute;
      left: 15px;
      width: 20px;
      height: 20px;
      background: url("../images/icons/uEA13-round_check_white.svg") center no-repeat; }
    .btn__round-check:hover::before {
      background: url("../images/icons/uEA14-round_check.svg") center no-repeat; }
  .btn__arrow {
    padding-right: 55px; }
    .btn__arrow::before {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translatey(-50%);
      width: 25px;
      height: 25px;
      background: url("../images/full_arrow.svg") center no-repeat; }
    .btn__arrow:hover::before {
      background: url("../images/full_arrow_orange.svg") center no-repeat; }
  .btn__search {
    padding-left: 55px; }
    .btn__search::before {
      content: '';
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translatey(-50%);
      width: 25px;
      height: 25px;
      background: url("../images/icons/uEA10-loupe_white.svg") center no-repeat; }
    .btn__search:hover {
      background-color: transparent; }
      .btn__search:hover span {
        color: #ef604e; }
      .btn__search:hover::before {
        background: url("../images/icons/uEA10-loupe.svg") center no-repeat; }

form,
.billing-address-form {
  font-family: "Roboto", sans-serif; }
  form fieldset,
  form .fieldset,
  .billing-address-form fieldset,
  .billing-address-form .fieldset {
    border: none;
    margin: 0 0 50px;
    padding: 0; }
  form legend,
  .billing-address-form legend {
    font-size: 1.6rem;
    font-weight: 600; }
  form label,
  .billing-address-form label {
    display: block;
    font-size: 1.6rem; }
  form ul li,
  .billing-address-form ul li {
    margin: 0 0 15px 0; }
  form textarea,
  form .textarea,
  .billing-address-form textarea,
  .billing-address-form .textarea {
    resize: vertical;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    background: #eeeeee; }
    form textarea.validation-failed,
    form .textarea.validation-failed,
    .billing-address-form textarea.validation-failed,
    .billing-address-form .textarea.validation-failed {
      border-color: #f50057 !important; }
  form .field,
  .billing-address-form .field {
    margin: 0 0 15px;
    position: relative; }
    form .field .error:before,
    .billing-address-form .field .error:before {
      content: "X";
      color: #ef604e;
      display: block;
      font-size: 2.4rem;
      position: absolute;
      right: 0;
      bottom: 25px;
      font-weight: bold; }
    form .field .checked:before,
    .billing-address-form .field .checked:before {
      content: "\EA01";
      color: #66cc9b;
      display: block;
      font-family: "iconfont";
      font-size: 2rem;
      position: absolute;
      right: 0;
      bottom: 10px; }
    form .field .control,
    .billing-address-form .field .control {
      position: relative; }
      form .field .control.One-Time-Code,
      .billing-address-form .field .control.One-Time-Code {
        text-align: center; }
  form .validation-advice,
  .billing-address-form .validation-advice {
    color: #f50057;
    font: 1.2rem; }
  form .button,
  .billing-address-form .button {
    width: 235px;
    margin: 40px 0 0; }
  form .password-strength-meter,
  .billing-address-form .password-strength-meter {
    color: #8c8c8c;
    font-size: 1.1rem;
    margin: 5px 0 0; }
    form .password-strength-meter span,
    .billing-address-form .password-strength-meter span {
      font-weight: bold;
      font-size: 1.2rem; }
  form div.mage-error, form div.field-error,
  .billing-address-form div.mage-error,
  .billing-address-form div.field-error {
    font-size: 1.2rem;
    color: #ef604e;
    margin: 5px 0 0;
    position: absolute;
    top: 100%; }

#checkout-step-shipping .form-login label,
.form-shipping-address label,
.billing-address-form label,
.payments label {
  margin: 15px 0; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

input {
  margin: 0;
  padding: 0; }
  input.mage-error {
    border-color: #ef604e; }

._error input {
  border-color: #ef604e; }

.input-text, .textarea {
  background: #eeeeee;
  border: solid 1px #dddddd;
  border-radius: 6px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  padding: 20px;
  width: 100%;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  transition: all 250ms ease; }
  .input-text:focus, .textarea:focus {
    border-color: #8c8c8c; }
  .input-text.validation-failed, .validation-failed.textarea {
    border-color: #f50057; }
  .input-text.validate, .validate.textarea {
    background-color: #ffffff;
    border-color: #66cc9b;
    color: #66cc9b; }

div.control:has(.validate)::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 15px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("../images/green_check.png"); }

.textarea {
  height: 100px;
  line-height: normal; }

.form-text {
  margin: 0 0 20px; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url(../images/select-bg.svg) no-repeat 100% 45%;
  background-size: 30px 60px;
  border: 1px solid #d8d8d8;
  height: 40px;
  padding: 0 25px 0 10px;
  text-indent: .01em;
  border-radius: 5px;
  min-width: 50px; }
  select option:checked {
    color: red; }

input::-webkit-input-placeholder {
  color: #afafaf;
  font-size: 1.3rem;
  font-weight: normal; }

input::-moz-placeholder {
  color: #afafaf;
  font-size: 1.3rem;
  font-weight: normal; }

input:-moz-placeholder {
  color: #afafaf;
  font-size: 1.3rem;
  font-weight: normal; }

input:-ms-input-placeholder {
  color: #afafaf;
  font-size: 1.3rem;
  font-weight: normal; }

@media only screen and (max-width: 800px) {
  form .button {
    margin: 40px auto 0; } }

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px; }
  .grid_container .grid-col-1 {
    grid-column: 1; }
  .grid_container .grid-col-2 {
    grid-column: 2; }
  @media screen and (max-width: 640px) {
    .grid_container .grid-col-1,
    .grid_container .grid-col-2 {
      grid-column: 1/3; } }

.radio-container {
  text-align: center; }
  .radio-container input[type="radio"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0; }
  .radio-container label {
    width: 100%;
    line-height: 1.8;
    padding: 20px 25px;
    background-color: #F4F5F6;
    color: #A8B9C1;
    border-radius: 5px; }
  .radio-container input[type="radio"] + label {
    position: relative;
    display: inline-block;
    min-height: 24px;
    cursor: pointer; }
  .radio-container input:checked + label {
    background-color: #ef604e;
    color: #fff; }

.field-row {
  position: relative;
  padding: 20px 0px 20px 25px;
  border: 1px solid #D7DFE2;
  font-size: 16px;
  border-radius: 5px; }

form .field .field-row .checked:before {
  font-size: 1rem;
  right: 10px;
  bottom: 25px; }

.float-labels input[type="text"],
.float-labels input[type="email"] {
  display: block;
  height: 28px;
  width: 100%;
  margin-top: 0px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #282B31;
  border: 0 none;
  background-color: transparent;
  padding: 0 30px 0 0;
  border-radius: 0;
  -webkit-transition: all .2s;
  transition: all .2s; }
  .float-labels input[type="text"]::placeholder,
  .float-labels input[type="email"]::placeholder {
    opacity: 0; }

.float-labels label {
  padding: 0; }
  .float-labels label span {
    position: absolute;
    transform: translateY(-25px);
    color: #A8B9C1;
    font-size: 1.4rem;
    transition: all .1s; }

.float-labels input[type="text"]:focus + label span,
.float-labels input[type="email"]:focus + label span,
.float-labels div.mage-error + label span,
.float-labels label.filled span {
  transform: translateY(-45px); }

.checkbox-container {
  position: relative;
  min-height: 30px; }
  .checkbox-container .custom-checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0; }
  .checkbox-container .custom-checkbox + label {
    position: relative;
    padding-top: 0;
    padding-left: 40px;
    min-height: 24px;
    cursor: pointer;
    font-size: 1.4rem;
    display: flex;
    align-items: flex-start; }
    .checkbox-container .custom-checkbox + label::before, .checkbox-container .custom-checkbox + label::after {
      cursor: pointer;
      position: absolute;
      transition: all .2s; }
    .checkbox-container .custom-checkbox + label::before {
      content: "";
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      background-color: transparent; }
    .checkbox-container .custom-checkbox + label::after {
      content: "\EA01";
      font-family: "iconfont";
      left: 5px;
      top: 5px;
      color: #F44D57;
      font-size: 1.5rem;
      line-height: 2rem;
      opacity: 0;
      transform: scale(0); }
  .checkbox-container input[type="checkbox"].custom-checkbox:checked + label::after {
    opacity: 1;
    transform: scale(1); }

div.mage-error {
  position: absolute;
  margin-top: 0;
  bottom: -15px; }

:-webkit-autofill {
  border: none !important; }

.return {
  height: auto;
  padding: 10px 25px;
  width: 50%;
  margin: 20px auto;
  font-size: 1.8rem;
  line-height: 1.6;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  background-color: #ef604e;
  border-radius: 5px; }
  .return a {
    display: block;
    color: #fff; }

.field.choice {
  margin: 0 20px 10px 0;
  position: relative;
  overflow: hidden;
  display: inline-block; }
  .field.choice label {
    cursor: pointer;
    position: relative; }
  .field.choice input[type="checkbox"]:not(.custom-checkbox) {
    opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0; }
    .field.choice input[type="checkbox"]:not(.custom-checkbox) + label {
      display: block;
      min-height: 20px;
      line-height: 20px;
      padding: 0 0 0 30px;
      width: auto; }
      .field.choice input[type="checkbox"]:not(.custom-checkbox) + label:before {
        cursor: pointer;
        content: " ";
        display: inline-block;
        border: 2px solid #8c8c8c;
        width: 20px;
        height: 20px;
        background: url(../images/checkbox.jpg) 0 0 no-repeat;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        -webkit-transition: border-color 0.3s linear;
        -moz-transition: border-color 0.3s linear;
        transition: border-color 0.3s linear; }
      .field.choice input[type="checkbox"]:not(.custom-checkbox) + label:hover:before {
        border: 2px solid #ef604e; }
    .field.choice input[type="checkbox"]:not(.custom-checkbox):checked + label:before,
    .field.choice input[type="checkbox"]:not(.custom-checkbox):disabled + label:before {
      background-position: 0 100%;
      border: 2px solid #ef604e; }
  .field.choice input[type="radio"] {
    opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0; }
    .field.choice input[type="radio"].active + label::after {
      content: " ";
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #ef604e;
      border-radius: 100%; }
    .field.choice input[type="radio"] + label {
      display: block;
      padding-left: 31px; }
      .field.choice input[type="radio"] + label:before {
        cursor: pointer;
        content: " ";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid #dddddd;
        border-radius: 100%; }
    .field.choice input[type="radio"]:checked + label:before {
      background-position: 0 100%; }

._active .field.choice input[type="radio"] + label::after {
  content: " ";
  display: inline-block;
  width: 12px;
  height: 12px;
  left: 0;
  top: 0;
  position: absolute;
  background-color: #ef604e;
  border-radius: 100%;
  transform: translate(50%, 50%); }

.toolbar .sorter .sort-by {
  float: right;
  margin: 0 10px; }
  .toolbar .sorter .sort-by label {
    color: #000000;
    font: 600 1.2rem;
    float: left;
    margin: 6px 15px 0; }

.message.cookie {
  background-color: rgba(245, 245, 245, 0.8);
  bottom: 30px;
  right: 30px;
  left: auto;
  top: auto;
  padding: 40px 30px;
  position: fixed;
  vertical-align: middle;
  text-align: center;
  width: 350px;
  z-index: 999; }
  .message.cookie .actions a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: underline; }
  .message.cookie .action:after {
    content: "\00d7";
    cursor: pointer;
    font-size: 35px;
    position: absolute;
    right: 15px;
    top: 0px; }

/* ---------------------------
    Wrapper
---------------------------*/
.wrapper-large {
  max-width: 1410px;
  margin: 0 auto; }
  @media (min-width: 480px) {
    .wrapper-large {
      padding: 0 10px; } }

.wrapper-medium {
  max-width: 1300px;
  margin: 0 auto; }

/* ---------------------------
    Responsive classes
---------------------------*/
.mobile_screen-only {
  display: initial; }
  @media (min-width: 480px) {
    .mobile_screen-only {
      display: none; } }

.tablet_screen-only {
  display: initial; }
  @media (min-width: 800px) {
    .tablet_screen-only {
      display: none !important; } }

.medium_screen-only {
  display: initial; }
  .medium_screen-only.btn {
    display: inline-block; }
  @media (min-width: 1024px) {
    .medium_screen-only {
      display: none !important; }
      .medium_screen-only.btn {
        display: none; } }

.large_screen-only {
  display: none; }
  @media (min-width: 1200px) {
    .large_screen-only {
      display: initial; } }

.extra-large_screen-only {
  display: none; }
  @media (min-width: 1445px) {
    .extra-large_screen-only {
      display: initial; } }

.mobile_screen-start {
  display: none; }
  @media (min-width: 480px) {
    .mobile_screen-start {
      display: initial; } }

.tablet_screen-start {
  display: none; }
  @media (min-width: 800px) {
    .tablet_screen-start {
      display: initial; } }

.medium_screen-start {
  display: none; }
  @media (min-width: 1024px) {
    .medium_screen-start {
      display: initial; }
      .medium_screen-start.btn {
        display: inline-block; } }

/* ---------------------------
    Utils classes
---------------------------*/
.flex {
  display: flex; }

.text__main-color {
  color: #ef604e; }

.text__blue {
  color: #4e8eef; }

.text__small-light-grey {
  color: #8c8c8c;
  font-weight: 400; }

.text__small {
  font-size: 1.2rem; }

.text__bold {
  font-weight: 600; }

.text__end {
  text-align: end; }

.vue-variables {
  display: none; }
  .vue-variables.vue-loaded {
    display: block; }

/* ---------------------------
    Layout block classes
---------------------------*/
.round-white_box {
  border-radius: 12px;
  background-color: #ffffff;
  padding: 30px 20px; }
  @media (min-width: 801px) {
    .round-white_box {
      padding: 30px 40px; } }

.info-block {
  position: relative;
  padding: 20px;
  background: rgba(86, 148, 242, 0.2);
  text-align: left;
  border-radius: 6px; }
  .info-block__text {
    color: #5694F2;
    padding: 0 0 0 40px;
    display: inline-block;
    vertical-align: middle; }
  .info-block:before {
    background-image: url("../images/info_icon.svg");
    background-size: cover;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .info-block.dark-blue {
    background: #5694F2; }
    .info-block.dark-blue .info-block__text {
      color: #ffffff; }
    .info-block.dark-blue:before {
      background-image: url("../images/info_icon_white.svg"); }

.price__cross-out {
  position: relative;
  display: inline-block;
  color: #373737; }
  .price__cross-out::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: #373737; }

.vue-modal {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); }
  .vue-modal.show {
    display: flex; }
  .vue-modal__content {
    max-width: 600px;
    text-align: center; }
  .vue-modal__picto {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    background-size: 100%;
    background-repeat: no-repeat; }
    .vue-modal__picto.warning {
      background-image: url("../images/icons/warning.svg"); }
  .vue-modal__title {
    margin: 20px 0;
    font-size: 2.5rem;
    font-weight: 600; }
  .vue-modal__infos {
    font-size: 1.8rem;
    line-height: 2.5rem; }
  .vue-modal__primary {
    font-size: 2rem;
    width: 100%;
    margin: 20px 0;
    padding: 10px 30px; }
  .vue-modal__secondary {
    cursor: pointer;
    font-size: 1.6rem;
    color: #8c8c8c;
    text-decoration: underline; }

[autocomplete=one-time-code] {
  --otp-digits: 6;
  --otp-ls: 2ch;
  --otp-gap: 1.25;
  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);
  --_otp-digit: 0;
  all: unset;
  background: linear-gradient(90deg, var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)), transparent 0), linear-gradient(90deg, var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)), transparent 0);
  text-align: left;
  background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
  background-repeat: no-repeat, repeat-x;
  background-size: var(--_otp-bgsz) 100%;
  caret-color: var(--otp-cc, #222);
  caret-shape: block;
  clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
  font-size: var(--otp-fz, 2em);
  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
  letter-spacing: var(--otp-ls);
  padding-block: var(--otp-pb, 1ch);
  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap)); }
  @media (min-width: 801px) {
    [autocomplete=one-time-code] {
      font-size: var(--otp-fz, 2.5em); } }

.proxi_Wheel-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99;
  pointer-events: none; }
  .proxi_Wheel-loader svg {
    width: 80px;
    height: 80px;
    animation: loaderProxiWheel 3s infinite;
    transform: rotate(30deg);
    fill: #EC3D2D; }

.col-1, .col-s-1, .col-m-1, .col-l-1, .col-2, .col-s-2, .col-m-2, .col-l-2, .col-3, .col-s-3, .col-m-3, .col-l-3, .col-4, .col-s-4, .col-m-4, .col-l-4, .col-5, .col-s-5, .col-m-5, .col-l-5, .col-6, .col-s-6, .col-m-6, .col-l-6, .col-7, .col-s-7, .col-m-7, .col-l-7, .col-8, .col-s-8, .col-m-8, .col-l-8, .col-9, .col-s-9, .col-m-9, .col-l-9, .col-10, .col-s-10, .col-m-10, .col-l-10, .col-11, .col-s-11, .col-m-11, .col-l-11, .col-12, .col-s-12, .col-m-12, .col-l-12, .col-13, .col-s-13, .col-m-13, .col-l-13, .col-14, .col-s-14, .col-m-14, .col-l-14, .col-15, .col-s-15, .col-m-15, .col-l-15, .col-16, .col-s-16, .col-m-16, .col-l-16 {
  float: left;
  position: relative;
  min-height: 1px;
  padding: 0 10px; }

.row {
  margin: 0 -10px; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }
  .row {
    *zoom: 1; }
  .row > * {
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    transition: all 400ms ease-out; }

.m-show {
  display: none; }

.l-show {
  display: none; }

.col-l-push-0 {
  left: 0%; }

.col-l-offset-0 {
  margin-left: 0%; }

.col-l-pull-0 {
  right: 0%; }

.col-l-1 {
  width: 6.25%; }

.col-l-push-1 {
  left: 6.25%; }

.col-l-offset-1 {
  margin-left: 6.25%; }

.col-l-pull-1 {
  right: 6.25%; }

.col-l-2 {
  width: 12.5%; }

.col-l-push-2 {
  left: 12.5%; }

.col-l-offset-2 {
  margin-left: 12.5%; }

.col-l-pull-2 {
  right: 12.5%; }

.col-l-3 {
  width: 18.75%; }

.col-l-push-3 {
  left: 18.75%; }

.col-l-offset-3 {
  margin-left: 18.75%; }

.col-l-pull-3 {
  right: 18.75%; }

.col-l-4 {
  width: 25%; }

.col-l-push-4 {
  left: 25%; }

.col-l-offset-4 {
  margin-left: 25%; }

.col-l-pull-4 {
  right: 25%; }

.col-l-5 {
  width: 31.25%; }

.col-l-push-5 {
  left: 31.25%; }

.col-l-offset-5 {
  margin-left: 31.25%; }

.col-l-pull-5 {
  right: 31.25%; }

.col-l-6 {
  width: 37.5%; }

.col-l-push-6 {
  left: 37.5%; }

.col-l-offset-6 {
  margin-left: 37.5%; }

.col-l-pull-6 {
  right: 37.5%; }

.col-l-7 {
  width: 43.75%; }

.col-l-push-7 {
  left: 43.75%; }

.col-l-offset-7 {
  margin-left: 43.75%; }

.col-l-pull-7 {
  right: 43.75%; }

.col-l-8 {
  width: 50%; }

.col-l-push-8 {
  left: 50%; }

.col-l-offset-8 {
  margin-left: 50%; }

.col-l-pull-8 {
  right: 50%; }

.col-l-9 {
  width: 56.25%; }

.col-l-push-9 {
  left: 56.25%; }

.col-l-offset-9 {
  margin-left: 56.25%; }

.col-l-pull-9 {
  right: 56.25%; }

.col-l-10 {
  width: 62.5%; }

.col-l-push-10 {
  left: 62.5%; }

.col-l-offset-10 {
  margin-left: 62.5%; }

.col-l-pull-10 {
  right: 62.5%; }

.col-l-11 {
  width: 68.75%; }

.col-l-push-11 {
  left: 68.75%; }

.col-l-offset-11 {
  margin-left: 68.75%; }

.col-l-pull-11 {
  right: 68.75%; }

.col-l-12 {
  width: 75%; }

.col-l-push-12 {
  left: 75%; }

.col-l-offset-12 {
  margin-left: 75%; }

.col-l-pull-12 {
  right: 75%; }

.col-l-13 {
  width: 81.25%; }

.col-l-push-13 {
  left: 81.25%; }

.col-l-offset-13 {
  margin-left: 81.25%; }

.col-l-pull-13 {
  right: 81.25%; }

.col-l-14 {
  width: 87.5%; }

.col-l-push-14 {
  left: 87.5%; }

.col-l-offset-14 {
  margin-left: 87.5%; }

.col-l-pull-14 {
  right: 87.5%; }

.col-l-15 {
  width: 93.75%; }

.col-l-push-15 {
  left: 93.75%; }

.col-l-offset-15 {
  margin-left: 93.75%; }

.col-l-pull-15 {
  right: 93.75%; }

.col-l-16 {
  width: 100%; }

.col-l-push-16 {
  left: 100%; }

.col-l-offset-16 {
  margin-left: 100%; }

.col-l-pull-16 {
  right: 100%; }

.col-l-center {
  margin: 0 auto;
  float: none; }

@media only screen and (max-width: 1100px) {
  .col-m-push-0 {
    left: 0%; }
  .col-m-offset-0 {
    margin-left: 0%; }
  .col-m-pull-0 {
    right: 0%; }
  .col-m-1 {
    width: 6.25%; }
  .col-m-push-1 {
    left: 6.25%; }
  .col-m-offset-1 {
    margin-left: 6.25%; }
  .col-m-pull-1 {
    right: 6.25%; }
  .col-m-2 {
    width: 12.5%; }
  .col-m-push-2 {
    left: 12.5%; }
  .col-m-offset-2 {
    margin-left: 12.5%; }
  .col-m-pull-2 {
    right: 12.5%; }
  .col-m-3 {
    width: 18.75%; }
  .col-m-push-3 {
    left: 18.75%; }
  .col-m-offset-3 {
    margin-left: 18.75%; }
  .col-m-pull-3 {
    right: 18.75%; }
  .col-m-4 {
    width: 25%; }
  .col-m-push-4 {
    left: 25%; }
  .col-m-offset-4 {
    margin-left: 25%; }
  .col-m-pull-4 {
    right: 25%; }
  .col-m-5 {
    width: 31.25%; }
  .col-m-push-5 {
    left: 31.25%; }
  .col-m-offset-5 {
    margin-left: 31.25%; }
  .col-m-pull-5 {
    right: 31.25%; }
  .col-m-6 {
    width: 37.5%; }
  .col-m-push-6 {
    left: 37.5%; }
  .col-m-offset-6 {
    margin-left: 37.5%; }
  .col-m-pull-6 {
    right: 37.5%; }
  .col-m-7 {
    width: 43.75%; }
  .col-m-push-7 {
    left: 43.75%; }
  .col-m-offset-7 {
    margin-left: 43.75%; }
  .col-m-pull-7 {
    right: 43.75%; }
  .col-m-8 {
    width: 50%; }
  .col-m-push-8 {
    left: 50%; }
  .col-m-offset-8 {
    margin-left: 50%; }
  .col-m-pull-8 {
    right: 50%; }
  .col-m-9 {
    width: 56.25%; }
  .col-m-push-9 {
    left: 56.25%; }
  .col-m-offset-9 {
    margin-left: 56.25%; }
  .col-m-pull-9 {
    right: 56.25%; }
  .col-m-10 {
    width: 62.5%; }
  .col-m-push-10 {
    left: 62.5%; }
  .col-m-offset-10 {
    margin-left: 62.5%; }
  .col-m-pull-10 {
    right: 62.5%; }
  .col-m-11 {
    width: 68.75%; }
  .col-m-push-11 {
    left: 68.75%; }
  .col-m-offset-11 {
    margin-left: 68.75%; }
  .col-m-pull-11 {
    right: 68.75%; }
  .col-m-12 {
    width: 75%; }
  .col-m-push-12 {
    left: 75%; }
  .col-m-offset-12 {
    margin-left: 75%; }
  .col-m-pull-12 {
    right: 75%; }
  .col-m-13 {
    width: 81.25%; }
  .col-m-push-13 {
    left: 81.25%; }
  .col-m-offset-13 {
    margin-left: 81.25%; }
  .col-m-pull-13 {
    right: 81.25%; }
  .col-m-14 {
    width: 87.5%; }
  .col-m-push-14 {
    left: 87.5%; }
  .col-m-offset-14 {
    margin-left: 87.5%; }
  .col-m-pull-14 {
    right: 87.5%; }
  .col-m-15 {
    width: 93.75%; }
  .col-m-push-15 {
    left: 93.75%; }
  .col-m-offset-15 {
    margin-left: 93.75%; }
  .col-m-pull-15 {
    right: 93.75%; }
  .col-m-16 {
    width: 100%; }
  .col-m-push-16 {
    left: 100%; }
  .col-m-offset-16 {
    margin-left: 100%; }
  .col-m-pull-16 {
    right: 100%; }
  .col-m-center {
    margin: 0 auto;
    float: none; }
  .m-hide {
    display: none; }
  .m-show {
    display: block; }
  .s-show {
    display: none; } }

@media only screen and (max-width: 800px) {
  .col-s-push-0 {
    left: 0%; }
  .col-s-offset-0 {
    margin-left: 0%; }
  .col-s-pull-0 {
    right: 0%; }
  .col-s-1 {
    width: 6.25%; }
  .col-s-push-1 {
    left: 6.25%; }
  .col-s-offset-1 {
    margin-left: 6.25%; }
  .col-s-pull-1 {
    right: 6.25%; }
  .col-s-2 {
    width: 12.5%; }
  .col-s-push-2 {
    left: 12.5%; }
  .col-s-offset-2 {
    margin-left: 12.5%; }
  .col-s-pull-2 {
    right: 12.5%; }
  .col-s-3 {
    width: 18.75%; }
  .col-s-push-3 {
    left: 18.75%; }
  .col-s-offset-3 {
    margin-left: 18.75%; }
  .col-s-pull-3 {
    right: 18.75%; }
  .col-s-4 {
    width: 25%; }
  .col-s-push-4 {
    left: 25%; }
  .col-s-offset-4 {
    margin-left: 25%; }
  .col-s-pull-4 {
    right: 25%; }
  .col-s-5 {
    width: 31.25%; }
  .col-s-push-5 {
    left: 31.25%; }
  .col-s-offset-5 {
    margin-left: 31.25%; }
  .col-s-pull-5 {
    right: 31.25%; }
  .col-s-6 {
    width: 37.5%; }
  .col-s-push-6 {
    left: 37.5%; }
  .col-s-offset-6 {
    margin-left: 37.5%; }
  .col-s-pull-6 {
    right: 37.5%; }
  .col-s-7 {
    width: 43.75%; }
  .col-s-push-7 {
    left: 43.75%; }
  .col-s-offset-7 {
    margin-left: 43.75%; }
  .col-s-pull-7 {
    right: 43.75%; }
  .col-s-8 {
    width: 50%; }
  .col-s-push-8 {
    left: 50%; }
  .col-s-offset-8 {
    margin-left: 50%; }
  .col-s-pull-8 {
    right: 50%; }
  .col-s-9 {
    width: 56.25%; }
  .col-s-push-9 {
    left: 56.25%; }
  .col-s-offset-9 {
    margin-left: 56.25%; }
  .col-s-pull-9 {
    right: 56.25%; }
  .col-s-10 {
    width: 62.5%; }
  .col-s-push-10 {
    left: 62.5%; }
  .col-s-offset-10 {
    margin-left: 62.5%; }
  .col-s-pull-10 {
    right: 62.5%; }
  .col-s-11 {
    width: 68.75%; }
  .col-s-push-11 {
    left: 68.75%; }
  .col-s-offset-11 {
    margin-left: 68.75%; }
  .col-s-pull-11 {
    right: 68.75%; }
  .col-s-12 {
    width: 75%; }
  .col-s-push-12 {
    left: 75%; }
  .col-s-offset-12 {
    margin-left: 75%; }
  .col-s-pull-12 {
    right: 75%; }
  .col-s-13 {
    width: 81.25%; }
  .col-s-push-13 {
    left: 81.25%; }
  .col-s-offset-13 {
    margin-left: 81.25%; }
  .col-s-pull-13 {
    right: 81.25%; }
  .col-s-14 {
    width: 87.5%; }
  .col-s-push-14 {
    left: 87.5%; }
  .col-s-offset-14 {
    margin-left: 87.5%; }
  .col-s-pull-14 {
    right: 87.5%; }
  .col-s-15 {
    width: 93.75%; }
  .col-s-push-15 {
    left: 93.75%; }
  .col-s-offset-15 {
    margin-left: 93.75%; }
  .col-s-pull-15 {
    right: 93.75%; }
  .col-s-16 {
    width: 100%; }
  .col-s-push-16 {
    left: 100%; }
  .col-s-offset-16 {
    margin-left: 100%; }
  .col-s-pull-16 {
    right: 100%; }
  .col-s-center {
    margin: 0 auto;
    float: none; }
  .s-hide {
    display: none; }
  .s-show {
    display: block; }
  .m-show {
    display: none; } }

.video-holder {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  display: block; }
  .video-holder iframe,
  .video-holder object,
  .video-holder embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.page-title {
  text-align: center;
  margin-bottom: 30px; }

.block-title {
  margin: 0 0 30px; }
  .block-title p {
    text-transform: uppercase;
    margin-bottom: 5px; }

.col1-layout .section {
  clear: both;
  overflow: hidden; }

.col1-layout .full {
  width: 100%; }

.col1-layout .half {
  float: left;
  width: 50%; }

.col1-layout .third {
  float: left;
  width: 33.33333%; }

.col1-layout .quarter,
.col1-layout .fourth {
  float: left;
  width: 25%; }

.col1-layout .three-quarter {
  float: left;
  width: 75%; }

.col1-layout .two-thirds {
  float: left;
  width: 66.66667%; }

.col2-left-layout .col-main {
  float: left;
  overflow: hidden; }

.col2-left-layout .col-left {
  float: left; }

.col2-right-layout .col-main {
  float: left;
  overflow: hidden; }

.col2-right-layout .col-right {
  float: right; }

.col3-layout .col-main {
  float: left; }

.col3-layout .col-left {
  float: left; }

.col3-layout .col-right {
  float: right; }

.col2-set .col-1 {
  width: 50%; }

.col2-set .col-2 {
  width: 50%; }

@media (min-width: 800px) {
  .cms-home .page-header .panel.wrapper {
    overflow-y: hidden; }
    .cms-home .page-header .panel.wrapper .panel.header .ee-list-link #ee_home-link {
      opacity: 0;
      transition: all .6s 1.2s linear; }
    .cms-home .page-header .panel.wrapper .panel.header .ee-list-link .link {
      opacity: 0;
      transform: translateY(100%); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-list-link .link:nth-child(1) {
        transition: all 0.5s calc( 1s + (0.1s * calc(1/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-list-link .link:nth-child(2) {
        transition: all 0.5s calc( 1s + (0.1s * calc(2/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-list-link .link:nth-child(3) {
        transition: all 0.5s calc( 1s + (0.1s * calc(3/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-list-link .link:nth-child(4) {
        transition: all 0.5s calc( 1s + (0.1s * calc(4/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li {
      opacity: 0;
      transform: translateX(-20%); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(1) {
        transition: all 0.5s calc(1s + (0.1s * calc(1/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(2) {
        transition: all 0.5s calc(1s + (0.1s * calc(2/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(3) {
        transition: all 0.5s calc(1s + (0.1s * calc(3/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(4) {
        transition: all 0.5s calc(1s + (0.1s * calc(4/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(5) {
        transition: all 0.5s calc(1s + (0.1s * calc(5/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(6) {
        transition: all 0.5s calc(1s + (0.1s * calc(6/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(7) {
        transition: all 0.5s calc(1s + (0.1s * calc(7/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li:nth-child(8) {
        transition: all 0.5s calc(1s + (0.1s * calc(8/2))) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .cms-home .page-header .panel.wrapper .panel.header .ee-infos-link .reviews-widget {
      transform: translateY(-100%);
      transition: transform .3s 1.2s ease-in-out; }
    .cms-home .page-header .panel.wrapper::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #E5002E;
      transform: scaleX(0);
      transition: transform .3s .9s linear; }
  .cms-home .page-header .ee-banner {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity .5s 1.2s ease-in, transform .3s 1.2s ease-in; }
  .cms-home .page-header .header.content .EE_logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    transform: scale(5); }
    .cms-home .page-header .header.content .EE_logo-container svg #svgEE-wheel-ee {
      stroke-dashoffset: 140;
      stroke-dasharray: 140;
      fill-opacity: 0; }
    .cms-home .page-header .header.content .EE_logo-container svg .svgEE-red_letter {
      stroke-dashoffset: 65;
      stroke-dasharray: 65;
      fill-opacity: 0; }
    .cms-home .page-header .header.content .EE_logo-container svg .svgEE-black_letter {
      stroke-dashoffset: 60;
      stroke-dasharray: 60;
      fill-opacity: 0; }
    .cms-home .page-header .header.content .EE_logo-container svg #svgEE-by {
      stroke-dashoffset: 75;
      stroke-dasharray: 75;
      fill-opacity: 0; }
    .cms-home .page-header .header.content .EE_logo-container svg #svgEE-wheel_mcp {
      opacity: 0;
      transition: opacity 0.2s ease-out; }
    .cms-home .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path {
      opacity: 0;
      transform: translateX(-5%); }
  .cms-home.animate-in .page-header .panel.wrapper {
    z-index: 200; }
    .cms-home.animate-in .page-header .panel.wrapper::after {
      transform: scaleX(1); }
    .cms-home.animate-in .page-header .panel.wrapper .panel.header {
      opacity: 1;
      transform: translateY(0); }
      .cms-home.animate-in .page-header .panel.wrapper .panel.header .ee-list-link #ee_home-link {
        opacity: 1; }
      .cms-home.animate-in .page-header .panel.wrapper .panel.header .ee-list-link .link {
        opacity: 1;
        transform: translateY(0); }
      .cms-home.animate-in .page-header .panel.wrapper .panel.header .ee-infos-link .panel-right li {
        opacity: 1;
        transform: translateX(0); }
      .cms-home.animate-in .page-header .panel.wrapper .panel.header .ee-infos-link .reviews-widget {
        transform: translateY(0); }
  .cms-home.animate-in .page-header .ee-banner {
    z-index: 150;
    transform: translateY(0);
    opacity: 1; }
  .cms-home.animate-in .page-header .header.content .EE_logo-container {
    z-index: 100;
    animation: 2s forwards removeElement;
    -webkit-animation: 2s forwards removeElement; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-wheel-ee,
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg .svgEE-black_letter,
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg .svgEE-red_letter,
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-by {
      animation: 3s forwards draw, 1s 0.5s forwards fill-in; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-wheel_mcp {
      display: inline-block;
      position: relative;
      opacity: 1;
      transform-origin: 61.5% 80%;
      animation: roll 1.5s 1; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(1) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(1/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(2) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(2/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(3) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(3/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(4) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(4/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(5) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(5/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(6) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(6/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(7) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(7/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(8) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(8/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(9) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(9/ 4)) ease-in-out; }
    .cms-home.animate-in .page-header .header.content .EE_logo-container svg #svgEE-proxiserve path:nth-child(10) {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.1s calc(0.2s * calc(10/ 4)) ease-in-out; } }

.page-header {
  background-color: #ffffff; }
  .page-header .logo img {
    width: auto; }
  .page-header .header_mobile,
  .page-header .ee-banner_background.mobile {
    display: none; }
  .page-header .panel.wrapper {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.09); }
  .page-header .panel.header {
    max-width: 1400px;
    margin: 0 auto;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-content: flex-end; }
  .page-header .panel .ee-list-link {
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end; }
    .page-header .panel .ee-list-link li {
      position: relative;
      height: 90%;
      margin-right: 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      font-family: "Roboto", sans-serif; }
      .page-header .panel .ee-list-link li a {
        display: flex;
        align-items: center;
        height: 100%;
        color: #373737;
        line-height: 36px;
        padding: 0 15px;
        text-align: center;
        position: relative; }
        .page-header .panel .ee-list-link li a img,
        .page-header .panel .ee-list-link li a span {
          display: inline-block; }
    .page-header .panel .ee-list-link li:not(.ee-list-link-proxi) a {
      background-color: #eeeeee;
      border: 1px solid #dddddd;
      border-radius: 6px 6px 0 0; }
    .page-header .panel .ee-list-link li:not(.ee-list-link-proxi).link:hover a {
      color: #ffffff;
      background-color: #ef604e;
      border-color: #ef604e; }
    .page-header .panel .ee-list-link li:not(.ee-list-link-proxi).link .current {
      color: #ffffff;
      background-color: #ef604e;
      border-color: #ef604e; }
  .page-header .panel .ee-infos-link {
    display: flex; }
    .page-header .panel .ee-infos-link .panel-right {
      display: flex;
      align-items: center;
      margin: 10px 0 0;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem; }
      .page-header .panel .ee-infos-link .panel-right li {
        padding: 0 0.5rem; }
        @media (min-width: 1281px) {
          .page-header .panel .ee-infos-link .panel-right li {
            padding: 0 1.3rem; } }
      .page-header .panel .ee-infos-link .panel-right li:nth-child(1) a {
        color: #373737;
        font-size: 1.6rem;
        font-weight: 500; }
      .page-header .panel .ee-infos-link .panel-right li:nth-child(1):hover a {
        color: #ef604e; }
      .page-header .panel .ee-infos-link .panel-right .sponsorship-link {
        display: none; }
      .page-header .panel .ee-infos-link .panel-right .btn__account {
        width: 5.5rem;
        height: 4.2rem; }
        .page-header .panel .ee-infos-link .panel-right .btn__account span {
          display: none; }
      @media (max-width: 1280px) {
        .page-header .panel .ee-infos-link .panel-right .packages {
          display: none; } }
      @media screen and (max-width: 1245px) {
        .page-header .panel .ee-infos-link .panel-right .packages-link {
          display: none; } }
    @media (max-width: 1100px) and (min-width: 800px) {
      .page-header .panel .ee-infos-link .reviews-widget {
        display: none; } }
  .page-header .infos {
    display: flex;
    padding-right: 0;
    align-items: center;
    height: fit-content;
    color: #8c8c8c;
    font-size: 1.4rem; }
    .page-header .infos .number {
      margin-left: 10px;
      position: relative;
      height: fit-content;
      border: 2px solid #ef604e;
      border-radius: 50px; }
      .page-header .infos .number a {
        display: block;
        padding: 10px 15px;
        font-size: 1.6rem;
        color: #ef604e; }
        .page-header .infos .number a .phone_number {
          opacity: 1;
          transition: opacity 0.3s linear; }
        .page-header .infos .number a .hover_infos {
          position: absolute;
          top: 5px;
          left: 35px;
          width: calc(100% - 35px);
          height: calc(100% - 10px);
          color: #ef604e;
          font-size: 1.2rem;
          font-weight: 600;
          opacity: 0;
          transition: opacity 0.3s linear; }
          .page-header .infos .number a .hover_infos .desc {
            margin: 0;
            color: #000000;
            font-weight: normal; }
        .page-header .infos .number a:hover .phone_number {
          opacity: 0; }
        .page-header .infos .number a:hover .hover_infos {
          opacity: 1; }
    .page-header .infos .icon {
      font-size: 1.6rem;
      vertical-align: middle;
      position: relative;
      top: 0px;
      margin: 0 6px 0 0;
      -webkit-transform: rotate(15deg);
      -moz-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      -o-transform: rotate(15deg);
      transform: rotate(15deg); }
  .page-header .reviews-widget .reviews-widget__container {
    position: relative;
    display: flex;
    align-self: flex-start;
    padding: 12px 15px 12px 0;
    box-shadow: 0px 0px 15px rgba(11, 35, 45, 0.2);
    border-radius: 5px 5px 10px 5px;
    background-color: #ffffff;
    max-width: 155px; }
    @media (min-width: 1025px) {
      .page-header .reviews-widget .reviews-widget__container {
        margin-left: 20px;
        max-width: none; } }
    .page-header .reviews-widget .reviews-widget__container .reviews-widget__logo {
      width: 40px;
      height: 40px; }
      .page-header .reviews-widget .reviews-widget__container .reviews-widget__logo img {
        margin-left: -10px;
        transition: transform 0.2s ease-in; }
    .page-header .reviews-widget .reviews-widget__container .reviews-widget__rate {
      height: 15px;
      margin-bottom: 5px; }
      .page-header .reviews-widget .reviews-widget__container .reviews-widget__rate img {
        max-height: 100%; }
    .page-header .reviews-widget .reviews-widget__container .reviews-widget__content p {
      font-size: 1.2rem;
      margin: 0;
      font-style: italic; }
    .page-header .reviews-widget .reviews-widget__container .reviews-widget__content span {
      color: #000000;
      font-weight: 700; }
    .page-header .reviews-widget .reviews-widget__container .pleats {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background: #d7dfe2;
      border-radius: 0px 0px 10px 0px; }
    .page-header .reviews-widget .reviews-widget__container:hover .reviews-widget__logo img {
      transform: scale(1.1); }
    .page-header .reviews-widget .reviews-widget__container:hover span {
      color: #ef604e; }
  .page-header .header.content {
    position: relative; }

@media only screen and (max-width: 992px) and (min-width: 801px) {
  .page-header .infos .icon {
    display: none; }
  .page-header .panel .ee-list-link li a {
    padding: 0 5px; }
  .page-header .panel .ee-infos-link .panel-right li {
    padding: 0 0.5rem; }
  .page-header .panel .ee-infos-link .panel-right .btn__account {
    width: auto;
    padding: 20px; } }

.nav-toggle {
  cursor: pointer;
  display: none; }

.expandMenu-transition {
  transition: all 0.3s ease; }

.expandMenu-enter,
.expandMenu-leave {
  height: 0;
  opacity: 0; }

.ee-banner {
  position: relative; }
  .ee-banner .ee-banner_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 50%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; }
    .ee-banner .ee-banner_background::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%); }

.ee-banner-content {
  position: relative;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 20px;
  height: 700px; }

.ee-banner-list li {
  position: relative;
  margin: 10px;
  padding-left: 25px;
  font-size: 1.8rem; }
  .ee-banner-list li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-image: url("../images/green_check.png"); }

.faq-header {
  background: #fff;
  position: absolute;
  right: 20px;
  top: 100px;
  width: 50%;
  z-index: 5;
  transition: all 0.3s ease;
  padding: 10px;
  -webkit-transition: opacity 0.3s, max-height 0.3s linear;
  -moz-transition: opacity 0.3s, max-height 0.3s linear;
  transition: opacity 0.3s, max-height 0.3s linear;
  opacity: 0;
  max-height: 0;
  overflow: hidden; }
  .faq-header.active {
    max-height: 300px;
    opacity: 1; }
  .faq-header .faq-content {
    padding: 10px 15px; }
  .faq-header .faq-questions-answers {
    border-top: 1px solid #8c8c8c;
    cursor: pointer;
    padding: 20px 5px; }
    .faq-header .faq-questions-answers:first-child {
      border: none; }
  .faq-header .link {
    border-top: 1px solid #8c8c8c;
    color: #000000;
    display: inline-block;
    padding: 10px 0 0;
    width: 100%; }
    .faq-header .link:after {
      content: "\25b6";
      display: inline-block;
      margin: 0 0 1px 10px;
      vertical-align: middle; }

.faq-banner {
  background-image: url("../images/faq-banner.jpg"); }

.ee-banner-text {
  color: #ffffff; }
  .ee-banner-text .buttons-set,
  .ee-banner-text h1 {
    margin-top: 30px; }
  .ee-banner-text .buttons-set {
    font-size: 2rem; }
    @media (max-width: 350px) {
      .ee-banner-text .buttons-set .btn__search {
        display: block;
        padding: 20px; }
        .ee-banner-text .buttons-set .btn__search::before {
          display: none; } }

.title-banner {
  font-family: "Roboto", sans-serif;
  font-size: 4.8rem;
  font-weight: 600;
  margin-bottom: 15px; }
  .title-banner .price-box .price {
    font-weight: inherit;
    font-size: inherit; }

.subtitle-banner {
  font-size: 1.8rem;
  margin: 0 0 10px 0;
  line-height: 2.5rem; }

@media (min-width: 769px) {
  .ee-banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
    margin-left: 50%; } }

@media only screen and (max-width: 1280px) {
  .page-header .ee-list-link li a {
    padding: 0 8px; }
  .page-header .panel-right {
    font-size: 1.2rem; }
    .page-header .panel-right .number {
      margin: 0; }
    .page-header .panel-right .desc {
      font-size: 1rem; } }

@media only screen and (max-width: 1150px) {
  .page-header .panel-right {
    display: none; } }

@media only screen and (max-width: 1100px) {
  .panel.header {
    text-align: center; } }

@media only screen and (max-width: 800px) {
  .panel {
    display: none; }
  .page-header .header_mobile {
    display: block; }
  .page-header .logo-block {
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: auto;
    height: 60px;
    background: rgba(55, 55, 55, 0.3); }
    .page-header .logo-block .logo {
      display: block;
      width: 175px;
      padding: 13px 0 0 20px; }
  .page-header .header_mobile {
    padding: 30px 36px; }
  .page-header .ee-banner_background.mobile {
    display: block;
    border-radius: 24px; }
    .page-header .ee-banner_background.mobile::before {
      border-radius: 24px;
      background: rgba(0, 0, 0, 0.5); }
  .page-header .ee-banner_background.desktop {
    display: none; }
  .nav-toggle {
    display: block;
    color: #ffffff;
    font-size: 1.8rem;
    padding: 20px; }
    .nav-toggle:before {
      content: "\EA05";
      display: inline-block;
      font-family: "iconfont";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: none; }
    .nav-toggle span {
      display: none; }
  .faq-header {
    width: 100%;
    right: 0;
    left: 0;
    top: 140px; }
  .ee-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 560px; }
  .ee-banner-text {
    padding-bottom: 60px; }
    .ee-banner-text h1 {
      margin-bottom: 40px; }
    .ee-banner-text .buttons-set {
      margin: 45px 0 0 0; }
    .ee-banner-text .button {
      padding: 0 20px; }
  .title-banner {
    font-size: 3.4rem;
    line-height: 4.6rem; }
  .subtitle-banner {
    display: none; }
  .ee-banner-list li {
    font-size: 1.6rem;
    margin: 20px 10px; } }

.nav {
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 20px; }
  .nav > ul {
    display: table;
    width: 100%;
    position: relative; }
    .nav > ul > li {
      display: table-cell;
      text-align: center;
      font-size: 1.6rem; }
      .nav > ul > li > a {
        display: block;
        padding: 20px 5px;
        -webkit-transition: all 150ms linear;
        -moz-transition: all 150ms linear;
        transition: all 150ms linear;
        position: relative;
        cursor: pointer; }
      .nav > ul > li:hover > a {
        color: #000000; }
      .nav > ul > li.active > a {
        font-weight: 600;
        color: #000000; }
      .nav > ul > li:hover > .submenu {
        opacity: 1;
        visibility: visible;
        z-index: 9999; }
    .nav > ul .over-menu {
      position: absolute;
      width: 13px;
      height: 13px;
      top: -6.5px;
      background: #eeeeee;
      border-radius: 50%;
      display: inline-block; }
  .nav .submenu {
    position: absolute;
    left: 0;
    right: 0;
    background: #eeeeee;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    text-align: left; }
    .nav .submenu li.level1 {
      width: 33%;
      display: inline-block;
      vertical-align: top;
      padding: 0 20px 0 0; }
      .nav .submenu li.level1 > a {
        display: block;
        margin-bottom: 20px;
        font-size: 1.6rem;
        font-weight: bold; }
      .nav .submenu li.level1 span {
        display: block;
        margin-bottom: 15px; }
      .nav .submenu li.level1 li a {
        color: #424242;
        display: inline-block;
        padding: 6px 0; }
        .nav .submenu li.level1 li a:hover {
          color: #000000; }
      .nav .submenu li.level1 ul.level1 {
        padding: 0 0 50px; }
    .nav .submenu .custom-attributes-block {
      position: absolute;
      top: 0;
      right: 0;
      width: 33%;
      background: #f2f2f2;
      padding: 40px 25px 25px; }
    .nav .submenu .menu-subcategory-block {
      max-width: 250px; }
      .nav .submenu .menu-subcategory-block.first {
        margin-bottom: 20px; }
        .nav .submenu .menu-subcategory-block.first a {
          display: block;
          height: 40px;
          line-height: 38px;
          text-align: center;
          border: 1px solid #000000;
          color: #000000;
          text-transform: uppercase;
          -webkit-transition: all 250ms ease-in-out;
          -moz-transition: all 250ms ease-in-out;
          transition: all 250ms ease-in-out; }
      .nav .submenu .menu-subcategory-block.second {
        position: relative; }
        .nav .submenu .menu-subcategory-block.second a {
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          color: #ffffff;
          font-size: 3.0rem;
          padding-top: 35%;
          text-shadow: 0 0 16px #000000;
          -webkit-transition: all 250ms ease-in-out;
          -moz-transition: all 250ms ease-in-out;
          transition: all 250ms ease-in-out; }
          .nav .submenu .menu-subcategory-block.second a:hover {
            padding-top: 40%;
            background: rgba(255, 255, 255, 0.3); }
  .nav .custom-attributes-block .inspiration-menu-item {
    float: none;
    width: 100%;
    padding: 0;
    max-width: 250px;
    min-height: 350px; }
    .nav .custom-attributes-block .inspiration-menu-item .item-description p.inspiration-menu-title {
      margin: 5px 0 0; }

.bloc-left-submenu {
  padding: 25px 0 20px 5%;
  min-height: 328px;
  width: 60%;
  float: left; }

.block-right-menu-push {
  float: left;
  padding: 25px 0 20px 0;
  width: 40%; }
  .block-right-menu-push .first-push, .block-right-menu-push .second-push {
    float: left;
    padding: 0 10px;
    position: relative;
    text-align: center;
    width: 50%; }
    .block-right-menu-push .first-push a, .block-right-menu-push .second-push a {
      color: #FFFFFF;
      font-size: 1.8rem;
      font-weight: bold;
      margin: 0 auto;
      padding-top: 75%;
      width: 70%;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2; }

.menu-mob,
.phone-mob {
  position: fixed;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 200px;
  box-shadow: 0px 10px 15px rgba(12, 35, 44, 0.15);
  z-index: 100; }

.menu-mob {
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; }
  .menu-mob label {
    display: flex;
    flex-direction: column;
    width: 25px;
    cursor: pointer; }
    .menu-mob label input[type="checkbox"] {
      display: none; }
    .menu-mob label span {
      background: #ef604e;
      border-radius: 10px;
      height: 3px;
      margin: 3px 0;
      transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6); }
    .menu-mob label span:nth-of-type(1) {
      width: 50%; }
    .menu-mob label span:nth-of-type(2) {
      width: 100%; }
    .menu-mob label span:nth-of-type(3) {
      width: 75%; }
    .menu-mob label input[type="checkbox"]:checked ~ span:nth-of-type(1) {
      transform-origin: bottom;
      transform: rotatez(45deg) translate(4px, 0px); }
    .menu-mob label input[type="checkbox"]:checked ~ span:nth-of-type(2) {
      transform-origin: top;
      transform: rotatez(-45deg); }
    .menu-mob label input[type="checkbox"]:checked ~ span:nth-of-type(3) {
      transform-origin: bottom;
      width: 50%;
      transform: translate(11px, -6px) rotatez(45deg); }

.menu-mobile {
  display: none; }

.phone-mob {
  top: 130px;
  background-color: #ef604e; }
  @media (min-width: 769px) {
    .phone-mob {
      display: none; } }
  .phone-mob a {
    display: block; }
    .phone-mob a::after {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      top: 0;
      left: 0;
      background-image: url("../images/icons/uEA24-phone_white.svg");
      background-size: 30%;
      background-repeat: no-repeat;
      background-position: 50%; }

.main-container .footer_algolia {
  display: none; }

.main-container #algolia-autocomplete-container .aa-dropdown-menu {
  border: 3px solid #000000;
  left: 0 !important; }

@media (min-width: 992px) {
  .main-container #algolia-autocomplete-container .aa-dropdown-menu {
    width: 100%; } }

@media only screen and (max-width: 1100px) {
  .main-menu-content .nav-container .submenu {
    right: 20px; }
  .bloc-left-submenu {
    width: 70%; }
  .block-right-menu-push {
    width: 30%; }
    .block-right-menu-push .first-push, .block-right-menu-push .second-push {
      float: none;
      width: 100%; } }

@media only screen and (max-width: 800px) {
  body {
    overflow-y: initial;
    position: relative; }
    body::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background: #0C232C;
      opacity: 0; }
    body:has(.menu-mobile.active) {
      overflow-y: hidden; }
      body:has(.menu-mobile.active)::before {
        opacity: .7;
        z-index: 10;
        height: 100vh;
        transition: opacity .3s .3s linear; }
  .bt-menu-mob {
    display: block; }
  .bloc-left-submenu {
    padding: 0 0 0 30px;
    min-height: auto; }
  .menu-mobile {
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    width: 90vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 99;
    transform: translateX(-200%);
    transition: transform .5s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6vh 40px; }
    .menu-mobile.active {
      transform: translateX(0); }
    .menu-mobile .panel-right {
      display: block;
      margin: 40px 0;
      /* ---------------
                Products list
            ----------------- */
      /* --------------
                sponsorship btn
            ----------------- */
      /* --------------
                Reviews widget
            ----------------- */ }
      .menu-mobile .panel-right li {
        margin: 35px auto; }
      .menu-mobile .panel-right .btn {
        padding: 15px 20px;
        width: 100%;
        font-size: 1.8rem; }
        .menu-mobile .panel-right .btn__white {
          margin-top: 15px; }
      .menu-mobile .panel-right .packages {
        font-size: 1.8rem;
        font-weight: 600; }
        .menu-mobile .panel-right .packages > div {
          margin-bottom: 30px;
          text-align: center; }
        .menu-mobile .panel-right .packages .push-product-head {
          padding: 0; }
        .menu-mobile .panel-right .packages .push-product-title {
          display: none; }
        .menu-mobile .panel-right .packages .push-product-tabs {
          margin: 15px 0;
          padding: 0;
          flex-wrap: wrap;
          justify-content: center;
          gap: 10px; }
          .menu-mobile .panel-right .packages .push-product-tabs li {
            display: flex;
            width: 45%;
            flex-direction: column;
            justify-content: space-between;
            font-weight: 400;
            margin: 0; }
          .menu-mobile .panel-right .packages .push-product-tabs .tab.active {
            position: static;
            background-color: #ffffff;
            color: #373737; }
            .menu-mobile .panel-right .packages .push-product-tabs .tab.active::after {
              display: none; }
            .menu-mobile .panel-right .packages .push-product-tabs .tab.active .icon-category.ch_gaz {
              background: url("../images/icons/uEA19-ch_gaz.svg") center no-repeat; }
            .menu-mobile .panel-right .packages .push-product-tabs .tab.active .icon-category.ch_fioul {
              background: url("../images/icons/uEA20-ch_fioul.svg") center no-repeat; }
            .menu-mobile .panel-right .packages .push-product-tabs .tab.active .icon-category.pac {
              background: url("../images/icons/uEA09-pump.svg") center no-repeat; }
            .menu-mobile .panel-right .packages .push-product-tabs .tab.active .icon-category.clim {
              background: url("../images/icons/uEA12-clim.svg") center no-repeat; }
      .menu-mobile .panel-right .packages-link .btn__round-check::before {
        display: none; }
      .menu-mobile .panel-right .sponsorship-link .btn__white {
        color: #ef604e;
        border-color: #ef604e;
        border-width: 2px; }
      .menu-mobile .panel-right .reviews-widget {
        width: fit-content;
        margin: 25px auto; }
      .menu-mobile .panel-right .btn__account::before {
        left: 80%; }
    .menu-mobile .ee-list-link {
      display: flex;
      flex-wrap: wrap;
      min-height: 150px; }
      .menu-mobile .ee-list-link li {
        font-size: 1.5rem;
        width: 49%; }
        .menu-mobile .ee-list-link li a {
          display: inline-block;
          margin: 0 auto; }
          .menu-mobile .ee-list-link li a.current {
            color: #ef604e;
            border-bottom: 1px solid #ef604e; } }

.breadcrumbs {
  padding: 15px 0;
  border-top: 1px solid #000000; }
  .breadcrumbs li {
    display: inline-block;
    vertical-align: top; }
    .breadcrumbs li:before {
      content: ">"; }
    .breadcrumbs li:first-child:before {
      display: none; }
    .breadcrumbs li:last-child {
      font-weight: 600; }

@media only screen and (max-width: 800px) {
  .breadcrumbs {
    display: none; } }

.page.messages {
  max-width: 610px;
  margin: 0 auto; }
  .page.messages .messages {
    padding: 0 10px;
    position: relative; }
    .page.messages .messages .message-content {
      margin: 50px 0;
      text-align: center;
      font-size: 20px; }
      .page.messages .messages .message-content .icon {
        display: none; }
      .page.messages .messages .message-content .icon__success {
        display: block; }
    .page.messages .messages .error-msg,
    .page.messages .messages .message-error,
    .page.messages .messages .notice-msg,
    .page.messages .messages .warning-msg,
    .page.messages .messages .success-msg {
      border: 1px solid;
      margin: 50px 0;
      padding: 15px 20px;
      border-radius: 5px;
      background-repeat: no-repeat; }
      .page.messages .messages .error-msg .message-content,
      .page.messages .messages .message-error .message-content,
      .page.messages .messages .notice-msg .message-content,
      .page.messages .messages .warning-msg .message-content,
      .page.messages .messages .success-msg .message-content {
        color: #373737;
        padding: 0 0 0 20px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        width: 80%; }
      .page.messages .messages .error-msg:before,
      .page.messages .messages .message-error:before,
      .page.messages .messages .notice-msg:before,
      .page.messages .messages .warning-msg:before,
      .page.messages .messages .success-msg:before {
        content: "";
        display: inline-block;
        height: 53px;
        width: 53px;
        vertical-align: middle; }
    .page.messages .messages .error-msg, .page.messages .messages .message-error {
      background-color: #fde6e4;
      border-color: #ef604e; }
      .page.messages .messages .error-msg:before, .page.messages .messages .message-error:before {
        background: url("../images/picto-error.png"); }
    .page.messages .messages .notice-msg {
      background-color: #fcf6e2;
      border-color: #EDCA4E; }
      .page.messages .messages .notice-msg:before {
        background: url("../images/picto-notice.png"); }
    .page.messages .messages .success-msg {
      background-color: #e2f2e1;
      border-color: #72C070; }
      .page.messages .messages .success-msg:before {
        background: url("../images/picto-success.png"); }
    .page.messages .messages .warning-msg {
      background-color: #e6f5f7;
      border-color: #1f81ad; }
      .page.messages .messages .warning-msg:before {
        background: url(../images/picto-notice.png) no-repeat;
        width: 36px;
        background-size: 25px; }

@media only screen and (max-width: 800px) {
  .page.messages .messages .error-msg .message-content,
  .page.messages .messages .message-error .message-content,
  .page.messages .messages .notice-msg .message-content,
  .page.messages .messages .warning-msg .message-content,
  .page.messages .messages .success-msg .message-content {
    padding: 0; } }

.cms-home .page.messages:has(.message-success) {
  position: fixed;
  align-content: center;
  max-width: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; }
  .cms-home .page.messages:has(.message-success) .message-success {
    position: relative;
    margin: 0 auto;
    max-width: 50%;
    padding: 25px;
    border-radius: 15px;
    background-color: #ffffff; }
    .cms-home .page.messages:has(.message-success) .message-success .message-content {
      padding-left: 55px; }
    .cms-home .page.messages:has(.message-success) .message-success .icon__success {
      display: block !important;
      position: absolute;
      top: 50%;
      left: 50px;
      width: 50px;
      height: 50px;
      transform: translateY(-50%); }
      .cms-home .page.messages:has(.message-success) .message-success .icon__success path:nth-child(1) {
        stroke-dasharray: 55px;
        stroke-dashoffset: 55px;
        animation: drawSuccess .5s .3s ease-in forwards; }
      .cms-home .page.messages:has(.message-success) .message-success .icon__success path:nth-child(2) {
        stroke-dasharray: 17px;
        stroke-dashoffset: 17px;
        animation: drawSuccess .25s .6s ease-in forwards; }

@keyframes drawSuccess {
  to {
    stroke-dashoffset: 0; } }

.cms-home .page.messages.hide {
  display: none; }

.footer-top {
  background: #373737; }
  .footer-top .footer-top-content {
    margin: 0 auto;
    padding: 100px 20px 70px; }

.footer-top-table {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.footer-top-col:first-child {
  vertical-align: middle;
  padding: 0; }
  .footer-top-col:first-child img {
    max-width: 210px; }

.footer-top-col:last-child li.packages,
.footer-top-col:last-child li.infos,
.footer-top-col:last-child li.nav.item {
  display: none; }

.footer-top-col:last-child .btn {
  width: 225px;
  margin-bottom: 25px;
  padding: 10px 50px 10px 75px; }
  .footer-top-col:last-child .btn::before {
    left: 50px; }

.footer-top-col:last-child .account_conainer a.btn__account {
  font-size: 1.7rem;
  font-weight: 700;
  color: #ffffff; }
  .footer-top-col:last-child .account_conainer a.btn__account::before {
    background: url("../images/icons/uEA11-account_white.svg") center no-repeat; }
  .footer-top-col:last-child .account_conainer a.btn__account:hover {
    background-color: #ef604e; }

.footer-top-col .number {
  position: relative;
  height: fit-content;
  width: fit-content;
  border: 2px solid #ffffff;
  margin-top: 40px;
  border-radius: 50px; }
  .footer-top-col .number a {
    display: block;
    padding: 10px 15px;
    font-size: 1.6rem;
    color: #ffffff; }

.footer-top-col .number_infos {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600; }
  .footer-top-col .number_infos .desc {
    margin: 0;
    color: #8c8c8c;
    font-weight: normal; }

.footer-top-col .panel-right {
  font-size: 1.3rem;
  color: #ffffff;
  position: relative; }
  .footer-top-col .panel-right .sponsorship-link .btn:hover {
    color: #ffffff; }
  .footer-top-col .panel-right .icon {
    color: #bfbfbf;
    position: absolute;
    left: 10px;
    top: 15px;
    font-size: 3rem;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg); }
  .footer-top-col .panel-right .number {
    color: #ef604e; }
  .footer-top-col .panel-right span {
    display: block;
    font-weight: 700;
    font-size: 1.7rem; }
  .footer-top-col .panel-right .desc {
    font-size: 1.1rem;
    font-weight: normal;
    color: #bfbfbf; }

.footer-top-title {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  margin: 0 0 15px 0;
  color: #ffffff; }
  .footer-top-title.whoweareLinks {
    margin: 0; }

.footer-top-list li {
  margin: 20px 0;
  font-size: 1.3rem; }

.footer-top-list a {
  color: #8c8c8c; }
  .footer-top-list a:hover {
    color: #dddddd; }

.newsletter-footer .label {
  color: #8c8c8c;
  font-size: 1.3rem;
  margin: 0 0 20px 0;
  padding: 0; }

.field-news {
  position: relative;
  padding-right: 80px; }
  .field-news .input-text, .field-news .textarea {
    border-radius: 5px 0 0 5px;
    background: #ffffff;
    border: none;
    padding: 0 10px; }
  .field-news .button {
    position: absolute;
    border-radius: 0 5px 5px 0;
    top: 0;
    right: 0;
    width: 80px;
    margin: 0; }

.footer-bottom-content {
  max-width: 1260px;
  margin: 0 auto;
  padding: 22px 20px; }
  .footer-bottom-content .copyright {
    color: #161616;
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif; }
  .footer-bottom-content ul {
    float: right; }
  .footer-bottom-content li {
    display: inline-block;
    font-size: 1.3rem; }
    .footer-bottom-content li a {
      color: #161616; }
      .footer-bottom-content li a:hover {
        color: #878787; }

.expandMaintenance-transition,
.expandService-transition,
.expandWhoweare-transition {
  transition: all .3s ease; }

.expandMaintenance-enter,
.expandMaintenance-leave,
.expandService-enter,
.expandService-leave,
.expandWhoweare-enter,
.expandWhoweare-leave {
  height: 0;
  opacity: 0; }

#copyright-tod {
  background: #373737;
  text-align: right; }
  #copyright-tod span {
    display: block;
    padding: 0 20px 30px;
    opacity: 0.3; }
  #copyright-tod span,
  #copyright-tod a {
    color: #ffffff;
    font-size: 1rem; }

@media only screen and (max-width: 1100px) {
  .footer-top-col {
    width: 33.3333%; }
    .footer-top-col:first-child {
      display: none; } }

@media only screen and (max-width: 800px) {
  .footer-top .footer-top-content {
    padding: 100px 36px; }
  .footer-top-table {
    width: auto;
    display: block; }
  .footer-top-col {
    display: block;
    width: auto;
    padding: 15px 0; }
    .footer-top-col:first-child {
      border: none;
      display: block;
      margin: 0 0 20px 0; }
      .footer-top-col:first-child img {
        max-width: 200px; }
    .footer-top-col:last-child .btn {
      width: 100%; }
      .footer-top-col:last-child .btn::before {
        left: 30%; }
    .footer-top-col .panel-right {
      margin: 20px auto 0; }
      .footer-top-col .panel-right span {
        display: block;
        font-weight: 700;
        font-size: 1.7rem; }
  .footer-top-title {
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    margin: 0 0 15px 0;
    color: #ffffff;
    cursor: pointer; }
  .footer-top-list li {
    margin-bottom: 20px;
    font-size: 1.3rem; }
  .footer-top-list a {
    color: #8c8c8c; }
    .footer-top-list a:hover {
      color: #dddddd; }
  .newsletter-footer {
    margin: 0 0 20px; }
    .newsletter-footer .label {
      color: #8c8c8c;
      font-size: 1.3rem;
      margin: 0 0 20px 0;
      padding: 0; }
  .field-news {
    position: relative;
    padding-right: 80px; }
    .field-news .input-text, .field-news .textarea {
      border-radius: 5px 0 0 5px; }
    .field-news .button {
      position: absolute;
      border-radius: 0 5px 5px 0;
      top: 0;
      right: 0;
      width: 80px;
      margin: 0; }
  .footer-bottom {
    display: none; }
  #copyright-tod {
    text-align: center; } }

.cms-no-route .column.main {
  max-width: 870px;
  margin: 150px auto 300px;
  padding: 0 10px;
  text-align: center; }
  .cms-no-route .column.main h1 {
    margin: 0 0 80px; }
  .cms-no-route .column.main .button {
    display: inline-block;
    margin: 0 10px;
    width: auto; }

.cms-no-route .ee-banner_background,
.cms-no-route .ee-banner-content {
  display: none; }

.cms-home .page-header .panel-right .infos > span {
  display: none; }

.sponsorship-home__content {
  margin-bottom: 75px; }
  @media (min-width: 1025px) {
    .sponsorship-home__content {
      display: flex;
      gap: 50px; } }
  .sponsorship-home__content .sponsorship-banner {
    position: relative;
    display: flex;
    overflow: hidden;
    padding: 60px 50px;
    max-width: 1000px;
    border-radius: 12px;
    background-repeat: no-repeat; }
    @media (min-width: 1025px) {
      .sponsorship-home__content .sponsorship-banner {
        width: 75%; }
        .sponsorship-home__content .sponsorship-banner__left, .sponsorship-home__content .sponsorship-banner__right {
          position: relative;
          width: 50%;
          z-index: 5; } }
    .sponsorship-home__content .sponsorship-banner::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(239, 96, 78, 0.6);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .6s linear; }
      @media (min-width: 1025px) {
        .sponsorship-home__content .sponsorship-banner::before {
          background: linear-gradient(270deg, #EF604E 0%, rgba(239, 96, 78, 0) 100%); } }
    .sponsorship-home__content .sponsorship-banner__right {
      z-index: 5; }
      .sponsorship-home__content .sponsorship-banner__right p {
        margin: 0;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.6rem;
        color: #ffffff; }
      .sponsorship-home__content .sponsorship-banner__right .btn__white {
        margin-top: 20px;
        padding: 15px 30px;
        border-color: transparent;
        font-size: 2rem;
        color: #ef604e; }
        .sponsorship-home__content .sponsorship-banner__right .btn__white:hover {
          color: #ffffff;
          border-color: #D7DFE2; }
  .sponsorship-home__content .sponsorship-partners {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 25px; }
    @media (max-width: 1024px) {
      .sponsorship-home__content .sponsorship-partners {
        justify-content: space-around; } }
    @media (min-width: 1025px) {
      .sponsorship-home__content .sponsorship-partners {
        max-width: 30%;
        margin-top: 0; } }
    .sponsorship-home__content .sponsorship-partners li {
      margin: 20px;
      opacity: 0;
      transform: translateX(-50%); }
      .sponsorship-home__content .sponsorship-partners li:nth-child(1) {
        transition: all 0.5s calc(0.2s * calc(1/2)) linear; }
      .sponsorship-home__content .sponsorship-partners li:nth-child(2) {
        transition: all 0.5s calc(0.2s * calc(2/2)) linear; }
      .sponsorship-home__content .sponsorship-partners li:nth-child(3) {
        transition: all 0.5s calc(0.2s * calc(3/2)) linear; }
      .sponsorship-home__content .sponsorship-partners li:nth-child(4) {
        transition: all 0.5s calc(0.2s * calc(4/2)) linear; }
      .sponsorship-home__content .sponsorship-partners li:nth-child(5) {
        transition: all 0.5s calc(0.2s * calc(5/2)) linear; }

.sponsorship-home.animate-in .sponsorship-home__content .sponsorship-banner::before {
  transform: scaleX(1); }

.sponsorship-home.animate-in .sponsorship-home__content .sponsorship-partners li {
  transform: translateX(0);
  opacity: 1; }

.reinssurance-home {
  text-align: center;
  padding: 100px 0; }
  .reinssurance-home .h2-title {
    margin: 10px 0;
    font-size: 3.6rem;
    font-weight: 600; }
  .reinssurance-home .light-title {
    font-size: 1.8rem;
    color: #8c8c8c; }
    @media only screen and (max-width: 800px) {
      .reinssurance-home .light-title {
        margin: 25px 0;
        line-height: 2.5rem;
        padding: 10px; } }

.reinssurance-home-list {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 50px 0 0 0; }
  .reinssurance-home-list li {
    padding: 40px 20px;
    background-color: #ffffff;
    box-shadow: 0px 20px 30px 0px #0000000D;
    text-align: center;
    border-radius: 12px; }
    .reinssurance-home-list li img {
      max-width: 165px; }
    .reinssurance-home-list li .reinssurance-text {
      position: relative;
      margin: 20px 0 0 0;
      font-family: "Roboto", sans-serif;
      font-size: 1.8rem;
      line-height: 2.1rem;
      font-weight: 600;
      color: #373737; }
      .reinssurance-home-list li .reinssurance-text span {
        display: block;
        background: white;
        position: relative;
        z-index: 2; }
      .reinssurance-home-list li .reinssurance-text strong {
        font-size: 2.2rem;
        line-height: 3rem;
        font-weight: 400;
        color: #ef604e; }
        @media (min-width: 800px) {
          .reinssurance-home-list li .reinssurance-text strong {
            display: block;
            position: relative;
            z-index: 1;
            transform: translateY(-90%);
            opacity: 0;
            transition: all .3s .3s ease-in; } }
    .reinssurance-home-list li:nth-child(1) {
      z-index: 4;
      transition: transform 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8); }
      @media (min-width: 800px) {
        .reinssurance-home-list li:nth-child(1) {
          transform: translateX(calc(150% + 20px)); } }
    .reinssurance-home-list li:nth-child(2) {
      z-index: 3;
      transition: transform 0.4s 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      transform: translateY(calc(-100%)); }
      @media (min-width: 800px) {
        .reinssurance-home-list li:nth-child(2) {
          transform: translateX(calc(50% + 20px)); } }
    .reinssurance-home-list li:nth-child(3) {
      z-index: 2;
      transition: transform 0.4s 0.2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      transform: translateY(calc(-200%)); }
      @media (min-width: 800px) {
        .reinssurance-home-list li:nth-child(3) {
          transform: translateX(calc(-50% + 20px)); } }
    .reinssurance-home-list li:nth-child(4) {
      z-index: 1;
      transition: transform 0.4s 0.1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      transform: translateY(calc(-300% + 60px)); }
      @media (min-width: 800px) {
        .reinssurance-home-list li:nth-child(4) {
          transform: translateX(calc(-150% + 20px)); } }
  .reinssurance-home-list.animate-in li {
    transform: translateX(0); }
  .reinssurance-home-list.animate-in .reinssurance-text strong {
    transform: translateY(0);
    opacity: 1; }

.push-product {
  position: relative;
  padding: 0 20px;
  background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0) 100%); }
  .push-product .expandgaz-transition,
  .push-product .expandfioul-transition {
    transition: all .5s ease; }
  .push-product .expandgaz-enter,
  .push-product .expandgaz-leave,
  .push-product .expandfioul-enter,
  .push-product .expandfioul-leave {
    opacity: 0; }

.push-product-head {
  text-align: center;
  padding: 100px 0 40px 0; }

.push-product-title {
  font-family: "Roboto", sans-serif;
  font-size: 3.6rem;
  margin: 0 0 50px 0;
  color: #ef604e;
  font-weight: 600; }

.push-product-tabs {
  margin: 0 auto;
  display: flex;
  gap: 10px;
  justify-content: space-around; }
  .push-product-tabs .tab {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 6px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    @media (min-width: 801px) {
      .push-product-tabs .tab {
        flex: 1; } }
    .push-product-tabs .tab p {
      margin: 0; }
    @media only screen and (max-width: 800px) {
      .push-product-tabs .tab {
        width: 100%; } }
    @media (min-width: 1281px) {
      .push-product-tabs .tab {
        display: flex;
        padding: 10px 40px;
        height: 60px;
        gap: 10px;
        justify-content: center;
        align-items: center; } }
    .push-product-tabs .tab:hover {
      background: #373737;
      color: #ffffff; }
    .push-product-tabs .tab.active {
      position: relative;
      background: #373737;
      color: #ffffff; }
      .push-product-tabs .tab.active::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        background: #373737;
        transform: rotate(45deg); }
    .push-product-tabs .tab:hover .icon-category.ch_gaz, .push-product-tabs .tab.active .icon-category.ch_gaz {
      background: url("../images/icons/uEA19-ch_gaz_white.svg") center no-repeat; }
    .push-product-tabs .tab:hover .icon-category.ch_fioul, .push-product-tabs .tab.active .icon-category.ch_fioul {
      background: url("../images/icons/uEA20-ch_fioul_white.svg") center no-repeat; }
    .push-product-tabs .tab:hover .icon-category.pac, .push-product-tabs .tab.active .icon-category.pac {
      background: url("../images/icons/uEA09-pump_white.svg") center no-repeat; }
    .push-product-tabs .tab:hover .icon-category.clim, .push-product-tabs .tab.active .icon-category.clim {
      background: url("../images/icons/uEA12-clim_white.svg") center no-repeat; }
    .push-product-tabs .tab .icon-category {
      margin: 0 auto 10px; }
      @media (min-width: 1281px) {
        .push-product-tabs .tab .icon-category {
          margin: 0; } }
    .push-product-tabs .tab .icon {
      font-size: 4rem;
      margin: 0 25px 0 0;
      vertical-align: middle;
      position: relative;
      top: -2px; }
    .push-product-tabs .tab span {
      text-transform: uppercase; }

.block-products-list:first-child {
  display: none; }

.block-products-list {
  left: 0;
  right: 0; }

.push-product-content {
  margin: 0 auto; }
  .push-product-content .products-grid {
    position: relative;
    z-index: 10; }

.product-items {
  padding: 20px 0;
  font-size: 0;
  text-align: center;
  justify-content: center;
  gap: 10px; }
  .product-items .product-item {
    opacity: 0; }
    .product-items .product-item:nth-child(1) {
      transition: opacity 0.2s calc(.2s * calc(1/4)) linear, transform 0.25s ease-in; }
    .product-items .product-item:nth-child(2) {
      transition: opacity 0.2s calc(.2s * calc(2/4)) linear, transform 0.25s ease-in; }
    .product-items .product-item:nth-child(3) {
      transition: opacity 0.2s calc(.2s * calc(3/4)) linear, transform 0.25s ease-in; }
    .product-items .product-item:nth-child(4) {
      transition: opacity 0.2s calc(.2s * calc(4/4)) linear, transform 0.25s ease-in; }
  .product-items.animate-in .product-item {
    opacity: 1; }
    .product-items.animate-in .product-item:nth-child(1) {
      animation: scaledAndShadow 0.3s calc(.3s * calc(1/4)) linear; }
    .product-items.animate-in .product-item:nth-child(2) {
      animation: scaledAndShadow 0.3s calc(.3s * calc(2/4)) linear; }
    .product-items.animate-in .product-item:nth-child(3) {
      animation: scaledAndShadow 0.3s calc(.3s * calc(3/4)) linear; }
    .product-items.animate-in .product-item:nth-child(4) {
      animation: scaledAndShadow 0.3s calc(.3s * calc(4/4)) linear; }
  .product-items .price-box .special-price,
  .product-items .price-box .old-price {
    display: block; }
  @media (min-width: 800px) {
    .product-items {
      display: flex; } }

#one-shot-container .one-shot-banner {
  opacity: 0;
  transform: translateY(-50%);
  transition: all .3s linear; }

#one-shot-container.animate-in .one-shot-banner {
  transform: translateY(0);
  opacity: 1; }

.one-shot-banner {
  margin-top: 50px;
  padding: 40px 60px;
  background: #ef604e;
  border-radius: 12px; }
  .one-shot-banner .one-shot-content {
    position: relative; }
    @media (min-width: 1281px) {
      .one-shot-banner .one-shot-content {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    .one-shot-banner .one-shot-content .price-excluding-tax {
      display: none; }
  .one-shot-banner .one-shot-left {
    padding-right: 10px; }
    @media (min-width: 1281px) {
      .one-shot-banner .one-shot-left {
        width: 25%; } }
    .one-shot-banner .one-shot-left .one-shot-title {
      font-size: 3rem;
      font-weight: 600;
      padding: 0 0 20px 0;
      color: #ffffff;
      border-bottom: 1px solid #ffffff; }
    .one-shot-banner .one-shot-left .one-shot-price {
      font-size: 1.4rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      margin: 20px 0px 5px 0;
      color: #ffffff; }
    .one-shot-banner .one-shot-left .price-container {
      display: flex;
      align-items: baseline;
      gap: 10px;
      color: #ffffff; }
    .one-shot-banner .one-shot-left .price-box .price-container.price-final_price {
      font-size: 3.6rem;
      color: #ffffff; }
    .one-shot-banner .one-shot-left .asterix {
      display: block;
      padding: 10px 0 0 0;
      font-size: 1.5rem;
      color: #ffffff; }
  .one-shot-banner .one-shot-list {
    color: #ffffff; }
    @media (min-width: 1281px) {
      .one-shot-banner .one-shot-list {
        padding: 17px 0; } }
    @media (max-width: 1280px) {
      .one-shot-banner .one-shot-list {
        display: inline-block;
        width: 45%;
        margin: 15px 0;
        vertical-align: top; } }
    .one-shot-banner .one-shot-list li {
      margin: 10px 0;
      font-size: 1.6rem;
      color: #ffffff; }
      .one-shot-banner .one-shot-list li .icon {
        font-size: 1.3rem;
        margin: 0 10px 0 0; }
      .one-shot-banner .one-shot-list li.not-included {
        position: relative;
        padding-left: 45px; }
        .one-shot-banner .one-shot-list li.not-included::before {
          content: '';
          position: absolute;
          left: 17px;
          top: 0;
          width: 15px;
          height: 100%;
          background: url("../images/icons/uEA21-cross.svg") center no-repeat; }
  .one-shot-banner .one-shot-right .btn__white {
    font-family: "Roboto", sans-serif;
    padding: 15px 30px;
    font-size: 2rem;
    border-color: transparent; }
    @media (min-width: 769px) {
      .one-shot-banner .one-shot-right .btn__white {
        min-width: 300px; } }
    .one-shot-banner .one-shot-right .btn__white:hover {
      color: #ffffff;
      background-color: #373737; }

.partners-home {
  position: relative;
  margin-top: 100px;
  padding: 50px 0;
  border-top: 1px solid #ef604e; }
  .partners-home.animate-in .partners-home-content .partners-slider .slick-slide {
    transform: translateX(0); }
  .partners-home .partners-home-content .h4-title {
    margin: 30px 0;
    font-size: 2.4rem;
    text-align: center;
    font-weight: 600; }
  .partners-home .partners-home-content .partners-slider::before, .partners-home .partners-home-content .partners-slider::after {
    content: '';
    position: absolute;
    top: 0;
    width: 300px;
    height: 100%;
    z-index: 5;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }
  .partners-home .partners-home-content .partners-slider::before {
    left: 0; }
  .partners-home .partners-home-content .partners-slider::after {
    right: 0;
    transform-origin: center;
    transform: rotate(180deg); }
  .partners-home .partners-home-content .partners-slider .slick-slide {
    transform: translateX(100vw); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(1) {
      transition: transform 0.6s calc(0.1s * calc(1/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(2) {
      transition: transform 0.6s calc(0.1s * calc(2/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(3) {
      transition: transform 0.6s calc(0.1s * calc(3/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(4) {
      transition: transform 0.6s calc(0.1s * calc(4/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(5) {
      transition: transform 0.6s calc(0.1s * calc(5/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(6) {
      transition: transform 0.6s calc(0.1s * calc(6/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
    .partners-home .partners-home-content .partners-slider .slick-slide:nth-child(7) {
      transition: transform 0.6s calc(0.1s * calc(7/2)) cubic-bezier(0.47, 1.64, 0.41, 0.8); }
  .partners-home .list-partners {
    display: flex;
    justify-content: center;
    gap: 4vw;
    overflow: hidden; }
    .partners-home .list-partners li {
      min-width: 130px; }

.push-reviews {
  position: relative;
  background-image: url("../images/engagement.jpeg");
  background-size: cover;
  padding: 100px 30px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; }
  .push-reviews.animate-in::before {
    transform: scaleY(1); }
  .push-reviews.animate-in .push-review-list .review-item {
    opacity: 1; }
  @media (min-width: 801px) {
    .push-reviews.animate-in .push-review-list .review-item:nth-child(1),
    .push-reviews.animate-in .push-review-list .review-item:nth-child(2),
    .push-reviews.animate-in .push-review-list .review-item:nth-child(3),
    .push-reviews.animate-in .push-review-list .review-item:nth-child(4) {
      transform: translateY(0); } }
  .push-reviews.animate-in .review_left .h2-title,
  .push-reviews.animate-in .review_left .light-text,
  .push-reviews.animate-in .review_left .btn__main {
    opacity: 1;
    transform: translateY(0); }
  .push-reviews::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000BF;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px; }
    @media (min-width: 800px) {
      .push-reviews::before {
        transform: scaleY(0);
        transform-origin: top;
        transition: transform .6s linear; } }
  .push-reviews .push-review-content > div {
    position: relative;
    z-index: 2; }
    @media (min-width: 801px) {
      .push-reviews .push-review-content > div {
        display: flex;
        align-items: center; } }
  .push-reviews .review_left {
    min-width: 30%; }
    @media (min-width: 800px) {
      .push-reviews .review_left .h2-title,
      .push-reviews .review_left .light-text,
      .push-reviews .review_left .btn__main {
        opacity: 0;
        transform: translateY(50px); } }
    .push-reviews .review_left .h2-title {
      font-size: 3.6rem;
      line-height: 4.3rem;
      font-weight: 600;
      color: #ffffff;
      transition: all .4s .2s linear; }
    .push-reviews .review_left .light-text {
      margin: 30px 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #ffffff;
      transition: all .4s .3s linear; }
    .push-reviews .review_left .btn__main {
      font-size: 2rem;
      padding: 15px 30px;
      transition: all .4s .4s linear; }
  @media (min-width: 801px) {
    .push-reviews .push-review-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; } }
  .push-reviews .push-review-list .review-item {
    margin: 20px;
    padding: 40px;
    border-radius: 12px;
    background: #f9f9f9; }
    @media (min-width: 800px) {
      .push-reviews .push-review-list .review-item {
        opacity: 0;
        transition: all .5s .2s linear; } }
    @media (min-width: 801px) {
      .push-reviews .push-review-list .review-item {
        width: 40%; } }
    .push-reviews .push-review-list .review-item .review-header {
      display: flex;
      justify-content: space-between; }
      .push-reviews .push-review-list .review-item .review-header div:nth-child(2) {
        max-width: 90px; }
    .push-reviews .push-review-list .review-item p {
      font-size: 1.4rem;
      color: #373737;
      margin: 0;
      text-align: justify; }
      .push-reviews .push-review-list .review-item p.review-name {
        font-family: "Roboto", sans-serif;
        font-size: 1.6rem;
        margin: 0 0 5px 0; }
      .push-reviews .push-review-list .review-item p.review-date {
        font-size: 1.2rem;
        color: #8c8c8c;
        margin: 0 0 15px 0; }
  @media (min-width: 801px) {
    .push-reviews .push-review-list .review-item:nth-child(1),
    .push-reviews .push-review-list .review-item:nth-child(3) {
      margin-bottom: 70px;
      margin-top: -30px;
      transform: translateY(100px); }
    .push-reviews .push-review-list .review-item:nth-child(2),
    .push-reviews .push-review-list .review-item:nth-child(4) {
      transform: translateY(-100px); } }
  .push-reviews .allReviews-link {
    position: relative;
    font-weight: 600;
    z-index: 5; }
    .push-reviews .allReviews-link a {
      color: #ef604e; }

.faq-home {
  padding-top: 100px;
  padding-bottom: 100px; }
  .faq-home .faq__head .h2-title {
    margin: 10px 0;
    font-size: 3.6rem;
    font-weight: 600; }
  .faq-home .faq__head p {
    max-width: 800px;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: #8c8c8c; }
  @media (min-width: 801px) {
    .faq-home .faq__category {
      display: flex;
      gap: 20px;
      margin: 30px 0; } }
  .faq-home .faq__category .category-item {
    cursor: pointer;
    padding: 10px;
    font-size: 1.6rem;
    border-radius: 6px;
    background-color: #eeeeee; }
    .faq-home .faq__category .category-item.active {
      color: #ffffff;
      background-color: #ef604e; }
  .faq-home .faq__content {
    padding: 20px 50px;
    border: 20px solid #eeeeee;
    border-radius: 24px; }
    .faq-home .faq__content .faq-questions-answers {
      padding: 20px 0; }
    .faq-home .faq__content .faq-questions-answers:not(:first-child) {
      border-top: 2px solid #eeeeee; }
  .faq-home .faq__question {
    cursor: pointer;
    position: relative;
    padding-right: 100px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.6rem; }
    .faq-home .faq__question::after, .faq-home .faq__question::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 40px;
      width: 40px; }
    .faq-home .faq__question::after {
      border-radius: 6px;
      background-color: #eeeeee; }
    .faq-home .faq__question::before {
      z-index: 2;
      background-image: url("../images/icons/faq-plus.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      transform-origin: center;
      transition: transform .2s ease-in; }
    .faq-home .faq__question.active::before {
      transform: rotate(-45deg); }
  .faq-home .faq__answer {
    font-size: 1.5rem;
    line-height: 2.4rem; }
    .faq-home .faq__answer .answer-container {
      margin-top: 30px; }
      @media (max-width: 800px) {
        .faq-home .faq__answer .answer-container .pagebuilder-column-group .pagebuilder-column-line {
          display: block !important; }
          .faq-home .faq__answer .answer-container .pagebuilder-column-group .pagebuilder-column-line .pagebuilder-column {
            width: 100% !important; } }
    .faq-home .faq__answer ul li {
      position: relative;
      padding-left: 30px; }
      .faq-home .faq__answer ul li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-image: url("../images/icons/uEA23-coche_green.svg");
        background-position: 50%;
        background-repeat: no-repeat; }
    .faq-home .faq__answer img.pagebuilder-mobile-hidden {
      display: none; }
    @media (min-width: 769px) {
      .faq-home .faq__answer img.pagebuilder-mobile-hidden {
        display: block; }
      .faq-home .faq__answer img.pagebuilder-mobile-only {
        display: none; } }

.asterisk-character {
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 5px; }

@media only screen and (max-width: 1100px) {
  .push-review-col {
    width: 50%; }
    .push-review-col:last-child {
      display: none; } }

@media only screen and (max-width: 800px) {
  .sponsorship-home .sponsorship-partners {
    justify-content: center; }
  .reinssurance-home {
    padding: 75px 0; }
    .reinssurance-home__content {
      padding: 0 35px; }
  .reinssurance-home-list {
    margin: 10px 0 0 0;
    display: inline-block; }
    .reinssurance-home-list li {
      display: block;
      float: none;
      width: auto;
      margin: 0 0 20px 0; }
      .reinssurance-home-list li img {
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px 0 10px; }
  .reinssurance-text {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    width: 70%; }
  .block-products-list {
    position: static;
    padding: 0; }
  .push-product {
    padding: 0px;
    height: auto; }
  .push-product-title {
    padding: 0 20px;
    font-size: 3.6rem; }
  .push-product-tabs {
    padding: 0 20px; }
    .push-product-tabs .tab {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 1.4rem;
      width: 40%; }
      .push-product-tabs .tab .icon {
        display: none; }
  .push-product-content .products-grid {
    margin: 0; } }
  @media only screen and (max-width: 800px) and (max-width: 800px) {
    .push-product-content .products-grid .widget-product-grid-mobile .info-block {
      padding: 20px 15px; }
      .push-product-content .products-grid .widget-product-grid-mobile .info-block::before {
        top: 15px;
        transform: translateY(0); }
      .push-product-content .products-grid .widget-product-grid-mobile .info-block .info-block__text {
        text-indent: 25px;
        padding: 0; } }

@media only screen and (max-width: 800px) {
  #one-shot-container {
    width: calc(100vw - 50px); }
    #one-shot-container .one-shot-banner {
      padding: 30px 20px;
      height: auto; }
      #one-shot-container .one-shot-banner .one-shot-left {
        width: 100%;
        padding: 0; }
      #one-shot-container .one-shot-banner .price-container .n-time {
        font-weight: 600; }
    #one-shot-container .one-shot-title {
      margin: 0 0 15px 0;
      font-size: 3rem;
      text-align: center; }
    #one-shot-container .one-shot-price {
      display: none; }
    #one-shot-container .one-shot-list {
      border: none;
      width: auto;
      display: inline-block; }
      #one-shot-container .one-shot-list.includes {
        padding-top: 20px;
        padding-bottom: 0px; }
      #one-shot-container .one-shot-list.not-included {
        padding-top: 0px;
        padding-bottom: 20px; }
      #one-shot-container .one-shot-list li {
        display: block;
        position: relative;
        padding-left: 30px;
        font-size: 1.6rem;
        text-align: left;
        margin: 10px 0; }
        #one-shot-container .one-shot-list li:last-child {
          text-align: left; }
        #one-shot-container .one-shot-list li.not-included::before {
          left: 0; }
        #one-shot-container .one-shot-list li .icon {
          position: absolute;
          font-size: 1.1rem;
          top: 0;
          left: 0;
          display: block;
          margin: 0 auto 7px; }
    #one-shot-container .asterix {
      padding: 0;
      font-size: 1.3rem; }
    #one-shot-container .one-shot-right {
      position: relative;
      top: 0;
      right: 0;
      margin: 0 auto; }
      #one-shot-container .one-shot-right .btn__white {
        padding: 10px;
        width: 100%; }
  .partners-home .partners-home-content {
    padding: 15px 0px; }
    .partners-home .partners-home-content .partners-slider::before, .partners-home .partners-home-content .partners-slider::after {
      width: 50px; }
    .partners-home .partners-home-content .slick-slide {
      margin: 0 25px; }
  .push-reviews {
    padding: 100px 36px; }
    .push-reviews .h2-title {
      font-size: 2rem; }
    .push-reviews .buttons-set {
      margin: 20px 0 0 0; }
    .push-reviews .push-review-list .review-item {
      margin: 20px 15px;
      padding: 30px 25px; }
      .push-reviews .push-review-list .review-item p {
        text-align: start;
        line-height: 24px; }
  .push-review-content {
    padding: 25px 0; }
    .push-review-content .review_left {
      margin-bottom: 50px; }
  .push-review-col {
    width: auto;
    float: none;
    padding: 60px 0 0; }
  .picture-review {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  .faq-home {
    padding: 100px 36px; }
    .faq-home .faq__category {
      display: flex;
      margin: 25px 0;
      padding: 10px 0;
      overflow-x: scroll;
      gap: 20px; }
      .faq-home .faq__category .category-item {
        white-space: nowrap; }
    .faq-home .faq__content {
      padding: 0px;
      border: none; }
    .faq-home .faq__question {
      padding-right: 70px; } }

.cms-page-view:not(.cms-parrainage) .column.main {
  max-width: 870px;
  margin: 50px auto;
  padding: 0 10px; }

.cms-page-view:not(.cms-parrainage) h1, .cms-page-view:not(.cms-parrainage) h2, .cms-page-view:not(.cms-parrainage) h3, .cms-page-view:not(.cms-parrainage) h4, .cms-page-view:not(.cms-parrainage) h5, .cms-page-view:not(.cms-parrainage) h6 {
  color: #373737;
  font-family: "Montserrat", sans-serif; }

.cms-page-view:not(.cms-parrainage) h1 {
  font-size: 2.5rem;
  margin: 0 0 35px;
  text-align: center;
  text-transform: uppercase; }

.cms-page-view:not(.cms-parrainage) h2 {
  font-size: 2.2rem;
  margin: 30px 0 10px; }

.cms-page-view:not(.cms-parrainage) h3 {
  font-size: 1.8rem;
  margin: 20px 0 10px; }

.cms-page-view:not(.cms-parrainage) h4, .cms-page-view:not(.cms-parrainage) h5, .cms-page-view:not(.cms-parrainage) h6 {
  font-size: 1.6rem;
  margin: 20px 0 10px; }

.cms-page-view:not(.cms-parrainage) p, .cms-page-view:not(.cms-parrainage) div {
  font-size: 1.2rem;
  line-height: 20px; }

.cms-page-view:not(.cms-parrainage) table {
  margin: 0 0 15px;
  width: 100%;
  border: 1px solid #000000; }
  .cms-page-view:not(.cms-parrainage) table thead th {
    font-size: 1.4rem;
    padding: 10px 15px;
    border-bottom: 1px solid #000000; }
  .cms-page-view:not(.cms-parrainage) table tbody td {
    padding: 10px 15px; }

.cms-particuliers-cookie .column.main {
  max-width: 870px;
  margin: 50px auto;
  padding: 0 10px; }

.cms-particuliers-cookie h1 {
  text-align: center; }

.cms-page-view:not(.cms-parrainage) .no-banner .ee-banner_background,
.cms-page-view:not(.cms-parrainage) .no-banner .ee-banner-content {
  display: none; }

.sl-top-banner {
  height: 300px;
  line-height: 300px;
  text-align: center;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 500;
  background: url(../images/banners/sl-banner.jpg) 50% 0 no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin: 0 0 20px 0; }

.sl-top-search {
  padding: 25px;
  background: #eeeeee; }

.sl-title {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 15px 0; }

.sl-input-box {
  position: relative;
  padding: 0 110px 0 0; }
  .sl-input-box .button {
    width: 110px;
    position: absolute;
    right: 0;
    top: 0; }

.sl-input-text {
  background: #ffffff; }

.sl-country-selector {
  height: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1; }

.sl-bt-geoloc {
  display: inline-block;
  position: relative;
  font-weight: 600;
  margin: 15px 0 0 0; }
  .sl-bt-geoloc:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: #000000;
    margin: 2px 0 0 0;
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease; }
  .sl-bt-geoloc:hover {
    color: #000000; }
    .sl-bt-geoloc:hover:after {
      height: 3px; }

.sl-counter {
  padding: 15px 0;
  font-weight: 500; }

.sl-list {
  max-height: 560px;
  overflow-y: auto; }
  .sl-list li {
    padding: 15px 25px 15px 60px;
    border-bottom: 1px solid #eeeeee;
    position: relative; }
    .sl-list li:before {
      font-family: "icons";
      content: "\a003";
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      font-weight: normal;
      font-style: normal;
      speak: none;
      text-decoration: inherit;
      text-transform: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      left: 22px;
      top: 17px;
      font-size: 2.4rem; }

.sl-item-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6rem; }

.sl-item-address {
  margin: 5px 0 15px 0;
  line-height: 18px;
  padding: 0 115px 0 0; }

.title-open-hours {
  font-weight: 600;
  font-size: 1.3rem; }

.sl-bt-details {
  position: absolute;
  top: 20px;
  right: 25px;
  max-width: 110px; }

.sl-bt-fav {
  margin: 15px 0 0 0; }

.sl-map-container {
  width: 100%;
  height: 750px; }

.sl-content-view {
  max-width: 1320px;
  margin: 0 auto; }

.sl-view-top {
  margin: 0 0 45px 0; }

.sl-view-bt-back {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;
  padding: 0 0 0 25px;
  margin: 0 0 25px 0; }
  .sl-view-bt-back .icon {
    position: absolute;
    top: 3px;
    left: 0;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    transition: all 200ms ease; }
  .sl-view-bt-back:hover {
    color: #000000;
    padding: 0 0 0 30px; }
    .sl-view-bt-back:hover .icon {
      left: -7px; }

.sl-view-title {
  font-size: 3.2rem;
  font-weight: 500;
  margin: 0 0 10px 0; }

.sl-view-address {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0 0 10px 0; }

.sl-view-desc {
  font-size: 1.4rem;
  line-height: 18px;
  color: #424242; }

.sl-view-blocks {
  display: table;
  width: 100%;
  margin: 0 0 45px 0; }

.sl-view-block {
  display: table-cell;
  width: 50%;
  border-right: 1px solid #eeeeee;
  padding: 0 15px 0 0; }
  .sl-view-block:last-child {
    padding: 0 15px 0 25px;
    border: none; }

.sl-view-block-title {
  position: relative;
  padding: 0 0 0 40px; }
  .sl-view-block-title .icon {
    font-size: 2.8rem;
    position: absolute;
    left: 0;
    top: -5px; }

.sl-view-list-open {
  padding: 0 0 0 40px;
  font-size: 1.4rem;
  color: #424242;
  line-height: 20px; }
  .sl-view-list-open span {
    color: #000000;
    font-weight: 500;
    display: inline-block;
    width: 95px; }

.sl-view-sub-block {
  margin: 0 0 25px 0; }
  .sl-view-sub-block:last-child {
    margin: 0; }

.sl-view-special-open {
  padding: 0 0 0 40px;
  line-height: 20px;
  font-size: 1.3rem; }

.sl-view-tel {
  padding: 0 0 0 40px; }

@media only screen and (max-width: 1100px) {
  .sl-list {
    max-height: 390px;
    margin: 0 0 20px 0; }
  .sl-map-container {
    height: 500px; }
  .sl-view-block {
    display: block;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #eeeeee;
    padding: 0 0 20px 0;
    margin: 0 0 30px 0; }
    .sl-view-block:last-child {
      padding: 0 15px 0 0; }
  .sl-view-push-market {
    text-align: center;
    margin: 0 0 50px; }
  .picture-content {
    text-align: center; }
    .picture-content img {
      margin: 0 auto; } }

@media only screen and (max-width: 800px) {
  .sl-top-search {
    padding: 20px 15px; }
  .sl-list {
    max-height: 460px; }
    .sl-list li {
      padding: 50px 0 15px; }
      .sl-list li:before {
        left: 10px; }
  .sl-bt-details {
    right: 0; }
  .sl-map-container {
    height: 300px; } }

.soon-faq-index-index .page-faq {
  background: #eeeeee; }
  .soon-faq-index-index .page-faq .h2-title {
    font-size: 2.5rem;
    margin: 0 0 35px;
    text-align: center;
    text-transform: uppercase; }
  .soon-faq-index-index .page-faq .faq-container {
    padding: 0 0 80px; }
  .soon-faq-index-index .page-faq .faq-content {
    max-width: 870px;
    margin: 0 auto;
    padding: 0 10px; }
  .soon-faq-index-index .page-faq .category {
    background: #ffffff;
    border-radius: 5px;
    display: table;
    max-width: 1046px;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    text-align: center;
    top: -40px;
    width: 100%; }
  .soon-faq-index-index .page-faq .category-item {
    color: #373737;
    cursor: pointer;
    display: table-cell;
    font-family: "Montserrat", sans-serif;
    padding: 30px 0 20px; }
    .soon-faq-index-index .page-faq .category-item:after {
      content: "";
      background: #ef604e;
      display: block;
      height: 4px;
      margin: 10px auto 0;
      width: 0px;
      border-radius: 5px;
      -webkit-transition: width 0.5s ease-in-out;
      -moz-transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out; }
    .soon-faq-index-index .page-faq .category-item:hover:after {
      width: 30%; }
    .soon-faq-index-index .page-faq .category-item.active:after {
      width: 30%; }
  .soon-faq-index-index .page-faq .faq-questions-answers {
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #8c8c8c;
    margin: 0 0 15px; }
    .soon-faq-index-index .page-faq .faq-questions-answers.active {
      border: none;
      border-bottom: 1px solid red;
      border-top: 1px solid orange;
      border-radius: 5px;
      background-size: 1px 100%;
      background-position: 0 0, 100% 0;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=), url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
      background-image: -webkit-linear-gradient(orange, red), -webkit-linear-gradient(orange, red);
      background-image: -moz-linear-gradient(orange, red), -moz-linear-gradient(orange, red);
      background-image: -o-linear-gradient(orange, red), -o-linear-gradient(orange, red);
      background-image: linear-gradient(orange, red), linear-gradient(orange, red); }
      .soon-faq-index-index .page-faq .faq-questions-answers.active .faq-question:after {
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        transform: scaleY(-1); }
  .soon-faq-index-index .page-faq .faq-question {
    color: #373737;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    padding: 15px 75px 15px 30px;
    position: relative; }
    .soon-faq-index-index .page-faq .faq-question:after {
      background: url("../images/arrow-down.png");
      background-size: cover;
      content: "";
      display: block;
      height: 6px;
      width: 9px;
      position: absolute;
      right: 30px;
      top: 50%;
      margin-top: -3px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      transition: all 0.3s linear; }
  .soon-faq-index-index .page-faq .faq-answer {
    padding: 0 75px 0 30px; }
    .soon-faq-index-index .page-faq .faq-answer p {
      margin: 15px 0 20px; }

.soon-faq-index-index .faq-reinssurance {
  color: #373737;
  max-width: 1046px;
  margin: 0 auto;
  padding: 50px 0 60px; }
  .soon-faq-index-index .faq-reinssurance .title-blocks {
    color: #373737;
    font-family: "Montserrat", sans-serif; }

.soon-faq-index-index .any-question, .soon-faq-index-index .faq-newsletter {
  float: left;
  position: relative;
  width: 50%; }
  .soon-faq-index-index .any-question:after, .soon-faq-index-index .faq-newsletter:after {
    content: "";
    display: block;
    width: 100px;
    height: 80px;
    position: absolute;
    top: 10px; }

.soon-faq-index-index .any-question {
  border-right: 1px solid #373737;
  padding: 0 90px 0 100px; }
  .soon-faq-index-index .any-question:after {
    background: url("../images/phone.png") no-repeat 50% 0;
    left: 0; }

.soon-faq-index-index .faq-newsletter {
  padding: 0 20px 0 180px; }
  .soon-faq-index-index .faq-newsletter:after {
    background: url("../images/mail.png") no-repeat 50% 0;
    left: 60px; }
  .soon-faq-index-index .faq-newsletter label {
    padding: 0 0 10px; }
  .soon-faq-index-index .faq-newsletter .field-news {
    max-width: 260px; }
  .soon-faq-index-index .faq-newsletter .input-text, .soon-faq-index-index .faq-newsletter .textarea {
    border: 1px solid #ef604e; }

.question-transition,
.listQuestion-transition {
  transition: all .3s ease;
  overflow: hidden; }

.question-enter, .question-leave,
.listQuestion-enter, .listQuestion-leave {
  height: 0;
  opacity: 0; }

@media only screen and (max-width: 1100px) {
  .soon-faq-index-index .faq-newsletter:after {
    left: 30px; }
  .soon-faq-index-index .faq-newsletter {
    padding: 0 20px 0 150px; } }

@media only screen and (max-width: 800px) {
  .soon-faq-index-index .page-faq .h2-title {
    font-size: 2rem;
    margin: 0 0 35px;
    text-align: center;
    text-transform: uppercase; }
  .soon-faq-index-index .page-faq .question-content {
    padding: 0 0 0 20px; }
  .soon-faq-index-index .page-faq .faq-question {
    font-size: 1.4rem; }
    .soon-faq-index-index .page-faq .faq-question:after {
      right: 15px; }
  .soon-faq-index-index .page-faq .faq-response {
    margin: 0 0 30px 0; }
  .soon-faq-index-index .page-faq .category {
    padding: 0 10px; }
  .soon-faq-index-index .faq-reinssurance {
    padding: 30px 0; }
  .soon-faq-index-index .any-question, .soon-faq-index-index .faq-newsletter {
    float: none;
    padding: 0 20px 0 110px;
    width: 100%; }
    .soon-faq-index-index .any-question:after, .soon-faq-index-index .faq-newsletter:after {
      left: 0;
      top: 50%;
      margin-top: -40px; }
  .soon-faq-index-index .any-question {
    border-right: none;
    margin: 0 0 60px; } }

.contact-index-index .header.content,
.easycore_contact-index-success .header.content {
  display: none; }

.contact-index-index #maincontent {
  margin-top: 100px; }

.contact-index-index .form.contact {
  position: relative;
  max-width: 1070px;
  margin: 50px auto;
  padding: 65px 77px; }
  .contact-index-index .form.contact::before {
    content: "";
    position: absolute;
    inset: 0px 0px 50%;
    box-shadow: 0px -25px 25px rgba(39, 73, 94, 0.15); }
  .contact-index-index .form.contact :-webkit-autofill {
    border: none !important; }
  .contact-index-index .form.contact select.field-row {
    height: auto;
    width: 100%; }
  .contact-index-index .form.contact textarea {
    position: relative;
    font-weight: 400;
    min-height: 100px;
    height: auto;
    width: 100%;
    border: none; }
  .contact-index-index .form.contact .custom-checkbox + label {
    margin-bottom: 20px; }
  .contact-index-index .form.contact .actions-toolbar .button.submit {
    height: auto;
    margin-top: 0;
    padding: 10px 25px;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.6;
    text-transform: uppercase; }

.contact-index-index .h2-title {
  font-size: 2.5rem;
  margin: 0 0 35px;
  text-align: center;
  text-transform: uppercase; }

.contact-index-index .select-box {
  position: relative; }

.contact-index-index .requestObject-option {
  display: none; }
  .contact-index-index .requestObject-option.displayed {
    display: block; }

.contact-index-index .recaptcha-container .grecaptcha-badge {
  margin: 0 auto; }

.easycore_contact-index-success .success-img {
  width: 350px;
  margin: 100px auto 25px; }
  .easycore_contact-index-success .success-img svg {
    width: 100%;
    height: 100%; }
    .easycore_contact-index-success .success-img svg path.outside {
      stroke-dasharray: 400px;
      stroke-dashoffset: 400px;
      animation: anim .5s ease-in forwards; }
    .easycore_contact-index-success .success-img svg path.inside-top {
      stroke-dasharray: 120px;
      stroke-dashoffset: 120px;
      animation: anim .5s ease-in forwards; }
    .easycore_contact-index-success .success-img svg path.inside,
    .easycore_contact-index-success .success-img svg path.inside,
    .easycore_contact-index-success .success-img svg path.wind,
    .easycore_contact-index-success .success-img svg path.wind {
      stroke-dasharray: 70px;
      stroke-dashoffset: 70px;
      animation: anim .5s ease-in forwards; }
    .easycore_contact-index-success .success-img svg path.circle {
      stroke-dasharray: 170px;
      stroke-dashoffset: 170px;
      animation: anim .3s .4s ease-in forwards; }
    .easycore_contact-index-success .success-img svg path.check {
      stroke-dasharray: 50px;
      stroke-dashoffset: 50px;
      animation: anim .3s .7s ease-in forwards; }

@keyframes anim {
  to {
    stroke-dashoffset: 0; } }

.easycore_contact-index-success .success-message {
  width: 80%;
  margin: 0px auto 50px;
  text-align: center;
  font-size: 2.5rem; }

@media only screen and (max-width: 800px) {
  .contact-index-index .header.content,
  .easycore_contact-index-success .header.content {
    display: block; }
  .easycore_contact-index-success .success-img {
    width: 100px;
    margin: 100px auto 25px; }
    .easycore_contact-index-success .success-img svg {
      width: 100%;
      height: 100%; }
  .easycore_contact-index-success .success-message {
    font-size: 1.5rem; } }

.cms-parrainage {
  /** ---------------
        HEADER
    ------------------ */
  /** ---------------
        Main content
    ------------------ */
  /** ---------------
        Footer
    ------------------ */ }
  .cms-parrainage .page-header {
    margin-bottom: 50px; }
    .cms-parrainage .page-header .subtitle-banner {
      display: block; }
    .cms-parrainage .page-header .ee-banner__infos-boxes .transparent-box {
      padding: 25px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(10px); }
      .cms-parrainage .page-header .ee-banner__infos-boxes .transparent-box__text-middle {
        font-size: 4.8rem; }
      .cms-parrainage .page-header .ee-banner__infos-boxes .transparent-box__text-bottom {
        font-size: 1.8rem; }
    .cms-parrainage .page-header .ee-banner-cta .btn {
      color: #ffffff; }
      .cms-parrainage .page-header .ee-banner-cta .btn:hover {
        color: #ef604e; }
  .cms-parrainage #maincontent .h2-title {
    margin: 10px 0;
    font-size: 3.6rem;
    line-height: 4.3rem;
    font-weight: 600; }
  .cms-parrainage #maincontent .sponsorship-content__Steps {
    margin: 50px auto;
    padding-top: 50px; }
    .cms-parrainage #maincontent .sponsorship-content__Steps p {
      margin: 30px 0;
      font-size: 1.6rem;
      line-height: 2.4rem; }
    .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 15px;
      padding: 35px;
      border-radius: 12px;
      box-shadow: 0px 20px 30px 0px rgba(0, 0, 13, 0.05); }
      @media (min-width: 1025px) {
        .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item {
          width: 45%; } }
      .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item:nth-child(1) {
        margin-bottom: 70px;
        margin-top: -30px; }
      .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item img {
        display: block;
        height: 80px; }
      .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item__title {
        font-size: 2rem;
        color: #ef604e;
        font-weight: 600; }
      .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list .step-item__desc {
        margin: 5px 0; }
  .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner {
    padding: 0 25px; }
    @media (min-width: 1025px) {
      .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner {
        display: flex;
        justify-content: center;
        gap: 5vw; } }
    .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item__text-content {
      font-size: 3.4rem;
      line-height: 3.5rem;
      font-family: "Caveat-Regular", sans-serif; }
      .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item__text-content .red-amount {
        font-size: 5.8rem;
        line-height: 5.9rem;
        color: #ef604e; }
    .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item__illustration:nth-child(2) svg {
      display: block;
      margin-left: auto; }
    .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item .text__end {
      width: 50%;
      margin-left: auto; }
  .cms-parrainage #maincontent .sponsorship-content__Advantages {
    padding: 50px 0;
    background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0) 100%); }
    .cms-parrainage #maincontent .sponsorship-content__Advantages .h2-title {
      margin: 0 auto;
      padding: 50px 0;
      text-align: center; }
    .cms-parrainage #maincontent .sponsorship-content__Advantages .advantages-list .advantage-item {
      flex: 1;
      padding: 40px;
      text-align: center;
      box-shadow: 0px 20px 30px 0px rgba(0, 0, 13, 0.05); }
      .cms-parrainage #maincontent .sponsorship-content__Advantages .advantages-list .advantage-item__title {
        margin: 5px 0;
        font-size: 3rem;
        line-height: 4.2rem;
        color: #ef604e; }
      .cms-parrainage #maincontent .sponsorship-content__Advantages .advantages-list .advantage-item__desc {
        font-size: 1.8rem;
        line-height: 2.16rem;
        font-weight: 600; }
  .cms-parrainage #maincontent .sponsorship-content__Form {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px; }
    @media (min-width: 1025px) {
      .cms-parrainage #maincontent .sponsorship-content__Form {
        flex-direction: row; } }
    .cms-parrainage #maincontent .sponsorship-content__Form .banner {
      display: none; }
      @media (min-width: 1025px) {
        .cms-parrainage #maincontent .sponsorship-content__Form .banner {
          display: flex; } }
    .cms-parrainage #maincontent .sponsorship-content__Form .sponsorship-partners {
      display: flex;
      align-items: center;
      gap: 40px; }
    .cms-parrainage #maincontent .sponsorship-content__Form .form-container {
      padding: 60px;
      box-shadow: 0px 20px 30px 0px #0000000D; }
      .cms-parrainage #maincontent .sponsorship-content__Form .form-container .desc {
        padding: 25px 0;
        font-size: 1.8rem;
        line-height: 2.5rem;
        border-bottom: 2px solid #dddddd; }
        .cms-parrainage #maincontent .sponsorship-content__Form .form-container .desc span {
          font-weight: 700; }
      .cms-parrainage #maincontent .sponsorship-content__Form .form-container .btn {
        display: block;
        margin: 50px 0;
        padding: 20px;
        font-size: 2rem; }
  .cms-parrainage .page-footer .sponsorship-link {
    display: none; }

/** ----------------------------------------
            RESPONSIVE
------------------------------------------ */
/**
* Max width 801px
*/
@media only screen and (max-width: 800px) {
  .cms-parrainage .page-header .ee-banner .ee-banner_background {
    background-position: 15% 50%; }
  .cms-parrainage .page-header .ee-banner .ee-banner-content {
    padding: 75px 35px;
    height: auto; }
    .cms-parrainage .page-header .ee-banner .ee-banner-content .ee-banner__infos-boxes .transparent-box {
      margin: 15px auto; }
  .cms-parrainage .page-header .ee-mobile_cta {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 25px 0;
    transform: translateY(200%);
    animation: all .3s ease-in; }
    .cms-parrainage .page-header .ee-mobile_cta.intersect_fixed {
      transform: translateY(0); }
    .cms-parrainage .page-header .ee-mobile_cta a {
      display: block;
      margin: 0 auto;
      width: 80%; }
  .cms-parrainage #maincontent .wrapper-large {
    padding: 0 35px; }
  .cms-parrainage #maincontent .sponsorship-content__Advantages .h2-title img {
    display: block;
    margin: 0 auto; }
  .cms-parrainage #maincontent .sponsorship-content__Advantages .advantages-list .advantage-item {
    margin: 20px auto; }
  .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item__illustration svg {
    max-width: 80%; }
  .cms-parrainage #maincontent .sponsorship-content__Form.wrapper-large {
    padding: 0; }
  .cms-parrainage #maincontent .sponsorship-content__Form .sponsorship-partners {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    padding: 0 35px; } }

/** 
* Min width 801px
*/
@media (min-width: 801px) {
  .cms-parrainage .page-header {
    margin-bottom: 100px; }
    .cms-parrainage .page-header .ee-banner__infos-boxes {
      display: flex;
      gap: 20px; }
      .cms-parrainage .page-header .ee-banner__infos-boxes .transparent-box {
        padding: 40px 25px;
        max-width: 200px; }
    .cms-parrainage .page-header .ee-banner-cta {
      display: flex; }
  .cms-parrainage #maincontent .sponsorship-content__Steps {
    display: flex;
    gap: 40px; }
    .cms-parrainage #maincontent .sponsorship-content__Steps .left-content {
      max-width: 440px; }
    .cms-parrainage #maincontent .sponsorship-content__Steps .steps-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
  .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner {
    overflow-x: hidden; }
    .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item {
      display: flex;
      align-items: center; }
      .cms-parrainage #maincontent .sponsorship-content__Card-amount_banner .amount-item__illustration {
        padding: 0 3%; }
  .cms-parrainage #maincontent .sponsorship-content__Advantages {
    margin-bottom: 50px;
    padding: 100px 0; }
    .cms-parrainage #maincontent .sponsorship-content__Advantages .h2-title img {
      margin-left: 20px;
      vertical-align: middle; }
    .cms-parrainage #maincontent .sponsorship-content__Advantages .advantages-list {
      display: flex;
      gap: 40px; }
  .cms-parrainage #maincontent .sponsorship-content__Form {
    margin: 50px auto; }
    .cms-parrainage #maincontent .sponsorship-content__Form .banner {
      position: relative;
      overflow: hidden;
      padding: 90px 50px;
      max-width: 1000px;
      min-width: 650px;
      border-radius: 12px;
      background-repeat: no-repeat;
      background-position-x: center; }
      .cms-parrainage #maincontent .sponsorship-content__Form .banner__left, .cms-parrainage #maincontent .sponsorship-content__Form .banner__right {
        position: relative;
        width: 50%;
        z-index: 5; }
      .cms-parrainage #maincontent .sponsorship-content__Form .banner__right {
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.6rem;
        color: #ffffff; }
      .cms-parrainage #maincontent .sponsorship-content__Form .banner::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #EF604E 0%, rgba(0, 0, 0, 0) 100%); }
    .cms-parrainage #maincontent .sponsorship-content__Form .sponsorship-partners {
      margin-top: 50px;
      height: 55px; } }

.product-item.directenergie, .product-item.foncia, .product-item.myfoncia {
  overflow: hidden; }
  .product-item.directenergie .web-price, .product-item.foncia .web-price, .product-item.myfoncia .web-price {
    top: -65px; }
    .product-item.directenergie .web-price .web-price-content, .product-item.foncia .web-price .web-price-content, .product-item.myfoncia .web-price .web-price-content {
      font-size: 1.3rem;
      height: 105px;
      line-height: 160px;
      max-width: 200px;
      text-transform: uppercase;
      width: 95%;
      border-radius: 0 0 50% 50%; }

.product-item.directenergie .web-price .web-price-content {
  background: #fee600;
  color: #373737; }

.product-item.directenergie .price-box .price {
  color: #373737; }

.product-item.directenergie .listactions li .icon {
  color: #fee600; }

.product-item.directenergie .button {
  background: #fee600;
  border: 2px solid #fee600;
  color: #373737; }
  .product-item.directenergie .button:hover {
    background: transparent; }

.product-item.foncia .web-price .web-price-content, .product-item.myfoncia .web-price .web-price-content {
  background: #063869;
  color: #ffffff; }

.product-item.foncia .button:hover, .product-item.myfoncia .button:hover {
  background: transparent;
  border: 2px solid #063869;
  color: #063869; }

.product-item .desc {
  border-bottom: 1px solid #ef604e;
  border-top: 1px solid #ef604e;
  padding: 5px 0;
  margin: 20px 10px; }
  .product-item .desc .pdc-price {
    color: #ef604e; }

.product-item .info-block {
  margin: 20px 0; }
  @media (min-width: 801px) {
    .product-item .info-block {
      margin: 20px 10px; } }

.product-item .services-list {
  flex: 1; }

.web-price {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center; }
  .web-price .web-price-content {
    display: inline-block;
    margin: -1px auto 0;
    width: 95%;
    max-width: 175px;
    height: 30px;
    line-height: 30px;
    background: #ef604e;
    color: #ffffff;
    border-radius: 0 0 5px 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem; }

.product-item {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  padding: 45px 5px 35px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 20px 30px 0px #0000000D;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  vertical-align: top; }
  @media (min-width: 801px) {
    .product-item {
      flex: 1;
      max-width: 25%; } }
  .product-item:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0 2px 51px rgba(0, 0, 0, 0.14);
    z-index: 2; }
  .product-item .product-name {
    margin: 0 3rem;
    padding-bottom: 20px;
    font-family: "Roboto", sans-serif;
    color: #373737;
    font-size: 3rem;
    font-weight: bold;
    border-bottom: 1px solid #dddddd; }
    .product-item .product-name span {
      font-weight: normal;
      display: block;
      text-transform: uppercase;
      letter-spacing: .1rem;
      font-size: 1.2rem;
      color: #8c8c8c; }
  .product-item .internet-rate-text {
    color: #373737;
    font-size: 1.4rem;
    margin: 15px 0; }
  .product-item .price-box {
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: middle; }
    .product-item .price-box .price {
      font-family: "Montserrat", sans-serif;
      font-size: 3.4rem;
      font-weight: normal;
      color: #ef604e; }
    .product-item .price-box .price-excluding-tax {
      display: none; }
  .product-item .per-month {
    color: #9b9b9b;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.1rem;
    margin: 0; }
  .product-item .rate-agency-text span {
    color: #9b9b9b;
    display: block; }
  .product-item .listactions {
    text-align: left;
    display: flex;
    flex-direction: column-reverse; }
    .product-item .listactions li {
      padding: 7px 7px 7px 45px;
      font-size: 1.6rem;
      color: #373737;
      position: relative; }
      .product-item .listactions li .icon {
        font-size: 1.1rem;
        color: #65CC9B;
        position: absolute;
        left: 17px;
        top: 11px; }
      .product-item .listactions li.not-included {
        position: relative;
        color: #9b9b9b; }
        .product-item .listactions li.not-included::before {
          content: '';
          position: absolute;
          left: 17px;
          top: 0;
          width: 15px;
          height: 100%;
          background: url("../images/icons/uEA21-cross.svg") center no-repeat; }
      .product-item .listactions li.free-option {
        color: #008000;
        font-weight: bold; }
        .product-item .listactions li.free-option .icon {
          color: #008000; }
  .product-item .buttons-set {
    margin-top: 30px; }
    .product-item .buttons-set .btn {
      padding: 10px;
      width: calc(100% - 34px);
      margin: 0 auto;
      font-size: 2rem;
      font-weight: 500; }

.head-product {
  padding: 5px 0 0 0; }

.product-item-infos {
  padding: 30px 0 0 0;
  min-height: 145px; }

.product-name {
  font-size: 1.4rem; }

@media only screen and (max-width: 1100px) {
  .product-item .product-name {
    font-size: 2rem; }
  .product-item .price-box .price {
    font-size: 3rem; }
  .product-item .price-box .old-price .price {
    font-size: 2rem; }
  .product-item .buttons-set .button {
    width: 100%;
    padding: 0;
    font-size: 1.1rem; } }

@media only screen and (max-width: 800px) {
  .web-price {
    left: auto; }
    .web-price .web-price-content {
      width: 95px;
      height: 25px;
      line-height: 25px;
      border-radius: 0 0 0 5px;
      font-size: 1.3rem; }
  .product-item {
    width: auto;
    display: block;
    padding: 40px 20px;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    margin: 0 10px 40px 10px; }
    .product-item:last-child {
      margin: 0; }
    .product-item:hover {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      box-shadow: none; }
    .product-item .product-name {
      margin: 0 0 5px 0;
      font-size: 3rem; }
    .product-item .price-box {
      display: block;
      margin: 0 auto; }
      .product-item .price-box .price {
        font-size: 3.6rem; }
      .product-item .price-box span {
        display: block; }
      .product-item .price-box .price-including-tax * {
        display: inline-block; }
    .product-item .per-month {
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 3px 5px;
      font-size: 1.4rem; }
    .product-item .listactions {
      overflow-x: auto;
      padding: 15px 0 0;
      font-size: 0; }
      .product-item .listactions li {
        margin: 5px 0;
        padding-top: 0px;
        padding-left: 30px;
        display: inline-block;
        vertical-align: top;
        font-size: 1.6rem; }
        .product-item .listactions li .icon {
          top: 0;
          left: 0;
          display: block;
          margin: 0 auto 7px; }
        .product-item .listactions li:nth-child(odd) {
          background: none; }
        .product-item .listactions li.not-included {
          color: #9b9b9b; }
          .product-item .listactions li.not-included::before {
            left: 0; }
    .product-item .buttons-set {
      margin: 25px 0 0 0; }
  .slick-initialized .slick-slide.product-item {
    width: calc(100vw - 100px); }
    .slick-initialized .slick-slide.product-item:first-child {
      margin-left: 50px; }
  .head-product {
    padding: 5px 15px 15px;
    text-align: center; }
  .product-name {
    font-size: 1.4rem; } }

.catalog-category-view .category-description-block,
.catalogsearch-result-index .category-description-block {
  margin: 0 10px 20px;
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: 70% 0%; }
  .catalog-category-view .category-description-block > img,
  .catalogsearch-result-index .category-description-block > img {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1; }
  .catalog-category-view .category-description-block .std,
  .catalogsearch-result-index .category-description-block .std {
    color: #ffffff;
    font: 500 1.2rem;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 500px;
    z-index: 2; }
  .catalog-category-view .category-description-block .title,
  .catalogsearch-result-index .category-description-block .title {
    font-size: 2.5rem;
    padding: 63px 0 25px; }

.catalog-category-view .breadcrumbs,
.catalogsearch-result-index .breadcrumbs {
  border: none;
  padding: 15px 10px; }

.catalog-category-view .toolbar,
.catalogsearch-result-index .toolbar {
  height: 42px;
  margin: -42px 0 10px; }

.catalog-category-view .amshopby-filters-left,
.catalogsearch-result-index .amshopby-filters-left {
  background-color: #eeeeee;
  padding: 30px; }
  .catalog-category-view .amshopby-filters-left dt,
  .catalogsearch-result-index .amshopby-filters-left dt {
    display: none; }
  .catalog-category-view .amshopby-filters-left .amshopby-cat.level1:not(.has-child) a, .catalog-category-view .amshopby-filters-left .amshopby-cat.level2 a, .catalog-category-view .amshopby-filters-left .amshopby-cat.amshopby-cat-level-2 a,
  .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.level1:not(.has-child) a,
  .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.level2 a,
  .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.amshopby-cat-level-2 a {
    color: rgba(0, 0, 0, 0.6);
    font: normal 1.3rem; }
    .catalog-category-view .amshopby-filters-left .amshopby-cat.level1:not(.has-child) a:hover, .catalog-category-view .amshopby-filters-left .amshopby-cat.level2 a:hover, .catalog-category-view .amshopby-filters-left .amshopby-cat.amshopby-cat-level-2 a:hover,
    .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.level1:not(.has-child) a:hover,
    .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.level2 a:hover,
    .catalogsearch-result-index .amshopby-filters-left .amshopby-cat.amshopby-cat-level-2 a:hover {
      color: #000000; }
  .catalog-category-view .amshopby-filters-left .amshopby-cat a,
  .catalogsearch-result-index .amshopby-filters-left .amshopby-cat a {
    color: #000000;
    font: 600 1.6rem; }
  .catalog-category-view .amshopby-filters-left .amshopby-cat .count,
  .catalogsearch-result-index .amshopby-filters-left .amshopby-cat .count {
    display: none; }

.catalog-category-view .amshopby-container-top,
.catalogsearch-result-index .amshopby-container-top {
  display: table;
  margin: 0 0 30px;
  table-layout: fixed;
  width: 100%; }

.catalog-category-view .amshopby-item-top,
.catalogsearch-result-index .amshopby-item-top {
  display: table-cell;
  padding: 0 15px 0 0;
  position: relative; }
  .catalog-category-view .amshopby-item-top:last-child,
  .catalogsearch-result-index .amshopby-item-top:last-child {
    margin: 0;
    padding: 0; }
    .catalog-category-view .amshopby-item-top:last-child dd,
    .catalogsearch-result-index .amshopby-item-top:last-child dd {
      left: auto;
      right: 0; }
      .catalog-category-view .amshopby-item-top:last-child dd li,
      .catalogsearch-result-index .amshopby-item-top:last-child dd li {
        background: none;
        margin: 0;
        padding: 0 10px;
        width: 100%; }
  .catalog-category-view .amshopby-item-top dt,
  .catalogsearch-result-index .amshopby-item-top dt {
    border: 2px solid #8c8c8c;
    color: #000000;
    cursor: pointer;
    font: 500 1.2rem;
    padding: 8px 13px;
    position: relative;
    text-transform: uppercase;
    z-index: 10; }
    .catalog-category-view .amshopby-item-top dt.open,
    .catalogsearch-result-index .amshopby-item-top dt.open {
      border-bottom-color: #ffffff; }
      .catalog-category-view .amshopby-item-top dt.open:after,
      .catalogsearch-result-index .amshopby-item-top dt.open:after {
        top: 14px;
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
        transform: rotateX(180deg); }
    .catalog-category-view .amshopby-item-top dt:after,
    .catalogsearch-result-index .amshopby-item-top dt:after {
      background: url("../images/customdrop.png") no-repeat 0 0 transparent;
      content: '';
      display: inline-block;
      height: image_height("../images/customdrop.png");
      position: absolute;
      right: 0;
      top: 18px;
      width: image_width("../images/customdrop.png");
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
  .catalog-category-view .amshopby-item-top dd,
  .catalogsearch-result-index .amshopby-item-top dd {
    background-color: #ffffff;
    border: 2px solid #8c8c8c;
    display: none;
    left: 0;
    min-height: 0 !important;
    padding: 10px;
    position: absolute;
    top: 37px;
    width: 200%;
    z-index: 9; }
    .catalog-category-view .amshopby-item-top dd ol,
    .catalogsearch-result-index .amshopby-item-top dd ol {
      max-width: none; }
    .catalog-category-view .amshopby-item-top dd li,
    .catalogsearch-result-index .amshopby-item-top dd li {
      background-color: #eeeeee;
      display: inline-block;
      margin: 0 30px 25px 0;
      width: 28%; }
      .catalog-category-view .amshopby-item-top dd li:nth-child(3n),
      .catalogsearch-result-index .amshopby-item-top dd li:nth-child(3n) {
        margin: 0 0 25px; }
  .catalog-category-view .amshopby-item-top .amshopby-attr,
  .catalog-category-view .amshopby-item-top .amshopby-attr:hover,
  .catalogsearch-result-index .amshopby-item-top .amshopby-attr,
  .catalogsearch-result-index .amshopby-item-top .amshopby-attr:hover {
    background: none no-repeat 0 0;
    color: #000000;
    display: block;
    float: none;
    font: 600 1.1rem;
    height: 25px;
    padding: 0;
    line-height: 28px;
    text-align: center;
    vertical-align: middle; }
    .catalog-category-view .amshopby-item-top .amshopby-attr .count,
    .catalog-category-view .amshopby-item-top .amshopby-attr:hover .count,
    .catalogsearch-result-index .amshopby-item-top .amshopby-attr .count,
    .catalogsearch-result-index .amshopby-item-top .amshopby-attr:hover .count {
      display: none; }
  .catalog-category-view .amshopby-item-top .amshopby-attr-selected,
  .catalogsearch-result-index .amshopby-item-top .amshopby-attr-selected {
    height: 25px;
    line-height: 28px;
    padding: 0;
    width: 100%; }

.catalog-category-view .currently,
.catalogsearch-result-index .currently {
  margin: 0 0 30px;
  padding: 0 180px 0 0; }
  .catalog-category-view .currently li,
  .catalogsearch-result-index .currently li {
    display: inline-block;
    margin: 0 0 10px; }
  .catalog-category-view .currently a,
  .catalogsearch-result-index .currently a {
    cursor: pointer; }
  .catalog-category-view .currently .multiselect-child,
  .catalogsearch-result-index .currently .multiselect-child {
    background-color: #eeeeee;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    margin: 0 10px 0 0;
    padding: 0 5px; }
  .catalog-category-view .currently .single-select,
  .catalogsearch-result-index .currently .single-select {
    background-color: #eeeeee;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    margin: 0 10px 0 0;
    padding: 0 5px; }

.catalog-category-view .amshopby-filters-top .actions,
.catalogsearch-result-index .amshopby-filters-top .actions {
  position: absolute;
  right: 0;
  top: 75px; }
  .catalog-category-view .amshopby-filters-top .actions a,
  .catalogsearch-result-index .amshopby-filters-top .actions a {
    border-bottom: 1px solid transparent;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }

.filter-content-mob {
  border: 2px solid #8c8c8c;
  cursor: pointer;
  display: none;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 0 10px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .filter-content-mob.open {
    background: #eeeeee; }

.products-grid {
  margin: 0 -10px;
  position: relative; }
  .products-grid .sticky-products {
    right: 0; }
    .products-grid .sticky-products.with-operation {
      left: 0; }
      .products-grid .sticky-products.with-operation .operation {
        float: right; }
    .products-grid .sticky-products span {
      margin: 0; }

.btn-more-products {
  font-weight: bold;
  line-height: 35px; }
  .btn-more-products:before {
    background-color: #eeeeee;
    content: "+";
    display: block;
    height: 22px;
    line-height: 25px;
    margin: 0 auto;
    width: 22px;
    border-radius: 100%; }
  .btn-more-products:after {
    content: "\a004";
    color: #eeeeee;
    font-family: 'icons';
    font-size: 20px;
    font-weight: bold;
    display: block; }

.result-select-product {
  margin: 35px 0 100px; }

@media only screen and (max-width: 1100px) {
  .catalog-category-view .sidebar,
  .catalogsearch-result-index .sidebar {
    margin: 0 0 20px; } }

@media only screen and (max-width: 800px) {
  .catalog-category-view .main,
  .catalogsearch-result-index .main {
    max-width: 480px;
    padding: 20px 10px 0; }
  .catalog-category-view .category-description-block,
  .catalogsearch-result-index .category-description-block {
    margin: 0 -10px 20px; }
    .catalog-category-view .category-description-block .std,
    .catalogsearch-result-index .category-description-block .std {
      width: 100%; }
  .catalog-category-view .sidebar,
  .catalogsearch-result-index .sidebar {
    display: none; }
  .catalog-category-view .toolbar,
  .catalogsearch-result-index .toolbar {
    margin: 5px 0 20px; }
    .catalog-category-view .toolbar .sorter .sort-by,
    .catalogsearch-result-index .toolbar .sorter .sort-by {
      float: none; }
      .catalog-category-view .toolbar .sorter .sort-by label,
      .catalogsearch-result-index .toolbar .sorter .sort-by label {
        margin: 0 0 5px; }
      .catalog-category-view .toolbar .sorter .sort-by .customdropbg,
      .catalogsearch-result-index .toolbar .sorter .sort-by .customdropbg {
        width: 100%; }
  .catalog-category-view .amshopby-filters-top .block-container,
  .catalogsearch-result-index .amshopby-filters-top .block-container {
    display: none; }
  .catalog-category-view .amshopby-filters-top .actions,
  .catalogsearch-result-index .amshopby-filters-top .actions {
    top: 100%; }
  .catalog-category-view .amshopby-item-top,
  .catalogsearch-result-index .amshopby-item-top {
    display: block;
    margin: 0 0 10px;
    padding: 0;
    max-width: 100%; }
    .catalog-category-view .amshopby-item-top dt,
    .catalogsearch-result-index .amshopby-item-top dt {
      font-size: 1.3rem; }
      .catalog-category-view .amshopby-item-top dt.open,
      .catalogsearch-result-index .amshopby-item-top dt.open {
        font-weight: bold; }
    .catalog-category-view .amshopby-item-top dd,
    .catalogsearch-result-index .amshopby-item-top dd {
      background-color: #ffffff;
      border-top: none;
      max-height: 180px;
      overflow: auto;
      position: static;
      width: 100%;
      z-index: 15; }
      .catalog-category-view .amshopby-item-top dd li,
      .catalogsearch-result-index .amshopby-item-top dd li {
        background-color: transparent;
        display: block;
        margin: 0 0 10px;
        width: 100%; }
        .catalog-category-view .amshopby-item-top dd li:nth-child(3n),
        .catalogsearch-result-index .amshopby-item-top dd li:nth-child(3n) {
          margin: 0 0 10px; }
      .catalog-category-view .amshopby-item-top dd .amshopby-attr,
      .catalogsearch-result-index .amshopby-item-top dd .amshopby-attr {
        font-weight: 100;
        font-size: 1.2rem;
        text-align: left; }
        .catalog-category-view .amshopby-item-top dd .amshopby-attr:hover,
        .catalogsearch-result-index .amshopby-item-top dd .amshopby-attr:hover {
          text-align: left; }
  .catalog-category-view .category-products,
  .catalogsearch-result-index .category-products {
    padding: 10px 0 0; }
  .catalog-category-view .currently,
  .catalogsearch-result-index .currently {
    padding: 0 0 10px; }
    .catalog-category-view .currently .single-select, .catalog-category-view .currently .multiselect-child,
    .catalogsearch-result-index .currently .single-select,
    .catalogsearch-result-index .currently .multiselect-child {
      height: 40px;
      line-height: 42px;
      padding: 0 20px; }
  .catalog-category-view .actions,
  .catalogsearch-result-index .actions {
    top: 100%; }
  .filter-content-mob {
    display: block; } }

.catalog-product-view .product-top-left {
  float: left;
  width: 60%;
  padding: 0 20px 0 0; }

.catalog-product-view .product-top-right,
.catalog-product-view .product-name-container {
  float: right;
  width: 40%; }

.catalog-product-view .product-top-details {
  margin: 0 0 20px; }

.catalog-product-view .product-img-box {
  position: relative;
  text-align: right; }
  .catalog-product-view .product-img-box .product-image {
    padding: 0 0 0 100px; }
    .catalog-product-view .product-img-box .product-image a {
      opacity: 0;
      -webkit-transition: all 350ms ease-in-out;
      -moz-transition: all 350ms ease-in-out;
      transition: all 350ms ease-in-out;
      display: block;
      visibility: hidden;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0; }
      .catalog-product-view .product-img-box .product-image a.is-active {
        position: relative;
        z-index: 2;
        opacity: 1;
        visibility: visible; }
  .catalog-product-view .product-img-box .more-views {
    left: 0;
    position: absolute;
    text-align: center;
    top: 0; }
  .catalog-product-view .product-img-box .more-views-item a {
    border: 2px solid #eeeeee;
    display: inline-block;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .catalog-product-view .product-img-box .more-views-item a:hover {
      border: 2px solid #000000; }
    .catalog-product-view .product-img-box .more-views-item a.is-active {
      border: 2px solid #000000; }
  .catalog-product-view .product-img-box .btn-zoom {
    font-size: 2.4rem;
    margin: 10px 0 0; }

.catalog-product-view .product-name-container .product-name {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 30px;
  margin: 0 0 10px; }

.catalog-product-view .product-cart-sku {
  color: #eeeeee;
  font-weight: 500;
  font-size: 1.2rem; }

.catalog-product-view .product-price {
  margin: 20px 0 15px; }
  .catalog-product-view .product-price .price {
    font-size: 2.6rem; }

.catalog-product-view .short-description {
  margin: 0 0 20px;
  line-height: 20px; }

.catalog-product-view .product-shop .product-options {
  margin: 0 0 20px; }
  .catalog-product-view .product-shop .product-options label {
    text-transform: none;
    font-size: 1.2rem; }

.catalog-product-view .product-shop .add-to-cart {
  margin: 0 0 20px; }
  .catalog-product-view .product-shop .add-to-cart .customdropbg {
    width: 75px;
    display: inline-block;
    margin: 0 20px 10px 0; }
    .catalog-product-view .product-shop .add-to-cart .customdropbg .customdroptext {
      background-color: #ffffff;
      border: 1px solid #8c8c8c;
      padding: 0 25px; }

.catalog-product-view .product-shop .btn-cart {
  display: inline-block;
  margin: 0 0 10px;
  width: auto;
  letter-spacing: 1px; }

.catalog-product-view .sticky-products {
  right: 0; }
  .catalog-product-view .sticky-products span {
    margin-right: 0px; }

.catalog-product-view .size-content {
  margin: 0 0 20px; }
  .catalog-product-view .size-content li {
    border: 2px solid #000000;
    display: inline-block;
    font-weight: bold;
    height: 25px;
    line-height: 24px;
    margin: 0 10px 0 0;
    padding: 0 5px;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .catalog-product-view .size-content li:hover {
      background-color: #000000;
      color: #ffffff; }
    .catalog-product-view .size-content li.active {
      background-color: #000000;
      color: #ffffff; }

.catalog-product-view .colors-content {
  margin: 0 0 30px; }

.catalog-product-view .colors-list {
  margin: 10px 0 0; }
  .catalog-product-view .colors-list li {
    color: #eeeeee;
    display: inline-block;
    font-weight: bold;
    margin: 0 15px 0 0;
    position: relative; }
  .catalog-product-view .colors-list img {
    border: 2px solid transparent;
    margin: 0 5px 0 0;
    vertical-align: middle;
    padding: 5px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .catalog-product-view .colors-list a {
    color: rgba(0, 0, 0, 0.6); }
    .catalog-product-view .colors-list a:hover {
      color: #000000; }
    .catalog-product-view .colors-list a.active img,
    .catalog-product-view .colors-list a:hover img {
      border-color: #000000; }

.catalog-product-view .availability {
  font-size: 1.5rem;
  padding: 10px;
  position: relative; }
  .catalog-product-view .availability .icon {
    border: 1px solid;
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    left: 10px;
    top: 15px; }

.catalog-product-view .product-stock {
  background: white;
  border: 1px solid #66cc9b;
  color: #66cc9b; }

.catalog-product-view .product-out-of-stock {
  background: white;
  border: 1px solid #ef604e;
  color: #ef604e; }

.catalog-product-view .availability-content {
  font-weight: bold;
  line-height: 20px;
  padding: 0 0 0 40px; }
  .catalog-product-view .availability-content span {
    display: block;
    font-weight: normal;
    font-size: 1.2rem; }

.catalog-product-view .product-choice-store {
  border: 1px solid #8c8c8c;
  padding: 20px; }
  .catalog-product-view .product-choice-store .block-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 20px;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .catalog-product-view .product-choice-store .icon {
    font-size: 2.2rem; }
  .catalog-product-view .product-choice-store .button {
    display: inline-block;
    margin: 0 0 10px;
    vertical-align: middle; }
  .catalog-product-view .product-choice-store .block-zipcode {
    display: inline-block;
    margin: 0 0 10px;
    vertical-align: top; }
  .catalog-product-view .product-choice-store span {
    color: #eeeeee;
    display: inline-block;
    margin: 0 5px;
    text-transform: uppercase; }
  .catalog-product-view .product-choice-store input {
    background: #eeeeee;
    border: 1px solid #8c8c8c;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    text-transform: uppercase; }
  .catalog-product-view .product-choice-store input[type="submit"] {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
    cursor: pointer;
    margin: 0 0 0 -5px;
    width: 40px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .catalog-product-view .product-choice-store input[type="submit"]:hover {
      background: #eeeeee;
      color: #000000; }

.catalog-product-view .popin-store-stock {
  display: none;
  max-width: 500px;
  margin: auto; }
  .catalog-product-view .popin-store-stock .h2-title {
    text-align: center;
    margin: 0 0 40px; }
  .catalog-product-view .popin-store-stock .store-item {
    background-color: #eeeeee;
    margin: 20px 0;
    height: 40px;
    line-height: 40px;
    padding: 0 210px 0 20px;
    position: relative; }
  .catalog-product-view .popin-store-stock .button {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px; }

.catalog-product-view .popin-store-stock-content {
  margin: 0 0 35px; }

.catalog-product-view .social-content {
  margin: 20px 0 0; }
  .catalog-product-view .social-content .social-link {
    border: 1px solid;
    color: #eeeeee;
    display: inline-block;
    font-size: 15px;
    margin: 0 15px 0 0;
    line-height: 34px;
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 100%; }
    .catalog-product-view .social-content .social-link:hover {
      color: #000000; }
  .catalog-product-view .social-content .icon_facebook:before {
    margin: 0 0 0 6px; }

.catalog-product-view .product-bottom-details {
  margin: 0 -10px;
  line-height: 20px; }
  .catalog-product-view .product-bottom-details .description, .catalog-product-view .product-bottom-details .composition {
    padding: 0 10px;
    float: left;
    width: 50%; }
    .catalog-product-view .product-bottom-details .description .content, .catalog-product-view .product-bottom-details .composition .content {
      background: #eeeeee;
      padding: 40px 12%;
      min-height: 200px; }

.catalog-product-view .box-up-sell {
  margin: 30px -5px; }
  .catalog-product-view .box-up-sell .products-grid {
    margin: 0; }

.catalog-product-view .discoveralso .h2-title {
  text-align: center; }

.catalog-product-view .discoveralso .discoveralso-product {
  position: relative; }
  .catalog-product-view .discoveralso .discoveralso-product strong {
    bottom: 15px;
    color: #ffffff;
    display: block;
    font: 500 2.5rem;
    left: 0;
    line-height: 30px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2; }

.catalog-product-view .box-collateral .h2-title {
  text-align: center; }

.catalog-product-view .products-grid {
  width: 100%; }

@media only screen and (max-width: 1100px) {
  .catalog-product-view .product-img-box .product-image {
    padding: 0 0 0 75px; }
  .catalog-product-view .product-img-box .more-views-item a {
    width: 70px; }
  .catalog-product-view .product-img-box .btn-zoom {
    left: -50px;
    top: 360px; } }

@media only screen and (max-width: 768px) {
  .catalog-product-view .discoveralso .discoveralso-product strong {
    font-size: 2rem; } }

@media only screen and (max-width: 800px) {
  .catalog-product-view .product-name-container {
    text-align: center;
    margin: 0 0 30px; }
  .catalog-product-view .product-top-left {
    float: none;
    width: 100%; }
  .catalog-product-view .product-top-right,
  .catalog-product-view .product-name-container {
    float: none;
    width: 100%; }
  .catalog-product-view .product-img-box .product-image {
    padding: 0; }
  .catalog-product-view .product-img-box .more-views {
    position: static; }
  .catalog-product-view .product-img-box .more-views-list {
    text-align: center; }
  .catalog-product-view .product-img-box .more-views-item {
    display: inline-block;
    margin: 0 5px; }
    .catalog-product-view .product-img-box .more-views-item a {
      width: 50px; }
  .catalog-product-view .product-img-box .btn-zoom {
    display: none; }
  .catalog-product-view .product-price {
    text-align: center;
    margin: 20px 0; }
  .catalog-product-view .product-shop .add-to-cart .customdropbg {
    display: block;
    float: none;
    margin: 0 0 20px 0;
    width: 100px; }
  .catalog-product-view .product-shop .btn-cart {
    display: block;
    height: 50px;
    width: 100%; }
  .catalog-product-view .product-bottom-details .description, .catalog-product-view .product-bottom-details .composition {
    padding: 0;
    float: none;
    width: 100%; }
    .catalog-product-view .product-bottom-details .description .content, .catalog-product-view .product-bottom-details .composition .content {
      background: #eeeeee;
      padding: 40px 12%;
      min-height: 200px; }
  .catalog-product-view .box-collateral {
    max-width: 480px;
    margin: 30px auto; }
  .catalog-product-view .discoveralso .discoveralso-product {
    margin: 0 0 20px; } }

.price-box .special-price {
  display: inline-block; }

.price-box .old-price {
  display: inline-block;
  margin: 0 0 0 10px; }
  .price-box .old-price .price {
    text-decoration: line-through;
    font-size: 2.2rem;
    color: #373737;
    font-weight: normal; }

.price-box .price {
  font-weight: 600;
  font-size: 2rem; }

.checkout-cart-index .page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

.checkout-cart-index .cart-empty {
  background-color: #dddddd;
  max-width: 700px;
  margin: 100px auto 150px;
  padding: 20px;
  line-height: 20px; }
  .checkout-cart-index .cart-empty p a {
    text-decoration: underline; }

.checkout-cart-index .wishlist-banner {
  background: url(../images/banners/wishlist-banner.jpg) 50% 0 no-repeat; }

.checkout-cart-index .page-title.page-empty-title {
  max-width: 700px;
  margin: 0 auto;
  text-align: left; }

.checkout-cart-index .cart .data-table {
  margin: 50px 0 0; }
  .checkout-cart-index .cart .data-table thead th {
    border-bottom: 2px solid #000000;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px 20px;
    text-transform: uppercase; }
  .checkout-cart-index .cart .data-table tbody td {
    border-bottom: 1px solid #000000;
    padding: 5px 10px; }
    .checkout-cart-index .cart .data-table tbody td.last {
      text-align: right; }
  .checkout-cart-index .cart .data-table tbody .product-image {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle; }
  .checkout-cart-index .cart .data-table tbody .product-name-content {
    display: inline-block;
    vertical-align: middle; }
  .checkout-cart-index .cart .data-table tbody .product-name {
    font-weight: bold; }
  .checkout-cart-index .cart .data-table tbody .product-sku {
    color: #424242; }
  .checkout-cart-index .cart .data-table tbody .color-content {
    color: #424242; }
    .checkout-cart-index .cart .data-table tbody .color-content img {
      margin: 0 10px 0 0;
      border-radius: 100%; }
  .checkout-cart-index .cart .data-table tbody .item-options {
    border: 2px solid #000000;
    display: inline-block;
    font-weight: bold;
    height: 25px;
    line-height: 24px;
    padding: 0 5px; }
  .checkout-cart-index .cart .data-table tbody .qty .customdroptext {
    background-color: #ffffff;
    border: 1px solid #8c8c8c; }
  .checkout-cart-index .cart .data-table tbody .price-unit {
    font-weight: bold; }
  .checkout-cart-index .cart .data-table tbody .stock-content {
    font-weight: bold; }
    .checkout-cart-index .cart .data-table tbody .stock-content .in-stock {
      color: #66cc9b; }
    .checkout-cart-index .cart .data-table tbody .stock-content .out-of-stock {
      color: #ef604e; }
  .checkout-cart-index .cart .data-table tbody .btn-remove {
    border: 2px solid #000000;
    font: 500 1.8rem;
    display: block;
    height: 32px;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    width: 32px;
    border-radius: 50%;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
    .checkout-cart-index .cart .data-table tbody .btn-remove:hover {
      border-color: rgba(0, 0, 0, 0.5); }

.checkout-cart-index .cart .total-container {
  float: right;
  width: 50%; }
  .checkout-cart-index .cart .total-container .shopping-cart-totals-table {
    margin: 0 0 20px; }
    .checkout-cart-index .cart .total-container .shopping-cart-totals-table tr td {
      border-bottom: 1px solid #000000; }
    .checkout-cart-index .cart .total-container .shopping-cart-totals-table tr.grand_total-total td {
      background-color: #424242; }

.checkout-cart-index .cart .buttons-set {
  margin: 0 0 25px; }

.checkout-cart-index .block-favorite-store {
  background: #eeeeee;
  display: inline-block;
  min-width: 440px;
  min-height: 150px;
  padding: 30px 30px 25px 70px;
  position: relative;
  vertical-align: middle;
  width: 40%; }
  .checkout-cart-index .block-favorite-store .icon {
    font-size: 30px;
    position: absolute;
    left: 30px;
    top: 20px; }
  .checkout-cart-index .block-favorite-store .button {
    letter-spacing: 1px;
    position: absolute;
    right: 30px;
    top: 30px;
    text-transform: uppercase; }

.checkout-cart-index .no-favorite-store-footer {
  position: relative; }

.checkout-cart-index .cart-links {
  display: inline-block;
  padding: 0 0 0 5%;
  text-align: center;
  vertical-align: middle;
  width: 50%; }

.checkout-cart-index .print-cart {
  display: inline-block;
  margin: 5px 10px;
  width: 250px; }
  .checkout-cart-index .print-cart .icon {
    margin: 5px 10px 0 0; }

.checkout-cart-index .fb-share-cart {
  display: inline-block;
  margin: 5px 10px;
  width: 250px; }
  .checkout-cart-index .fb-share-cart .icon {
    margin: 5px 0 0 5px; }

@media only screen and (max-width: 1100px) {
  .checkout-cart-index .cart-links {
    width: 300px; } }

@media only screen and (max-width: 800px) {
  .checkout-cart-index .block-favorite-store {
    margin: 0 0 50px;
    width: 100%; }
  .checkout-cart-index .cart-links {
    text-align: right;
    width: 100%; }
  .checkout-cart-index .print-cart {
    margin: 0 10px 0 0; }
  .checkout-cart-index .fb-share-cart {
    margin: 0; } }

@media only screen and (max-width: 800px) {
  .checkout-cart-index .cart .data-table {
    display: block;
    overflow: auto; }
  .checkout-cart-index .wishlist-banner {
    margin-bottom: 20px; } }

@media only screen and (max-width: 600px) {
  .checkout-cart-index .block-favorite-store {
    min-width: 1px; }
    .checkout-cart-index .block-favorite-store .button {
      position: static; }
  .checkout-cart-index .cart-links {
    padding: 0; }
  .checkout-cart-index .print-cart, .checkout-cart-index .fb-share-cart {
    display: block;
    margin: 10px auto 0; } }

.easyentretien-checkout-steps {
  min-height: 100vh;
  background-color: #eeeeee;
  /* .summary {
    width: 400px;
  } */ }
  .easyentretien-checkout-steps .column.main {
    min-height: 500px; }
  .easyentretien-checkout-steps .page-header {
    border-bottom: 2px solid #ef604e; }
    @media only screen and (max-width: 1150px) {
      .easyentretien-checkout-steps .page-header .panel-right {
        display: block; } }
    @media only screen and (max-width: 992px) and (min-width: 801px) {
      .easyentretien-checkout-steps .page-header .infos .icon {
        display: inline-block; } }
  .easyentretien-checkout-steps .panel.wrapper {
    background: none;
    box-shadow: none;
    border: none; }
  .easyentretien-checkout-steps .panel.header {
    max-width: 1500px;
    align-items: center;
    height: auto;
    margin: 0 auto;
    padding: 0 10px; }
    .easyentretien-checkout-steps .panel.header .phone-mob {
      display: block;
      top: 20px; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .panel.header .phone-mob {
          display: none; } }
    @media (min-width: 1025px) {
      .easyentretien-checkout-steps .panel.header {
        display: grid;
        grid-template-columns: repeat(3, 1fr); } }
    .easyentretien-checkout-steps .panel.header .panel-left,
    .easyentretien-checkout-steps .panel.header .panel-center {
      display: flex;
      align-items: center; }
    .easyentretien-checkout-steps .panel.header .panel-center p,
    .easyentretien-checkout-steps .panel.header .panel-left .reviews-widget {
      margin-left: 25px; }
    .easyentretien-checkout-steps .panel.header .panel-center {
      font-size: 2rem; }
      @media (max-width: 1024px) {
        .easyentretien-checkout-steps .panel.header .panel-center {
          display: none; } }
      @media (min-width: 1025px) {
        .easyentretien-checkout-steps .panel.header .panel-center {
          margin: 0 auto;
          grid-column-start: 2;
          grid-column-end: 3; } }
      .easyentretien-checkout-steps .panel.header .panel-center .technician-logo svg {
        max-width: 33px;
        max-height: 32px; }
    .easyentretien-checkout-steps .panel.header .panel-right .packages,
    .easyentretien-checkout-steps .panel.header .panel-right .packages-link,
    .easyentretien-checkout-steps .panel.header .panel-right .sponsorship-link,
    .easyentretien-checkout-steps .panel.header .panel-right .account_conainer {
      display: none; }
    .easyentretien-checkout-steps .panel.header .panel-right .infos {
      display: flex;
      justify-content: center; }
      @media (max-width: 800px) {
        .easyentretien-checkout-steps .panel.header .panel-right .infos {
          display: none; } }
  .easyentretien-checkout-steps .page-main {
    margin: 0 auto; }
  .easyentretien-checkout-steps .footer-checkout {
    clear: both;
    max-width: 1240px;
    margin: 0 auto;
    padding: 45px 10px; }
    .easyentretien-checkout-steps .footer-checkout .logo {
      width: 160px; }
    .easyentretien-checkout-steps .footer-checkout .panel-right {
      float: right;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 1.4rem;
      margin: 0;
      color: #8c8c8c; }
      .easyentretien-checkout-steps .footer-checkout .panel-right a {
        background: none;
        border: none;
        color: #ef604e;
        margin: 0;
        padding: 0 0 0 5px;
        text-transform: none; }
  .easyentretien-checkout-steps .btn__back {
    color: #373737;
    background-color: transparent; }
  .easyentretien-checkout-steps .content-steps-eligibility,
  .easyentretien-checkout-steps .content-steps-options,
  .easyentretien-checkout-steps .content-steps-appointment {
    position: relative; }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .content-steps-eligibility,
      .easyentretien-checkout-steps .content-steps-options,
      .easyentretien-checkout-steps .content-steps-appointment {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding: 50px 0; }
        .easyentretien-checkout-steps .content-steps-eligibility .steps-item-container,
        .easyentretien-checkout-steps .content-steps-options .steps-item-container,
        .easyentretien-checkout-steps .content-steps-appointment .steps-item-container {
          width: 15%; } }
    @media (min-width: 1025px) {
      .easyentretien-checkout-steps .content-steps-eligibility,
      .easyentretien-checkout-steps .content-steps-options,
      .easyentretien-checkout-steps .content-steps-appointment {
        gap: 20px; } }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .content-steps-eligibility .eligibility-content,
      .easyentretien-checkout-steps .content-steps-eligibility .options-content,
      .easyentretien-checkout-steps .content-steps-eligibility .appointment-content,
      .easyentretien-checkout-steps .content-steps-options .eligibility-content,
      .easyentretien-checkout-steps .content-steps-options .options-content,
      .easyentretien-checkout-steps .content-steps-options .appointment-content,
      .easyentretien-checkout-steps .content-steps-appointment .eligibility-content,
      .easyentretien-checkout-steps .content-steps-appointment .options-content,
      .easyentretien-checkout-steps .content-steps-appointment .appointment-content {
        width: 45%; } }
    @media (min-width: 1025px) {
      .easyentretien-checkout-steps .content-steps-eligibility .eligibility-content,
      .easyentretien-checkout-steps .content-steps-eligibility .options-content,
      .easyentretien-checkout-steps .content-steps-eligibility .appointment-content,
      .easyentretien-checkout-steps .content-steps-options .eligibility-content,
      .easyentretien-checkout-steps .content-steps-options .options-content,
      .easyentretien-checkout-steps .content-steps-options .appointment-content,
      .easyentretien-checkout-steps .content-steps-appointment .eligibility-content,
      .easyentretien-checkout-steps .content-steps-appointment .options-content,
      .easyentretien-checkout-steps .content-steps-appointment .appointment-content {
        width: 55%; } }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .content-steps-eligibility .summary,
      .easyentretien-checkout-steps .content-steps-options .summary,
      .easyentretien-checkout-steps .content-steps-appointment .summary {
        width: 35%; } }
    @media (min-width: 1025px) {
      .easyentretien-checkout-steps .content-steps-eligibility .summary,
      .easyentretien-checkout-steps .content-steps-options .summary,
      .easyentretien-checkout-steps .content-steps-appointment .summary {
        width: 25%; } }
  .easyentretien-checkout-steps .steps-item {
    font-size: 1.4rem; }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .steps-item {
        margin: 50px 0; } }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .steps-item__action {
        margin-bottom: 50px; } }
    .easyentretien-checkout-steps .steps-item .stepItem-container {
      display: flex;
      position: relative; }
      @media screen and (max-width: 1024px) {
        .easyentretien-checkout-steps .steps-item .stepItem-container {
          text-align: center; } }
      @media only screen and (max-width: 1024px) and (min-width: 800px) {
        .easyentretien-checkout-steps .steps-item .stepItem-container {
          display: block; } }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .steps-item .stepItem-container {
          gap: 10px; } }
      .easyentretien-checkout-steps .steps-item .stepItem-container .stepItem__link {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }
    .easyentretien-checkout-steps .steps-item.active {
      margin: 25px 0; }
      .easyentretien-checkout-steps .steps-item.active .stepItem-container .step-number {
        background: #ef604e;
        border: 1px solid #ef604e;
        color: #ffffff; }
      .easyentretien-checkout-steps .steps-item.active .stepItem-container .step-label {
        color: #ef604e; }
    .easyentretien-checkout-steps .steps-item.passed {
      margin: 25px 0; }
      .easyentretien-checkout-steps .steps-item.passed .stepItem-container .step-number {
        background: #66cc9b;
        border: 1px solid #66cc9b;
        color: #ffffff; }
      .easyentretien-checkout-steps .steps-item.passed .stepItem-container .step-label {
        color: #66cc9b; }
    .easyentretien-checkout-steps .steps-item .stepItem__number {
      border-radius: 100%;
      border: 1px solid #CCCCCC;
      color: #ffffff;
      background-color: #CCCCCC;
      display: inline-block;
      font-family: "Roboto", sans-serif;
      height: 25px;
      line-height: 25px;
      width: 25px;
      text-align: center; }
    .easyentretien-checkout-steps .steps-item .stepItem__infos {
      font-family: "Roboto", sans-serif; }
      .easyentretien-checkout-steps .steps-item .stepItem__infos .step-label {
        color: #373737;
        line-height: 2.5rem; }
      .easyentretien-checkout-steps .steps-item .stepItem__infos .step-answer {
        position: relative;
        margin: 10px 0;
        padding: 5px 20px 5px 5px;
        color: #373737;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 6px; }
        .easyentretien-checkout-steps .steps-item .stepItem__infos .step-answer::after {
          content: '';
          position: absolute;
          right: 5px;
          top: 5px;
          width: 10px;
          height: 10px;
          background-image: url("../images/rewrite.svg");
          background-repeat: no-repeat;
          background-size: cover; }
  .easyentretien-checkout-steps .steps-main-content .h1-subtitle {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.1rem;
    color: #ef604e; }
  .easyentretien-checkout-steps .steps-main-content .h1-title {
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd; }
  .easyentretien-checkout-steps .steps-main-content .h2-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 10px; }
  .easyentretien-checkout-steps .steps-main-content .h2-subtitle {
    font-size: 1.4rem;
    color: #8c8c8c;
    margin: 0; }
  .easyentretien-checkout-steps .steps-main-content form .button {
    height: 55px;
    line-height: 55px;
    margin: 20px auto 0;
    width: 100%;
    max-width: 300px; }
  .easyentretien-checkout-steps .steps-main-content .icon-scroll {
    margin: 0;
    top: -15px; }
  .easyentretien-checkout-steps .list-choice {
    font-size: 0;
    margin: 20px 0; }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .list-choice {
        display: flex;
        justify-content: space-between;
        gap: 20px; } }
    .easyentretien-checkout-steps .list-choice .list-item {
      cursor: pointer;
      flex: 1;
      position: relative;
      text-align: center;
      padding: 25px;
      font-size: 1.4rem;
      border: 2px solid #dddddd;
      border-radius: 6px;
      -webkit-transition: all 250ms linear;
      -moz-transition: all 250ms linear;
      transition: all 250ms linear; }
      .easyentretien-checkout-steps .list-choice .list-item:hover {
        border: 2px solid #ef604e; }
      .easyentretien-checkout-steps .list-choice .list-item.active {
        border: 2px solid #65CC9B; }
        .easyentretien-checkout-steps .list-choice .list-item.active:after {
          background-image: url("../images/green_check.png");
          background-size: cover;
          content: "";
          height: 15px;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 15px; }
        .easyentretien-checkout-steps .list-choice .list-item.active .item-label {
          color: #000000; }
      .easyentretien-checkout-steps .list-choice .list-item .list-item-content {
        background: #ffffff;
        display: block;
        margin: 0 auto 20px;
        position: relative;
        min-height: 57px; }
    @media (max-width: 1024px) {
      .easyentretien-checkout-steps .list-choice.tax-list {
        display: block; }
        .easyentretien-checkout-steps .list-choice.tax-list .list-item {
          margin: 10px 0; } }
    @media (min-width: 1025px) {
      .easyentretien-checkout-steps .list-choice.tax-list .list-item {
        width: 30%; } }
  .easyentretien-checkout-steps .select-choice {
    cursor: pointer;
    position: relative;
    min-width: calc(50% - 15px);
    height: auto;
    padding: 25px;
    text-align: center;
    font-size: 1.4rem;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    transition: all 250ms linear; }
    .easyentretien-checkout-steps .select-choice.active {
      border: 2px solid #65CC9B; }
  .easyentretien-checkout-steps .item-label {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    color: #373737; }
  .easyentretien-checkout-steps .title-presentation {
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    color: #373737;
    margin: 30px 0; }
  .easyentretien-checkout-steps .back-content {
    color: #8c8c8c;
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem; }
    .easyentretien-checkout-steps .back-content .back {
      cursor: pointer;
      text-decoration: underline; }
  .easyentretien-checkout-steps #summary-col {
    -webkit-transition: top 0.3s linear;
    -moz-transition: top 0.3s linear;
    transition: top 0.3s linear;
    max-width: 400px; }
    .easyentretien-checkout-steps #summary-col.stick {
      margin-top: 0;
      position: fixed;
      top: 20px;
      z-index: 10;
      /* width: 100%; */ }
      .easyentretien-checkout-steps #summary-col.stick.pos-fixed {
        position: fixed;
        /* width: 100%; */ }
  .easyentretien-checkout-steps .summary-content {
    margin: 0 10px 0 0;
    max-width: 400px;
    /* position: absolute; */
    background-color: #ffffff;
    top: 50px;
    width: 100%; }
    .easyentretien-checkout-steps .summary-content .title-cart-summary {
      margin: 25px 0;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      color: #373737;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      background-color: #eeeeee;
      border-radius: 6px; }
    .easyentretien-checkout-steps .summary-content .title-cart-summary .label {
      font-size: 1.6rem;
      max-width: 60%; }
    .easyentretien-checkout-steps .summary-content .options-list {
      margin: 25px 0;
      padding: 10px;
      border: 1px solid #dddddd;
      border-radius: 6px; }
    .easyentretien-checkout-steps .summary-content .prorata-price__container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .easyentretien-checkout-steps .summary-content .prorata-price__label, .easyentretien-checkout-steps .summary-content .prorata-price__amount {
      font-weight: 600; }
  .easyentretien-checkout-steps .block-products-list.grid {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
    .easyentretien-checkout-steps .block-products-list.grid.visible {
      display: flex; }
    .easyentretien-checkout-steps .block-products-list.grid .push-product-content {
      max-width: 1400px;
      background-color: #ffffff; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .block-products-list.grid .push-product-content {
          overflow: auto;
          height: 90vh;
          width: 95%;
          padding: 50px 15px;
          border-radius: 12px; } }
      @media (min-width: 1025px) {
        .easyentretien-checkout-steps .block-products-list.grid .push-product-content {
          width: 90%;
          padding: 50px; } }
      .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header {
        position: relative;
        margin: 25px 0;
        text-align: center;
        font-size: 2.5rem;
        color: #ef604e; }
        @media (min-width: 801px) {
          .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header {
            font-size: 3.6rem; } }
        .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header span {
          cursor: pointer;
          position: absolute;
          margin-left: auto;
          font-size: 1.6rem;
          color: #8c8c8c;
          text-decoration: underline; }
          @media (min-width: 801px) {
            .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header span {
              top: 50%;
              right: 0;
              transform: translateY(-50%); } }
      @media (min-width: 800px) {
        .easyentretien-checkout-steps .block-products-list.grid .push-product-content .product-items.widget-product-grid-mobile {
          display: none; } }
  .easyentretien-checkout-steps .eligibility-content .title-presentation {
    max-width: 465px; }
  @media screen and (max-width: 800px) {
    .easyentretien-checkout-steps .eligibility-content .info-block {
      padding: 20px 15px; }
      .easyentretien-checkout-steps .eligibility-content .info-block::before {
        top: 15px;
        transform: translateY(0); }
      .easyentretien-checkout-steps .eligibility-content .info-block .info-block__text {
        text-indent: 25px;
        padding: 0; } }
  .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility {
    position: relative;
    z-index: 2;
    margin-bottom: 10px; }
    @media (min-width: 801px) {
      .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility {
        margin-bottom: 25px; } }
    .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility .zipcode {
      width: 100%; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility .zipcode {
          padding: 25px 0; } }
    .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility input[type="text"] {
      background-color: #eeeeee;
      border: 1px solid #dddddd;
      border-radius: 6px;
      color: #373737;
      font-family: "Roboto", sans-serif;
      font-size: 1.8rem;
      margin-top: 10px;
      padding: 20px;
      width: 100%; }
      .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility input[type="text"].valid {
        border-bottom: 2px solid #66cc9b !important; }
        .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility input[type="text"].valid:-webkit-autofill {
          border: none !important;
          border-bottom: 2px solid #66cc9b !important; }
      .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility input[type="text"]:-webkit-autofill {
        border: none !important;
        border-bottom: 2px solid #373737 !important;
        -webkit-box-shadow: 0 0 0 1000px #eeeeee inset;
        -webkit-text-fill-color: #000; }
    .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility span {
      display: block;
      margin: 10px 0;
      color: #ef604e; }
      .easyentretien-checkout-steps .eligibility-content .check-zipcode-eligibility span.success {
        padding: 15px;
        font-weight: 500;
        color: #ffffff;
        background-color: #72C070;
        border-radius: 6px; }
  .easyentretien-checkout-steps .eligibility-content #customer_profil {
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: translateY(-20%);
    transition: all .4s ease-in-out; }
    .easyentretien-checkout-steps .eligibility-content #customer_profil.show {
      opacity: 1;
      transform: translateY(0); }
  @media (min-width: 801px) {
    .easyentretien-checkout-steps .options-content .products-type_list {
      padding: 50px 0 15px; } }
  .easyentretien-checkout-steps .options-content .products-type_list,
  .easyentretien-checkout-steps .options-content .units_ext-list,
  .easyentretien-checkout-steps .options-content .equipment_age-list,
  .easyentretien-checkout-steps .options-content .options-list {
    position: relative; }
    .easyentretien-checkout-steps .options-content .products-type_list .list-choice,
    .easyentretien-checkout-steps .options-content .units_ext-list .list-choice,
    .easyentretien-checkout-steps .options-content .equipment_age-list .list-choice,
    .easyentretien-checkout-steps .options-content .options-list .list-choice {
      flex-wrap: wrap;
      gap: 0; }
    .easyentretien-checkout-steps .options-content .products-type_list .list-item,
    .easyentretien-checkout-steps .options-content .units_ext-list .list-item,
    .easyentretien-checkout-steps .options-content .equipment_age-list .list-item,
    .easyentretien-checkout-steps .options-content .options-list .list-item {
      margin-right: 15px;
      padding: 20px 40px;
      text-align: start; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .options-content .products-type_list .list-item,
        .easyentretien-checkout-steps .options-content .units_ext-list .list-item,
        .easyentretien-checkout-steps .options-content .equipment_age-list .list-item,
        .easyentretien-checkout-steps .options-content .options-list .list-item {
          display: flex;
          align-items: center;
          flex: 1; } }
      @media screen and (max-width: 1024px) {
        .easyentretien-checkout-steps .options-content .products-type_list .list-item,
        .easyentretien-checkout-steps .options-content .units_ext-list .list-item,
        .easyentretien-checkout-steps .options-content .equipment_age-list .list-item,
        .easyentretien-checkout-steps .options-content .options-list .list-item {
          display: block;
          text-align: center; }
          .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content {
            margin: 0 0 10px; } }
      .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content,
      .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content,
      .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content,
      .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content {
        min-height: 0;
        margin: 0 20px 0 0; }
        @media screen and (max-width: 1024px) {
          .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content {
            margin: 0 0 10px; } }
        @media only screen and (max-width: 800px) {
          .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content,
          .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content {
            margin: 0 20px 0 0; } }
        .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content img,
        .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content img,
        .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content img,
        .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content img {
          max-width: 50px;
          padding: 0; }
          @media (min-width: 801px) {
            .easyentretien-checkout-steps .options-content .products-type_list .list-item .list-item-content img,
            .easyentretien-checkout-steps .options-content .units_ext-list .list-item .list-item-content img,
            .easyentretien-checkout-steps .options-content .equipment_age-list .list-item .list-item-content img,
            .easyentretien-checkout-steps .options-content .options-list .list-item .list-item-content img {
              max-width: 40px; } }
    .easyentretien-checkout-steps .options-content .products-type_list:before,
    .easyentretien-checkout-steps .options-content .units_ext-list:before,
    .easyentretien-checkout-steps .options-content .equipment_age-list:before,
    .easyentretien-checkout-steps .options-content .options-list:before {
      top: 0; }
    .easyentretien-checkout-steps .options-content .products-type_list:after,
    .easyentretien-checkout-steps .options-content .units_ext-list:after,
    .easyentretien-checkout-steps .options-content .equipment_age-list:after,
    .easyentretien-checkout-steps .options-content .options-list:after {
      bottom: 0; }
  .easyentretien-checkout-steps .options-content .clim_questions-container {
    padding: 25px 0; }
    .easyentretien-checkout-steps .options-content .clim_questions-container .units_ext-list .list-item {
      justify-content: center; }
  .easyentretien-checkout-steps .options-content .options-list .list-item {
    justify-content: space-between; }
  @media (min-width: 801px) {
    .easyentretien-checkout-steps .options-content .options-list .item {
      display: flex; } }
  @media screen and (max-width: 1024px) {
    .easyentretien-checkout-steps .options-content .options-list .item {
      display: block; } }
  .easyentretien-checkout-steps .options-content .options-list .item img {
    margin-right: 10px;
    width: 50px;
    height: 50px; }
  .easyentretien-checkout-steps .options-content .options-list .item p {
    margin: 0;
    line-height: 2rem; }
  .easyentretien-checkout-steps .options-content .options-list .item .product-label {
    position: relative;
    padding-right: 30px;
    width: fit-content;
    color: #373737;
    font-weight: 600; }
    @media screen and (max-width: 1024px) {
      .easyentretien-checkout-steps .options-content .options-list .item .product-label {
        margin: 0 auto; } }
  .easyentretien-checkout-steps .options-content .options-list .item .product-info {
    font-size: 1.4rem;
    color: #8c8c8c; }
  .easyentretien-checkout-steps .tax-profil-container .list-item .type {
    font-size: 1.4rem;
    color: #8c8c8c; }
  .easyentretien-checkout-steps .check-eligibility-content {
    margin: 55px auto 50px; }
    .easyentretien-checkout-steps .check-eligibility-content .messages {
      display: none; }
    .easyentretien-checkout-steps .check-eligibility-content .success {
      display: none; }
    .easyentretien-checkout-steps .check-eligibility-content .messages {
      padding: 0; }
  .easyentretien-checkout-steps .options-content {
    /*
     * VueJs Transition
     */ }
    .easyentretien-checkout-steps .options-content .list-item {
      width: 50%;
      min-width: 40%;
      padding: 0 10px;
      margin: 0 0 10px; }
    .easyentretien-checkout-steps .options-content .product-label {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      display: block; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .options-content .product-label {
          margin: 0 0 10px; } }
    .easyentretien-checkout-steps .options-content .qty-content {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0; }
    .easyentretien-checkout-steps .options-content .price-content {
      display: inline-block;
      vertical-align: middle; }
      .easyentretien-checkout-steps .options-content .price-content .price {
        font-family: "Roboto", sans-serif;
        font-size: 2.4rem; }
      .easyentretien-checkout-steps .options-content .price-content .old-price {
        font-family: "Roboto", sans-serif;
        font-size: 1.6rem;
        color: #8c8c8c;
        position: relative; }
        .easyentretien-checkout-steps .options-content .price-content .old-price:after {
          background: #8c8c8c;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%; }
    .easyentretien-checkout-steps .options-content .special-price {
      font-family: "Roboto", sans-serif;
      font-size: 2.4rem;
      color: #ef604e;
      margin: 0 0 0 10px; }
    .easyentretien-checkout-steps .options-content .discount-details {
      font-size: 1.3rem;
      color: #8c8c8c;
      margin: 0; }
    .easyentretien-checkout-steps .options-content .title-presentation {
      max-width: 320px; }
    .easyentretien-checkout-steps .options-content .option-info__mobile {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      z-index: 100; }
    .easyentretien-checkout-steps .options-content .show-modal {
      position: absolute;
      font-size: 1.5rem;
      padding: 5px 0;
      color: #ffffff;
      cursor: pointer;
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: #5694F2;
      line-height: 16px;
      top: 10px;
      right: 10px;
      text-align: center; }
      .easyentretien-checkout-steps .options-content .show-modal.tablet_screen-start {
        display: none; }
      @media (min-width: 801px) {
        .easyentretien-checkout-steps .options-content .show-modal {
          font-size: 1.2rem;
          padding: 0;
          width: 15px;
          height: 15px;
          right: 0px;
          top: 0px; } }
    .easyentretien-checkout-steps .options-content .modal-mask {
      display: none; }
      .easyentretien-checkout-steps .options-content .modal-mask.active {
        position: absolute;
        z-index: 9998;
        top: 0;
        left: -75%;
        min-width: 400px;
        max-width: 450px;
        display: block;
        transform: translateY(-50%);
        transition: opacity .3s ease; }
        @media (min-width: 801px) {
          .easyentretien-checkout-steps .options-content .modal-mask.active {
            left: calc(100% + 20px); } }
    .easyentretien-checkout-steps .options-content .modal-container {
      position: relative; }
    .easyentretien-checkout-steps .options-content .modal-bg {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      color: #8c8c8c;
      padding: 40px 30px;
      position: relative;
      text-align: left;
      transition: all .3s ease;
      width: 100%; }
    .easyentretien-checkout-steps .options-content .modal-header h3 {
      color: #000000;
      font-family: "Roboto", sans-serif;
      font-size: 3rem;
      letter-spacing: 1px;
      line-height: 30px; }
    .easyentretien-checkout-steps .options-content .modal-body {
      font-weight: 400;
      margin: 10px 0 0; }
      .easyentretien-checkout-steps .options-content .modal-body .sub-title {
        color: #373737;
        font-family: "Roboto", sans-serif;
        margin: 30px 0 15px;
        text-transform: uppercase; }
      .easyentretien-checkout-steps .options-content .modal-body .list {
        padding: 0 0 0 20px;
        list-style: disc; }
      .easyentretien-checkout-steps .options-content .modal-body .redlight {
        color: #ef604e; }
    .easyentretien-checkout-steps .options-content .modal-default-button {
      background: transparent;
      border: 2px solid #fff;
      cursor: pointer;
      border-radius: 100%;
      height: 30px;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1px;
      position: absolute;
      color: #fff;
      right: -30px;
      top: -40px;
      width: 30px; }
    .easyentretien-checkout-steps .options-content .modal-enter, .easyentretien-checkout-steps .options-content .modal-leave {
      opacity: 0; }
    .easyentretien-checkout-steps .options-content .modal-enter .modal-container,
    .easyentretien-checkout-steps .options-content .modal-leave .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
  .easyentretien-checkout-steps .content-steps-appointment {
    /* .appointment-content {
      min-width: 50%;
    } */ }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker {
      border-radius: 5px;
      background: #ffffff;
      border: 1px solid #dddddd;
      box-shadow: none;
      font-family: "Roboto", sans-serif;
      padding: 30px; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-title {
      color: #373737;
      font-size: 1.6rem;
      text-transform: uppercase; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-header {
      padding: 0 0 30px; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-month {
      margin: 0 5px 0 0; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-prev {
      left: 25%;
      width: 25px;
      height: 25px;
      background-color: #373737;
      border-radius: 100%; }
      @media (max-width: 1024px) {
        .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-prev {
          left: 10%; } }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-prev::before {
        content: '';
        position: absolute;
        background-image: url("../images/icons/arrow-prev.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-prev span {
        display: none; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-next {
      width: 25px;
      height: 25px;
      right: 25%;
      background-color: #373737;
      border-radius: 100%; }
      @media (max-width: 1024px) {
        .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-next {
          right: 10%; } }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-next::before {
        content: '';
        position: absolute;
        background-image: url("../images/icons/arrow-next.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-next span {
        display: none; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar {
      border: none;
      background: none; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar th, .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar td {
        border: none;
        background: none; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar th {
        color: #8c8c8c;
        font-size: 1.4rem;
        font-weight: normal; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar td a {
        font-weight: bold;
        height: 36px;
        line-height: 30px; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-default {
        text-align: center;
        position: relative; }
        .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-default:before {
          border-radius: 100%;
          -webkit-transform: translate(-50%);
          -moz-transform: translate(-50%);
          -ms-transform: translate(-50%);
          -o-transform: translate(-50%);
          transform: translate(-50%);
          background: #65CC9B;
          content: "";
          display: block;
          height: 36px;
          line-height: 36px;
          position: absolute;
          left: 50%;
          top: 0;
          width: 36px;
          z-index: -1;
          opacity: 0;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          transition: all 0.3s linear; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-hover {
        background: none;
        color: #ffffff;
        position: relative;
        z-index: 0; }
        .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-hover:before {
          opacity: 0.5; }
      .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-active {
        background: none;
        color: #ffffff;
        position: relative;
        z-index: 0; }
        .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-calendar .ui-state-active:before {
          border-radius: 100%;
          -webkit-transform: translate(-50%);
          -moz-transform: translate(-50%);
          -ms-transform: translate(-50%);
          -o-transform: translate(-50%);
          transform: translate(-50%);
          background: #65CC9B;
          content: "";
          display: block;
          height: 36px;
          line-height: 36px;
          position: absolute;
          left: 50%;
          top: 0;
          width: 36px;
          z-index: -1;
          opacity: 1; }
    .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-buttonpane {
      display: none; }
    .easyentretien-checkout-steps .content-steps-appointment .calendar-info {
      color: #8c8c8c;
      font-size: 1.3rem;
      text-align: left; }
    .easyentretien-checkout-steps .content-steps-appointment .time-slot {
      margin: 35px 0 0; }
      .easyentretien-checkout-steps .content-steps-appointment .time-slot .label {
        margin: 0;
        padding-bottom: 5px;
        font-family: "Roboto", sans-serif;
        font-size: 1.3rem;
        color: #8c8c8c; }
      .easyentretien-checkout-steps .content-steps-appointment .time-slot .buttons_container {
        gap: 10px; }
      .easyentretien-checkout-steps .content-steps-appointment .time-slot .morning-content, .easyentretien-checkout-steps .content-steps-appointment .time-slot .afternoon-content {
        flex: 1; }
      .easyentretien-checkout-steps .content-steps-appointment .time-slot .item {
        border-radius: 5px;
        -webkit-transition: all 250ms linear;
        -moz-transition: all 250ms linear;
        transition: all 250ms linear;
        background: #ffffff;
        border: 2px solid #dddddd;
        display: block;
        font-size: 1.4rem;
        margin: 0 auto 10px;
        position: relative;
        cursor: pointer;
        padding: 30px 0;
        width: 100%; }
        .easyentretien-checkout-steps .content-steps-appointment .time-slot .item:hover:not(:disabled) {
          border: 2px solid #65CC9B; }
        .easyentretien-checkout-steps .content-steps-appointment .time-slot .item.active {
          border: 2px solid #65CC9B; }
          .easyentretien-checkout-steps .content-steps-appointment .time-slot .item.active:after {
            background-image: url("../images/green_check.png");
            background-size: cover;
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 10px; }
        .easyentretien-checkout-steps .content-steps-appointment .time-slot .item:disabled {
          cursor: not-allowed;
          opacity: .3; }
    .easyentretien-checkout-steps .content-steps-appointment .time-slot-confirm {
      margin: 50px 0; }
  .easyentretien-checkout-steps .loading-mask {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    z-index: 50; }
    .easyentretien-checkout-steps .loading-mask .loader .technician-logo {
      margin: 25px; }
      .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg {
        width: 150px !important;
        height: 150px !important; }
        .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg path:nth-child(1) {
          stroke-dashoffset: 56;
          stroke-dasharray: 56;
          animation: draw 2s ease-out infinite;
          stroke: #ffffff;
          stroke-width: 1; }

@keyframes draw {
  40% {
    stroke-dashoffset: 0; }
  65% {
    stroke-dashoffset: 0; } }
        .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg path:nth-child(2) {
          stroke-dashoffset: 17;
          stroke-dasharray: 17;
          animation: draw 2s ease-out infinite;
          stroke: #ffffff;
          stroke-width: 1; }

@keyframes draw {
  40% {
    stroke-dashoffset: 0; }
  65% {
    stroke-dashoffset: 0; } }
        .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg path:nth-child(3) {
          stroke-dashoffset: 17;
          stroke-dasharray: 17;
          animation: draw 2s ease-out infinite;
          stroke: #ffffff;
          stroke-width: 1; }

@keyframes draw {
  40% {
    stroke-dashoffset: 0; }
  65% {
    stroke-dashoffset: 0; } }
        .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg path:nth-child(4) {
          stroke-dashoffset: 54;
          stroke-dasharray: 54;
          animation: draw 2s ease-out infinite;
          stroke-width: 1; }

@keyframes draw {
  40% {
    stroke-dashoffset: 0; }
  65% {
    stroke-dashoffset: 0; } }
        .easyentretien-checkout-steps .loading-mask .loader .technician-logo svg path:nth-child(5) {
          stroke-dashoffset: 5;
          stroke-dasharray: 5;
          animation: draw 2s ease-out infinite;
          stroke: #ffffff;
          stroke-width: 1; }

@keyframes draw {
  40% {
    stroke-dashoffset: 0; }
  65% {
    stroke-dashoffset: 0; } }
    .easyentretien-checkout-steps .loading-mask .loader .loader-text {
      overflow: hidden;
      font-weight: 600;
      color: #ffffff; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(1) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(1/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(2) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(2/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(3) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(3/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(4) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(4/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(5) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(5/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(6) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(6/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(7) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(7/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(8) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(8/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(9) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(9/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(10) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(10/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(11) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(11/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(12) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(12/4)) ease-out infinite alternate; }
      .easyentretien-checkout-steps .loading-mask .loader .loader-text span:nth-child(13) {
        display: inline-block;
        margin: 0 2px;
        font-size: 2.5rem;
        transform: translateY(150%);
        animation: letter-translate 1s calc(0.5s * calc(13/4)) ease-out infinite alternate; }
    .easyentretien-checkout-steps .loading-mask .loader p {
      display: none; }

.checkout-index-index {
  position: relative; }
  .checkout-index-index .modals-wrapper,
  .checkout-index-index .submitCta-shipping,
  .checkout-index-index .submitCta-contract_visualisation {
    display: none; }
  .checkout-index-index .column.main .checkout-content {
    justify-content: center;
    gap: 25px; }
    @media (min-width: 801px) {
      .checkout-index-index .column.main .checkout-content {
        padding: 50px 25px; } }
    .checkout-index-index .column.main .checkout-content::after {
      display: none; }
    .checkout-index-index .column.main .checkout-content #shipping-method-buttons-container {
      display: none; }
  .checkout-index-index .summary-container {
    width: 30%; }
  .checkout-index-index .summary {
    position: relative; }
    .checkout-index-index .summary .summary-content {
      top: 0 !important;
      bottom: auto !important; }
    .checkout-index-index .summary #summary-content .info-block.pdc-info {
      display: none; }
  .checkout-index-index .contract_validation-content {
    display: none; }

.checkout-index-index.tunnel_step-contract_validation .shipping-content {
  display: none; }

.checkout-index-index.tunnel_step-contract_validation .contract_validation-content {
  display: block; }

.checkout-index-index.tunnel_step-contract_validation .checkout-container {
  width: 50%; }

.checkout-index-index.tunnel_step-contract_validation .contract_container iframe {
  border: none;
  border-radius: 15px; }

.checkout-index-index.tunnel_step-contract_validation .checkbox-container .custom-checkbox + label::after {
  color: #65CC9B; }

.checkout-index-index.tunnel_step-contract_validation .checkbox-container .custom-checkbox + label {
  align-items: center;
  text-transform: none;
  font-size: 1.4rem; }
  .checkout-index-index.tunnel_step-contract_validation .checkbox-container .custom-checkbox + label a {
    padding-left: 5px;
    color: #ef604e;
    text-decoration: underline; }

/* ----------------------------------------------
  CHECKOUT CONTRACT VISUALISATION/VALIDATIONS
----------------------------------------------- */
.checkout-index-index .contract_container .btn__full-screen {
  display: none; }

.checkout-index-index .contract_container {
  display: none; }

.checkout-index-index .contract_container:has(iframe) {
  display: block;
  padding: 25px 0; }
  .checkout-index-index .contract_container:has(iframe) .btn__full-screen {
    display: block;
    width: fit-content;
    margin-bottom: 10px;
    margin-left: auto;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #8c8c8c;
    box-shadow: 0px 20px 30px 0px #0000000D; }
  .checkout-index-index .contract_container:has(iframe) #iframe-yousign-container,
  .checkout-index-index .contract_container:has(iframe) #iframe-contract-container {
    width: 100%;
    height: 600px; }
    .checkout-index-index .contract_container:has(iframe) #iframe-yousign-container iframe,
    .checkout-index-index .contract_container:has(iframe) #iframe-contract-container iframe {
      border: none;
      border-radius: 15px;
      width: 100%;
      height: 100%; }
  .checkout-index-index .contract_container:has(iframe):has(iframe) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000; }
    .checkout-index-index .contract_container:has(iframe):has(iframe) .btn__full-screen {
      display: none; }
    .checkout-index-index .contract_container:has(iframe):has(iframe) .full-screen__overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
    .checkout-index-index .contract_container:has(iframe):has(iframe) #iframe-yousign-container:has(iframe),
    .checkout-index-index .contract_container:has(iframe):has(iframe) #iframe-contract-container:has(iframe) {
      position: relative;
      margin: 2.5% auto;
      width: 90%;
      height: 90%; }

/* -----------------------
  CHECKOUT PAYEMENT STEP
------------------------ */
.checkout-index-index.tunnel_step-payment .shipping-content,
.checkout-index-index.tunnel_step-payment .contract_validation-content {
  display: none; }

@media (min-width: 801px) {
  .checkout-index-index.tunnel_step-payment .checkout-container {
    width: 50%; } }

.checkout-index-index.tunnel_step-payment .payment-method.dalenys {
  display: none; }
  .checkout-index-index.tunnel_step-payment .payment-method.dalenys._available {
    display: block; }

.checkout-index-index.tunnel_step-payment .payment-method.dalenys,
.checkout-index-index.tunnel_step-payment .payment-method.iban-method {
  margin-bottom: 25px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  padding: 10px; }
  .checkout-index-index.tunnel_step-payment .payment-method.dalenys .field,
  .checkout-index-index.tunnel_step-payment .payment-method.iban-method .field {
    margin: 0;
    width: auto; }
  .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title,
  .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title {
    width: 100%; }
    .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label,
    .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label {
      display: flex; }
      .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label > span,
      .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label .dalenys-method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label .method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label > span,
      .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label .dalenys-method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label .method-logo {
        display: block; }
      .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label .dalenys-method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-title label .method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label .dalenys-method-logo,
      .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-title label .method-logo {
        max-height: 20px;
        margin-left: auto; }
  .checkout-index-index.tunnel_step-payment .payment-method.dalenys .payment-method-content,
  .checkout-index-index.tunnel_step-payment .payment-method.iban-method .payment-method-content {
    display: none; }
  .checkout-index-index.tunnel_step-payment .payment-method.dalenys._active .payment-method-content,
  .checkout-index-index.tunnel_step-payment .payment-method.iban-method._active .payment-method-content {
    display: block; }

.checkout-index-index.tunnel_step-payment .dalenys-payment-method-title.field.choice {
  width: 80%; }

.checkout-index-index.tunnel_step-payment .checkout-agreement {
  margin: 10px 0; }

.checkout-index-index.tunnel_step-payment .dalenys-form .brand-area {
  display: none; }

.checkout-index-index.tunnel_step-payment .dalenys-form form,
.checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order {
  padding: 0; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form label,
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order label {
    margin: 0;
    font-size: 1.3rem;
    color: #8c8c8c; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form input[type=text],
  .checkout-index-index.tunnel_step-payment .dalenys-form form .input-container,
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order input[type=text],
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order .input-container {
    padding: 5px 7px 3px 7px;
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    border: none;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid #8c8c8c; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#card-container) span,
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#card-container) span {
    width: 100%; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form .sepa-field,
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order .sepa-field {
    margin: 15px 0 !important; }
    .checkout-index-index.tunnel_step-payment .dalenys-form form .sepa-field.field-iban,
    .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order .sepa-field.field-iban {
      width: 75% !important; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#expiry-container),
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#expiry-container) {
    margin-right: 8%; }
  .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#expiry-container),
  .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#cvv-container),
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#expiry-container),
  .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#cvv-container) {
    display: inline-block;
    width: 45%; }
    .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#expiry-container) > span,
    .checkout-index-index.tunnel_step-payment .dalenys-form form p:has(#cvv-container) > span,
    .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#expiry-container) > span,
    .checkout-index-index.tunnel_step-payment form.form-sepadirectdebit-order p:has(#cvv-container) > span {
      width: 100%; }

.checkout-index-index.tunnel_step-payment .dalenys-form .submit {
  margin: 0; }

.checkout-index-index.tunnel_step-payment #summary-content .info-block.pdc-info {
  display: block !important;
  opacity: 1;
  transform: translateY(0); }

.checkout-index-index.lead_payment-page #maincontent {
  opacity: 0; }

/*
  Checkout message popin
*/
.checkout-messages_container .message-error {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 112;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5); }

.checkout-messages_container .popin-error {
  position: relative;
  font-size: 1.4rem;
  padding: 110px 50px 50px 50px;
  border-radius: 15px;
  background-color: #ffffff;
  max-width: 90vw; }
  .checkout-messages_container .popin-error:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    background-image: url("../images/icons/warning.svg");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat; }

.checkout-agreement,
.submitCta-payment,
.btn-place-order,
.btn-dalenys-place-order {
  display: none; }

.checkout-index-index .annual-price_show,
.checkout-index-index .annual-payment_show,
.checkout-index-index .periodic-price_show,
.checkout-index-index .periodic-payment_show {
  opacity: 0;
  transform: translateY(30%);
  transition: all 0.3s ease-in; }

.checkout-index-index .monthly-payment .annual-price_show,
.checkout-index-index .monthly-payment .annual-payment_show {
  display: none !important; }

.checkout-index-index .monthly-payment .periodic-price_show,
.checkout-index-index .monthly-payment .periodic-payment_show {
  opacity: 1;
  transform: translateY(0); }

.checkout-index-index .annual-payment .periodic-price_show,
.checkout-index-index .annual-payment .periodic-payment_show {
  display: none !important; }

.checkout-index-index .annual-payment .annual-price_show,
.checkout-index-index .annual-payment .annual-payment_show {
  opacity: 1;
  transform: translateY(0); }

.checkout-container {
  /* ------------------------
    billing-new-address-form
  ------------------------ */
  /* ------------------------
    Payment Step
  ------------------------ */ }
  @media (min-width: 801px) {
    .checkout-container {
      width: 50%; } }
  .checkout-container .steps-main-content {
    border-radius: 5px;
    color: #373737;
    padding: 30px 50px; }
    .checkout-container .steps-main-content .button {
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .checkout-container .steps-main-content .button.disabled {
        opacity: 0;
        visibility: hidden;
        max-height: 0; }
  .checkout-container *:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #eeeeee inset; }
  .checkout-container .authentication-dropdown {
    text-align: left;
    visibility: hidden;
    width: 100%;
    height: 0;
    overflow: hidden;
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
    -moz-transition: -moz-transform linear 0.1s, visibility 0s linear 0.1s;
    transition: transform linear 0.1s, visibility 0s linear 0.1s; }
    .checkout-container .authentication-dropdown._show {
      visibility: visible;
      height: auto;
      overflow: visible;
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
      -moz-transition: -moz-transform linear 0.1s, visibility 0s linear 0s;
      transition: transform linear 0.1s, visibility 0s linear 0s; }
    .checkout-container .authentication-dropdown .block-title {
      display: none; }
    .checkout-container .authentication-dropdown .field {
      padding: 0;
      width: 100%; }
    .checkout-container .authentication-dropdown *:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #ffffff inset; }
    .checkout-container .authentication-dropdown .actions-toolbar .primary,
    .checkout-container .authentication-dropdown .actions-toolbar .secondary {
      display: block;
      float: none;
      margin: 0 auto;
      text-align: center; }
      .checkout-container .authentication-dropdown .actions-toolbar .primary button,
      .checkout-container .authentication-dropdown .actions-toolbar .secondary button {
        margin: 0 auto 20px;
        max-width: 280px; }
  .checkout-container .checkout-form-step {
    text-align: left;
    position: relative; }
    @media (min-width: 801px) {
      .checkout-container .checkout-form-step {
        padding-top: 75px; } }
    .checkout-container .checkout-form-step .authentication-wrapper {
      display: none;
      border-radius: 5px;
      background-color: #ffffff;
      border: 1px solid #72C070;
      color: #373737;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      margin: 0 auto 50px;
      padding: 15px;
      position: relative;
      max-width: 500px; }
      .checkout-container .checkout-form-step .authentication-wrapper button {
        font-family: "Roboto", sans-serif; }
      .checkout-container .checkout-form-step .authentication-wrapper .block-content {
        padding: 20px 0 0; }
      .checkout-container .checkout-form-step .authentication-wrapper .action-auth-toggle {
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.4rem;
        width: 100%; }
        .checkout-container .checkout-form-step .authentication-wrapper .action-auth-toggle .link {
          text-decoration: underline; }
      .checkout-container .checkout-form-step .authentication-wrapper .modal-header {
        position: absolute;
        top: -20px;
        right: 0px; }
        .checkout-container .checkout-form-step .authentication-wrapper .modal-header .action-close {
          background: transparent;
          border: none;
          cursor: pointer; }
          .checkout-container .checkout-form-step .authentication-wrapper .modal-header .action-close:before {
            background-image: url("../images/crush.png");
            content: "";
            display: block;
            height: 14px;
            width: 14px; }
          .checkout-container .checkout-form-step .authentication-wrapper .modal-header .action-close span {
            display: none; }
      .checkout-container .checkout-form-step .authentication-wrapper .modal-inner-wrap {
        max-width: 380px;
        margin: 0 auto; }
    .checkout-container .checkout-form-step #opc-shipping_method .billing-address-same-as-shipping-block.checkbox-container {
      position: absolute;
      top: 25px; }
      @media only screen and (max-width: 800px) {
        .checkout-container .checkout-form-step #opc-shipping_method .billing-address-same-as-shipping-block.checkbox-container {
          top: -45px; } }
  .checkout-container .checkout-payment-method fieldset.street .control .label > span,
  .checkout-container .checkout-payment-method .field-tooltip.toggle > span.label,
  .checkout-container .checkout-form-step fieldset.street .control .label > span,
  .checkout-container .checkout-form-step .field-tooltip.toggle > span.label {
    display: none; }
  .checkout-container .checkout-payment-method .form-login fieldset,
  .checkout-container .checkout-form-step .form-login fieldset {
    margin: 0; }
  .checkout-container .checkout-payment-method .form-login .fieldset.hidden-fields,
  .checkout-container .checkout-form-step .form-login .fieldset.hidden-fields {
    margin: 0; }
    .checkout-container .checkout-payment-method .form-login .fieldset.hidden-fields .note,
    .checkout-container .checkout-form-step .form-login .fieldset.hidden-fields .note {
      display: block;
      margin: 15px 0; }
  .checkout-container .checkout-payment-method .form-login .field,
  .checkout-container .checkout-form-step .form-login .field {
    width: 100%; }
    .checkout-container .checkout-payment-method .form-login .field .note,
    .checkout-container .checkout-form-step .form-login .field .note {
      display: block;
      margin: 30px 0 0;
      color: #8c8c8c;
      text-align: center; }
  .checkout-container .checkout-payment-method .form-login .actions-toolbar,
  .checkout-container .checkout-form-step .form-login .actions-toolbar {
    padding: 0 15px; }
    .checkout-container .checkout-payment-method .form-login .actions-toolbar .secondary,
    .checkout-container .checkout-form-step .form-login .actions-toolbar .secondary {
      text-align: center;
      margin: 10px 0 0; }
      .checkout-container .checkout-payment-method .form-login .actions-toolbar .secondary button,
      .checkout-container .checkout-form-step .form-login .actions-toolbar .secondary button {
        margin: 0 auto; }
  .checkout-container .checkout-payment-method .form.payments .fieldset,
  .checkout-container .checkout-form-step .form.payments .fieldset {
    margin: 0;
    padding-bottom: 10px; }
  .checkout-container .checkout-payment-method .form.payments .h2-title,
  .checkout-container .checkout-form-step .form.payments .h2-title {
    margin: 0 auto 20px; }
  .checkout-container fieldset.field.street .field._required {
    width: 100%; }
  .checkout-container fieldset.field.street .field.additional {
    display: none; }
  .checkout-container .field:not(.full-width) {
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1025px) {
      .checkout-container .field:not(.full-width) {
        width: 49%; } }
    .checkout-container .field:not(.full-width).admin__control-fields {
      width: 100%; }
      .checkout-container .field:not(.full-width).admin__control-fields legend.label {
        font-family: "Roboto", sans-serif;
        font-weight: 400; }
      .checkout-container .field:not(.full-width).admin__control-fields > .control {
        margin: 0 -15px; }
    .checkout-container .field:not(.full-width)[name^="billingAddresscheckmo.telephone"] .control._with-tooltip .input-text, .checkout-container .field:not(.full-width)[name^="billingAddresscheckmo.telephone"] .control._with-tooltip .textarea {
      width: 100%; }
    .checkout-container .field:not(.full-width)[name^="billingAddresscheckmo.telephone"] .control._with-tooltip .field-tooltip.toggle {
      display: none; }
    .checkout-container .field:not(.full-width)[name^="billingAddresscheckmo.custom_attributes.comment"] {
      width: 100%; }
      .checkout-container .field:not(.full-width)[name^="billingAddresscheckmo.custom_attributes.comment"] textarea {
        width: 50%; }
  .checkout-container .field:has(textarea) {
    width: 100% !important; }
    .checkout-container .field:has(textarea) textarea {
      width: 100%;
      height: 200px; }
  .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0; }
  .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] + label {
    position: relative;
    padding-top: 0;
    padding-left: 40px;
    min-height: 24px;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: flex-start; }
    .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] + label::before, .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] + label::after {
      cursor: pointer;
      position: absolute;
      transition: all .2s; }
    .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] + label::before {
      content: "";
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      background-color: transparent; }
    .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]'] + label::after {
      content: "\EA01";
      font-family: "iconfont";
      left: 5px;
      top: 5px;
      color: #F44D57;
      font-size: 1.5rem;
      line-height: 2rem;
      opacity: 0;
      transform: scale(0); }
  .checkout-container .optin-container input[name='custom_attributes[proxiserve_optin]']:checked + label::after {
    opacity: 1;
    transform: scale(1); }
  .checkout-container .billing-address-same-as-shipping-block > label {
    align-items: center; }
    .checkout-container .billing-address-same-as-shipping-block > label::after {
      color: #65CC9B; }
  .checkout-container #billing-new-address-form {
    margin-top: 50px; }
  .checkout-container .billing-address-form,
  .checkout-container .checkout-shipping-address {
    margin: 0 -15px; }
    .checkout-container .billing-address-form select,
    .checkout-container .checkout-shipping-address select {
      width: 100%;
      height: 63px;
      font-size: 1.6rem; }
  .checkout-container .billing-address-details .content {
    border: 1px solid #ccc;
    background: #fff;
    padding: 20px;
    margin: 0 auto;
    border-radius: 5px; }
  .checkout-container .control._with-tooltip .input-text:not([type=email]):not([name=telephone]), .checkout-container .control._with-tooltip .textarea:not([type=email]):not([name=telephone]) {
    display: inline-block;
    margin: 0 5% 0 0;
    vertical-align: middle;
    width: 48%; }
  .checkout-container .control._with-tooltip .field-tooltip.toggle {
    display: block;
    margin-top: 10px;
    font-size: 1.4rem;
    color: #5694F2;
    background: rgba(86, 148, 242, 0.2);
    border-radius: 6px;
    padding: 15px 20px 15px 40px;
    position: relative; }
    .checkout-container .control._with-tooltip .field-tooltip.toggle:before {
      background-image: url("../images/info_icon.svg");
      background-repeat: no-repeat;
      content: "";
      display: block;
      height: 14px;
      width: 14px;
      position: absolute;
      top: 50%;
      left: 15px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
  .checkout-container .billing-address-details {
    display: none; }
  .checkout-container .checkout-shipping-method .step-title, .checkout-container .checkout-shipping-method .table-checkout-shipping-method {
    display: none; }
  .checkout-container #payment #terms-agreement.checkbox-container {
    margin-bottom: 30px; }
    .checkout-container #payment #terms-agreement.checkbox-container label {
      align-items: center; }
      .checkout-container #payment #terms-agreement.checkbox-container label a {
        margin-left: 5px;
        color: #8c8c8c;
        text-decoration: underline; }
        .checkout-container #payment #terms-agreement.checkbox-container label a:hover {
          color: #ef604e; }
    .checkout-container #payment #terms-agreement.checkbox-container div.mage-error {
      display: block;
      top: 60%;
      padding-left: 40px; }
    .checkout-container #payment #terms-agreement.checkbox-container div.mage-error.hide,
    .checkout-container #payment #terms-agreement.checkbox-container input[name="terms"]:checked + label + div.mage-error {
      display: none; }
  .checkout-container .checkout-payment-type {
    gap: 20px;
    padding: 25px 0; }
    @media (min-width: 1281px) {
      .checkout-container .checkout-payment-type {
        display: flex; } }
    .checkout-container .checkout-payment-type p {
      margin: 0; }
    .checkout-container .checkout-payment-type .payment-type {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px;
      border: 2px solid #dddddd;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 25px; }
      @media (min-width: 801px) {
        .checkout-container .checkout-payment-type .payment-type {
          margin-bottom: 0; } }
      @media (max-width: 1280px) {
        .checkout-container .checkout-payment-type .payment-type {
          margin: 10px 0; } }
      .checkout-container .checkout-payment-type .payment-type.active {
        border-color: #65CC9B; }
        .checkout-container .checkout-payment-type .payment-type.active:after {
          background-image: url("../images/green_check.png");
          background-size: cover;
          content: "";
          height: 15px;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 15px; }
      .checkout-container .checkout-payment-type .payment-type__label {
        font-size: 1.6rem;
        padding-bottom: 20px;
        border-bottom: 1px solid #dddddd; }
      .checkout-container .checkout-payment-type .payment-type__price {
        margin-top: 15px;
        margin-bottom: 5px; }
      .checkout-container .checkout-payment-type .payment-type__infos {
        color: #8c8c8c; }
      .checkout-container .checkout-payment-type .payment-type__icon {
        margin-top: 25px; }
        .checkout-container .checkout-payment-type .payment-type__icon .icon {
          display: inline-block;
          width: 30px;
          height: 20px;
          background-size: 90%;
          background-repeat: no-repeat;
          background-position: center; }
          .checkout-container .checkout-payment-type .payment-type__icon .icon .text {
            vertical-align: super;
            color: #8c8c8c; }
          .checkout-container .checkout-payment-type .payment-type__icon .icon.sepa {
            background-image: url("../images/sepa.svg"); }
          .checkout-container .checkout-payment-type .payment-type__icon .icon.amex {
            background-image: url("../images/amex.svg"); }
          .checkout-container .checkout-payment-type .payment-type__icon .icon.visa {
            background-image: url("../images/visa.svg"); }
          .checkout-container .checkout-payment-type .payment-type__icon .icon.mastercard {
            background-image: url("../images/mastercard.svg"); }

.easyentretien-checkout-steps .summary-content,
.checkout-onepage-success .summary-content {
  border-radius: 12px;
  padding: 40px 30px 20px 30px;
  box-shadow: 0px 20px 30px 0px #0000000D; }
  @media only screen and (max-width: 1024px) and (min-width: 800px) {
    .easyentretien-checkout-steps .summary-content,
    .checkout-onepage-success .summary-content {
      padding: 40px 10px 20px 10px; } }

.easyentretien-checkout-steps .summary-content-title,
.checkout-onepage-success .summary-content-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #373737; }
  .easyentretien-checkout-steps .summary-content-title__head,
  .checkout-onepage-success .summary-content-title__head {
    display: flex;
    justify-content: space-between; }
    .easyentretien-checkout-steps .summary-content-title__head div:not(.contract-change),
    .checkout-onepage-success .summary-content-title__head div:not(.contract-change) {
      font-size: 1.8rem;
      font-weight: 700; }
    .easyentretien-checkout-steps .summary-content-title__head .contract-change,
    .checkout-onepage-success .summary-content-title__head .contract-change {
      cursor: pointer;
      font-size: 1.2rem;
      color: #8c8c8c;
      text-decoration: underline; }
      .easyentretien-checkout-steps .summary-content-title__head .contract-change:hover,
      .checkout-onepage-success .summary-content-title__head .contract-change:hover {
        color: #ef604e; }
  .easyentretien-checkout-steps .summary-content-title.directenergie, .easyentretien-checkout-steps .summary-content-title.foncia,
  .checkout-onepage-success .summary-content-title.directenergie,
  .checkout-onepage-success .summary-content-title.foncia {
    padding: 5px 10px;
    letter-spacing: 0;
    font-weight: bold; }
  .easyentretien-checkout-steps .summary-content-title.directenergie,
  .checkout-onepage-success .summary-content-title.directenergie {
    color: #373737; }
    .easyentretien-checkout-steps .summary-content-title.directenergie .cobranding-name,
    .checkout-onepage-success .summary-content-title.directenergie .cobranding-name {
      background: #fcda01; }
    .easyentretien-checkout-steps .summary-content-title.directenergie .contract-title,
    .checkout-onepage-success .summary-content-title.directenergie .contract-title {
      color: #373737; }
  .easyentretien-checkout-steps .summary-content-title.foncia .cobranding-name,
  .checkout-onepage-success .summary-content-title.foncia .cobranding-name {
    color: #fff;
    background: #063869; }
  .easyentretien-checkout-steps .summary-content-title .summary-title,
  .checkout-onepage-success .summary-content-title .summary-title {
    font-size: 1.8rem;
    position: relative; }
  .easyentretien-checkout-steps .summary-content-title .summary-title .flex,
  .checkout-onepage-success .summary-content-title .summary-title .flex {
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px; }
  .easyentretien-checkout-steps .summary-content-title .cobranding-name,
  .checkout-onepage-success .summary-content-title .cobranding-name {
    border-radius: 6px;
    text-align: center; }
  .easyentretien-checkout-steps .summary-content-title .clearer,
  .checkout-onepage-success .summary-content-title .clearer {
    min-height: 45px; }
    .easyentretien-checkout-steps .summary-content-title .clearer::after,
    .checkout-onepage-success .summary-content-title .clearer::after {
      display: none; }
  .easyentretien-checkout-steps .summary-content-title .contract-title__type,
  .checkout-onepage-success .summary-content-title .contract-title__type {
    font-size: 1.4rem; }
  .easyentretien-checkout-steps .summary-content-title .amount,
  .checkout-onepage-success .summary-content-title .amount {
    font-family: "Roboto", sans-serif;
    text-align: right; }
    .easyentretien-checkout-steps .summary-content-title .amount .label,
    .checkout-onepage-success .summary-content-title .amount .label {
      font-size: 1.2rem; }

.easyentretien-checkout-steps .info-block,
.checkout-onepage-success .info-block {
  margin: 20px 0px;
  padding: 15px; }
  @media (min-width: 801px) {
    .easyentretien-checkout-steps .info-block,
    .checkout-onepage-success .info-block {
      margin: 20px 10px; } }

.easyentretien-checkout-steps .product-desc,
.checkout-onepage-success .product-desc {
  padding: 10px 30px 0;
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 11px;
  font-weight: 600;
  max-width: 250px; }

.easyentretien-checkout-steps .options-list li,
.checkout-onepage-success .options-list li {
  color: #373737;
  font-size: 1.4rem;
  line-height: 35px; }
  .easyentretien-checkout-steps .options-list li .icon,
  .checkout-onepage-success .options-list li .icon {
    margin-right: 5px;
    font-size: .7rem;
    color: #65CC9B; }

.easyentretien-checkout-steps .firstYear-resume,
.easyentretien-checkout-steps .contract-resume,
.checkout-onepage-success .firstYear-resume,
.checkout-onepage-success .contract-resume {
  justify-content: space-between; }
  .easyentretien-checkout-steps .firstYear-resume__price .special-price,
  .easyentretien-checkout-steps .contract-resume__price .special-price,
  .checkout-onepage-success .firstYear-resume__price .special-price,
  .checkout-onepage-success .contract-resume__price .special-price {
    font-weight: 600; }
  .easyentretien-checkout-steps .firstYear-resume__price .old-price,
  .easyentretien-checkout-steps .contract-resume__price .old-price,
  .checkout-onepage-success .firstYear-resume__price .old-price,
  .checkout-onepage-success .contract-resume__price .old-price {
    font-size: 1rem; }
    .easyentretien-checkout-steps .firstYear-resume__price .old-price span:nth-child(2),
    .easyentretien-checkout-steps .contract-resume__price .old-price span:nth-child(2),
    .checkout-onepage-success .firstYear-resume__price .old-price span:nth-child(2),
    .checkout-onepage-success .contract-resume__price .old-price span:nth-child(2) {
      font-size: .9rem; }
  .easyentretien-checkout-steps .firstYear-resume__price .tax_label,
  .easyentretien-checkout-steps .contract-resume__price .tax_label,
  .checkout-onepage-success .firstYear-resume__price .tax_label,
  .checkout-onepage-success .contract-resume__price .tax_label {
    font-size: 1rem; }

.easyentretien-checkout-steps .options-selected,
.checkout-onepage-success .options-selected {
  font-family: "Roboto", sans-serif;
  padding: 20px 0px; }
  .easyentretien-checkout-steps .options-selected .title,
  .checkout-onepage-success .options-selected .title {
    color: #373737;
    font-weight: 600; }
  .easyentretien-checkout-steps .options-selected .list-option,
  .checkout-onepage-success .options-selected .list-option {
    padding: 10px 0 0; }
  .easyentretien-checkout-steps .options-selected .item-option,
  .checkout-onepage-success .options-selected .item-option {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    position: relative; }
  .easyentretien-checkout-steps .options-selected .option-name,
  .checkout-onepage-success .options-selected .option-name {
    font-family: "Roboto", sans-serif;
    padding: 0 15px 0 0; }
  .easyentretien-checkout-steps .options-selected .option-price_container,
  .checkout-onepage-success .options-selected .option-price_container {
    margin-left: auto;
    margin-right: 10px; }
    .easyentretien-checkout-steps .options-selected .option-price_container .tax_label,
    .checkout-onepage-success .options-selected .option-price_container .tax_label {
      font-size: 1rem; }
  .easyentretien-checkout-steps .options-selected .option-price,
  .checkout-onepage-success .options-selected .option-price {
    font-family: "Roboto", sans-serif;
    font-weight: 700; }
  .easyentretien-checkout-steps .options-selected .option-old-price,
  .checkout-onepage-success .options-selected .option-old-price {
    color: #8c8c8c;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.2rem; }
    .easyentretien-checkout-steps .options-selected .option-old-price:after,
    .checkout-onepage-success .options-selected .option-old-price:after {
      background: #8c8c8c;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%; }
  .easyentretien-checkout-steps .options-selected .special-price,
  .checkout-onepage-success .options-selected .special-price {
    color: #ef604e;
    font-weight: 700;
    font-size: 1.4rem; }
  .easyentretien-checkout-steps .options-selected .qty,
  .checkout-onepage-success .options-selected .qty {
    color: #8c8c8c;
    margin: 5px 0 0; }
  .easyentretien-checkout-steps .options-selected .remove-option,
  .checkout-onepage-success .options-selected .remove-option {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: #eeeeee;
    background-image: url("../images/icons/uEA22-bin.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 6px; }
    .easyentretien-checkout-steps .options-selected .remove-option:hover,
    .checkout-onepage-success .options-selected .remove-option:hover {
      background-color: #ef604e; }

.easyentretien-checkout-steps .cart-summary,
.checkout-onepage-success .cart-summary {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem; }
  .easyentretien-checkout-steps .cart-summary .subtotal,
  .checkout-onepage-success .cart-summary .subtotal {
    margin: 0 0 15px;
    position: relative; }
  .easyentretien-checkout-steps .cart-summary .total,
  .checkout-onepage-success .cart-summary .total {
    color: #373737;
    position: relative;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    padding-top: 20px;
    text-align: end;
    margin: 10px 0;
    border-top: 1px solid #8c8c8c; }
  .easyentretien-checkout-steps .cart-summary .tax,
  .checkout-onepage-success .cart-summary .tax {
    display: block;
    font-family: "Roboto", sans-serif;
    margin: 0 0 10px;
    padding: 0 75px 0 0;
    text-transform: uppercase;
    position: relative; }
  .easyentretien-checkout-steps .cart-summary .tax-list,
  .checkout-onepage-success .cart-summary .tax-list {
    display: block;
    font-family: "Roboto", sans-serif;
    margin: 0 0 10px;
    text-transform: uppercase; }
    .easyentretien-checkout-steps .cart-summary .tax-list li,
    .checkout-onepage-success .cart-summary .tax-list li {
      display: block;
      margin: 0 0 10px;
      position: relative;
      padding: 0 75px 0 0; }
  .easyentretien-checkout-steps .cart-summary .desc,
  .checkout-onepage-success .cart-summary .desc {
    display: flex;
    gap: 10px;
    margin: 16px 0;
    padding: 15px 20px;
    text-transform: none;
    color: #5694F2;
    font-size: 1.4rem;
    line-height: 1.6rem;
    background: rgba(86, 148, 242, 0.2);
    border-radius: 6px; }
    .easyentretien-checkout-steps .cart-summary .desc .icon_container,
    .checkout-onepage-success .cart-summary .desc .icon_container {
      min-width: 15px; }
      .easyentretien-checkout-steps .cart-summary .desc .icon_container img,
      .checkout-onepage-success .cart-summary .desc .icon_container img {
        width: 15px;
        height: 15px; }

.easyentretien-checkout-steps .title-cart-summary .tax_label,
.checkout-onepage-success .title-cart-summary .tax_label {
  font-size: 1rem;
  font-weight: 500; }

.easyentretien-checkout-steps .amount,
.checkout-onepage-success .amount {
  color: #373737;
  font-family: "Roboto", sans-serif; }

.easyentretien-checkout-steps .cta-next_step,
.checkout-onepage-success .cta-next_step {
  margin: 20px 0; }
  .easyentretien-checkout-steps .cta-next_step .btn,
  .checkout-onepage-success .cta-next_step .btn {
    width: 100%;
    padding: 15px;
    font-size: 2rem; }
  .easyentretien-checkout-steps .cta-next_step span.checkout-cta_label,
  .checkout-onepage-success .cta-next_step span.checkout-cta_label {
    display: none; }
    .easyentretien-checkout-steps .cta-next_step span.checkout-cta_label.active,
    .checkout-onepage-success .cta-next_step span.checkout-cta_label.active {
      display: block; }

.checkout-onepage-success .success__container {
  margin: 200px auto 0;
  max-width: 850px;
  text-align: center; }

.checkout-onepage-success .success__subText {
  font-size: 1.8rem;
  font-weight: 600;
  color: #8c8c8c;
  line-height: 2.1rem; }

.checkout-onepage-success .success__bigText {
  margin: 10px 0;
  font-size: 3.6rem;
  color: #ef604e;
  line-height: 4.3rem; }

.checkout-onepage-success .success__infoText {
  font-size: 2.4rem;
  line-height: 3.3rem; }

.checkout-onepage-success .success__img {
  margin-top: 100px; }

/*----------------------------------------
                Loaded state || Animate out  (Transitions pages)
    ----------------------------------------*/
.easyentretien-checkout-steps:not(.tunnel_step-payment) .page-header,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) .page-header {
  transform: translateY(-150%);
  transition: transform .3s linear; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .page.messages,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .page.messages {
  display: none; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item__action,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item__action {
  opacity: 0;
  transition: opacity .2s ease-in; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(1),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(1) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(1/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(2),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(2) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(2/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(3),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(3) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(3/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(4),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(4) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(4/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(5),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(5) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(5/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(6),
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(6) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s calc(.2s * calc(6/4)) ease-in-out; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .round-white_box,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .round-white_box {
  opacity: 0;
  transition: all .3s ease-in; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .cgv-info,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .cgv-info {
  transform: translateY(100px);
  opacity: 0;
  transition: all .3s ease-in; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent .summary-content,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent .summary-content {
  transform: translateY(-50px);
  opacity: 0;
  transition: all .3s ease-in; }

.easyentretien-checkout-steps:not(.tunnel_step-payment) #maincontent #mobile-fixed_summary,
.easyentretien-checkout-steps.animate-out:not(.tunnel_step-payment) #maincontent #mobile-fixed_summary {
  transform: translateY(100%);
  opacity: 0;
  transition: all .2s .3s ease-in; }

.easyentretien-checkout-steps.animate-out:not(.checkout-index-index) #maincontent .round-white_box,
.easyentretien-checkout-steps:not(.checkout-index-index) #maincontent .round-white_box {
  transform: translateY(-20%);
  opacity: 0;
  transition: all .3s ease-in; }

/*----------------------------------------
                Animate In (Transitions pages)
    ----------------------------------------*/
.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) .page-header {
  transform: translateY(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item__action {
  opacity: 1; }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(1) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(2) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(3) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(4) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(5) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .steps-item-container .steps-item:nth-child(6) {
  opacity: 1;
  transform: translateX(0); }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .round-white_box {
  opacity: 1; }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .cgv-info {
  transform: translateY(0);
  opacity: 1; }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent .summary-content {
  transform: translateY(0);
  opacity: 1; }

.easyentretien-checkout-steps.animate-in:not(.tunnel_step-payment) #maincontent #mobile-fixed_summary {
  transform: translateY(0);
  opacity: 1; }

.easyentretien-checkout-steps.animate-in:not(.checkout-index-index) #maincontent .round-white_box {
  transform: translateY(0);
  opacity: 1; }

@media only screen and (max-width: 1100px) {
  .easyentretien-checkout-steps .steps-main-content .modal-default-button {
    right: 10px;
    top: 10px;
    color: #8c8c8c;
    border-color: #8c8c8c; }
  .easyentretien-checkout-steps .steps-item-container:after {
    max-width: 600px; }
  .easyentretien-checkout-steps .products-type_list .list-choice {
    display: block;
    margin: 0 auto; }
  .easyentretien-checkout-steps .products-type_list .list-item {
    width: 100%; }
    .easyentretien-checkout-steps .products-type_list .list-item .item {
      margin: 0 0 10px; }
  .easyentretien-checkout-steps .summary-content-title {
    padding: 15px 15px 0; }
    .easyentretien-checkout-steps .summary-content-title .contract-title, .easyentretien-checkout-steps .summary-content-title .amount {
      margin: 5px 0 10px; }
  .easyentretien-checkout-steps .options-list {
    padding: 15px; }
  .easyentretien-checkout-steps .options-selected {
    padding: 20px 40px 10px 15px; }
    .easyentretien-checkout-steps .options-selected .remove-option {
      right: -30px; } }

@media only screen and (max-width: 800px) {
  .easyentretien-checkout-steps .cart-summary .desc span,
  .checkout-onepage-success .cart-summary .desc span {
    display: inline; }
  .easyentretien-checkout-steps .page-header .panel.wrapper {
    display: block; }
  .easyentretien-checkout-steps .page-header .panel.header {
    padding: 20px 10px 20px 50px; }
    .easyentretien-checkout-steps .page-header .panel.header .panel-left .reviews-widget,
    .easyentretien-checkout-steps .page-header .panel.header .panel-center {
      display: none; }
  .easyentretien-checkout-steps .options-list {
    padding: 15px 0; }
    .easyentretien-checkout-steps .options-list .list-item {
      width: 100%; }
  .easyentretien-checkout-steps .content-steps-eligibility .summary,
  .easyentretien-checkout-steps .content-steps-options .summary,
  .easyentretien-checkout-steps .content-steps-appointment .summary,
  .easyentretien-checkout-steps .checkout-content .summary {
    position: fixed;
    width: 100vw;
    height: fit-content;
    max-height: calc(100vh - 20px);
    bottom: 0;
    left: 0;
    overflow: auto;
    transform: translateY(100%);
    transition: transform 1s linear;
    z-index: 50; }
    .easyentretien-checkout-steps .content-steps-eligibility .summary.show,
    .easyentretien-checkout-steps .content-steps-options .summary.show,
    .easyentretien-checkout-steps .content-steps-appointment .summary.show,
    .easyentretien-checkout-steps .checkout-content .summary.show {
      animation: bounce-in-up .8s ease forwards; }
  .easyentretien-checkout-steps .summary-content {
    padding: 40px 30px 120px 30px;
    position: static;
    width: 100vw;
    max-width: none; }
  .easyentretien-checkout-steps .submit-cta_container {
    position: fixed;
    padding: 20px;
    width: 100vw;
    bottom: 0;
    left: 0;
    gap: 15px;
    border-top: 1px solid #dddddd;
    background-color: #ffffff;
    z-index: 100; }
    .easyentretien-checkout-steps .submit-cta_container .cross.toggle-recap {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid #dddddd;
      box-shadow: 0px 4px 13px 2px rgba(0, 0, 0, 0.33);
      background-color: #ffffff;
      background-image: url("../images/icons/uEA25-close_orange.svg");
      background-size: 35%;
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-50%, -50%) rotate(45deg);
      transform-origin: center;
      transition: transform .2s ease-in; }
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume,
    .easyentretien-checkout-steps .submit-cta_container .cta-next_step {
      margin: 10px 0 0 0; }
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume__line,
      .easyentretien-checkout-steps .submit-cta_container .cta-next_step__line {
        justify-content: space-between;
        align-items: center;
        margin: 2px 0;
        line-height: 1.4rem; }
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume .contract-title {
      font-size: 1.8rem;
      font-weight: 600; }
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume.show .contract-title .cross {
      transform: rotate(0deg); }
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume .contract-resume,
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume .firstYear-resume {
      margin: 5px 0;
      font-size: 1.2rem; }
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume .contract-resume__label .text__blue.text__bold,
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume .firstYear-resume__label .text__blue.text__bold {
        margin-left: 5px; }
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume .contract-resume .tax_label,
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume .firstYear-resume .tax_label {
        font-size: 1rem; }
    .easyentretien-checkout-steps .submit-cta_container .fixed-resume .amount {
      font-size: 1.8rem; }
      .easyentretien-checkout-steps .submit-cta_container .fixed-resume .amount span.label {
        font-size: 1rem; }
    .easyentretien-checkout-steps .submit-cta_container .cta-next_step .btn__main {
      width: 100%;
      font-size: 2rem;
      padding: 10px; }
    .easyentretien-checkout-steps .submit-cta_container.show .cross.toggle-recap {
      transform: translate(-50%, -50%) rotate(90deg);
      box-shadow: 0px -4px 13px 2px rgba(0, 0, 0, 0.33); }
    .easyentretien-checkout-steps .submit-cta_container.show .fixed-resume {
      display: none; }
  .easyentretien-checkout-steps .panel.header .panel-left {
    margin-left: 15px; } }

@media only screen and (max-width: 800px) and (max-width: 800px) {
  .easyentretien-checkout-steps .steps-item__action .btn__back {
    position: absolute;
    top: -60px;
    left: 5px;
    padding: 18px; }
    .easyentretien-checkout-steps .steps-item__action .btn__back::before {
      left: 18px; }
    .easyentretien-checkout-steps .steps-item__action .btn__back span {
      display: none; } }

@media only screen and (max-width: 800px) {
  .easyentretien-checkout-steps .steps-item {
    margin: 0 10px;
    white-space: nowrap; }
    .easyentretien-checkout-steps .steps-item .step-label {
      display: none; }
    .easyentretien-checkout-steps .steps-item.active {
      display: block;
      margin: 0; }
      .easyentretien-checkout-steps .steps-item.active .stepItem-container {
        gap: 10px; }
      .easyentretien-checkout-steps .steps-item.active .step-label {
        display: block;
        font-size: 1.6rem; }
    .easyentretien-checkout-steps .steps-item.active .step-answers {
      display: none; }
    .easyentretien-checkout-steps .steps-item.passed {
      margin: 0 10px; }
      .easyentretien-checkout-steps .steps-item.passed .stepItem__infos {
        display: none; }
  .easyentretien-checkout-steps .steps-item-container {
    display: flex;
    padding: 25px 5px;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #eeeeee; }
  .easyentretien-checkout-steps .block-products-list.grid .push-product-content {
    position: relative;
    max-height: 100vh;
    overflow-y: scroll; }
    .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header {
      padding-top: 25px; }
      .easyentretien-checkout-steps .block-products-list.grid .push-product-content .modal-header span {
        top: 0;
        right: 10px; }
  .easyentretien-checkout-steps .steps-main-content {
    margin-bottom: 50px; }
    .easyentretien-checkout-steps .steps-main-content .h1-title {
      font-size: 2.2rem;
      margin: 0; }
    .easyentretien-checkout-steps .steps-main-content .h2-title {
      margin-top: 35px;
      font-size: 1.8rem; }
    .easyentretien-checkout-steps .steps-main-content .list-item {
      margin: 25px 0; }
    .easyentretien-checkout-steps .steps-main-content .products-type_list .list-item,
    .easyentretien-checkout-steps .steps-main-content .equipment_age-list .list-item {
      display: flex;
      align-items: center;
      width: 100%; }
  .easyentretien-checkout-steps .cgv-info {
    padding-left: 25px;
    margin-bottom: 90px; }
  .easyentretien-checkout-steps.checkout-index-index .checkout-content {
    display: block; }
    .easyentretien-checkout-steps.checkout-index-index .checkout-content .steps-item-container .steps-item:last-child {
      display: block;
      margin: 0 auto; }
  .easyentretien-checkout-steps .footer-checkout .logo {
    display: none; }
  .easyentretien-checkout-steps .footer-checkout .panel-right {
    float: none;
    text-align: center; }
    .easyentretien-checkout-steps .footer-checkout .panel-right .link {
      display: block;
      margin: 5px 0 0; }
  .easyentretien-checkout-steps .appointment-content {
    padding: 0; }
  .checkout-onepage-success .summary-content {
    margin: 20px 0 0; }
  #checkout-step-shipping .form-login {
    margin-top: 70px; } }

@media only screen and (max-width: 700px) {
  .easyentretien-checkout-steps .options-content .modal-content {
    width: 60%; }
  .easyentretien-checkout-steps .options-content .modal-picture {
    padding: 0 20px 0 0;
    width: 40%; } }

@media only screen and (max-width: 600px) {
  .easyentretien-checkout-steps .page-header .panel.wrapper {
    display: block; }
  .easyentretien-checkout-steps .page-header .panel.header .panel-left .reviews-widget,
  .easyentretien-checkout-steps .page-header .panel.header .panel-center {
    display: none; }
  .easyentretien-checkout-steps .options-content .modal-content {
    float: none;
    width: 100%;
    margin: 0; }
  .easyentretien-checkout-steps .options-content .modal-body {
    overflow: visible; }
  .easyentretien-checkout-steps .options-content .modal-picture {
    position: static;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%); }
    .easyentretien-checkout-steps .options-content .modal-picture img {
      width: 100%;
      max-width: 300px; }
  .easyentretien-checkout-steps .options-content .modal-bg {
    padding: 40px 20px;
    overflow: auto; }
    .easyentretien-checkout-steps .options-content .modal-bg:before {
      display: none; }
  .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-prev {
    left: 5%; }
  .easyentretien-checkout-steps .content-steps-appointment .ui-datepicker-next {
    right: 5%; }
  .easyentretien-checkout-steps .content-steps-appointment .time-slot .morning-content, .easyentretien-checkout-steps .content-steps-appointment .time-slot .afternoon-content {
    float: none;
    width: 100%;
    padding: 0; }
  .easyentretien-checkout-steps .content-steps-appointment .time-slot .morning-content {
    margin: 0 0 20px; }
  .easyentretien-checkout-steps .content-steps-appointment .time-slot-confirm {
    margin: 20px auto;
    padding: 20px 0; }
  .easyentretien-checkout-steps .back-content span {
    display: block; } }

@media only screen and (max-width: 600px) {
  .easyentretien-checkout-steps .page-header .infos .icon {
    margin-left: -3px; }
  .easyentretien-checkout-steps .page-header .infos .number a {
    width: 39px; }
    .easyentretien-checkout-steps .page-header .infos .number a span {
      display: none; } }

.account .columns {
  margin: 60px auto;
  max-width: 1440px;
  padding: 0 20px; }

.account .ee-banner-text {
  max-width: 660px;
  margin: 0 auto; }

.account .h3-title {
  border-bottom: 1px solid #8c8c8c;
  color: #373737;
  padding: 10px 0;
  text-transform: uppercase; }

.account .columns {
  font-family: "Montserrat", sans-serif; }

.account .sidebar {
  float: left;
  padding: 0 10px;
  width: 20%; }
  .account .sidebar .block.account-nav {
    background: #eeeeee;
    padding: 40px 0; }
  .account .sidebar .content {
    max-width: 165px;
    margin: 0 auto; }
    .account .sidebar .content .nav {
      padding: 0; }
    .account .sidebar .content .nav.item {
      color: #373737;
      cursor: pointer;
      margin: 0 0 20px; }
      .account .sidebar .content .nav.item:last-child {
        margin: 0; }
      .account .sidebar .content .nav.item:after {
        content: "";
        background: #ef604e;
        display: block;
        height: 4px;
        margin: 10px 0 0;
        width: 0px;
        border-radius: 5px;
        background-color: #ef604e;
        background-image: -webkit-linear-gradient(left, #ef604e, #f8b166);
        background-image: linear-gradient(to right,#ef604e, #f8b166);
        -webkit-transition: width 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out;
        transition: width 0.5s ease-in-out; }
      .account .sidebar .content .nav.item:hover:after {
        width: 45%; }
      .account .sidebar .content .nav.item.current:after {
        width: 45%;
        background-color: #ef604e;
        background-image: -webkit-linear-gradient(left, #ef604e, #f8b166);
        background-image: linear-gradient(to right,#ef604e, #f8b166); }
      .account .sidebar .content .nav.item.current strong {
        font-weight: normal; }
      .account .sidebar .content .nav.item a {
        color: #373737; }

.account .column.main {
  float: left;
  padding: 0 10px;
  width: 80%; }

.account .action.edit {
  border-bottom: 1px solid transparent;
  color: #ef604e;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; }
  .account .action.edit:hover {
    border-bottom: 1px solid #ef604e; }

.account .name-prefix {
  font-size: 1.4rem;
  text-transform: uppercase; }
  .account .name-prefix .required {
    margin-right: 20px;
    position: relative;
    top: 2px; }
  .account .name-prefix .control {
    display: inline-block; }

.account address {
  font-style: normal; }

.account .wide > div,
.account .widget-name {
  margin: 0 0 15px; }
  .account .wide > div:last-child,
  .account .widget-name:last-child {
    margin: 0; }

.account .back-link {
  float: left; }
  .account .back-link + .button {
    float: right; }

.account .block + .block {
  margin-top: 30px; }
  .account .block + .block + .actions-toolbar .primary {
    margin-bottom: 20px; }

.account .block-content .box + .box {
  margin-top: 20px; }

.account .block-content .box-content {
  margin-bottom: 10px; }

.customer-account-create .page-main,
.block-new-customer .page-main {
  margin: 50px auto;
  max-width: 1480px;
  min-height: 500px; }

.customer-account-create .block-new-customer,
.block-new-customer .block-new-customer {
  float: none;
  margin: 0 auto; }

.customer-account-create .create-account-button,
.block-new-customer .create-account-button {
  margin: 40px 0 0; }

.customer-account-create .title-customer-create,
.block-new-customer .title-customer-create {
  font-size: 1.8rem;
  margin: 0 0 20px 0; }

.customer-account-create form,
.block-new-customer form {
  margin: 50px 0; }
  .customer-account-create form ul li,
  .block-new-customer form ul li {
    margin: 0; }

.customer-account-create .input-box,
.block-new-customer .input-box {
  margin-bottom: 15px; }

.customer-account-create .buttons-set,
.block-new-customer .buttons-set {
  margin-top: 50px; }

.customer-account-create .name-prefix,
.block-new-customer .name-prefix {
  font-size: 1.4rem;
  text-transform: uppercase; }
  .customer-account-create .name-prefix .required,
  .block-new-customer .name-prefix .required {
    margin-right: 20px;
    position: relative;
    top: 2px; }

.customer-account-login .ee-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px; }

.customer-account-login .ee-banner-content {
  height: 650px; }

.customer-account-login .ee-infos-link .packages-link,
.customer-account-login .ee-infos-link .packages {
  display: none; }

.customer-account-login .accountForm-transition {
  transition: all .3s ease;
  overflow: hidden; }

.customer-account-login .accountForm-enter, .customer-account-login .accountForm-leave {
  height: 0;
  opacity: 0; }

.customer-account-login .ee-banner-text {
  max-width: 660px;
  margin: 0 auto; }

.customer-account-login .h3-title {
  border-bottom: 1px solid #8c8c8c;
  color: #373737;
  padding: 10px 0;
  text-transform: uppercase; }

.customer-account-login .login-container {
  max-width: 1140px;
  margin: 50px auto; }
  .customer-account-login .login-container .block {
    padding: 0 20px; }

.customer-account-login .block-customer-login {
  margin: 0 0 60px; }
  .customer-account-login .block-customer-login .action.remind {
    text-decoration: underline; }

.customer-account-login .registered-users {
  padding: 0 10% 0 0;
  border-right: 1px solid #8c8c8c; }
  .customer-account-login .registered-users .buttons-set {
    text-align: right; }
  .customer-account-login .registered-users .link {
    display: block;
    font-size: 1.3rem;
    text-transform: uppercase;
    text-decoration: underline; }
  .customer-account-login .registered-users button {
    display: inline-block;
    margin: 15px 0 0; }

.customer-account-login .new-users {
  padding: 0 0 0 10%;
  font-size: 1.4rem; }
  .customer-account-login .new-users button {
    width: 100%; }

.customer-account-login .footer-top-col:has(.account_conainer) {
  display: none; }

.customer-account-createpassword .page-main,
.customer-account-forgotpassword .page-main {
  margin: 50px auto;
  max-width: 580px;
  min-height: 500px; }
  .customer-account-createpassword .page-main .button,
  .customer-account-forgotpassword .page-main .button {
    width: 100%; }

.customer-account-createpassword .primary,
.customer-account-forgotpassword .primary {
  margin: 0 0 20px; }

.customer-account-createpassword .back,
.customer-account-forgotpassword .back {
  text-decoration: underline; }

.block-dashboard-order {
  margin: 0 0 60px; }
  .block-dashboard-order .data.table td.status {
    color: #ef604e; }
  .block-dashboard-order .data.table .toolbar-number {
    display: none; }

.block-dashboard-info, .block-dashboard-addresses {
  margin: 0 0 60px; }
  .block-dashboard-info .box-content, .block-dashboard-addresses .box-content {
    margin: 20px 0; }

.box-newsletter .box-content {
  font-family: "Open Sans", sans-serif; }

.box-newsletter,
.box-shipping-address {
  padding: 0 35px 0 0; }

.box-information,
.box-billing-address {
  padding: 0; }

.block-account {
  background: #eeeeee;
  padding: 50px 50px 42px 20px; }
  .block-account li {
    line-height: 30px; }
  .block-account .block-title {
    margin: 0 0 30px 20px; }
  .block-account .block-content {
    position: relative; }
    .block-account .block-content li {
      padding: 0 0 0 20px;
      border-left: 5px solid transparent;
      font-size: 1.4rem;
      font-weight: 500; }
      .block-account .block-content li.current {
        border-left: 5px solid #66cc9b; }
      .block-account .block-content li.last {
        margin-top: 30px; }

.box-account .button {
  max-width: 300px; }

.dashboard .item-informations,
.my-account-container .item-informations {
  font: 500 1.4rem;
  margin: 0 0 8px; }
  .dashboard .item-informations span,
  .my-account-container .item-informations span {
    color: #a6a6a6; }

.dashboard .box-content,
.my-account-container .box-content {
  line-height: 20px; }

.customer-address-index .block-addresses-default .block-title {
  display: none; }

.customer-address-index .actions-toolbar {
  max-width: 250px; }

.customer-address-form .form-address-edit {
  font-size: 0; }
  .customer-address-form .form-address-edit > .fieldset {
    font-size: 1.3rem;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 20px; }
    .customer-address-form .form-address-edit > .fieldset:first-child {
      padding: 0 20px 0 0; }
    .customer-address-form .form-address-edit > .fieldset select {
      width: 100%; }
  .customer-address-form .form-address-edit .fieldset-fullname {
    margin: 0 0 20px; }

.customer-address-form .form-list {
  margin: 0 0 50px; }

.customer-address-form .fields .field {
  margin: 0 0 15px 0; }
  .customer-address-form .fields .field:last-child {
    margin: 0; }

.customer-address-form .buttons-set .button {
  margin: 20px auto 0; }

.customer-address-form .back-link {
  position: absolute;
  top: 0;
  right: 0; }
  .customer-address-form .back-link .button {
    display: inline;
    border: none; }
    .customer-address-form .back-link .button:hover {
      background: none;
      color: #424242; }

.sales-order-view .h3-title span {
  color: #ef604e;
  font-weight: bold; }

.sales-order-view .button {
  width: 235px;
  margin: 40px 0 0; }

.sales-order-view .order-details-items {
  margin: 0 0 50px; }
  .sales-order-view .order-details-items .grand_total {
    font-size: 1.8rem; }
    .sales-order-view .order-details-items .grand_total th {
      color: #000000; }

.sales-order-view .block-order-details-view {
  font-size: 0; }
  .sales-order-view .block-order-details-view .payment-method {
    margin: 0; }
  .sales-order-view .block-order-details-view .box-title {
    display: block;
    font-size: 13px;
    margin: 0 0 5px;
    text-transform: uppercase; }

.sales-order-view .box-order-shipping-address,
.sales-order-view .box-order-shipping-method,
.sales-order-view .box-order-billing-address,
.sales-order-view .box-order-billing-method {
  display: inline-block;
  vertical-align: top;
  font-size: 1.4rem;
  padding: 0 20px 0 0;
  width: 25%; }
  .sales-order-view .box-order-shipping-address:last-child,
  .sales-order-view .box-order-shipping-method:last-child,
  .sales-order-view .box-order-billing-address:last-child,
  .sales-order-view .box-order-billing-method:last-child {
    padding: 0; }

.customer-account-edit .form-list li {
  margin: 0 0 30px; }

.customer-account-edit .form-edit-account > .fieldset > .field {
  width: 50%; }
  .customer-account-edit .form-edit-account > .fieldset > .field select {
    width: 100%; }

.customer-account-logoutsuccess .col-main {
  padding: 100px 50px;
  text-align: center; }

.customer-account-logoutsuccess .logout-content {
  font-size: 1.6rem; }

@media (min-width: 1200px) {
  .customer-account .col-main {
    width: 66.66667%; }
  .customer-account .sidebar,
  .customer-account .col-main {
    left: 8.33333%; } }

@media only screen and (max-width: 1100px) {
  .account .column.main {
    width: 70%; }
  .account .sidebar {
    width: 30%; }
  .account .sidebar-menu {
    cursor: pointer;
    display: block;
    padding: 15px 20px;
    text-transform: uppercase; }
    .account .sidebar-menu:after {
      content: "";
      top: 15px;
      right: 15px;
      height: 0;
      width: 0;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out; }
    .account .sidebar-menu.active:after {
      height: 0;
      width: 0; }
  .sales-order-view .box-order-shipping-address,
  .sales-order-view .box-order-billing-address {
    margin: 0 0 50px;
    width: 50%; }
  .sales-order-view .box-order-shipping-method,
  .sales-order-view .box-order-billing-method {
    padding: 0;
    width: 50%; } }

@media only screen and (max-width: 800px) {
  .account .column.main {
    float: none;
    padding: 0;
    width: 100%; }
  .account .sidebar {
    float: none;
    margin: 0 0 50px;
    padding: 0;
    width: 100%; }
    .account .sidebar .block.account-nav {
      text-align: center; }
    .account .sidebar .content .nav.item.current:after {
      width: 100%; }
    .account .sidebar .content .nav.item:hover:after {
      width: 100%; }
  .account .block-dashboard-addresses,
  .account .block-dashboard-info,
  .account .block-dashboard-order,
  .account .box-newsletter,
  .account .box-shipping-address {
    margin: 0 0 40px; }
  .account .box-newsletter,
  .account .box-shipping-address {
    padding: 0; }
  .account .action.edit {
    padding: 0 10px; }
    .account .action.edit:first-child {
      padding-left: 0; }
  .account-login .registered-users,
  .account-login .new-users {
    border: none;
    padding: 0;
    margin: 0 0 40px;
    width: 100%; }
    .account-login .registered-users .button,
    .account-login .new-users .button {
      width: 100%; }
  .orders-history {
    overflow: auto; }
  .sales-order-view .order-items {
    overflow: auto; }
  .sales-order-view .button {
    margin: 40px auto 0; }
  .customer-account-login .ee-banner {
    border-radius: 24px; } }

@media only screen and (max-width: 600px) {
  .sales-order-view .box-order-shipping-address,
  .sales-order-view .box-order-billing-address,
  .sales-order-view .box-order-shipping-method,
  .sales-order-view .box-order-billing-method {
    margin: 0 0 50px;
    padding: 0;
    width: 100%; } }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-loading .slick-list {
    background: #ffffff url("./ajax-loader.gif") center center no-repeat; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  display: flex;
  position: relative;
  left: 0;
  top: 0;
  zoom: 1; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px; }
  .slick-slide img {
    display: block;
    margin: auto; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 50;
  opacity: 0;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  .slick-prev:focus,
  .slick-next:focus {
    outline: none; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

/* Show arrow when the cursor is over slider*/
.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  opacity: 1; }

.slick-prev:before, .slick-next:before {
  font-family: "pacifico";
  font-size: 20px;
  line-height: 1;
  color: black;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 20px; }
  .slick-prev:before {
    content: "←"; }

.slick-next {
  right: 20px; }
  .slick-next:before {
    content: "→"; }

.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0px;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 4px;
    width: 45px;
    margin: 0px 5px;
    padding: 0px;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 45px;
      width: 4px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 45px;
        height: 4px;
        font-family: "pacifico";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #000000; }
    .slick-dots li.slick-active button:before {
      opacity: 0.75; }

.slick-slider {
  margin-bottom: 50px; }
  .slick-slider .slick-slide img {
    margin: auto; }
  .slick-slider .slick-dots {
    z-index: 500; }
    .slick-slider .slick-dots li {
      width: auto; }
      .slick-slider .slick-dots li button:before {
        background-color: #dddddd;
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 100%; }
      .slick-slider .slick-dots li.slick-active button:before {
        background-color: #ef604e; }
