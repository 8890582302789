// =============================================
// Breadcrumbs
// =============================================

// Content
.breadcrumbs {
    padding: 15px 0;
    border-top: 1px solid $c-black;
    li {
        display:        inline-block;
        vertical-align: top;
        &:before{
            content:">"
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        &:last-child{
            font-weight: 600;
        }
    }
}
@include respond-to(medium) {

}
@include respond-to(small) {
    .breadcrumbs {
        display: none;
    }
}