.proxi_Wheel-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: .5);
    z-index: 99;
    pointer-events: none;
    
    svg {
        width: 80px;
        height: 80px;
        animation: loaderProxiWheel 3s infinite;
        transform: rotate(30deg);
        fill: #EC3D2D;
    }
}