// =============================================
// StoreLocator
// =============================================
.sl-top-banner {
    height:      300px;
    line-height: 300px;
    text-align:  center;
    color:       $c-white;
    font-size:   2.5rem;
    font-weight: 500;
    background:  url(../images/banners/sl-banner.jpg) 50% 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    margin:      0 0 20px 0;
}
// Sidebar Store locator
.sl-top-search {
    padding:    25px;
    background: $c-grey-light;
}
.sl-title {
    font-size:   1.6rem;
    font-weight: 600;
    margin:      0 0 15px 0;
}
.sl-input-box {
    position: relative;
    padding:  0 110px 0 0;
    .button {
        width:    110px;
        position: absolute;
        right:    0;
        top:      0;
    }
}
.sl-input-text { background: $c-white; }
.sl-country-selector {
    height:     0;
    visibility: hidden;
    position:   absolute;
    z-index:    -1;
}
.sl-bt-geoloc {
    display:     inline-block;
    position:    relative;
    font-weight: 600;
    margin:      15px 0 0 0;
    &:after {
        content:    "";
        display:    block;
        position:   absolute;
        width:      100%;
        height:     1px;
        background: $c-black;
        margin:     2px 0 0 0;
        @include transition(all 250ms ease);
    }
    &:hover {
        color: $c-black;
        &:after { height: 3px; }
    }
}
.sl-counter {
    padding:     15px 0;
    font-weight: 500;
}
.sl-list {
    max-height: 560px;
    overflow-y: auto;
    li {
        padding:       15px 25px 15px 60px;
        border-bottom: 1px solid $c-grey-light;
        position:      relative;
        &:before {
            font-family:             "icons";
            content:                 "\a003";
            display:                 inline-block;
            vertical-align:          middle;
            line-height:             1;
            font-weight:             normal;
            font-style:              normal;
            speak:                   none;
            text-decoration:         inherit;
            text-transform:          none;
            text-rendering:          auto;
            -webkit-font-smoothing:  antialiased;
            -moz-osx-font-smoothing: grayscale;
            position:                absolute;
            left:                    22px;
            top:                     17px;
            font-size:               2.4rem;
        }
    }
}
.sl-item-name {
    text-transform: uppercase;
    font-weight:    600;
    font-size:      1.6rem;
}
.sl-item-address {
    margin:      5px 0 15px 0;
    line-height: 18px;
    padding:     0 115px 0 0;
}
.title-open-hours {
    font-weight: 600;
    font-size:   1.3rem;
}
.sl-bt-details {
    position:  absolute;
    top:       20px;
    right:     25px;
    max-width: 110px;
}
.sl-bt-fav { margin: 15px 0 0 0; }
// Map
.sl-map-container {
    width:  100%;
    height: 750px;
}
//
// StoreLocator view Page
//
.sl-content-view {
    max-width: 1320px;
    margin:    0 auto;
}
.sl-view-top { margin: 0 0 45px 0; }
.sl-view-bt-back {
    display:   inline-block;
    position:  relative;
    font-size: 1.6rem;
    padding:   0 0 0 25px;
    margin:    0 0 25px 0;
    .icon {
        position: absolute;
        top:      3px;
        left:     0;
        @include transition(all 200ms ease);
    }
    &:hover {
        color:   $c-black;
        padding: 0 0 0 30px;
        .icon { left: -7px; }
    }
}
.sl-view-title {
    font-size:   3.2rem;
    font-weight: 500;
    margin:      0 0 10px 0;
}
.sl-view-address {
    font-size:   1.6rem;
    font-weight: 500;
    margin:      0 0 10px 0;
}
.sl-view-desc {
    font-size:   1.4rem;
    line-height: 18px;
    color:       $c-grey;
}
// Blocks store locator view
.sl-view-blocks {
    display: table;
    width:   100%;
    margin:  0 0 45px 0;
}
.sl-view-block {
    display:      table-cell;
    width:        50%;
    border-right: 1px solid $c-grey-light;
    padding:      0 15px 0 0;
    &:last-child {
        padding: 0 15px 0 25px;
        border:  none;
    }
}
.sl-view-block-title {
    position: relative;
    padding:  0 0 0 40px;
    .icon {
        font-size: 2.8rem;
        position:  absolute;
        left:      0;
        top:       -5px;
    }
}
.sl-view-list-open {
    padding:     0 0 0 40px;
    font-size:   1.4rem;
    color:       $c-grey;
    line-height: 20px;
    span {
        color:       $c-black;
        font-weight: 500;
        display:     inline-block;
        width:       95px;
    }
}
.sl-view-sub-block {
    margin: 0 0 25px 0;
    &:last-child { margin: 0; }
}
.sl-view-special-open {
    padding:     0 0 0 40px;
    line-height: 20px;
    font-size:   1.3rem;
}
.sl-view-tel { padding: 0 0 0 40px; }
//
// Responsive
//
@include respond-to(medium) {
    .sl-list {
        max-height: 390px;
        margin:     0 0 20px 0;
    }
    .sl-map-container { height: 500px; }
    .sl-view-block {
        display:       block;
        width:         100%;
        border-right:  none;
        border-bottom: 1px solid $c-grey-light;
        padding:       0 0 20px 0;
        margin:        0 0 30px 0;
        &:last-child { padding: 0 15px 0 0; }
    }
    .sl-view-push-market {
        text-align: center;
        margin:     0 0 50px;
    }
    .picture-content {
        text-align: center;
        img { margin: 0 auto; }
    }
}
@include respond-to(small) {
    .sl-top-search { padding: 20px 15px; }
    .sl-list {
        max-height: 460px;
        li {
            padding: 50px 0 15px;
            &:before { left: 10px; }
        }
    }
    .sl-bt-details { right: 0; }
    .sl-map-container { height: 300px; }
}
